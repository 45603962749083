import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Field, useFormikContext } from "formik";
import { Duration } from "luxon";

import { formatters, useTranslation } from "@equiem/localisation-eq1";
import { Button, Form, Table, Tag, Text, useConfirmer, useTheme } from "@equiem/react-admin-ui";
import { RiCloseLine, RiEditLine } from "@equiem/react-admin-ui/icons";

import { BookableResourceCancellationRateType as RateType } from "../../../../../generated/gateway-client";
import { useCurrencyCode } from "../../../../../hooks/useCurrency";
import { useSiteAudiencesCompanyOptions } from "../../../../../hooks/useSiteAudiencesCompanyOptions";
import type { CancellationPolicy } from "../../../../../lib/cancellationPolicy";
import type { CancellationRate } from "../../../../../lib/cancellationRate";
import type { FormValues } from "../../../../../lib/formValidation";
import { ResourceDivider } from "../ResourceDivider";

import { ResourceCreateAndEditFormCancellationPolicyModal } from "./ResourceCreateAndEditFormCancellationPolicyModal";
import type { CompanyEntry } from "./ResourceCreateAndEditFormCancellationRateModal";
import { ResourceCreateAndEditFormCancellationRateModal } from "./ResourceCreateAndEditFormCancellationRateModal";

export type CompanyOption = {
  value: string;
  label: string;
};

const MAX_POLICIES_NUMBER = 10;
const MAX_RATES_NUMBER = 10;

const MINUTES_IN_HOUR = 60;
const MINUTES_IN_DAY = MINUTES_IN_HOUR * 24;

export const ResourceCreateAndEditFormCancellationPermissions: React.FC = () => {
  const { i18n, t } = useTranslation();
  const fm = useFormikContext<FormValues>();
  const { breakpoints, colors, spacers } = useTheme(true);
  const currency = useCurrencyCode(fm.values.building);

  const [showPolicyModal, setShowPolicyModal] = useState(false);
  const [policyModalIndex, setPolicyModalIndex] = useState<number | null>(null);
  const [showRateModal, setShowRateModal] = useState(false);
  const [rateModalIndex, setRateModalIndex] = useState<number | null>(null);

  const { companyOptions, companiesLoading } = useSiteAudiencesCompanyOptions(fm.values.site, fm.values.siteAudiences);

  const { withConfirmation } = useConfirmer();

  const addEditBookingPolicy = useCallback(
    (editBookingPolicy: CancellationPolicy) => {
      fm.setFieldValue("editBookingPolicies", [...fm.values.editBookingPolicies, editBookingPolicy]).catch(
        console.error,
      );

      setShowPolicyModal(false);
    },
    [fm],
  );

  const updateEditBookingPolicy = useCallback(
    (editBookingPolicy: CancellationPolicy, index: number) => {
      const newEditBookingPolicies = [...fm.values.editBookingPolicies];
      newEditBookingPolicies[index] = editBookingPolicy;
      fm.setFieldValue("editBookingPolicies", newEditBookingPolicies).catch(console.error);

      setShowPolicyModal(false);
      setPolicyModalIndex(null);
    },
    [fm],
  );

  const removeEditBookingPolicy = useCallback(
    (index: number) => {
      const newEditBookingPolicies = [...fm.values.editBookingPolicies];
      newEditBookingPolicies.splice(index, 1);
      fm.setFieldValue("editBookingPolicies", newEditBookingPolicies).catch(console.error);
    },
    [fm],
  );

  const addPaymentRateCancellation = useCallback(
    (paymentRateCancellation: CancellationRate) => {
      fm.setFieldValue("paymentRateCancellation", [
        ...fm.values.paymentRateCancellation,
        paymentRateCancellation,
      ]).catch(console.error);

      setShowRateModal(false);
    },
    [fm],
  );

  const updatePaymentRateCancellation = useCallback(
    (paymentRateCancellation: CancellationRate, index: number) => {
      const newPaymentRateCancellation = [...fm.values.paymentRateCancellation];
      newPaymentRateCancellation[index] = paymentRateCancellation;
      fm.setFieldValue("paymentRateCancellation", newPaymentRateCancellation).catch(console.error);

      setShowRateModal(false);
      setRateModalIndex(null);
    },
    [fm],
  );

  const removePaymentRateCancellation = useCallback(
    (index: number) => {
      const newPaymentRateCancellation = [...fm.values.paymentRateCancellation];
      newPaymentRateCancellation.splice(index, 1);
      fm.setFieldValue("paymentRateCancellation", newPaymentRateCancellation).catch(console.error);
    },
    [fm],
  );

  useEffect(() => {
    if (!companiesLoading) {
      const companyOptionValues = new Set(companyOptions.map((opt) => opt.value));
      const filteredEditBookingPolicies = fm.values.editBookingPolicies.map((policy) => ({
        ...policy,
        companies: policy.companies.filter((company) => companyOptionValues.has(company)),
      }));
      const editBookingPoliciesChanged =
        JSON.stringify(filteredEditBookingPolicies) !== JSON.stringify(fm.values.editBookingPolicies);

      const filteredPaymentRateCancellation = fm.values.paymentRateCancellation.map((rate) => ({
        ...rate,
        companies: rate.companies.filter((company) => companyOptionValues.has(company)),
      }));
      const paymentRateCancellationChanged =
        JSON.stringify(filteredPaymentRateCancellation) !== JSON.stringify(fm.values.paymentRateCancellation);

      if (editBookingPoliciesChanged || paymentRateCancellationChanged) {
        const timer = setTimeout(() => {
          if (editBookingPoliciesChanged) {
            fm.setFieldValue("editBookingPolicies", filteredEditBookingPolicies).catch(console.error);
          }
          if (paymentRateCancellationChanged) {
            fm.setFieldValue("paymentRateCancellation", filteredPaymentRateCancellation).catch(console.error);
          }
        }, 0);

        return () => clearTimeout(timer);
      }
    }
    return () => undefined;
  }, [companiesLoading, companyOptions, fm]);

  const defaultPolicyUsed = useMemo(
    () => fm.values.editBookingPolicies.some((item) => item.companies.length === 0),
    [fm.values.editBookingPolicies],
  );

  const filteredCompanyOptions = useMemo(
    () =>
      companyOptions.filter(
        (opt) => !fm.values.editBookingPolicies.flatMap((item) => item.companies).includes(opt.value),
      ),
    [companyOptions, fm.values.editBookingPolicies],
  );

  const mergeCompaniesByNoticePeriod = (arr: CompanyEntry[]): Record<number, Set<string | null>> => {
    const grouped: Record<number, Set<string | null>> = arr.reduce<Record<number, Set<string | null>>>((acc, item) => {
      const { minutesBefore, companies } = item;

      if (minutesBefore != null) {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (acc[minutesBefore] == null) {
          acc[minutesBefore] = new Set();
        }

        if (companies.length === 0) {
          acc[minutesBefore].add(null);
        } else {
          companies.forEach((company) => acc[minutesBefore].add(company));
        }
      }

      return acc;
    }, {});

    return grouped;
  };

  return (
    <>
      <Form.Group error={fm.errors.userCanEditBookings}>
        <Field
          name="userCanEditBookings"
          label={t("bookings.resources.usersCanEditCancelTheBooking")}
          description={t("bookings.resources.usersCanEditCancelTheBookingHint")}
          as={Form.HeroCheckboxOld}
          disabled={fm.isSubmitting}
          value={fm.values.userCanEditBookings}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            fm.setFieldValue("userCanEditBookings", e.target.checked).catch(console.error);
          }}
        />
      </Form.Group>

      {fm.values.userCanEditBookings != null && fm.values.userCanEditBookings && (
        <>
          {fm.values.editBookingPolicies.length > 0 && (
            <Form.Group label={t("bookings.resources.cancellationPolicies")}>
              <div className="cancellation-permissions-container">
                <Table.Table className="w-100">
                  <thead>
                    <tr>
                      <Table.Header
                        label={t("bookings.resources.noticePeriod")}
                        style={{ minWidth: "150px", whiteSpace: "nowrap" }}
                      />
                      <Table.Header label={t("bookings.resources.appliesToLabel")} />
                      <Table.Header label="" style={{ width: "1px", whiteSpace: "nowrap" }} />
                      <Table.Header label="" style={{ width: "1px", whiteSpace: "nowrap" }} />
                    </tr>
                  </thead>
                  <tbody>
                    {fm.values.editBookingPolicies.map((editBookingPolicy, i) => (
                      <tr key={i}>
                        <td style={{ minWidth: "150px", whiteSpace: "nowrap" }}>
                          <Text variant="text" component="span" size="small">
                            {Number(editBookingPolicy.noticePeriodInMinutes) === 0
                              ? "0"
                              : Number(editBookingPolicy.noticePeriodInMinutes) < MINUTES_IN_DAY
                              ? formatters.durationshort(Number(editBookingPolicy.noticePeriodInMinutes), i18n.language)
                              : Duration.fromObject(
                                  { minutes: Number(editBookingPolicy.noticePeriodInMinutes) },
                                  { locale: i18n.language },
                                )
                                  .shiftTo("days")
                                  .toHuman()}
                          </Text>
                        </td>
                        <td>
                          {!companiesLoading && (
                            <div className="companies-tags">
                              {editBookingPolicy.companies.length === 0
                                ? t("bookings.resources.assignmentDefaultLabel")
                                : editBookingPolicy.companies.map((c) => (
                                    <Tag key={c}>{companyOptions.find((opt) => opt.value === c)?.label}</Tag>
                                  ))}
                            </div>
                          )}
                        </td>
                        <td style={{ width: "1px" }}>
                          <RiEditLine
                            size="16"
                            color={colors.primary}
                            cursor="pointer"
                            onClick={() => {
                              setPolicyModalIndex(i);
                              setShowPolicyModal(true);
                            }}
                          />
                        </td>
                        <td style={{ width: "1px" }}>
                          <RiCloseLine
                            size="16"
                            color={colors.danger}
                            cursor="pointer"
                            onClick={() => {
                              withConfirmation({
                                title: t("bookings.resources.deletePolicyTitle"),
                                message: t("bookings.resources.deletePolicyMessage"),
                                confirmButtonText: t("bookings.resources.deletePolicyConfirm"),
                                cancelButtonText: t("common.cancelNo"),
                                confirmButtonVariant: "danger",
                                size: "md",
                                onConfirm: () => removeEditBookingPolicy(i),
                              })();
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table.Table>
              </div>
            </Form.Group>
          )}

          {fm.values.editBookingPolicies.length < MAX_POLICIES_NUMBER && (
            <Button
              type="button"
              variant="secondary"
              size="lg"
              style={{ width: "100%" }}
              onClick={() => {
                setPolicyModalIndex(null);
                setShowPolicyModal(true);
              }}
            >
              {t("bookings.resources.addCancellationPolicy")}
            </Button>
          )}

          <ResourceDivider />

          {fm.values.paymentRateCancellation.length > 0 && (
            <Form.Group label={t("bookings.resources.cancellationRates")}>
              <div className="cancellation-permissions-container">
                <Table.Table className="w-100">
                  <thead>
                    <tr>
                      <Table.Header label={t("bookings.resources.rateType")} />
                      <Table.Header label={t("common.amount")} />
                      <Table.Header label={t("bookings.resources.noticePeriod")} />
                      <Table.Header label={t("bookings.resources.appliesToLabel")} />
                      <Table.Header label="" style={{ width: "1px", whiteSpace: "nowrap" }} />
                      <Table.Header label="" style={{ width: "1px", whiteSpace: "nowrap" }} />
                    </tr>
                  </thead>
                  <tbody>
                    {fm.values.paymentRateCancellation.map((paymentRateCancellation, i) => (
                      <tr key={i}>
                        <td>
                          <Text variant="text" component="span" size="small">
                            {paymentRateCancellation.type === RateType.FixedRate
                              ? t("bookings.resources.fixedRate")
                              : t("common.percentage")}
                          </Text>
                        </td>
                        <td>
                          <Text variant="text" component="span" size="small">
                            {paymentRateCancellation.type === RateType.FixedRate
                              ? formatters.currency(Number(paymentRateCancellation.amount), i18n.language, {
                                  currency,
                                })
                              : formatters.percentage(Number(paymentRateCancellation.amount) / 100, i18n.language)}
                          </Text>
                        </td>
                        <td>
                          <Text variant="text" component="span" size="small">
                            {Number(paymentRateCancellation.minutesBefore) < MINUTES_IN_DAY
                              ? formatters.durationshort(Number(paymentRateCancellation.minutesBefore), i18n.language)
                              : Duration.fromObject(
                                  { minutes: Number(paymentRateCancellation.minutesBefore) },
                                  { locale: i18n.language },
                                )
                                  .shiftTo("days")
                                  .toHuman()}
                          </Text>
                        </td>
                        <td>
                          {!companiesLoading && (
                            <div className="companies-tags">
                              {paymentRateCancellation.companies.length === 0
                                ? t("bookings.resources.assignmentDefaultLabel")
                                : paymentRateCancellation.companies.map((c) => (
                                    <Tag key={c}>{companyOptions.find((opt) => opt.value === c)?.label}</Tag>
                                  ))}
                            </div>
                          )}
                        </td>
                        <td style={{ width: "1px" }}>
                          <RiEditLine
                            size="16"
                            color={colors.primary}
                            cursor="pointer"
                            onClick={() => {
                              setRateModalIndex(i);
                              setShowRateModal(true);
                            }}
                          />
                        </td>
                        <td style={{ width: "1px" }}>
                          <RiCloseLine
                            size="16"
                            color={colors.danger}
                            cursor="pointer"
                            onClick={() => {
                              withConfirmation({
                                title: t("bookings.resources.deleteRateTitle"),
                                message: t("bookings.resources.deleteRateMessage"),
                                confirmButtonText: t("bookings.resources.deleteRateConfirm"),
                                cancelButtonText: t("common.cancelNo"),
                                confirmButtonVariant: "danger",
                                size: "md",
                                onConfirm: () => removePaymentRateCancellation(i),
                              })();
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table.Table>
              </div>
            </Form.Group>
          )}

          {fm.values.paymentRateCancellation.length < MAX_RATES_NUMBER && (
            <Button
              type="button"
              variant="secondary"
              size="lg"
              style={{ width: "100%" }}
              onClick={() => {
                setRateModalIndex(null);
                setShowRateModal(true);
              }}
            >
              {t("bookings.resources.addCancellationRate")}
            </Button>
          )}

          <ResourceCreateAndEditFormCancellationPolicyModal
            showModal={showPolicyModal}
            setShowModal={setShowPolicyModal}
            modalIndex={policyModalIndex}
            defaultPolicyUsed={
              defaultPolicyUsed &&
              (policyModalIndex == null || fm.values.editBookingPolicies[policyModalIndex].companies.length !== 0)
            }
            companyOptions={[
              ...companyOptions.filter(
                (opt) =>
                  policyModalIndex != null &&
                  fm.values.editBookingPolicies[policyModalIndex].companies.includes(opt.value),
              ),
              ...filteredCompanyOptions,
            ].sort((a, b) => a.label.localeCompare(b.label))}
            editBookingPolicy={policyModalIndex != null ? fm.values.editBookingPolicies[policyModalIndex] : undefined}
            onAdd={addEditBookingPolicy}
            onEdit={updateEditBookingPolicy}
          />

          <ResourceCreateAndEditFormCancellationRateModal
            showModal={showRateModal}
            setShowModal={setShowRateModal}
            modalIndex={rateModalIndex}
            building={fm.values.building}
            companiesGroupedByNoticePeriod={mergeCompaniesByNoticePeriod(
              fm.values.paymentRateCancellation
                .filter((_, i) => i !== rateModalIndex)
                .map((prc) => ({
                  minutesBefore: prc.minutesBefore,
                  companies: prc.companies,
                })),
            )}
            companyOptions={companyOptions}
            paymentRateCancellation={
              rateModalIndex != null ? fm.values.paymentRateCancellation[rateModalIndex] : undefined
            }
            onAdd={addPaymentRateCancellation}
            onEdit={updatePaymentRateCancellation}
          />
        </>
      )}

      <style jsx>
        {`
          .notice-period {
            width: 50%;
            min-width: 200px;
          }
          .companies-tags {
            display: flex;
            flex-wrap: wrap;
            gap: ${spacers.s3};
          }
          @media screen and (max-width: ${breakpoints.md}px) {
            .notice-period {
              width: 100%;
            }
            .cancellation-permissions-container :global(th),
            .cancellation-permissions-container :global(td) {
              padding: ${spacers.s3};
            }
          }
        `}
      </style>
    </>
  );
};
