import React, { useContext, useEffect } from "react";
import { useTheme } from "@equiem/react-admin-ui";
import { RiCloseCircleLine } from "@equiem/react-admin-ui/icons";
import { useTranslation, useErrorTranslation } from "@equiem/localisation-eq1";

import { BookingModal } from "../contexts/BookingModalContext";

interface Props {
  error: unknown;
  canBookResource: boolean;
}

export const BookingModalContentError: React.FC<Props> = ({ error, canBookResource }) => {
  const { t } = useTranslation();
  const { tError } = useErrorTranslation();
  const { colors, spacers } = useTheme();
  const { setShowFooter } = useContext(BookingModal);

  useEffect(() => {
    setShowFooter(false);
    return () => setShowFooter(true);
  }, [setShowFooter]);

  let message: string;
  if (error != null) {
    message = tError(error);
  } else if (!canBookResource) {
    message = t("bookings.operations.resourceNotAccessible");
  } else {
    message = t("bookings.operations.resourceNotFound");
  }

  return (
    <>
      <div className="error-container">
        <div className="error-icon">
          <RiCloseCircleLine size={32} color={colors.grayscale[50]} />
        </div>
        <div className="error-message">{message}</div>
      </div>
      <style jsx>{`
        .error-container {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          padding: ${spacers.s7};
          gap: ${spacers.s5};
        }
        .error-icon {
          padding: ${spacers.s4};
          background-color: ${colors.grayscale[5]};
          border-radius: 50%;
        }
      `}</style>
    </>
  );
};
