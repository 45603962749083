import React, { useContext, useEffect, useMemo, useState } from "react";
import type { FieldProps } from "formik";
import { Field, FieldArray } from "formik";

import { CurrentRole, Role, useSaferFormikContext, useShortcuts } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Dropdown, Form, Text, useIsMobileWidth, UserSearch, useTheme } from "@equiem/react-admin-ui";
import { RiAddLine, RiDeleteBinLine, RiMoreFill } from "@equiem/react-admin-ui/icons";

import { ReceptionFormContext } from "./ReceptionFormContext";
import type { ReceptionFormValues, RecipientActions } from "./ReceptionFormValues";

const PlaceholderSelect = ({ isLoading, data }: { isLoading: boolean; data: unknown[] }) => {
  const { t } = useTranslation();

  if (isLoading) {
    return <>{`${t("common.loading")}...`}</>;
  }

  if (data.length === 0) {
    return <>{t("common.noneAvailable")}</>;
  }

  return <>{`${t("common.pleaseSelect")}...`}</>;
};

export const ReceptionFormFields: React.FC = () => {
  const { currentRole } = useContext(CurrentRole);
  const { values, touched, errors, setFieldValue } = useSaferFormikContext<ReceptionFormValues>();
  const {
    buildings,
    buildingsLoading,
    reception,
    levels,
    companies,
    companiesLoading,
    barrierControlAccesses,
    barrierControlAccessesLoading,
    searchUsersRecipients,
    searchUsersLoadingRecipients,
  } = useContext(ReceptionFormContext);
  const theme = useTheme(true);
  const { t } = useTranslation();
  const [displayUserSearch, setDisplayUserSearch] = useState(false);
  const { dispatch } = useShortcuts<string>();
  const isMobile = useIsMobileWidth();
  const searchUserItems = useMemo(
    () => searchUsersRecipients.map((user) => ({ value: "", user })),
    [searchUsersRecipients],
  );

  const onDeleteRecipient = (remove: (index: number) => void, index: number) => {
    remove(index);
    const updatedRecipients = values.recipients.filter((_, i) => i !== index);
    void setFieldValue("recipients", updatedRecipients);
  };
  const disabled = useMemo(
    () => values.companyUuid === "" && values.receptionType === "tenant",
    [values.companyUuid, values.receptionType],
  );

  const renderRecipientsWithActions = ({ value, actions }: { value: string; actions: RecipientActions[] }) => (
    <div className="item">
      <Text variant="text" size="small" weight="medium" className="m-0 label">
        {value}
      </Text>
      <div className="item-actions">
        {isMobile ? (
          <Dropdown.Icon icon={RiMoreFill} size="md" placement="bottom-end" supportsMobile className="dropdown">
            {actions.map((action, index) => (
              <Dropdown.Item
                icon={() => <action.icon color={action.color} />}
                onClick={() => {
                  action.onClick();
                }}
                className={action.className}
                key={index}
              >
                <span>{action.title}</span>
              </Dropdown.Item>
            ))}
          </Dropdown.Icon>
        ) : (
          actions.map((action, index) => (
            <Button
              variant="ghost"
              onClick={action.onClick}
              className={action.className}
              key={index}
              aria-label={action.title}
              round
            >
              <action.icon size={16} color={action.color} />
            </Button>
          ))
        )}
      </div>
      <style jsx>
        {`
          .item {
            display: grid;
            grid-template-columns: 1fr auto;
            align-items: center;
            gap: 0.5rem;
            padding: 0.5rem;
            cursor: pointer;
            height: 3rem;
            min-height: 3rem;
          }

          .item--icon {
            grid-template-columns: 1.25rem 1fr auto;
          }

          .item--icon :global(*:first-child) {
            justify-self: center;
          }

          .item:hover :global(.item-actions) {
            display: flex;
          }

          .item:not(.item-input-mode):hover {
            background-color: ${theme.colors.grayscale[5]};
          }

          .item :global(.label) {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .item-actions {
            display: none;
            align-items: center;
          }

          .item-actions :global(.item-edit) {
            width: 2rem;
          }
        `}
      </style>
    </div>
  );

  useEffect(() => {
    if (reception != null) {
      const building = buildings.find((item) => item.uuid === reception.building?.uuid);
      const buildingLevel = building?.levels.find((level) => level.uuid === reception.buildingLevel?.uuid);

      if (buildingLevel == null) {
        void setFieldValue("buildingLevelUuid", "", true);
      } else {
        void setFieldValue("buildingLevelUuid", buildingLevel.uuid, true);
      }
    }
  }, [buildings, reception?.building?.uuid, reception?.uuid]);

  return (
    <>
      {currentRole === Role.PropertyManager && (
        <>
          <Form.Group
            label={t("visitors.settings.receptionType")}
            showTooltip={true}
            tooltipText={t("visitors.settings.receptionTypeTooltip")}
            required
          >
            <Field type="radio" id="building" name="receptionType" value="building" disabled={reception != null} />
            <label htmlFor="building" className="ml-3 text-dark">
              {t("visitors.settings.buildingReception")}
            </label>
            <Field
              style={{ marginLeft: "28px" }}
              type="radio"
              id="tenant"
              name="receptionType"
              value="tenant"
              disabled={reception != null}
            />
            <label htmlFor="tenant" className="ml-3 text-dark">
              {t("visitors.settings.tenantReception")}
            </label>
          </Form.Group>

          {values.receptionType === "tenant" && (
            <Form.Group
              label={t("common.company")}
              showTooltip={true}
              tooltipText={t("visitors.settings.tenantReceptionTooltip")}
              required
              error={touched.companyUuid === true ? errors.companyUuid : ""}
              hasError={touched.companyUuid === true && Boolean(errors.companyUuid)}
            >
              <Field name="companyUuid">
                {({ field }: FieldProps) => (
                  <Form.Select {...field} disabled={companies.length === 0}>
                    <option value="" disabled>
                      <PlaceholderSelect isLoading={companiesLoading} data={companies} />
                    </option>
                    {companies.map((company) => (
                      <option key={company.uuid} value={company.uuid}>
                        {company.name}
                      </option>
                    ))}
                  </Form.Select>
                )}
              </Field>
            </Form.Group>
          )}
        </>
      )}
      <Form.Group
        label={t("visitors.common.building")}
        showTooltip={true}
        tooltipText={t("visitors.settings.receptionBuildingTooltip")}
        required
        hasError={touched.buildingUuid === true && Boolean(errors.buildingUuid)}
        error={touched.buildingUuid === true ? errors.buildingUuid : ""}
      >
        <Field name="buildingUuid">
          {({ field }: FieldProps) => (
            <Form.Select {...field} disabled={buildings.length === 0} placeholder={`${t("common.pleaseSelect")}...`}>
              <option value="" disabled>
                <PlaceholderSelect isLoading={buildingsLoading} data={buildings} />
              </option>
              {buildings.map((build) => (
                <option key={build.uuid} value={build.uuid}>
                  {build.name}
                </option>
              ))}
            </Form.Select>
          )}
        </Field>
      </Form.Group>
      <Form.Group
        label={t("visitors.common.level")}
        showTooltip={true}
        tooltipText={t("visitors.settings.levelTooltip")}
        hasError={touched.buildingLevelUuid === true && Boolean(errors.buildingLevelUuid)}
        error={touched.buildingLevelUuid === true ? errors.buildingLevelUuid : ""}
        required={values.receptionType === "tenant"}
      >
        <Field name="buildingLevelUuid">
          {({ field }: FieldProps) => (
            <Form.Select {...field} disabled={levels.length === 0} placeholder={`${t("common.pleaseSelect")}...`}>
              <option value="" disabled>
                <PlaceholderSelect isLoading={buildingsLoading} data={levels} />
              </option>
              {levels.map((level) => (
                <option key={level.uuid} value={level.uuid}>
                  {level.name}
                </option>
              ))}
            </Form.Select>
          )}
        </Field>
      </Form.Group>
      <Form.Group
        label={t("visitors.common.receptionSuffix")}
        showTooltip={true}
        tooltipText={t("visitors.settings.receptionSuffixTooltip")}
        hasError={touched.suffix === true && Boolean(errors.suffix)}
        error={touched.suffix === true ? errors.suffix : ""}
      >
        <Field name="suffix">
          {({ field }: FieldProps<string, ReceptionFormValues>) => (
            <Form.Input {...field} placeholder={t("visitors.settings.receptionSuffixPlaceholder")} />
          )}
        </Field>
      </Form.Group>
      <>
        <hr />
        <Form.Group
          label={t("visitors.settings.cardIdTracking")}
          showTooltip
          tooltipText={t("visitors.settings.cardIdTrackingTooltip")}
        >
          <label htmlFor="enableAccessCard" className="d-flex">
            <Field type="checkbox" id="enableAccessCard" name="enableAccessCard" />
            <span className="ml-3">{t("visitors.settings.enableCardId")}</span>
          </label>
        </Form.Group>
      </>
      {currentRole === Role.PropertyManager && (
        <>
          <Form.Group
            label={t("visitors.settings.passPrinting")}
            showTooltip
            tooltipText={t("visitors.settings.passPrintingTooltip")}
          >
            <label htmlFor="enablePassPrinting" className="d-flex mb-3">
              <Field type="checkbox" id="enablePassPrinting" name="enablePassPrinting" />
              <span className="ml-3">{t("visitors.settings.enablePassPrinting")}</span>
            </label>
            <label htmlFor="enableAutomaticPassPrinting" className="d-flex">
              <Field
                type="checkbox"
                id="enableAutomaticPassPrinting"
                name="enableAutomaticPassPrinting"
                disabled={!values.enablePassPrinting}
              />
              <span className="ml-3">{t("visitors.settings.passPrintingOnCheckInV2")}</span>
            </label>
          </Form.Group>
        </>
      )}
      {values.receptionType === "tenant" && [
        <Form.Group
          key="sendPassOnCheckIn"
          label={t("visitors.settings.passDelivery")}
          showTooltip={true}
          tooltipText={t("visitors.settings.passDeliveryTooltip")}
        >
          <label htmlFor="sendPassOnCheckIn" className="d-flex">
            <Field
              type="checkbox"
              id="sendPassOnCheckIn"
              name="sendPassOnCheckIn"
              label={t("visitors.settings.sendPassOnCheckInV2")}
            />
            <span className="ml-3">{t("visitors.settings.sendPassOnCheckInV2")}</span>
          </label>
        </Form.Group>,
        currentRole === Role.PropertyManager && (
          <Form.Group
            key="barrierControlAccess"
            label={t("visitors.reception.barrierControlAccess")}
            showTooltip={true}
            tooltipText={t("visitors.reception.barrierControlAccessTooltip")}
            hasError={touched.barrierControlAccessUuid === true && Boolean(errors.barrierControlAccessUuid)}
            error={touched.barrierControlAccessUuid === true ? errors.barrierControlAccessUuid : ""}
          >
            <Field name="barrierControlAccessUuid">
              {({ field }: FieldProps) => (
                <Form.Select
                  {...field}
                  disabled={barrierControlAccesses.length === 0}
                  placeholder={`${t("common.pleaseSelect")}...`}
                >
                  <option value="" disabled={barrierControlAccessesLoading}>
                    <PlaceholderSelect isLoading={barrierControlAccessesLoading} data={barrierControlAccesses} />
                  </option>
                  {barrierControlAccesses
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((x) => (
                      <option key={x.uuid} value={x.uuid}>
                        {x.name}
                      </option>
                    ))}
                </Form.Select>
              )}
            </Field>
          </Form.Group>
        ),
        (currentRole === Role.PropertyManager || currentRole === Role.WorkplaceManager) && (
          <>
            <hr />
            <Form.Group
              key="enableHoldInLobby"
              label={t("visitors.settings.enableHoldInLobby")}
              showTooltip={true}
              tooltipText={t("visitors.settings.enableHoldInLobbyTooltip")}
            >
              <label htmlFor="enableHoldInLobby" className="d-flex">
                <Field
                  type="checkbox"
                  id="enableHoldInLobby"
                  name="enableHoldInLobby"
                  label={t("visitors.settings.enableHoldInLobbyLabel")}
                />
                <span className="ml-3">{t("visitors.settings.enableHoldInLobbyLabel")}</span>
              </label>
            </Form.Group>
            <hr />
            <Form.Group
              key="checkInNotifications"
              label={t("visitors.settings.receptionNotifications")}
              showTooltip
              tooltipText={t("visitors.settings.receptionNotificationsTooltip")}
              hasError={touched.recipients != null && Boolean(errors.recipients)}
              error={touched.recipients != null ? (errors.recipients as string) : ""}
            >
              <FieldArray name="recipients">
                {({ remove }) => (
                  <div className="recipients">
                    {(values.recipients.length > 0 || displayUserSearch) && (
                      <div className="recipients-list">
                        {values.recipients.map((recipient, index) => (
                          <div className="edit-form" key={index}>
                            {renderRecipientsWithActions({
                              value: recipient.email,
                              actions: [
                                {
                                  title: "delete",
                                  icon: RiDeleteBinLine,
                                  color: theme.colors.status.danger.primary,
                                  onClick: () => {
                                    onDeleteRecipient(remove, index);
                                  },
                                },
                              ],
                            })}
                          </div>
                        ))}
                        {displayUserSearch && (
                          <div
                            className={`user-search-adjustments ${values.recipients.length > 0 ? "remove-radius" : ""}`}
                          >
                            <UserSearch
                              disabled={disabled}
                              loading={searchUsersLoadingRecipients}
                              variant="md"
                              name="recipientsSearch"
                              onSearch={(search) => {
                                void setFieldValue("recipientsSearch", search);
                              }}
                              onSelect={({ user }) => {
                                if (!user.selected) {
                                  void setFieldValue("recipients", [
                                    ...values.recipients,
                                    { userUuid: user.uuid, email: user.email },
                                  ]);
                                } else {
                                  void setFieldValue(
                                    "recipients",
                                    values.recipients.filter((r) => r.userUuid !== user.uuid),
                                  );
                                }
                                setDisplayUserSearch(false);
                              }}
                              items={searchUserItems}
                              onAddUser={() => {
                                dispatch("addUserAsUnknownRole");
                              }}
                              buttonText={(item) => (item.user.selected ? t("common.remove") : t("common.add"))}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </FieldArray>
              <Button
                variant="secondary"
                name="add-recipients-button"
                size="md"
                type="button"
                onClick={() => setDisplayUserSearch(true)}
              >
                <div className="d-flex align-items-center">
                  <RiAddLine size={16} className="mr-2" />
                  {t("visitors.appointmentForm.addRecipient")}
                </div>
              </Button>
            </Form.Group>
          </>
        ),
      ]}

      <style jsx>
        {`
          .text-dark {
            color: black !important;
          }
          hr {
            border: 0;
            border-bottom: 1px solid ${theme.colors.border};
            margin: 1.5rem 0;
          }
          .recipients-list {
            border: 1px solid ${theme.colors.border};
            border-radius: 5px;
            margin-bottom: 0.5rem;
          }

          .recipients-list .edit-form:not(:last-child) {
            border-bottom: 1px solid ${theme.colors.border};
          }

          .recipients-list :global(.user-search-adjustments > div > div) {
            border: 0 !important;
            z-index: 9999;
          }

          .recipients-list :global(.user-search-adjustments.remove-radius > div > div) {
            border-top-left-radius: 0 !important;
            border-top-right-radius: 0 !important;
          }
        `}
      </style>
    </>
  );
};
