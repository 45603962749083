import React from "react";
import type { ThemeContext } from "../../contexts";

export interface ModalContext {
  title: string;
  renderTitle?: (() => React.ReactNode) | null;
  scrollable: boolean;
  show: boolean;
  modalBodyRef: React.RefObject<HTMLDivElement> | null;
  onHide: () => void;
  onBack?: () => void;
  headerBorder: boolean;
  setHeaderBorder: (border: boolean) => void;
  stickyHeader: boolean;
  setStickyHeader: (state: boolean) => void;
  stickyFooter: boolean;
  setStickyFooter: (state: boolean) => void;
  size: keyof ThemeContext["sizes"] | "auto";
  withFooter: boolean;
}

export const ModalContext = React.createContext<ModalContext>({
  title: "Unknown",
  renderTitle: null,
  scrollable: false,
  show: false,
  modalBodyRef: null,
  onHide: () => undefined,
  headerBorder: true,
  setHeaderBorder: () => undefined,
  stickyHeader: false,
  setStickyHeader: () => undefined,
  stickyFooter: false,
  setStickyFooter: () => undefined,
  size: "md",
  withFooter: false,
});
