import React from "react";
import type { DateTime } from "luxon";

import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Form, ProgressCircle, Tooltip, useTheme } from "@equiem/react-admin-ui";
import { RiCloseCircleLine, RiFileDownloadLine, RiRefreshLine, RiSearchLine } from "@equiem/react-admin-ui/icons";

import { ReceptionReports } from "./report/ReceptionReports";

export const DeskMenuBar = ({
  handleDateChange,
  onClearSearch,
  handleRefetchAppointments,
  reportLoading,
  exportCsv,
  handleSearch,
  search,
  date,
  loading,
  enablePdfReport = false,
}: {
  handleDateChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClearSearch: () => void;
  handleRefetchAppointments: () => void;
  reportLoading: boolean;
  exportCsv: () => void;
  handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  search: string;
  date: DateTime;
  loading: boolean;
  enablePdfReport?: boolean;
}) => {
  const { t } = useTranslation();
  const { colors } = useTheme(true);

  return (
    <div className="filters d-flex">
      <Form.Input
        type="date"
        variant="sm"
        onChange={handleDateChange}
        defaultValue={date.toISODate() ?? ""}
        className="date"
      />
      <Form.InputGroup.Group className="search-container">
        <Form.InputGroup.Prefix>
          <RiSearchLine color={colors.muted1} size={15} />
        </Form.InputGroup.Prefix>
        <Form.Input
          placeholder={t("common.search") ?? ""}
          onChange={handleSearch}
          className="search"
          value={search}
          variant="sm"
        />
        <Form.InputGroup.Suffix>
          <RiCloseCircleLine
            style={{
              display: search.length === 0 ? "none" : "block",
              cursor: "pointer",
            }}
            onClick={onClearSearch}
            color={colors.muted1}
            size={15}
          />
        </Form.InputGroup.Suffix>
      </Form.InputGroup.Group>
      {enablePdfReport ? (
        <ReceptionReports csvReportLoading={reportLoading} exportCsv={exportCsv}></ReceptionReports>
      ) : (
        <Tooltip title={t("visitors.reception.csvExport")} placement="bottom">
          <Button variant="ghost" round disabled={reportLoading} onClick={exportCsv} className="export-csv">
            {reportLoading ? <ProgressCircle size={16} /> : <RiFileDownloadLine size={16} />}
          </Button>
        </Tooltip>
      )}

      <div style={{ position: "relative" }}>
        <Tooltip title={t("common.refresh")} placement="bottom">
          <Button variant="ghost" round onClick={handleRefetchAppointments} disabled={search.length > 0}>
            <span className={loading ? "spin" : undefined}>
              <RiRefreshLine size={16} />
            </span>
          </Button>
        </Tooltip>
      </div>
      <style jsx>{`
        :global(.date) {
          margin-right: 8px;
        }
      `}</style>
    </div>
  );
};
