import React, { useContext, useEffect, useMemo, useState } from "react";
import { Field, Form, useFormikContext } from "formik";
import { isEqual } from "lodash";

import { CurrentProfile, useShowError, useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Alert, Button, Form as EqForm, Material, Modal, Tabs, useTheme } from "@equiem/react-admin-ui";
import { RiErrorWarningLine } from "@equiem/react-admin-ui/icons";

import { useCompaniesV2LazyQuery } from "../generated/requests-client";
import { useCategoryData } from "../pages/settings/category/hooks/useCategoryData";
import { ModalContext } from "../pages/settings/contexts/ModalContext";

import { CategoryCafmIntegrationModalContext } from "./category-cafm-integration/CategoryCafmIntegrationModalContext";
import type { CategoryFormValues, CategoryPreset } from "./utils/categoryFormTypes";
import { type CategoryTabKey, categoryTabs } from "./utils/categoryTabs";
import { BuildingsSelect } from "./BuildingsSelect";
import { CategoryIntegrationsForm } from "./CategoryIntegrationsForm";
import { Subcategories } from "./Subcategories";

interface Props {
  showTypeField: boolean;
  selectedPreset?: CategoryPreset;
  onNameChange?: (newName: string) => void;
  onCloseModal: (arg: boolean) => void;
  selectedTab: string;
  setSelectedTab: (key: string) => void;
}

export const CategoryEditForm: React.FC<Props> = ({
  showTypeField,
  selectedPreset,
  onNameChange,
  onCloseModal,
  selectedTab,
  setSelectedTab,
}) => {
  const {
    buildings,
    typeOptions,
    subCategories,
    categoriesMap,
    handleSubcategoriesActions,
    setSelectedCategoryUuid,
    selectedCategoryUuid,
  } = useCategoryData();
  const [selectedIcon, setSelectedIcon] = useState<string>();

  const { t } = useTranslation();
  const { values, touched, errors, setFieldValue, dirty, submitForm, isSubmitting, isValid, setTouched } =
    useFormikContext<CategoryFormValues>();
  const { canManageRegion } = useContext(CurrentProfile);
  const cafmLinkModalContext = useContext(CategoryCafmIntegrationModalContext);
  const { uuid } = useSiteContext();
  const tabs = categoryTabs(t);
  const { colors } = useTheme();
  const showError = useShowError();
  const modal = useContext(ModalContext);
  const category = useMemo(() => (modal.id != null ? categoriesMap.get(modal.id) : undefined), [modal, categoriesMap]);
  const [subcategoriesTouched, setSubcategoriesTouched] = useState(false);
  const [currentSubcategories, setCurrentSubcategories] = useState<undefined | Array<{ name: string; uuid: string }>>(
    undefined,
  );

  const [companiesQuery, companiesQueryData] = useCompaniesV2LazyQuery({
    variables: {
      first: 1000,
      destinationUuid: uuid,
    },
  });
  const companies = useMemo(() => companiesQueryData.data?.companiesV2.edges ?? [], [companiesQueryData]);

  useEffect(() => {
    if (selectedCategoryUuid != null) {
      setCurrentSubcategories(subCategories);
    }
  }, [selectedCategoryUuid, subCategories]);

  const currentTab = useMemo(() => {
    switch (selectedTab) {
      case "subcategories":
        return (
          <Subcategories
            isEditing={true}
            currentSubcategories={currentSubcategories}
            setCurrentSubcategories={(subs) => {
              if (!subcategoriesTouched) {
                setSubcategoriesTouched(true);
              }
              setCurrentSubcategories(subs);
            }}
          />
        );
      case "integrations":
        return cafmLinkModalContext.configurations != null &&
          cafmLinkModalContext.configurations.reqMgt.cafmConfigurationsBySiteUuid.length > 0 ? (
          <CategoryIntegrationsForm isEditing={true} />
        ) : null;
      default:
        return (
          <>
            <Form>
              <div className="d-flex ">
                <div className="flex-grow-0 iconNameGroup">
                  <EqForm.Group
                    label={t("requests.icon")}
                    required
                    error={dirty && errors.iconName != null ? errors.iconName : undefined}
                  >
                    <Material.Provider>
                      <Field
                        name="iconName"
                        as={Material.Selector}
                        size="lg"
                        selectedIcon={selectedIcon ?? (values.iconName !== "" ? values.iconName : undefined)}
                        onSelect={(name: string) => setSelectedIcon(name)}
                        className="icon-type"
                        error={errors.iconName}
                      />
                    </Material.Provider>
                  </EqForm.Group>
                </div>
                <div className="flex-grow-1 pl-5">
                  <EqForm.Group
                    label={t("requests.category.categoryName")}
                    required
                    error={touched.name != null && errors.name != null ? errors.name : undefined}
                  >
                    <Field name="name" as={EqForm.Input} />
                  </EqForm.Group>
                </div>
              </div>
              {showTypeField ? (
                <div>
                  <EqForm.Group
                    label={t("requests.category.categoryTypeLabel")}
                    required
                    error={touched.type != null && errors.type != null ? errors.type : undefined}
                  >
                    <Field
                      name="type"
                      as={EqForm.DynamicSelect}
                      options={typeOptions}
                      noneLabel={t("requests.category.categoryTypePlaceholder")}
                    />
                  </EqForm.Group>
                </div>
              ) : null}
              <div>
                <EqForm.Group
                  label={t("common.buildings")}
                  required
                  error={
                    touched.buildings != null && errors.buildings != null ? errors.buildings.toString() : undefined
                  }
                  onFocus={() => {
                    if (touched.buildings == null) {
                      void setTouched({ buildings: true });
                    }
                  }}
                >
                  <Field
                    name="buildings"
                    as={BuildingsSelect}
                    buildings={buildings}
                    noneLabel={t("requests.category.buildingsPlaceholder")}
                  />
                </EqForm.Group>
              </div>
              {canManageRegion && (
                <EqForm.Group
                  label={t("requests.category.owner")}
                  required
                  error={
                    touched.ownerCompanyUuid != null && errors.ownerCompanyUuid != null
                      ? t("requests.category.ownerPlaceholder")
                      : undefined
                  }
                >
                  <Field id="ownerCompanyUuid" name="ownerCompanyUuid" as={EqForm.Select} disabled>
                    <option value="">{t("requests.category.ownerPlaceholder")}</option>
                    {companies.flatMap(({ node }) => (
                      <option key={node?.name} value={node?.uuid}>
                        {node?.name}
                      </option>
                    ))}
                  </Field>
                </EqForm.Group>
              )}
              <div className="separator">
                <Alert
                  size="large"
                  variant="gray"
                  icon={<RiErrorWarningLine size={18} color={colors.grayscale[50]} />}
                  message={t("requests.category.queueDescription")}
                />
              </div>
              <EqForm.Group
                label={t("requests.category.queue")}
                required
                error={
                  touched.ownerCompanyUuid != null && errors.ownerCompanyUuid != null
                    ? t("requests.category.selectQueue")
                    : undefined
                }
              >
                <Field id="queueUuid" name="queueUuid" as={EqForm.Select} disabled>
                  <option value="">{t("requests.category.selectQueue")}</option>
                  {category?.queue != null && (
                    <option key={category.queue.name} value={category.queue.uuid}>
                      {category.queue.name}
                    </option>
                  )}
                </Field>
              </EqForm.Group>
            </Form>
            <style jsx>{`
              .separator {
                border-top: 1px solid ${colors.grayscale[10]};
                padding: 24px 0;
              }
              .iconNameGroup :global(p) {
                margin-right: -10rem;
                margin-bottom: -1rem;
              }
            `}</style>
          </>
        );
    }
  }, [
    selectedTab,
    buildings,
    values,
    canManageRegion,
    t,
    companies,
    currentSubcategories,
    selectedIcon,
    errors,
    showTypeField,
    typeOptions,
    subcategoriesTouched,
    touched,
    dirty,
    setTouched,
    cafmLinkModalContext,
    category,
  ]);

  useEffect(() => {
    onNameChange?.(values.name);
  }, [onNameChange, values.name]);

  useEffect(() => {
    if (modal.id != null) {
      setSelectedCategoryUuid(modal.id);
    }
  }, [modal, setSelectedCategoryUuid]);

  useEffect(() => {
    if (canManageRegion) {
      companiesQuery().catch((e) => {
        console.error(e);
      });
    }
  }, [selectedPreset?.iconName, setFieldValue, companiesQuery, canManageRegion]);

  return (
    <>
      <div className="px-6 mb-6 mobile-header">
        <Tabs
          items={tabs}
          selected={selectedTab}
          onSelect={(key) => {
            setSelectedTab(key as CategoryTabKey);
          }}
        />
      </div>
      <Modal.Body>{currentTab}</Modal.Body>
      <Modal.Footer>
        <div className="primary-actions">
          <Button
            variant="secondary"
            disabled={isSubmitting}
            onClick={() => onCloseModal(!isEqual(currentSubcategories, subCategories))}
          >
            {t("common.cancel")}
          </Button>
          <Button
            variant="primary"
            disabled={!isValid || isSubmitting || (!subcategoriesTouched && !dirty)}
            type="submit"
            onClick={() => {
              submitForm()
                .then(async () => {
                  if (currentSubcategories !== undefined) {
                    await handleSubcategoriesActions(currentSubcategories);
                    setCurrentSubcategories(undefined);
                  }
                })
                .catch(showError);
            }}
          >
            {t("common.saveChanges")}
          </Button>
        </div>
      </Modal.Footer>
      <style jsx>{`
        .primary-actions {
          display: flex;
          gap: 8px;
          width: 100%;
        }
        .primary-actions :global(> button) {
          width: 50%;
        }
        .mobile-header {
          border-bottom: 1px solid ${colors.grayscale[10]};
          position: sticky;
          top: 64px;
          z-index: 2;
        }
        .mobile-header ~ :global(.modal-body) {
          margin-top: 0;
        }
      `}</style>
    </>
  );
};
