import React, { forwardRef, useContext } from "react";
import type { ComponentProps, ReactElement, ReactNode } from "react";
import type { Placement } from "@floating-ui/react-dom";

import type { IconType } from "react-icons";
import { useTheme } from "../../contexts/Theme";
import { RiCheckLine } from "react-icons/ri";
import { NavContext } from "../../contexts/NavContext";

export interface NavItemProps extends Omit<ComponentProps<"div">, "ref"> {
  noMargin?: boolean;
  icon?: IconType;
  rightIcon?: IconType;
  selected?: boolean;
  showTick?: boolean;
  actionButton?: ReactElement;
  disabled?: boolean;
  nestedMenu?: ReactNode;
  nestedMenuPlacement?: Placement;
  nestedItemHasFocus?: boolean;
  variant?: "primary" | "danger";
}

const placementDirection = (placement: Placement) =>
  placement.startsWith("top") || placement.startsWith("bottom") ? "vertical" : "horizontal";

export const NavItem = forwardRef<HTMLDivElement | null, NavItemProps>(
  // eslint-disable-next-line complexity
  (
    {
      noMargin = false,
      selected = false,
      showTick = true,
      disabled = false,
      nestedItemHasFocus = false,
      icon: Icon,
      rightIcon: RightIcon,
      children,
      nestedMenu,
      nestedMenuPlacement,
      actionButton,
      className,
      variant,
      ...props
    },
    ref,
  ) => {
    const { fontWeight } = useContext(NavContext);
    const { spacers, colors, borderRadius } = useTheme();

    return (
      <>
        <div
          ref={ref}
          className={`nav-item ${className ?? ""} ${disabled ? "disabled" : ""} ${nestedItemHasFocus ? "focused" : ""}`}
          role="option"
          tabIndex={!disabled && props.onClick != null ? 0 : undefined}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !disabled) {
              e.target.dispatchEvent(new Event("click", { bubbles: true }));
            }
            props.onKeyDown?.(e);
          }}
          {...props}
          onClick={(e) => {
            if (!disabled) {
              props.onClick?.(e);
            }
          }}
        >
          {nestedMenuPlacement != null && <div className={`hover-bridge ${placementDirection(nestedMenuPlacement)}`} />}
          {Icon != null && (
            <span className="icon">
              <Icon size={16} color={variant === "danger" ? colors.danger : "default"} />
            </span>
          )}
          <span className="text">{children}</span>
          {(actionButton != null || (selected && showTick)) && (
            <span className="tick">
              {actionButton != null && <span className="actions">{actionButton}</span>}
              {selected && showTick && <RiCheckLine color="#0032E1" size={16} />}
            </span>
          )}
          {RightIcon != null && (
            <span className="icon-right">
              <RightIcon size={16} color={variant === "danger" ? colors.danger : "default"} />
            </span>
          )}
          {nestedMenu}
        </div>
        <style jsx>{`
          .nav-item {
            cursor: pointer;
            padding: ${spacers.s3};
            text-align: left;
            border-radius: ${borderRadius};
            margin: ${noMargin ? "none" : `0 ${spacers.s3}`};
            font-weight: ${fontWeight === "light" ? "initial" : "500"};
            font-size: 12px;
            display: flex;
            align-items: center;
            margin-bottom: ${spacers.s2};
            background: ${selected ? colors.lightHover : "none"};
            white-space: nowrap;
          }
          .disabled {
            opacity: 0.5;
            cursor: unset;
          }
          .text {
            overflow: hidden;
            text-overflow: ellipsis;
            flex-grow: 1;
          }
          .icon {
            margin-right: ${spacers.s3};
            color: ${colors.primary};
          }
          .icon-right {
            margin-left: auto;
          }
          .tick {
            margin-left: auto;
            display: flex;
            padding-left: ${spacers.s3};
          }
          .nav-item:hover:not(.disabled),
          .nav-item:focus:not(.disabled),
          .nav-item.focused:not(.disabled) {
            background: ${selected ? colors.lightHover : colors.grayscale_5};
          }
          .nav-item:focus:not(.disabled),
          .nav-item.focused:not(.disabled) {
            outline: none;
          }

          .hover-bridge {
            display: none;
          }
          .nav-item:hover:not(.disabled) > .hover-bridge,
          .nav-item:focus:not(.disabled) > .hover-bridge,
          .nav-item.focused:not(.disabled) > .hover-bridge {
            display: block;
          }
          .hover-bridge {
            position: absolute;
            background: transparent;
            left: 0;
            width: 100%;
            height: calc(1rem + 16px);
          }
          .hover-bridge.vertical {
            height: calc(1rem + 24px);
          }

          .actions {
            padding-right: ${selected ? spacers.s2 : spacers.s0};
          }
        `}</style>
      </>
    );
  },
);
NavItem.displayName = "NavItem";
