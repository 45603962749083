import React from "react";

import type { BookingPermissionFilters } from "@equiem/lib/generated/gateway-client";
import { useTranslation } from "@equiem/localisation-eq1";
import { FilterTab, useTheme } from "@equiem/react-admin-ui";

const tabOrder = ["all", "myResources", "myEmployees"] as const;

export type Tab = (typeof tabOrder)[number];
export const isTab = (f: unknown): f is Tab => tabOrder.includes(f as Tab);
export const defaultTab: Tab = "all";

export const tabFilters: Record<Tab, BookingPermissionFilters | null> = {
  all: null,
  myResources: {
    canObserveBookings: true,
  },
  myEmployees: {
    isMadeByMyEmployee: true,
  },
};

export const defaultTabFilter = tabFilters.all;

interface Props {
  onTabChanged: (tab: Tab) => void;
  activeTab: Tab;
  countLoading: boolean;
  totalCount?: number;
  myResourcesCount?: number;
  myEmployeesCount?: number;
}

export const BookingsManagementFilterTabs: React.FC<Props> = ({
  onTabChanged,
  activeTab,
  countLoading,
  totalCount,
  myResourcesCount,
  myEmployeesCount,
}) => {
  const { t } = useTranslation();
  const { spacers } = useTheme(true);

  const tabTitles: Record<Tab, string> = {
    all: t("common.all"),
    myResources: t("bookings.operations.myResources"),
    myEmployees: t("bookings.operations.myEmployees"),
  };

  const tabCounts: Record<Tab, number | undefined> = {
    all: totalCount,
    myResources: myResourcesCount,
    myEmployees: myEmployeesCount,
  };

  return (
    <div className="bookings-tabs-container">
      {tabOrder.map((tab) => (
        <FilterTab
          key={tab}
          title={tabTitles[tab]}
          value={tabCounts[tab]}
          valueLoading={countLoading}
          isActive={activeTab === tab}
          onClick={() => {
            onTabChanged(tab);
          }}
        />
      ))}
      <style jsx>{`
        .bookings-tabs-container {
          display: flex;
          gap: ${spacers.s3};
        }
        .bookings-tabs-container :global(.tab) {
          font-size: 14px;
          text-transform: capitalize;
        }
      `}</style>
    </div>
  );
};
