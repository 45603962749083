import React, { useContext, useMemo } from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import type { FilterItem } from "@equiem/react-admin-ui";
import { ComplexFilter, useIsMobileWidth, useTheme } from "@equiem/react-admin-ui";
import { CreditsFilterContext } from "../../../contexts/CreditsFilterContext";
import {
  CreditTransactionsQueryRole,
  CreditTransactionType,
  useDestinationDetailsQuery,
} from "../../../generated/gateway-client";
import { useCreditsFilterCreditMonth } from "../hooks/useCreditsFilterCreditMonth";
import { useCreditsFilterDate } from "../hooks/useCreditsFilterDate";
import { useCreditsFilterType } from "../hooks/useCreditsFilterType";
import { useCreditsFilterUser } from "../hooks/useCreditsFilterUser";
import { CurrentRole, Role, UrlParams, useSiteContext } from "@equiem/lib";
import { useCreditsFilterTenants } from "../hooks/useCreditsFilterTenants";
import { useRoleMapping } from "../hooks/useRoleMapping";
import { useCreditsFilterCompany } from "../hooks/useCreditsFilterCompany";

const typesToFilter = [
  CreditTransactionType.Adjustment,
  CreditTransactionType.Charge,
  CreditTransactionType.Purchase,
  CreditTransactionType.Refund,
];

export const CreditsFilters = ({ exportTemplate }: { exportTemplate?: React.ReactNode }) => {
  const isMobile = useIsMobileWidth();
  const { t, i18n } = useTranslation();
  const { colors, breakpoints } = useTheme();
  const urlParamsProvider = useContext(UrlParams);
  const { uuid: siteUuid } = useSiteContext();
  const { filters, setFilters } = useContext(CreditsFilterContext);
  const { currentRole } = useContext(CurrentRole);
  const role = useRoleMapping();
  const isCreditManager = role === CreditTransactionsQueryRole.CreditManager;
  const isFlexManager = currentRole === Role.FlexManager;
  const { data } = useDestinationDetailsQuery({
    variables: {
      uuid: siteUuid,
    },
  });
  const isFlexSite = data?.destination.siteFlexOperator != null;

  const { filter: user } = useCreditsFilterUser();
  const { filter: type } = useCreditsFilterType(typesToFilter);
  const { filter: date } = useCreditsFilterDate();
  const { filter: tenants } = useCreditsFilterTenants(isFlexSite);
  const { filter: datemonth } = useCreditsFilterCreditMonth();
  const { filter: companies } = useCreditsFilterCompany();
  const complexFilters = useMemo((): Record<string, FilterItem> => {
    return {
      date,
      ...(isCreditManager && !isFlexManager && { companies }),
      ...(!isFlexManager && !isCreditManager && { user }),
      ...(isFlexManager && { tenants }),
      type,
      datemonth,
    };
  }, [user, type, date, datemonth, isCreditManager, isFlexManager, companies, user, tenants]);

  return (
    <>
      <div className="filters">
        <ComplexFilter
          filters={complexFilters}
          onChange={setFilters}
          language={i18n.language}
          autoShow
          urlParamsProvider={urlParamsProvider}
        >
          {({ renderChips, renderFilterButton, renderClearButton }) => (
            <>
              <div className="main-row">
                <div className="left-side">
                  <h1>
                    <b>{t("credits.title")}</b>
                  </h1>
                </div>
                <div className="right-side">
                  {renderFilterButton("primary", "bottom-end")}
                  {exportTemplate}
                </div>
              </div>
              {!isMobile && Object.keys(filters).length > 0 && (
                <div className="chips-row">
                  <div className="left-side">{renderChips()}</div>
                  <div className="right-side">{renderClearButton()}</div>
                </div>
              )}
            </>
          )}
        </ComplexFilter>
      </div>
      <style jsx>{`
        .filters {
          display: flex;
          flex-direction: column;
          width: 100%;
        }
        .main-row {
          display: flex;
          justify-content: space-between;
          gap: 8px;
          margin-bottom: 16px;
        }
        .right-side,
        .left-side {
          display: flex;
          gap: 8px;
          align-items: center;
        }
        .chips-row {
          display: flex;
          justify-content: space-between;
          gap: 8px;
          border-top: 1px solid ${colors.grayscale[10]};
          padding: 8px 0 16px;
        }

        .search-container {
          width: 270px;
        }

        @media (max-width: ${breakpoints.lg}px) {
          .main-row {
            flex-direction: row-w;
            max-width: 100vw;
          }

          .main-row .left-side {
            overflow-x: auto;
            padding: 0;
          }

          .main-row .left-side h1 {
            font-size: 20px;
          }

          .main-row .left-side::-webkit-scrollbar,
          .quick-filters-dropdown::-webkit-scrollbar {
            display: none;
          }

          .main-row .right-side {
            display: grid;
            grid-template-columns: 32px 32px;
            align-items: center;
            padding: 0;
          }

          .search-container {
            width: 100%;
          }

          .quick-filters-dropdown {
            display: flex;
            gap: 8px;
            align-items: flex-start;
            padding: 8px;
            overflow-x: auto;
            min-height: 48px;
          }
        }
      `}</style>
    </>
  );
};
