import React, { useContext, useEffect, useState } from "react";
import { Formik } from "formik";
import { DateTime } from "luxon";
import { useRouter } from "next/router";
import * as yup from "yup";

import { stringNotEmpty } from "@equiem/lib";
import type { TFunction } from "@equiem/localisation-eq1";
import { useTranslation } from "@equiem/localisation-eq1";
import { Form, Modal, Text, useIsMobileWidth, useToast } from "@equiem/react-admin-ui";
import { RiFilePdf2Line } from "@equiem/react-admin-ui/icons";

import { Modal as ModalContext } from "../../../../contexts/ModalContext";
import {
  useGenerateVisitorAnalysisReportMutation,
  useGetReceptionLazyQuery,
} from "../../../../generated/visitors-client";

import { GenerateReportPlaque } from "./GenerateReportPlaque";

type FormValues = {
  fromDate?: string;
  toDate?: string;
};

const getValidationSchema = (t: TFunction) =>
  yup.object().shape<Partial<{ [key in keyof FormValues]: yup.AnySchema }>>({
    toDate: yup.string().required(),
    fromDate: yup
      .string()
      .required()
      .test("is-valid-from-date", t("visitors.widgets.pdfReport.fromDateInvalid"), function (value) {
        const { toDate } = this.parent as { toDate?: string };

        if (stringNotEmpty(toDate) && stringNotEmpty(value)) {
          const toDateObj = DateTime.fromFormat(toDate, "yyyy-MM-dd").valueOf();
          const fromDateObj = DateTime.fromFormat(value, "yyyy-MM-dd").valueOf();

          return fromDateObj <= toDateObj;
        }

        return false;
      }),
  });

/*
  This widget should ONLY EVER be called from building reception or all receptions view, as it relies on router.query to get reception's uuid
*/
export const ReceptionReportPdfWidget: React.FC = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const modal = useContext(ModalContext);
  const toast = useToast();
  const router = useRouter();
  /* istanbul ignore next */
  const [generateReport] = useGenerateVisitorAnalysisReportMutation();
  const [url, setUrl] = useState<string>();
  const isMobile = useIsMobileWidth();

  const initialValues: FormValues = {
    fromDate: DateTime.now().minus({ months: 1 }).startOf("month").toFormat("yyyy-MM-dd"),
    toDate: DateTime.now().minus({ months: 1 }).endOf("month").toFormat("yyyy-MM-dd"),
  };

  const [fetchReceptionData] = useGetReceptionLazyQuery({
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (modal.activeModal === "ReceptionReportPdfWidget") {
      setShowModal(true);
    }
  }, [modal.activeModal, modal.id, setShowModal]);

  const onClose = () => {
    setShowModal(false);
    modal.close();
  };

  const generateReportUrlFn = async (values: FormValues) => {
    if (!stringNotEmpty(values.fromDate) || !stringNotEmpty(values.toDate)) {
      return null;
    }

    let buildingUuids: string[] | undefined = undefined;
    const uuid = router.query.uuid as string | undefined;
    if (stringNotEmpty(uuid)) {
      const result = await fetchReceptionData({ variables: { uuid: uuid } });
      if (result.error != null || result.data?.visitorReception.building?.uuid == null) {
        throw result.error ?? new Error(t("common.somethingWrong"));
      }
      buildingUuids = [result.data.visitorReception.building.uuid];
    }

    const result = await generateReport({
      variables: {
        input: {
          fromDate: DateTime.fromFormat(values.fromDate, "yyyy-MM-dd").toMillis(),
          endDate: DateTime.fromFormat(values.toDate, "yyyy-MM-dd").endOf("day").toMillis(),
          buildingUuids: buildingUuids,
        },
      },
    })
      .then((res) => {
        const resultUrl = res.data?.generateVisitorAnalysisReport.url;
        if (stringNotEmpty(resultUrl)) {
          window.open(resultUrl, "_blank");
        }
        return resultUrl;
      })
      .catch((_) => {
        toast.negative(t("visitors.widgets.pdfReport.failedToGenerateReport"));
        return null;
      });

    return result;
  };

  return (
    <>
      <Modal.Dialog
        className="delete-resource-modal"
        title={t("visitors.widgets.pdfReport.title")}
        show={showModal}
        onHide={onClose}
        hideOnEsc={true}
        hideOnClick={false}
        centered={true}
        size="md"
      >
        <Modal.Header closeButton={true} noBorder={true}></Modal.Header>
        <Formik<FormValues>
          initialValues={initialValues}
          validationSchema={getValidationSchema(t)}
          onSubmit={console.log}
        >
          {({ isValid, errors, setFieldValue, values }) => (
            <>
              <Modal.Body>
                <Text variant="text">{t("visitors.widgets.pdfReport.description")}</Text>
                <div className="pdf-widget-dates-container">
                  <Form.Group label={t("visitors.widgets.pdfReport.fromDate")} error={errors.fromDate}>
                    <Form.Input
                      type="date"
                      value={values.fromDate}
                      calendarIconPosition={"left"}
                      min={DateTime.now().minus({ years: 20 }).toFormat("yyyy-MM-dd")}
                      max={DateTime.now().toFormat("yyyy-MM-dd")}
                      onChange={(e) => {
                        setUrl(undefined);
                        void setFieldValue("fromDate", e.target.value);
                      }}
                    />
                  </Form.Group>
                  <Form.Group label={t("visitors.widgets.pdfReport.toDate")} error={errors.toDate}>
                    <Form.Input
                      type="date"
                      value={values.toDate}
                      calendarIconPosition={"left"}
                      min={DateTime.now().minus({ years: 20 }).toFormat("yyyy-MM-dd")}
                      max={DateTime.now().toFormat("yyyy-MM-dd")}
                      onChange={(e) => {
                        setUrl(undefined);
                        void setFieldValue("toDate", e.target.value);
                      }}
                    />
                  </Form.Group>
                </div>
                <GenerateReportPlaque
                  title={t("visitors.widgets.pdfReport.plaqueTitle") as string}
                  description={t("visitors.widgets.pdfReport.plaqueDescription") as string}
                  icon={RiFilePdf2Line}
                  generateReportUrlFn={async () => generateReportUrlFn(values)}
                  url={url}
                  setUrl={setUrl}
                  disabled={!isValid}
                ></GenerateReportPlaque>
              </Modal.Body>
            </>
          )}
        </Formik>
      </Modal.Dialog>
      <style jsx>{`
        .pdf-widget-dates-container {
          display: flex;
          flex-direction: ${isMobile ? "column" : "row"};
          gap: ${isMobile ? "0px" : "8px"};
        }
        .spinner-container {
          display: flex;
          height: 378px;
          justify-content: center;
          align-items: center;
          background-color: white;
          color: gray;
        }
      `}</style>
    </>
  );
};
