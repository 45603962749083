import React, { forwardRef, useContext } from "react";

import { DropdownContext } from "../Dropdown";
import type { NavItemProps } from "../../NavItem/NavItem";
import { NavItem } from "../../NavItem/NavItem";

interface DropdownItemProps extends NavItemProps {
  autoClose?: boolean;
}

export const DropdownItem = forwardRef<HTMLDivElement | null, DropdownItemProps>(
  ({ children, autoClose = true, ...props }, ref) => {
    const { autoClose: triggerAutoClose } = useContext(DropdownContext);

    return (
      <NavItem
        {...props}
        onClick={(e) => {
          if (autoClose) {
            triggerAutoClose();
          }
          props.onClick?.(e);
        }}
        ref={ref}
      >
        {children}
      </NavItem>
    );
  },
);

DropdownItem.displayName = "DropdownItem";
