import type { PropsWithChildren } from "react";
import React, { createContext, useCallback, useRef, useState } from "react";
import type { BookableResourceFragmentFragment, BookingFragmentFragment } from "../../../generated/gateway-client";
import { Button, Modal, ConfirmerProvider } from "@equiem/react-admin-ui";
import { useTranslation } from "@equiem/localisation-eq1";
import { useRouter } from "next/router";

type Resource = BookableResourceFragmentFragment;
type Booking = BookingFragmentFragment;
type Expansion = { resource: Resource; booking?: Booking };
export type DisplayMode = "adminForm" | "selfForm" | "view";

export interface BookingModalContext {
  id?: string;
  start?: number;
  end?: number;
  selectFirstAvailability: boolean;
  open: (id: string, start?: number, end?: number, selectFirstAvailability?: boolean) => void;
  close: (skipConfirmation?: boolean, skipNavigation?: boolean) => void;
  setConfirmationTextBeforeClose: (text: string) => void;
  setShowConfirmationBeforeClose: (value: boolean) => void;
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  renderTitle: (() => React.ReactNode) | null;
  setTitleRenderer: (render: (() => React.ReactNode) | null) => void;
  footerRef: React.RefObject<HTMLDivElement> | null;
  showFooter: boolean;
  setShowFooter: (show: boolean) => void;
  showExpansion?: Expansion;
  setShowExpansion: (val?: Expansion) => void;
  canCloseModal: boolean;
  setCanCloseModal: (value: boolean) => void;
  canSubmitForm: boolean;
  setCanSubmitForm: (value: boolean) => void;
  setDisplayMode: (mode: DisplayMode) => void;
  displayMode: DisplayMode;
  setStart: (val?: number) => void;
  setEnd: (val?: number) => void;
}

export const BookingModal = createContext<BookingModalContext>({
  title: "",
  setTitle: () => undefined,
  renderTitle: null,
  setTitleRenderer: () => undefined,
  selectFirstAvailability: false,
  open: () => undefined,
  close: () => undefined,
  footerRef: null,
  showFooter: true,
  setShowFooter: () => undefined,
  setShowExpansion: () => undefined,
  canCloseModal: false,
  setCanCloseModal: () => undefined,
  canSubmitForm: false,
  setCanSubmitForm: () => undefined,
  setConfirmationTextBeforeClose: () => undefined,
  setShowConfirmationBeforeClose: () => undefined,
  setDisplayMode: () => undefined,
  displayMode: "selfForm",
  setStart: () => undefined,
  setEnd: () => undefined,
});

interface P extends PropsWithChildren {
  defaultId?: string;
  defaultDisplayMode?: DisplayMode;
  defaultSelectFirstAvailability?: boolean;
}
export const BookingModalProvider: React.FC<P> = ({
  children,
  defaultId,
  defaultDisplayMode = "selfForm",
  defaultSelectFirstAvailability = false,
}) => {
  const { t } = useTranslation();
  const [title, setTitle] = useState("");
  const [id, setId] = useState<string | undefined>(defaultId);
  const [start, setStart] = useState<number | undefined>();
  const [end, setEnd] = useState<number | undefined>();
  const [selectFirstAvailability, setSelectFirstAvailability] = useState(defaultSelectFirstAvailability);
  const [showExpansion, setShowExpansion] = useState<Expansion | undefined>(undefined);
  const footerRef = useRef<HTMLDivElement>(null);
  const [showFooter, setShowFooter] = useState(true);
  const [canCloseModal, setCanCloseModal] = useState(true);
  const [canSubmitForm, setCanSubmitForm] = useState(false);
  const router = useRouter();

  const [renderTitle, _setTitleRenderer] = useState<(() => React.ReactNode) | null>(null);
  const setTitleRenderer = useCallback((render: (() => React.ReactNode) | null) => _setTitleRenderer(() => render), []);

  const [displayMode, setDisplayMode] = useState(defaultDisplayMode);

  const [confirmationTextBeforeClose, setConfirmationTextBeforeClose] = useState(
    t("bookings.operations.cancelBookingEditConfirmation"),
  );
  const [showConfirmationBeforeClose, setShowConfirmationBeforeClose] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const closeConfirmationModal = useCallback(() => {
    setShowConfirmationModal(false);
  }, []);

  const open = useCallback(
    (idInput: string, startInput?: number, endInput?: number, selectFirstAvailabilityInput?: boolean) => {
      setId(idInput);
      setStart(startInput);
      setEnd(endInput);
      setSelectFirstAvailability(selectFirstAvailabilityInput ?? false);
    },
    [],
  );

  const closeModal = useCallback(
    (skipNavigation: boolean) => {
      if (canCloseModal) {
        const url = new URL(router.asPath, window.location.origin);
        url.searchParams.delete("resource");
        url.searchParams.delete("booking");
        url.searchParams.delete("first");
        const newAsPath = `${url.pathname}${url.search}`;
        const navPromise =
          !skipNavigation && newAsPath !== router.asPath
            ? router.push(newAsPath, undefined, { scroll: false })
            : Promise.resolve();
        setCanSubmitForm(false);
        setCanCloseModal(true);
        setShowConfirmationBeforeClose(false);
        closeConfirmationModal();
        setShowExpansion(undefined);

        navPromise
          .then(() => {
            setTitle("");
            setId(undefined);
            setStart(undefined);
            setEnd(undefined);
          })
          .catch((e) => {
            console.error(e);
          });
      }
    },
    [canCloseModal, closeConfirmationModal, router],
  );

  const close = useCallback(
    (skipConfirmation = false, skipNavigation = false) => {
      if (!skipConfirmation && showConfirmationBeforeClose) {
        setShowConfirmationModal(true);
        return;
      }
      closeModal(skipNavigation);
      setTitleRenderer(null);
    },
    [closeModal, showConfirmationBeforeClose, setTitleRenderer],
  );

  return (
    <ConfirmerProvider>
      <BookingModal.Provider
        value={{
          displayMode,
          setDisplayMode,
          setConfirmationTextBeforeClose,
          setShowConfirmationBeforeClose,
          showExpansion,
          canCloseModal,
          setCanCloseModal,
          canSubmitForm,
          setCanSubmitForm,
          setShowExpansion,
          footerRef,
          showFooter,
          setShowFooter,
          title,
          setTitle,
          renderTitle,
          setTitleRenderer,
          open,
          close,
          id,
          selectFirstAvailability,
          start,
          setStart,
          end,
          setEnd,
        }}
      >
        {children}
        {showConfirmationModal && (
          <Modal.Dialog
            title={t("common.areYouSure")}
            show
            onHide={closeConfirmationModal}
            hideOnEsc={true}
            supportsMobile={true}
            mobileView
            hideOnClick={false}
            focusTrapOptions={{ initialFocus: false }}
            size="md"
            className="cancel-action-modal"
            centered
          >
            <Modal.Header closeButton={false} noBorder={true} />
            <Modal.Body>{confirmationTextBeforeClose}</Modal.Body>
            <Modal.Footer>
              <Button variant="ghost" className="mr-4" onClick={closeConfirmationModal}>
                {t("common.cancelNo")}
              </Button>
              <Button variant="danger" className="cancel-action" onClick={() => closeModal(false)}>
                {t("common.yesCancel")}
              </Button>
            </Modal.Footer>
          </Modal.Dialog>
        )}
      </BookingModal.Provider>
    </ConfirmerProvider>
  );
};
