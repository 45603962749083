import type { ReactNode } from "react";
import React, { useCallback, useState, useContext, createContext, Fragment } from "react";
import { useTranslation } from "@equiem/localisation-eq1";

import * as Modal from "../components/Modal";
import type { BaseButtonVariants } from "../components/Button/AdminButton";
import { AdminButton as Button } from "../components/Button/AdminButton";
import { Button as EndUserButton } from "../components/Button/Button";
import { ToastContext } from "../components/Toast/ToastContext";
import { ToastProvider } from "../components/Toast/ToastProvider";
import { useTheme } from "./Theme";
import type { ModalContext } from "../components/Modal/ModalContext";
import { ProgressCircle } from "../components/ProgressCircle/ProgressCircle";

interface WithConfirmation {
  title: string;
  intro?: string;
  message: string;
  confirmButtonText: string;
  confirmButtonVariant?: Extract<BaseButtonVariants, "primary" | "danger">;
  cancelButtonText?: string;
  onConfirm: () => unknown;
  waitForOnConfirm?: boolean;
  size?: ModalContext["size"];
}

export interface ConfirmerContext {
  withConfirmation: (input: WithConfirmation) => () => void;
}

export const ConfirmerContext = createContext<ConfirmerContext>({
  withConfirmation: () => () => undefined,
});

const defaultVariant: BaseButtonVariants = "primary";

export const ConfirmerProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { t } = useTranslation();
  const defaultCancelBtn = t("common.cancelNo");
  const defaultSize = "sm";

  const existingToastContext = useContext(ToastContext);
  const ConditionalToastProvider = existingToastContext.provided ? Fragment : ToastProvider;

  const { isAdmin, spacers, breakpoints } = useTheme();
  const [showModal, setShowModal] = useState(false);
  const [handler, setHandler] = useState<() => void>();
  const [waitForHandler, setWaitForHandler] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [title, setTitle] = useState("");
  const [msg, setMessage] = useState("");
  const [variant, setVariant] = useState<BaseButtonVariants>(defaultVariant);
  const [confirmButtonText, setConfirmButtonText] = useState("");
  const [intro, setIntro] = useState<string>();
  const [cancelButtonText, setCancelButtonText] = useState(defaultCancelBtn);
  const [size, setSize] = useState<ModalContext["size"]>(defaultSize);

  const withConfirmation = useCallback(
    (input: WithConfirmation) => {
      return () => {
        setMessage(input.message);
        setTitle(input.title);
        setConfirmButtonText(input.confirmButtonText);
        setCancelButtonText(input.cancelButtonText ?? defaultCancelBtn);
        setIntro(input.intro);
        setHandler(() => input.onConfirm);
        setWaitForHandler(input.waitForOnConfirm ?? false);
        setVariant(input.confirmButtonVariant ?? defaultVariant);
        setShowModal(true);
        setSize(input.size ?? defaultSize);
      };
    },
    [defaultCancelBtn],
  );

  const hideModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const localHandler = useCallback(() => {
    if (waitForHandler) {
      setIsSubmitting(true);
      Promise.resolve(handler?.())
        .then(() => hideModal())
        .finally(() => setIsSubmitting(false));
    } else {
      handler?.();
      hideModal();
    }
  }, [waitForHandler, handler, hideModal]);

  return (
    <ConfirmerContext.Provider
      value={{
        withConfirmation,
      }}
    >
      <ConditionalToastProvider>
        <>
          {children}
          <Modal.Dialog
            title={title}
            show={showModal}
            onHide={hideModal}
            hideOnEsc={true}
            centered={true}
            withFooter={true}
            size={size}
            className="confirm-modal"
            containerClassName="confirm-modal-container"
            zIndex={1020}
          >
            <Modal.Header closeButton={false} intro={intro} noBorder={true} />
            <Modal.Body>{msg}</Modal.Body>
            <Modal.Footer>
              <div className="footer">
                {isAdmin ? (
                  <Button size="md" onClick={hideModal} className="cancel" variant="ghost">
                    {cancelButtonText}
                  </Button>
                ) : (
                  <EndUserButton size="md" onClick={hideModal} className="cancel" variant="secondary">
                    {cancelButtonText}
                  </EndUserButton>
                )}
                {isAdmin ? (
                  <Button onClick={localHandler} size="md" className="confirm" variant={variant}>
                    {isSubmitting && <ProgressCircle className="mr-2" size="xs" />}
                    {confirmButtonText}
                  </Button>
                ) : (
                  <EndUserButton onClick={localHandler} size="md" className="confirm" variant="primary">
                    {isSubmitting && <ProgressCircle className="mr-2" size="xs" />}
                    {confirmButtonText}
                  </EndUserButton>
                )}
              </div>
            </Modal.Footer>
          </Modal.Dialog>
          <style jsx>{`
            .footer :global(.cancel) {
              margin-right: ${spacers.s2};
            }

            @media (max-width: ${breakpoints.md}px) {
              :global(.confirm-modal) {
                max-width: unset !important;
                width: 100vw !important;
                position: fixed !important;
                top: unset !important;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: ${spacers.s2} ${spacers.s2} 0 0 !important;
              }
            }
          `}</style>
        </>
      </ConditionalToastProvider>
    </ConfirmerContext.Provider>
  );
};
