import { stringNotEmpty } from "@equiem/lib";
import { BookableResourceAddOnType, type ExistingBookingChargesQuery } from "../../../generated/gateway-client";
import type { PartialRefunds } from "../models/PartialRefunds";
import type { TFunction } from "@equiem/localisation-eq1";

type AddOnType = NonNullable<ExistingBookingChargesQuery["siteBookingByReference"]>["addOns"];

const nonEmptyOrNull = (val: string | null | undefined) => (stringNotEmpty(val) ? val : null);

const truncate = (val: string | null | undefined, limit = 300) =>
  stringNotEmpty(val) && val.length > limit ? `${val.slice(0, limit).trimEnd()}...` : val;

export interface FlatBookingAddOns {
  uuid: string;
  description: string;
  amount: number;
  quantity: number;
  partialRefunds: PartialRefunds[];
  fullDescription?: string;
}

export const flatExistingBookingAddOns = (
  addons: AddOnType,
  t: TFunction,
  includeTextValues = false,
): FlatBookingAddOns[] =>
  addons.flatMap((addOn) => {
    if (addOn.addOnType === BookableResourceAddOnType.FreeText) {
      return {
        uuid: addOn.uuid,
        description: includeTextValues
          ? `${stringNotEmpty(addOn.addOnName) ? `${addOn.addOnName}: ` : ""}${truncate(addOn.description) ?? ""}`
          : nonEmptyOrNull(addOn.addOnName) ?? nonEmptyOrNull(addOn.description) ?? "",
        amount: 0,
        quantity: 1,
        partialRefunds: [],
        fullDescription: addOn.description,
      };
    }

    return addOn.options.map((option) => {
      const quantity = option.hasQuantity ? option.quantity ?? 1 : 1;

      return {
        uuid: option.uuid,
        description: `${stringNotEmpty(addOn.addOnName) ? `${addOn.addOnName}: ` : ""}${option.description}`,
        amount: quantity * option.unitPrice,
        quantity,
        partialRefunds: option.partialRefunds.map((r) => ({
          uuid: r.uuid,
          reason: r.reason,
          amount: r.amount,
          user: r.user.profile?.displayName ?? t("common.unknown"),
        })),
      };
    });
  }, []);
