import React, { useState } from "react";

export type SettingsModal =
  | "EditProfile"
  | "CompanyDetails"
  | "ApartmentDetails"
  | "AddCatalogue"
  | "BuildingDetails"
  | "BuildingSpace"
  | "BuildingLevel";

export interface ModalContext {
  activeModal?: SettingsModal;
  id?: string;
  open: (type: SettingsModal, id?: string) => void;
  close: () => void;
}

/* istanbul ignore next */
export const Modal = React.createContext<ModalContext>({
  open: (_type: SettingsModal) => undefined,
  close: () => undefined,
});

interface Props {
  testActiveModal?: SettingsModal;
  testId?: string;
}

export const ModalProvider: React.FC<React.PropsWithChildren<Props>> = ({ children, testActiveModal, testId }) => {
  const [activeModal, setActiveModal] = useState<SettingsModal | undefined>(testActiveModal);
  const [id, setId] = useState<string | undefined>(testId);

  const open = (type: SettingsModal, _id?: string) => {
    setActiveModal(type);
    setId(_id);
  };

  const close = () => {
    setActiveModal(undefined);
    setId(undefined);
  };

  return (
    <Modal.Provider
      value={{
        open,
        close,
        activeModal,
        id,
      }}
    >
      {children}
    </Modal.Provider>
  );
};
