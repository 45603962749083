import { useTranslation, formatters } from "@equiem/localisation-eq1";
import { Form, Text, useTheme } from "@equiem/react-admin-ui";
import { Field, useFormikContext } from "formik";
import { DateTime, Duration } from "luxon";
import React, { useContext } from "react";
import { ResourceDivider } from "../ResourceDivider";
import { useCompanyIsFlexOperator } from "../../../hooks/useCompanyIsFlexOperator";
import { BookableResourcePaymentMethod } from "../../../../../generated/gateway-client";
import { useCurrency } from "../../../../../hooks/useCurrency";
import { parseTime, toSeconds } from "../../../../../lib/dateTimeHelpers";
import {
  addOnsHasPrice,
  hasAnyBaseRates,
  segmentationHasRates,
  type FormValues,
} from "../../../../../lib/formValidation";
import { ResourceCreateAndEditSiteContext } from "../ResourceCreateAndEditSiteContext";
import { useResourceBuildingInfo } from "../../../hooks/useResourceBuildingInfo";
import { resourceCanBeTaxExempt } from "../../../../../lib/taxExemption";

const defaultHalfDayDuration = ({ businessHoursStart, businessHoursEnd }: FormValues, timezone: string) => {
  const start = DateTime.fromFormat(businessHoursStart ?? "", "HH:mm", { zone: timezone });
  const end = DateTime.fromFormat(businessHoursEnd ?? "", "HH:mm", { zone: timezone });
  if (!start.isValid || !end.isValid || start >= end) {
    return "hh:mm";
  }

  const halfDayInMinutes = Math.round(end.diff(start).toMillis() / (2 * 60 * 1000));
  return Duration.fromDurationLike({ minutes: halfDayInMinutes }).toFormat("hh:mm");
};

export const ResourceCreateAndEditFormRates: React.FC = () => {
  const { t, i18n } = useTranslation();
  const fm = useFormikContext<FormValues>();
  const { breakpoints, colors, spacers } = useTheme(true);
  const { currency } = useCurrency(fm.values.building);
  const { canManageSite } = useContext(ResourceCreateAndEditSiteContext);

  const { taxRate, timezone } = useResourceBuildingInfo(fm.values.building);

  const taxExemption = resourceCanBeTaxExempt(fm.values);

  const isFlexOperator = useCompanyIsFlexOperator();

  return (
    <>
      <Text variant="label" color={colors.grayscale[60]}>
        {t("bookings.resources.defaultResourceRates")}
      </Text>

      <div className="form-group-container">
        <div>
          <Form.Group label={t("bookings.resources.hourly")}>
            <Field name="paymentRateHourly" as={Form.Money} currency={currency} min={0} max={9999.99} />
          </Form.Group>
        </div>

        <div>
          <Form.Group label={t("bookings.resources.halfDay")}>
            <Field name="paymentRateHalfDay" as={Form.Money} currency={currency} min={0} max={9999.99} />
          </Form.Group>
        </div>

        <div>
          <Form.Group label={t("bookings.resources.fullDay")}>
            <Field name="paymentRateFullDay" as={Form.Money} currency={currency} min={0} max={9999.99} />
          </Form.Group>
        </div>

        <div>
          <Form.Group label={t("bookings.resources.hourlyAfterHours")}>
            <Field name="paymentRateHourlyAfterHours" as={Form.Money} currency={currency} min={0} max={9999.99} />
          </Form.Group>
        </div>

        <div>
          <Form.Group label={t("bookings.resources.hourlyWeekend")}>
            <Field name="paymentRateHourlyWeekend" as={Form.Money} currency={currency} min={0} max={9999.99} />
          </Form.Group>
        </div>

        <div>
          <Form.Group label={t("bookings.resources.startOfBusinessHours")} error={fm.errors.businessHoursStart}>
            <Field name="businessHoursStart">
              {() => (
                <Form.TimeSelect
                  name="businessHoursStart"
                  defaultOptionValue={t("bookings.settings.startTime")}
                  value={toSeconds(fm.values.businessHoursStart)}
                  onChange={(e) => {
                    fm.setFieldValue("businessHoursStart", parseTime(e)).catch(console.error);
                  }}
                  startTime={{ hour: 0, minute: 0 }}
                  endTime={{ hour: 23, minute: 59 }}
                  diffMinutes={15}
                />
              )}
            </Field>
          </Form.Group>
        </div>

        <div>
          <Form.Group label={t("bookings.resources.endOfBusinessHours")} error={fm.errors.businessHoursEnd}>
            <Field name="businessHoursEnd">
              {() => (
                <Form.TimeSelect
                  name="businessHoursEnd"
                  defaultOptionValue={t("bookings.settings.endTime")}
                  value={toSeconds(fm.values.businessHoursEnd)}
                  onChange={(e) => {
                    fm.setFieldValue("businessHoursEnd", parseTime(e)).catch(console.error);
                  }}
                  startTime={{ hour: 0, minute: 0 }}
                  endTime={{ hour: 23, minute: 59 }}
                  diffMinutes={15}
                />
              )}
            </Field>
          </Form.Group>
        </div>

        <div>
          <Form.Group
            label={t("bookings.resources.halfDayDurationHours")}
            error={fm.errors.businessHoursHalfDayDuration}
          >
            <Field
              name="businessHoursHalfDayDuration"
              as={Form.Duration}
              placeholder={defaultHalfDayDuration(fm.values, timezone)}
            />
          </Form.Group>
        </div>
      </div>

      <ResourceDivider />

      <Form.Group
        label={t("bookings.resources.paymentMethods")}
        error={fm.errors.paymentMethods ?? undefined}
        required={hasAnyBaseRates(fm.values) || segmentationHasRates(fm.values) || addOnsHasPrice(fm.values)}
      >
        <div className="payment-methods-container">
          <Field
            as={Form.Checkbox}
            id={`pm-${BookableResourcePaymentMethod.CreditCard}`}
            name="paymentMethods"
            label={t("common.creditCard")}
            disabled={!canManageSite}
            value={fm.values.paymentMethods?.includes(BookableResourcePaymentMethod.CreditCard)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const updatedMethods = e.target.checked
                ? [...(fm.values.paymentMethods ?? []), BookableResourcePaymentMethod.CreditCard]
                : (fm.values.paymentMethods ?? []).filter(
                    (method) => method !== BookableResourcePaymentMethod.CreditCard,
                  );
              fm.setFieldValue("paymentMethods", updatedMethods).catch(console.error);
            }}
          />
          <Field
            as={Form.Checkbox}
            id={`pm-${BookableResourcePaymentMethod.Invoice}`}
            name="paymentMethods"
            label={t("common.invoice")}
            value={fm.values.paymentMethods?.includes(BookableResourcePaymentMethod.Invoice)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const updatedMethods = e.target.checked
                ? [...(fm.values.paymentMethods ?? []), BookableResourcePaymentMethod.Invoice]
                : (fm.values.paymentMethods ?? []).filter((method) => method !== BookableResourcePaymentMethod.Invoice);
              fm.setFieldValue("paymentMethods", updatedMethods).catch(console.error);
            }}
          />
          {isFlexOperator && (
            <Field
              as={Form.Checkbox}
              id={`pm-${BookableResourcePaymentMethod.Credits}`}
              name="paymentMethods"
              label={t("common.credits")}
              value={fm.values.paymentMethods?.includes(BookableResourcePaymentMethod.Credits)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const updatedMethods = e.target.checked
                  ? [...(fm.values.paymentMethods ?? []), BookableResourcePaymentMethod.Credits]
                  : (fm.values.paymentMethods ?? []).filter(
                      (method) => method !== BookableResourcePaymentMethod.Credits,
                    );
                fm.setFieldValue("paymentMethods", updatedMethods).catch(console.error);
              }}
            />
          )}
        </div>
      </Form.Group>

      <Form.Group label={t("bookings.resources.tax")} tooltipText={t("bookings.resources.taxRateTooltips")} showTooltip>
        <div className="tax-exempt-container">
          {taxExemption.allowed ? (
            <Field as={Form.Checkbox} id="taxExempt" name="taxExempt" label={t("bookings.resources.taxRate")} />
          ) : (
            <Form.Checkbox label={t("bookings.resources.taxRate")} disabled value={taxExemption.displayValue} />
          )}
          <strong>({formatters.percentage(taxRate, i18n.language)})</strong>
        </div>
      </Form.Group>

      <style jsx>{`
        .form-group-container {
          display: grid;
          grid-template-columns: repeat(3, minmax(30%, 1fr));
          grid-gap: ${spacers.s7} ${spacers.s4};
          align-items: flex-end;
        }
        .form-group-container > :nth-child(6) {
          grid-column: 1;
        }
        .form-group-container :global(.form-header label) {
          text-wrap: wrap;
        }
        .payment-methods-container {
          display: flex;
          gap: ${spacers.s7};
        }
        .tax-exempt-container {
          display: flex;
          gap: ${spacers.s3};
          align-items: center;
        }
        @media screen and (max-width: ${breakpoints.md}px) {
          .form-group-container {
            display: flex;
            flex-direction: column;
            gap: ${spacers.s5};
            align-items: stretch;
          }
        }
      `}</style>
    </>
  );
};
