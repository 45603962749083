import React, { useContext } from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { Dropdown } from "@equiem/react-admin-ui";
import { RiFileDownloadLine, RiFileExcelLine, RiFilePdf2Line } from "@equiem/react-admin-ui/icons";

import { Modal } from "../../../../contexts/ModalContext";

import { ReceptionReportPdfWidget } from "./ReceptionReportPdfWidget";

interface Props {
  exportCsv: () => void;
  csvReportLoading: boolean;
}

export const ReceptionReports: React.FC<Props> = ({ exportCsv, csvReportLoading }) => {
  const { t } = useTranslation();
  const modal = useContext(Modal);

  return (
    <>
      <Dropdown.Icon
        size="sm"
        className="reception-report-button"
        icon={RiFileDownloadLine}
        placement="bottom-end"
        title={undefined}
      >
        <Dropdown.Item
          onClick={() => {
            exportCsv();
          }}
          icon={RiFileExcelLine}
          disabled={csvReportLoading}
        >
          {t("visitors.reception.csvExportReceptionView")}
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            void modal.open("ReceptionReportPdfWidget");
          }}
          icon={RiFilePdf2Line}
        >
          {t("visitors.reception.pdfExportReceptionView")}
        </Dropdown.Item>
      </Dropdown.Icon>
      <ReceptionReportPdfWidget />
      <style jsx>{`
        :global(.reception-report-button) {
          align-self: center;
          margin-left: 4px;
          min-width: 32px;
          min-height: 34px;
          span {
            width: 32px;
            height: 32px;
          }
        }
      `}</style>
    </>
  );
};
