import type { FC } from "react";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { notNullOrUndefined } from "@equiem/lib";
import { formatters, useTranslation } from "@equiem/localisation-eq1";
import type { FilterValueOptions } from "@equiem/react-admin-ui";
import { Avatar, Dropdown, EmptyState, ProgressCircle, Table, useTheme } from "@equiem/react-admin-ui";
import { RiMoreLine } from "@equiem/react-admin-ui/icons";
import { DateTime } from "luxon";
import { SideModalContext } from "../../contexts/SideModalContext";
import type { FlexTenantFragmentFragment, FlexTenantStatus } from "../../generated/gateway-client";
import { useMyFlexTenantsQuery } from "../../generated/gateway-client";
import { StatusTag } from "../StatusTag";

export type Filters = Record<string, FilterValueOptions>;

interface Props {
  search: string | null;
  filters?: Filters;
}

const Row: React.FC<{ tenant: FlexTenantFragmentFragment }> = ({ tenant }) => {
  const { openTab } = useContext(SideModalContext);
  const { colors } = useTheme();
  const { t, i18n } = useTranslation();

  const uniqueBuildings = [...new Set(tenant.locations.map((l) => l.building.name))];

  const moveInDate = useMemo(
    () => formatters.dateshort(DateTime.fromMillis(tenant.moveInDate), i18n.language),
    [tenant, i18n.language],
  );

  const expiryDate = useMemo(
    () => formatters.dateshort(DateTime.fromMillis(tenant.expirationDate), i18n.language),
    [tenant, i18n.language],
  );

  const overLimit = tenant.memberLimit != null && tenant.membershipCount > tenant.memberLimit;

  return (
    <tr>
      <td>
        <span className="d-flex align-items-center">
          <div>
            <Avatar firstName={tenant.name} className="mr-4" />
          </div>
          <div>{tenant.company.name}</div>
        </span>
      </td>
      <td>{tenant.name}</td>
      <td>{uniqueBuildings.join(", ")}</td>
      <td>
        <span className={overLimit ? "alert" : ""}>
          {tenant.membershipCount} / {tenant.memberLimit ?? "-"}
        </span>
      </td>
      <td>{tenant.monthlyCreditAllowance}</td>
      <td>{moveInDate}</td>
      <td>{expiryDate}</td>
      <td className="status">
        <StatusTag status={tenant.status} />
      </td>
      <td>
        <Dropdown.Icon size="sm" placement="right-end" icon={RiMoreLine} className="action-menu">
          <Dropdown.Item key="edit" onClick={() => openTab("editFlexTenant", tenant)}>
            {t("flex.members.editMembership")}
          </Dropdown.Item>
        </Dropdown.Icon>
      </td>
      <style jsx>{`
        td.status :global(span.tag) {
          margin: 0 !important;
        }
        span.alert {
          color: ${colors.danger};
        }
      `}</style>
    </tr>
  );
};

export const MembersList: FC<Props> = ({ search, filters }) => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const [pageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const { data, loading, fetchMore } = useMyFlexTenantsQuery({
    variables: {
      search,
      first: pageSize,
      filters: {
        sites: filters?.sites?.value?.map((v) => v.value) ?? [],
        buildings: filters?.buildings?.value?.map((v) => v.value) ?? [],
        companies: filters?.companies?.value?.map((v) => v.value) ?? [],
        statuses: filters?.statuses?.value?.map((v) => v.value as FlexTenantStatus) ?? [],
      },
    },
  });
  const totalCount = data?.myFlexTenants.totalCount ?? 0;
  const tenants = data?.myFlexTenants.edges ?? [];
  const isEmpty = tenants.length === 0 && !loading;

  const fetchMoreData = useCallback(() => {
    void fetchMore({
      variables: {
        first: pageSize,
        after: data?.myFlexTenants.pageInfo.endCursor,
      },
      updateQuery(prev, { fetchMoreResult }) {
        return {
          ...fetchMoreResult,
          myFlexTenants: {
            ...fetchMoreResult.myFlexTenants,
            edges: [...prev.myFlexTenants.edges, ...fetchMoreResult.myFlexTenants.edges],
          },
        };
      },
    });
  }, [fetchMore, pageSize, data?.myFlexTenants.pageInfo.endCursor]);

  const onPageChange = (page: number, size: number) => {
    if (page > currentPage) {
      fetchMoreData();
    }

    setCurrentPage(page);
    setPageSize(size);
  };

  const defaultHeaders = [
    t("flex.members.form.company"),
    t("flex.members.form.membershipName"),
    t("flex.members.form.buildings"),
    t("flex.members.form.teamSize"),
    t("flex.members.form.creditBalance"),
    t("flex.members.form.moveInDate"),
    t("flex.members.form.expirationDate"),
    t("flex.members.form.status"),
    "",
  ];

  useEffect(() => {
    setCurrentPage(1);
  }, [filters]);

  return (
    <>
      {loading && tenants.length === 0 && (
        <div className="mt-6 mb-6 d-flex justify-content-center align-items-center">
          <ProgressCircle size="md" />
          <span className="ml-4">{`${t("common.loading")}...`}</span>
        </div>
      )}
      {isEmpty && <EmptyState />}
      {tenants.length > 0 && (
        <Table.StickyHeaderTable
          rowHeaders={defaultHeaders}
          loading={loading}
          pagination={{
            totalCount,
            currentPage,
            pageSize,
            onPageChange,
          }}
        >
          {tenants
            .slice((currentPage - 1) * pageSize, currentPage * pageSize)
            .map(({ node }) => {
              if (node == null) {
                return null;
              }
              return <Row key={node.uuid} tenant={node} />;
            })
            .filter(notNullOrUndefined)}
        </Table.StickyHeaderTable>
      )}
    </>
  );
};
