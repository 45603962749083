import React from "react";
import type { FormikErrors } from "formik";
import { Field } from "formik";

import { stringIsEmpty, useSaferFormikContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import getSymbolFromCurrency from "currency-symbol-map";
import { Button, Form as EqForm, useTheme } from "@equiem/react-admin-ui";
import { RiCheckLine, RiCloseLine, RiInformationLine } from "@equiem/react-admin-ui/icons";
import type { CreditPackage, CreditsGeneralSettingsValues } from "../FormValues";

const generateDiscountOptions = () => {
  const options = [];

  // we need to generate options from 5 to 100 with step 5
  for (let i = 5; i <= 90; i += 5) {
    options.push({ value: i, label: `${i}%` });
  }

  return options;
};

export const AddingCreditPackage = ({ onSave, onClose }: { onSave: () => void; onClose: () => void }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { values, isValid, errors } = useSaferFormikContext<CreditsGeneralSettingsValues>();
  const { newCreditsPackage, currencyCode } = values;

  return (
    <>
      <div className="adding-credit-package">
        <div className="inputs">
          <EqForm.Group
            noGroupMargin
            hasError={
              (errors as FormikErrors<{ newCreditsPackage: CreditPackage }>).newCreditsPackage?.creditsAmount != null
            }
          >
            <Field
              variant="sm"
              id="newCreditsPackage.creditsAmount"
              placeholder={t("credits.creditsAmount")}
              name="newCreditsPackage.creditsAmount"
              type="number"
              className="credit-input"
              as={EqForm.Input}
            />
          </EqForm.Group>
          <EqForm.Group
            noGroupMargin
            hasError={
              (errors as FormikErrors<{ newCreditsPackage: CreditPackage }>).newCreditsPackage?.netPrice != null
            }
            className="mb-0"
          >
            <Field
              id="newCreditsPackage.netPrice"
              type="number"
              placeholder={t("credits.netPrice")}
              className="credit-input price-input"
              name="newCreditsPackage.netPrice"
              currency={getSymbolFromCurrency(currencyCode)}
              as={EqForm.Money}
              tooltip={t("credits.netPriceBeforeDiscount")}
            />
          </EqForm.Group>
          <Field
            name="newCreditsPackage.discount"
            as={EqForm.Select}
            size="small"
            mobileView
            className="credit-select-input"
            variant="wrap"
            value={newCreditsPackage?.discount ?? undefined}
            placeholder={t("credits.selectDiscount")}
          >
            <option value="">{t("credits.selectDiscount")}</option>
            {generateDiscountOptions().map(({ value, label }) => (
              <option key={value + label} value={value}>
                {label}
              </option>
            ))}
            <RiInformationLine />
          </Field>
        </div>
        <div className="controls">
          <Button
            variant="primary"
            round
            disabled={
              !isValid ||
              newCreditsPackage?.creditsAmount == null ||
              newCreditsPackage.netPrice == null ||
              (typeof newCreditsPackage.netPrice === "string" && stringIsEmpty(newCreditsPackage.netPrice))
            }
            onClick={() => {
              onSave();
            }}
          >
            <RiCheckLine size={16} />
          </Button>
          <Button variant="outline" round onClick={onClose} className="ml-2">
            <RiCloseLine size={16} />
          </Button>
        </div>
      </div>
      <style jsx>
        {`
          .adding-credit-package {
            width: 100%;
            display: grid;
            gap: 16px;
            align-items: center;
            grid-template-columns: 1fr auto;
          }

          .adding-credit-package :global(.currency) {
            color: ${colors.grayscale[60]};
          }

          :global(.credit-input:focus-within .currency) {
            color: black;
          }

          .inputs {
            display: grid;
            gap: 8px;
            grid-template-columns: 1fr 1fr 1fr;
            width: 100%;
          }

          .adding-credit-package :global(.credit-input) {
            padding: 8px;
          }

          .adding-credit-package :global(.credit-input),
          .adding-credit-package :global(.credit-select-input) {
            max-height: 32px;
          }

          .adding-credit-package :global(.credit-select-input) {
            padding: 8px;
            line-height: 14px;
          }

          :global(.credit-input .currency) {
            font-size: 14px;
            font-weight: 600;
          }

          .adding-credit-package :global(.credit-input.price-input input) {
            padding: 0 0 0 8px;
          }
        `}
      </style>
    </>
  );
};
