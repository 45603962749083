import React, { createContext, useContext, useState } from "react";

export interface User {
  uuid: string;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  companyUuid?: string | undefined;
  companyName?: string | undefined;
  profileIconUrl?: string | null | undefined;
}

interface UserContextProps {
  assignees: User[];
  setAssignees: (assignees: User[]) => void;
  requestManagers: User[];
  setRequestManagers: (requestManagers: User[]) => void;
}

export const UserContext = createContext<UserContextProps | undefined>(undefined);

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [assignees, setAssignees] = useState<User[]>([]);
  const [requestManagers, setRequestManagers] = useState<User[]>([]);

  return (
    <UserContext.Provider value={{ assignees, setAssignees, requestManagers, setRequestManagers }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUsers = () => {
  const context = useContext(UserContext);
  if (context == null) {
    throw new Error("useUsers must be used within a UserProvider");
  }
  return context;
};
