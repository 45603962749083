import { useState } from "react";
import { DateTime } from "luxon";

import { notNullOrUndefined, stringNotEmpty, useSiteContext } from "@equiem/lib";
import { useDebounced } from "@equiem/react-admin-ui";

import type {
  BookingPermissionFilters,
  SiteBookingsQuery,
  SiteBookingsQueryVariables,
} from "../generated/gateway-client";
import { useSiteBookingsQuery } from "../generated/gateway-client";

import type { BookingsFilters } from "./useBookingFilters";

export type Booking = NonNullable<SiteBookingsQuery["siteBookingsList"]["edges"][number]["node"]>;
type Variables = Omit<SiteBookingsQueryVariables, "page">;

const SEARCH_TEXT_DEBOUNCE_MS = 500;
const DEFAULT_PAGE_SIZE = 30;

export function usePagedSiteBookings(
  filters: BookingsFilters,
  permissionFilters: BookingPermissionFilters | null,
  skip?: boolean,
  pageSize = DEFAULT_PAGE_SIZE,
) {
  const [searchTextRaw, setSearchTextRaw] = useState("");
  const searchTextDebounced = useDebounced(searchTextRaw.trim(), SEARCH_TEXT_DEBOUNCE_MS);
  const searchText = stringNotEmpty(searchTextDebounced) ? searchTextDebounced : null;

  const { timezone } = useSiteContext();
  const [loadingMore, setLoadingMore] = useState(false);

  const defaultStartDate = DateTime.now().setZone(timezone).startOf("day").toMillis();
  const defaultEndDate = DateTime.now().setZone(timezone).plus({ months: 1 }).endOf("day").toMillis();

  const variables: Variables = {
    ...filters,
    date: filters.startDate ?? defaultStartDate,
    endDate: filters.endDate ?? defaultEndDate,
    filters: {
      ...filters.filters,
      searchText,
    },
    permissionFilters: permissionFilters ?? {},
  };

  const result = useSiteBookingsQuery({
    variables: {
      ...variables,
      page: { first: pageSize },
    },
    fetchPolicy: "network-only",
    skip,
  });

  const fetchMoreAsync = async (latestData = result.data) =>
    result.fetchMore({
      variables: {
        ...variables,
        page: {
          first: pageSize,
          after: latestData?.siteBookingsList.pageInfo.endCursor,
        },
      },
      updateQuery(prev, { fetchMoreResult }) {
        return {
          ...fetchMoreResult,
          siteBookingsList: {
            ...fetchMoreResult.siteBookingsList,
            edges: [...prev.siteBookingsList.edges, ...fetchMoreResult.siteBookingsList.edges],
          },
        };
      },
    });

  const fetchMore = () => {
    if (result.loading || loadingMore) {
      return;
    }

    setLoadingMore(true);
    fetchMoreAsync()
      .catch((e) => console.error(e))
      .finally(() => setLoadingMore(false));
  };

  const fetchAll = async () => {
    if (result.loading || loadingMore) {
      return null;
    }

    setLoadingMore(true);
    try {
      let latestData = result.data;
      let allEdges = result.data?.siteBookingsList.edges ?? [];

      while (latestData?.siteBookingsList.pageInfo.hasNextPage ?? false) {
        latestData = (await fetchMoreAsync(latestData)).data;
        allEdges = [...allEdges, ...latestData.siteBookingsList.edges];
      }

      return allEdges.map((edge) => edge.node).filter(notNullOrUndefined);
    } finally {
      setLoadingMore(false);
    }
  };

  return {
    bookings: result.data?.siteBookingsList.edges.map((edge) => edge.node).filter(notNullOrUndefined) ?? [],
    error: result.error,

    searchTextRaw,
    setSearchTextRaw,

    searchText,

    loading: result.loading || loadingMore,
    hasMoreData: result.data?.siteBookingsList.pageInfo.hasNextPage ?? false,

    fetchMore,
    fetchAll,
    refetch: result.refetch,
  };
}
