import { notNullOrUndefined } from "@equiem/lib/notNullOrUndefined";
import { useTranslation } from "@equiem/localisation-eq1";
import {
  Button,
  EmptyState,
  InfiniteScrollTable,
  ProgressCircle,
  Tooltip,
  useToast,
  useTheme,
} from "@equiem/react-admin-ui";
import React, { useCallback, useContext, useEffect } from "react";
import { CreditsFilterContext } from "../../../contexts/CreditsFilterContext";
import type { CreditTransaction } from "../../../generated/gateway-client";
import {
  CreditTransactionsQueryRole,
  useGetCreditTransactionsQuery,
  useRequestCreditTransactionsExportCsvMutation,
} from "../../../generated/gateway-client";
import { useRoleMapping } from "../hooks/useRoleMapping";
import { CreditsTable } from "./CreditsTable";
import { CreditsFilters } from "./CreditsFilters";
import { CurrentRole, Role } from "@equiem/lib";
import { RiFileDownloadLine } from "@equiem/react-admin-ui/icons";

const ITEMS_PER_REQUEST = 30;

export const CreditsTableContainer = () => {
  const { t } = useTranslation();
  const { creditsFilters } = useContext(CreditsFilterContext);
  const role = useRoleMapping();
  const { currentRole } = useContext(CurrentRole);
  const isFlexManager = currentRole === Role.FlexManager;
  const isCreditManager = role === CreditTransactionsQueryRole.CreditManager;
  const { breakpoints } = useTheme();
  const toaster = useToast();

  const variables = {
    as: role,
    first: ITEMS_PER_REQUEST,
    filter: creditsFilters,
  };

  const {
    data: creditAccountsData,
    fetchMore,
    loading,
    refetch,
  } = useGetCreditTransactionsQuery({
    variables,
  });
  const creditAccounts =
    creditAccountsData?.creditTransactions.edges.map((e) => e.node).filter(notNullOrUndefined) ?? [];
  const hasMoreRecords = creditAccountsData?.creditTransactions.pageInfo.hasNextPage ?? false;

  const handleNextPage = useCallback(() => {
    void fetchMore({
      variables: {
        after: creditAccountsData?.creditTransactions.pageInfo.endCursor,
      },
      updateQuery(prev, { fetchMoreResult }) {
        return {
          ...fetchMoreResult,
          creditTransactions: {
            ...fetchMoreResult.creditTransactions,
            edges: [...prev.creditTransactions.edges, ...fetchMoreResult.creditTransactions.edges],
          },
        };
      },
    });
  }, [fetchMore, creditAccountsData?.creditTransactions.pageInfo.endCursor]);

  const [exportCsv, { loading: isExporting }] = useRequestCreditTransactionsExportCsvMutation();

  useEffect(() => {
    if (!loading) {
      void refetch();
    }
  }, []);

  return (
    <div className="main-cointainer py-6">
      <CreditsFilters
        exportTemplate={
          <Tooltip title={t("credits.csvExport")}>
            <Button
              variant="ghost"
              round
              onClick={() => {
                exportCsv({
                  variables: { input: { ...variables, first: creditAccountsData?.creditTransactions.edges.length } },
                })
                  .then(() => {
                    toaster.positive(t("credits.csvExportSuccess"), { autoDismiss: true });
                  })
                  .catch(console.error);
              }}
            >
              {isExporting ? <ProgressCircle size={16} /> : <RiFileDownloadLine size={16} />}
            </Button>
          </Tooltip>
        }
      />
      <InfiniteScrollTable
        loading={false}
        hasMoreData={hasMoreRecords}
        scrollDownToLoad={t("requests.list.scrollDownToLoad")}
        rowHeaders={[
          "",
          t("credits.date"),
          t("credits.type"),
          isFlexManager ? t("credits.membership") : isCreditManager ? t("credits.company") : t("credits.user"),
          t("credits.creditMonth"),
          t("credits.amount"),
          t("credits.balance"),
          t("credits.netCost"),
          t("credits.tax"),
          t("credits.total"),
        ].filter(notNullOrUndefined)}
        rowCount={creditAccounts.length}
        fetchMore={handleNextPage}
      >
        <CreditsTable loading={loading} credits={creditAccounts as CreditTransaction[]} />
        {!loading && creditAccounts.length === 0 && (
          <tr>
            <td colSpan={10} className="text-center">
              <EmptyState />
            </td>
          </tr>
        )}
      </InfiniteScrollTable>
      <style jsx>{`
        .main-cointainer {
          padding: 32px;
        }
        @media (max-width: ${breakpoints.sm}px) {
          .main-cointainer {
            padding: 16px;
          }
        }
      `}</style>
    </div>
  );
};
