import { useCallback, useContext, useMemo } from "react";
import { isEqual } from "lodash";
import { DateTime } from "luxon";
import type { Runtype } from "runtypes";

import { stringNotEmpty, useSiteContext } from "@equiem/lib";
import type { TFunction } from "@equiem/localisation-eq1";
import { useTranslation } from "@equiem/localisation-eq1";
import {
  type FilterItem,
  type FilterValue,
  durationString,
  FilterDateModifier,
  FilterDurationModifier,
  FilterOptionsModifier,
  FilterSelectModifier,
  FilterTextModifier,
  FilterTimeModifier,
  FilterType,
} from "@equiem/react-admin-ui";
import {
  RiBuilding4Line,
  RiDoorOpenLine,
  RiGroupLine,
  RiHomeLine,
  RiInputMethodLine,
  RiMoneyDollarBoxLine,
  RiTable2,
  RiTeamLine,
  RiText,
  RiTv2Line,
} from "@equiem/react-admin-ui/icons";

import { BookingsAuthContext } from "../contexts/BookingsAuthContext";
import {
  type BookableResourceFilterOptionsQuery,
  type BookableResourceFilters,
  type BookableResourcePermissionFilters,
  useBookableResourceFilterOptionsQuery,
} from "../generated/gateway-client";
import { resourceFeatureToString, resourceTypeToString } from "../lib/resourceTypeToString";
import type { Capacity } from "../models/CapacityFilter";
import { capacities } from "../models/CapacityFilter";
import { SavedResourceFilters } from "../models/SavedResourceFilters";

import { useLocalStorageState } from "./useLocalStorageState";

type FilterOptionLists = BookableResourceFilterOptionsQuery["bookableResourceFilterOptions"];

export type FilterKey =
  | "siteUuid"
  | "buildingUuid"
  | "levelUuid"
  | "ownerCompanyUuid"
  | "resourceType"
  | "resourceFeatures"
  | "resourceSharedFacilities"
  | "resourceName"
  | "date"
  | "startTime"
  | "durationMinutes"
  | "showPaidResources"
  | "capacity"
  | "managedBy";
type ConcreteFilterValues = Partial<Record<FilterKey, FilterValue>>;

const getSelectValue = <T extends string = string>(v: FilterValue | undefined): T | undefined =>
  v?.type === "select" && stringNotEmpty(v.value) ? (v.value as T) : undefined;
const getSelectBooleanValue = (v: FilterValue | undefined): boolean | undefined =>
  v?.type === "select" && stringNotEmpty(v.value) ? { true: true, false: false }[v.value] : undefined;
const getOptionValue = <T extends string = string>(v: FilterValue | undefined): T[] | undefined =>
  v?.type === "options" && v.value != null && v.value.length > 0 ? v.value.map(({ value }) => value as T) : undefined;
const getTextValue = <T extends string = string>(v: FilterValue | undefined): T | undefined =>
  v?.type === "text" && stringNotEmpty(v.value) ? (v.value as T) : undefined;
const getDateValue = (v: FilterValue | undefined, timezone: string) =>
  v?.type === "date" && typeof v.value === "string"
    ? DateTime.fromFormat(v.value, "yyyy-MM-dd", { zone: timezone }).toMillis()
    : undefined;
const getTimeValue = (v: FilterValue | undefined, baseDate: number, timezone: string) => {
  if (v?.type === "time" && typeof v.value === "string") {
    const date = DateTime.fromMillis(baseDate, { zone: timezone }).toFormat("yyyy-MM-dd");
    return DateTime.fromFormat(`${date} ${v.value}`, "yyyy-MM-dd HH:mm", { zone: timezone }).toMillis();
  }
  return undefined;
};
const getDurationValue = (v: FilterValue | undefined) =>
  v?.type === "duration" && typeof v.value === "string" ? durationString.toMinutes(v.value) ?? undefined : undefined;

const toFilterOption = (val: { uuid: string; name: string }, contextTokens: Array<string | undefined> = []) => {
  const context = contextTokens.filter(stringNotEmpty).join(", ");
  return { value: val.uuid, label: `${val.name}${stringNotEmpty(context) ? ` (${context})` : ""}` };
};

const getFilterOptions = (
  optionLists: FilterOptionLists | undefined,
  curr: BookableResourceFilters,
  timezone: string,
  t: TFunction,
  includeAdmin: boolean,
): Record<Exclude<FilterKey, "managedBy">, FilterItem> & { managedBy?: FilterItem } => {
  const siteOptions =
    optionLists?.sites.map((val) => toFilterOption(val)).sort((a, b) => a.label.localeCompare(b.label)) ?? [];
  const multiSite = siteOptions.length > 1 && curr.siteUuids == null;

  const buildingOptions =
    optionLists?.buildings
      .filter((b) => curr.siteUuids == null || (b.destination != null && curr.siteUuids.includes(b.destination.uuid)))
      .map((b) => toFilterOption(b, [multiSite ? b.destination?.name : undefined])) ?? [];
  const multiBuilding = buildingOptions.length > 1 && curr.buildingUuids == null;

  const levelOptions =
    optionLists?.levels
      .filter(
        (l) =>
          (curr.buildingUuids == null || curr.buildingUuids.includes(l.building.uuid)) &&
          (curr.siteUuids == null ||
            (l.building.destination != null && curr.siteUuids.includes(l.building.destination.uuid))),
      )
      .map((l) =>
        toFilterOption(l, [
          multiBuilding || multiSite ? l.building.name : undefined,
          multiSite ? l.building.destination?.name : undefined,
        ]),
      ) ?? [];

  const siteUuid: FilterItem = {
    title: t("common.site"),
    icon: RiHomeLine,
    type: FilterType.options,
    modifiers: [FilterOptionsModifier.includes],
    disabled: siteOptions.length <= 1,
    options: siteOptions,
  };
  const buildingUuid: FilterItem = {
    title: t("common.building"),
    icon: RiBuilding4Line,
    type: FilterType.options,
    modifiers: [FilterOptionsModifier.includes],
    disabled: (optionLists?.buildings ?? []).length <= 1,
    options: buildingOptions,
  };
  const levelUuid: FilterItem = {
    title: t("bookings.resources.resourceLevel"),
    icon: RiTable2,
    type: FilterType.options,
    modifiers: [FilterOptionsModifier.includes],
    disabled: (optionLists?.levels ?? []).length <= 1,
    options: levelOptions,
  };

  const ownerCompanyUuid: FilterItem = {
    title: t("bookings.resources.ownerCompany"),
    icon: RiTeamLine,
    type: FilterType.options,
    modifiers: [FilterOptionsModifier.includes],
    disabled: (optionLists?.resourceOwnerCompanies ?? []).length <= 1,
    options: optionLists?.resourceOwnerCompanies.map((val) => toFilterOption(val)) ?? [],
  };
  const resourceType: FilterItem = {
    title: t("bookings.resources.resourceTypeFull"),
    icon: RiInputMethodLine,
    type: FilterType.options,
    modifiers: [FilterOptionsModifier.includes],
    disabled: (optionLists?.resourceTypes ?? []).length <= 1,
    options:
      optionLists?.resourceTypes
        .map(({ uuid, name }) => ({ uuid, name: resourceTypeToString(name, t) }))
        .map((val) => toFilterOption(val)) ?? [],
  };
  const resourceFeatures: FilterItem = {
    title: t("bookings.resources.features"),
    icon: RiTv2Line,
    type: FilterType.options,
    modifiers: [FilterOptionsModifier.includes],
    disabled: (optionLists?.features ?? []).length <= 1,
    options:
      optionLists?.features
        .map(({ uuid, name }) => ({ uuid, name: resourceFeatureToString(name, t) }))
        .map((val) => toFilterOption(val)) ?? [],
  };
  const resourceSharedFacilities: FilterItem = {
    title: t("bookings.settings.sharedFacilities"),
    icon: RiDoorOpenLine,
    type: FilterType.options,
    modifiers: [FilterOptionsModifier.includes],
    disabled: (optionLists?.sharedFacilities ?? []).length <= 1,
    options:
      optionLists?.sharedFacilities
        .map(({ uuid, name }) => ({ uuid, name: resourceFeatureToString(name, t) }))
        .map((val) => toFilterOption(val)) ?? [],
  };
  const resourceName: FilterItem = {
    title: t("bookings.resources.resourceName"),
    icon: RiText,
    type: FilterType.text,
    modifiers: [FilterTextModifier.includes],
  };

  const date: FilterItem = {
    title: t("common.date"),
    type: FilterType.date,
    modifiers: [FilterDateModifier.is],
    min: DateTime.local({ zone: timezone }).toFormat("yyyy-MM-dd"),
  };
  const startTime: FilterItem = {
    title: t("common.time"),
    modifiers: [FilterTimeModifier.is],
    type: FilterType.time,
  };
  const durationMinutes: FilterItem = {
    title: t("bookings.operations.duration"),
    modifiers: [FilterDurationModifier.is],
    type: FilterType.duration,
  };

  const showPaidResources: FilterItem = {
    title: t("bookings.resources.showPaidResources"),
    icon: RiMoneyDollarBoxLine,
    type: FilterType.select,
    modifiers: [FilterSelectModifier.is],
    options: [
      { value: "false", label: t("common.free") },
      { value: "true", label: t("bookings.resources.feeApplies") },
    ],
  };
  const capacity: FilterItem = {
    title: t("bookings.resources.resouceCapacity"),
    icon: RiGroupLine,
    type: FilterType.select,
    modifiers: [FilterSelectModifier.is],
    options: Object.values(capacities).map((c) => c.option),
  };

  const managedBy: FilterItem = {
    title: t("bookings.resources.managedBy"),
    options: [
      { label: t("bookings.resources.managedByMe"), value: "me" },
      { label: t("bookings.resources.managedByAnyone"), value: "anyone" },
    ],
    type: FilterType.select,
    modifiers: [FilterSelectModifier.is],
  };

  return {
    siteUuid,
    buildingUuid,
    levelUuid,
    ownerCompanyUuid,
    resourceType,
    resourceFeatures,
    resourceSharedFacilities,
    resourceName,
    date,
    startTime,
    durationMinutes,
    showPaidResources,
    capacity,
    ...(includeAdmin ? { managedBy } : {}),
  };
};

const getFilters = (
  values: ConcreteFilterValues,
  timezone: string,
): BookableResourceFilters & { managedBy?: string } => {
  const date = getDateValue(values.date, timezone);

  const features = [
    ...(getOptionValue(values.resourceFeatures) ?? []),
    ...(getOptionValue(values.resourceSharedFacilities) ?? []),
  ];

  const capacity = getSelectValue<Capacity>(values.capacity);
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const capacityFilter = capacity != null ? capacities[capacity].filter ?? {} : {};

  return {
    siteUuids: getOptionValue(values.siteUuid),
    buildingUuids: getOptionValue(values.buildingUuid),
    levelUuids: getOptionValue(values.levelUuid),

    ownerCompanyUuids: getOptionValue(values.ownerCompanyUuid),
    resourceTypeUuid: getOptionValue(values.resourceType),
    resourceFeatureUuid: features.length > 0 ? features : undefined,
    name: getTextValue(values.resourceName),

    date,
    startTime: getTimeValue(values.startTime, date ?? Date.now(), timezone),
    durationMinutes: getDurationValue(values.durationMinutes),

    showPaidResources: getSelectBooleanValue(values.showPaidResources),
    managedBy: getSelectValue(values.managedBy),
    ...capacityFilter,
  };
};

export const useResourceFilters = (permissionFilters: BookableResourcePermissionFilters, pageName: string) => {
  const { timezone } = useSiteContext();
  const { t } = useTranslation();

  // only include `managedBy` filter in mixed-permission scenarios
  const { canManageSite, canManageSiteCompany, canManageBuildingCompany } = useContext(BookingsAuthContext);
  const isMixedPermissions =
    (canManageSite || canManageSiteCompany || canManageBuildingCompany) &&
    permissionFilters.isSegmentedToViewer === true &&
    (permissionFilters.canManageBookings === true || permissionFilters.canObserveBookings === true);

  const [filterValues, setFilterValues] = useLocalStorageState<Record<string, FilterValue>>(
    `${pageName}-filters`,
    {},
    // This is not especially type-safe, but ComplexFilter validates internally
    // that `initialValues` matches the filter config in `filters` and drops
    // invalid saved state, so we only really need to do a basic structural
    // check here.
    SavedResourceFilters as Runtype<Record<string, FilterValue>>,
  );

  const filters = useMemo(() => getFilters(filterValues.state, timezone), [filterValues.state, timezone]);

  const { data, loading } = useBookableResourceFilterOptionsQuery({
    variables: { permissionFilters },
    fetchPolicy: "cache-and-network",
  });
  const filterOptions = useMemo<Record<string, FilterItem>>(
    () => getFilterOptions(data?.bookableResourceFilterOptions, filters, timezone, t, isMixedPermissions),
    [data?.bookableResourceFilterOptions, filters, timezone, t, isMixedPermissions],
  );

  const onFilterChange = useCallback(
    (values: Record<string, FilterValue>): void => {
      if (!loading && !isEqual(values, filterValues.state)) {
        setFilterValues({ ...values } as ConcreteFilterValues);
      }
    },
    [loading, setFilterValues, filterValues.state],
  );

  return {
    loading: loading || filterValues.loading,
    filterOptions,
    filterValues: filterValues.state,
    filters,
    onFilterChange,
  };
};
