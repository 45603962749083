import React, { useContext } from "react";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button } from "@equiem/react-admin-ui";
import { RiAddLine } from "@equiem/react-admin-ui/icons";
import { Modal as ModalContext } from "../../../../contexts/ModalContext";

export const CompaniesCreditsTopSection = () => {
  const modal = useContext(ModalContext);
  const { t } = useTranslation();
  const handleAddCompaniesCredits = () => {
    modal.open("AddCompaniesCredits");
  };

  return (
    <div className="d-flex w-100 justify-content-between">
      <h3 className="font-weight-bold align-self-center">{t("credits.companiesCredits.manageCredits")}</h3>
      <Button onClick={handleAddCompaniesCredits}>
        <RiAddLine /> {t("common.add")}
      </Button>
    </div>
  );
};
