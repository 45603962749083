import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Form, Modal, Text, useTheme } from "@equiem/react-admin-ui";

import { ModalContext } from "../pages/settings/contexts/ModalContext";

import { QueueAssigneeSelector } from "./QueueAssigneeSelector";

export const QueueRemoveAssigneeModal: React.FC = () => {
  const { t } = useTranslation();

  const { colors } = useTheme();
  const modal = useContext(ModalContext);
  const [showModal, setShowModal] = useState(false);
  const [reassignOption, setReassignOption] = useState<"unassign" | "reassign" | undefined>(undefined);
  const [assigneeUuid, setAssigneeUuid] = useState("");
  const [newAssigneeUuid, setNewAssigneeUuid] = useState("");
  const [queueUuid, setQueueUuid] = useState("");
  const [requestsAssignedCount, setRequestsAssignedCount] = useState(0);

  useEffect(() => {
    setShowModal(modal.activeModal === "QueueRemoveAssigneeModal");
  }, [modal.activeModal]);

  useEffect(() => {
    if (showModal && modal.id != null) {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const vars: {
          assigneeUuid: string;
          queueUuid: string;
          requestsAssignedCount: number;
        } = JSON.parse(modal.id);
        setAssigneeUuid(vars.assigneeUuid);
        setQueueUuid(vars.queueUuid);
        setRequestsAssignedCount(vars.requestsAssignedCount);
      } catch {
        console.error("Error parsing modal id");
      }
    }
    return () => {
      setAssigneeUuid("");
      setQueueUuid("");
      setRequestsAssignedCount(0);
      setReassignOption(undefined);
    };
  }, [modal.id, showModal]);

  const onClose = useCallback(() => {
    setShowModal(false);
    modal.open(
      "QueueModal",
      JSON.stringify({
        queueUuid,
        assignedTo: assigneeUuid,
      }),
    );
  }, [setShowModal, modal, queueUuid, assigneeUuid]);

  const removeAndClose = useCallback(() => {
    setShowModal(false);
    modal.open(
      "QueueModal",
      JSON.stringify({
        queueUuid,
        assignedTo: assigneeUuid,
        reassignTo: reassignOption === "reassign" ? newAssigneeUuid : null,
      }),
    );
  }, [setShowModal, modal, queueUuid, reassignOption, assigneeUuid, newAssigneeUuid]);

  const isSingular = useMemo(() => requestsAssignedCount === 1, [requestsAssignedCount]);

  return (
    <>
      <Modal.Dialog
        title={t("requests.reassign.title")}
        show={showModal}
        sideModal={false}
        onHide={onClose}
        hideOnEsc={true}
        centered={true}
        hideOnClick={true}
        className="remove-assignee-modal"
        size="auto"
      >
        <Modal.Header closeButton noBorder={false} onClose={onClose} supportsMobile />
        <Modal.Body>
          <div className="content">
            <div className="pt-4 pb-6">
              <Text variant="label" color={colors.muted0} className="subtitle">
                {isSingular
                  ? t("requests.reassign.messageSingular")
                  : t("requests.reassign.messagePlural", { number: requestsAssignedCount })}
              </Text>
            </div>
            <Form.RadioButton
              name="unassign"
              className="mb-3"
              label={t("requests.reassign.unassignAll")}
              extraInfo={t("requests.reassign.requestsMoved")}
              checked={reassignOption === "unassign"}
              onChange={() => {
                setReassignOption("unassign");
              }}
            />
            <Form.RadioButton
              name="reassign"
              label={t("requests.reassign.reassignAll")}
              extraInfo={t("requests.reassign.selectNewAssignee")}
              className="pb-3"
              checked={reassignOption === "reassign"}
              onChange={() => {
                setReassignOption("reassign");
              }}
            />
            {reassignOption === "reassign" && (
              <div className="mt-4">
                <QueueAssigneeSelector
                  queueUuid={queueUuid}
                  label={t("requests.reassign.newAssignee")}
                  setAssigneeUuid={setNewAssigneeUuid}
                  assigneeUuid={assigneeUuid}
                />
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="preview-footer-container">
            <Button variant="ghost" onClick={onClose} className="mr-3">
              {t("common.cancel")}
            </Button>
            <Button
              variant="danger"
              disabled={reassignOption !== "unassign" && (reassignOption !== "reassign" || newAssigneeUuid === "")}
              onClick={removeAndClose}
            >
              {t("common.remove")}
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Dialog>
      <style jsx>{`
        .mobile-header {
          border-top: 1px solid ${colors.grayscale[10]};
          border-bottom: 1px solid ${colors.grayscale[10]};
          position: sticky;
          top: 64px;
          z-index: 2;
        }
        .preview-footer-container {
          flex-grow: 1;
          display: flex;
          flex-direction: row;
          justify-content: end;
        }
        :global(.remove-assignee-modal .header.with-border .title-row) {
          margin-bottom: 8px !important;
        }
        :global(.remove-assignee-modal .modal-body) {
          margin-top: 0;
          max-width: 560px;
        }
        :global(.remove-assignee-modal .header.with-border) {
          border-bottom: none !important;
        }
        :global(.remove-assignee-modal .header .title) {
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          color: ${colors.grayscale[100]};
          text-transform: none;
        }
        :global(.remove-assignee-modal .modal-body .subtitle) {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: ${colors.grayscale[100]};
          text-transform: none;
        }
        :global(.remove-assignee-modal .modal-body .info) {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: ${colors.grayscale[100]};
        }
        :global(.remove-assignee-modal .modal-body .extraInfo) {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: ${colors.grayscale[50]};
          padding: 0;
        }
        :global(.remove-assignee-modal .modal-body input[type="radio"]) {
          margin-top: 4px;
        }
      `}</style>
    </>
  );
};
