import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { differenceBy } from "lodash";

import { toRoleName } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Alert, Form, ListGroup, Text, Tooltip, useIsMobileWidth, useTheme } from "@equiem/react-admin-ui";
import { RiInformationLine } from "@equiem/react-admin-ui/icons";

import type { GetProfileRoleTuplesQuery, RolesICanGrantQuery } from "../../../generated/settings-client";

import { findLeaves } from "./findLeaves";
import { toResourceType } from "./ResourceType";
import type { Resource } from "./RolesContext";
import { Roles } from "./RolesContext";

export const RoleView = ({
  role: { role, resources },
  userTuples,
  submitted,
  siteUuid,
  onValidChange,
}: {
  role: RolesICanGrantQuery["rolesICanGrant"][number];
  userTuples: NonNullable<GetProfileRoleTuplesQuery["profile"]>["grantedRoleTuples"];
  submitted: boolean;
  siteUuid: string | null;
  onValidChange?: (valid: boolean) => void;
}) => {
  const roleName = toRoleName(role.name);
  const theme = useTheme(true);
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);
  const [selectedResources, setSelectedResources] = useState<
    Array<{ id: string; type: string; viewerCanGrant?: boolean | null }>
  >([]);
  const [touched, setTouched] = useState(false);
  const rolesCtx = useContext(Roles);
  const isMobile = useIsMobileWidth();

  const isWPManagerOnCurrentSite = useMemo(() => {
    const isInTheToAddList = rolesCtx.updates.toAdd.some(
      (x) => x.siteUuid === siteUuid && x.relation === "manager" && x.type === "siteCompany",
    );
    const isAlreadyWP =
      userTuples
        .find((x) => x.role.type === "siteCompany" && x.role.relation === "manager")
        ?.resources.flatMap((x) => x.children)
        .some((x) => x.children.some((y) => y.id === siteUuid)) === true;

    return isInTheToAddList || isAlreadyWP;
  }, [rolesCtx.updates.toAdd, siteUuid, userTuples]);

  const setRoleSelected = useCallback(
    (isChecked: boolean) => {
      setChecked(isChecked);
      if (!isChecked) {
        setTouched(false);
        if (selectedResources.length > 0) {
          selectedResources.forEach((resource) =>
            rolesCtx.remove({
              siteUuid,
              relation: role.relation,
              resource,
              type: role.type,
            }),
          );
          setSelectedResources([]);
        }
      } else if (resources.filter((r) => r.viewerCanGrant === true).length === 1) {
        const toSelect = resources.filter((r) => r.viewerCanGrant === true);
        toSelect.forEach((resource) =>
          rolesCtx.add({
            siteUuid,
            relation: role.relation,
            resource,
            type: role.type,
          }),
        );
        setSelectedResources(toSelect);
      }
    },
    [resources, role.relation, role.type, rolesCtx, selectedResources, siteUuid],
  );

  const resourceType = useMemo(() => toResourceType(resources[0]?.type), [resources]);

  const added = useMemo(
    () =>
      rolesCtx.updates.toAdd.filter(
        (tuple) =>
          tuple.type === role.type &&
          tuple.relation === role.relation &&
          resources.some((r) => r.id === tuple.resource.id),
      ),
    [resources, role.relation, role.type, rolesCtx.updates.toAdd],
  );
  const removed = useMemo(
    () =>
      rolesCtx.updates.toRemove.filter(
        (tuple) =>
          tuple.type === role.type &&
          tuple.relation === role.relation &&
          resources.some((r) => r.id === tuple.resource.id),
      ),
    [resources, role.relation, role.type, rolesCtx.updates.toRemove],
  );

  useEffect(() => {
    const selected: Resource[] = [
      ...resources
        .flatMap((resource) =>
          userTuples.flatMap((tuple) =>
            tuple.role.type === role.type && tuple.role.relation === role.relation
              ? findLeaves(tuple.resources, (leaf) => leaf.id === resource.id && leaf.type === resource.type)
              : [],
          ),
        )
        .filter((resource) => !removed.some((tuple) => tuple.resource.id === resource.id)),
      ...added.map((tuple) => tuple.resource),
    ];

    setSelectedResources(selected);
  }, [resources, role.relation, role.type, added, removed, userTuples]);

  useEffect(() => {
    if (selectedResources.length > 0) {
      setChecked(true);
    }
  }, [selectedResources.length]);

  useEffect(() => {
    onValidChange?.(!checked || selectedResources.length > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked, selectedResources.length]);

  return (
    <>
      <ListGroup.Item key={role.tuple} noMargin className="p-0">
        <div className={"role-view p-5 w-100"}>
          <div className="d-flex align-items-center" style={{ maxWidth: "100%" }}>
            <Text component="h2" size="small" variant="text">
              <b style={{ display: "inline-block" }}>
                <Form.Checkbox
                  label={t(`settings.roles.roleNames.${roleName}`, { count: 1 })}
                  value={checked}
                  disabled={!resources.some((r) => r.viewerCanGrant === true)}
                  onChange={(e) => {
                    setRoleSelected(e.target.checked);
                  }}
                />
              </b>
            </Text>
            <Tooltip title={t(`settings.roles.roleInfo.${roleName}`)} placement="top" showArrow>
              <span className="ml-2">
                <RiInformationLine size={16} color={theme.colors.muted0} />
              </span>
            </Tooltip>
          </div>
          {!checked || resourceType === "site" || resourceType === "siteCompany" ? null : (
            <Form.Group
              error={(touched || submitted) && selectedResources.length === 0 ? t("common.required") : undefined}
              noGroupMargin
            >
              <Form.MultiSelect
                onChange={(e) => {
                  const newResources = resources.filter((r) => e.target.value.includes(r.id));
                  const toAdd = differenceBy(newResources, selectedResources, "id");
                  const toRemove = differenceBy(selectedResources, newResources, "id");
                  toAdd.forEach((r) =>
                    rolesCtx.add({ siteUuid, relation: role.relation, type: role.type, resource: r }),
                  );
                  toRemove.forEach((r) =>
                    rolesCtx.remove({ siteUuid, relation: role.relation, type: role.type, resource: r }),
                  );
                  setSelectedResources(newResources);
                  setTouched(true);
                }}
                onClose={() => {
                  setTouched(true);
                }}
                options={resources.map((r) => ({
                  label: r.name,
                  value: r.id,
                  disabled: r.viewerCanGrant !== true,
                }))}
                value={selectedResources.map((r) => r.id)}
                variant="wrap"
                enableSelectAll
                className="mt-4"
                mobileView="full"
                placeholder={t("settings.roles.selectResourceType", {
                  type: t(`settings.roles.resourceTypes.${resourceType}`, { count: 2 }).toLocaleLowerCase(),
                })}
              />
            </Form.Group>
          )}
          {checked && resourceType === "site" && roleName === "Credit Manager" && isWPManagerOnCurrentSite && (
            <Alert
              variant="warning"
              icon={<RiInformationLine size={16} />}
              className="credit-manager-warning"
              size={"normal"}
              centerIcon={true}
              onClose={(display) => display(false)}
              message={t("settings.roles.roleInfo.Credit Manager Warning")}
            />
          )}
        </div>
      </ListGroup.Item>
      <style jsx>{`
        div :global(.credit-manager-warning) {
          height: ${isMobile ? "fit-content" : "80px !important"};
          margin-top: 8px;
        }
      `}</style>
    </>
  );
};
