import React, { useState } from "react";
import { Field } from "formik";

import { useTranslation } from "@equiem/localisation-eq1";
import { Form, useTheme } from "@equiem/react-admin-ui";

enum Units {
  HOURS = "hours",
  DAYS = "days",
}

interface TimeWithUnits {
  value: number;
  unit: Units;
}

const MINUTES_IN_HOUR = 60;
const MINUTES_IN_DAY = MINUTES_IN_HOUR * 24;

const HOURLY_UPPER_LIMIT = 23.75;
const DAILY_UPPER_LIMIT = 999;
const HOURLY_STEP = 0.25;
const DAILY_STEP = 1;

const getTimeWithUnits = (minutes: number): TimeWithUnits => {
  return minutes % MINUTES_IN_DAY === 0
    ? { value: minutes / MINUTES_IN_DAY, unit: Units.DAYS }
    : { value: minutes / MINUTES_IN_HOUR, unit: Units.HOURS };
};

const getTimeInMinutes = (time: TimeWithUnits): number =>
  time.value * (time.unit === Units.DAYS ? MINUTES_IN_DAY : MINUTES_IN_HOUR);

const getNoticePeriodUpperLimit = (units: Units) => (units === Units.HOURS ? HOURLY_UPPER_LIMIT : DAILY_UPPER_LIMIT);
const getNoticePeriodStep = (units: Units) => (units === Units.HOURS ? HOURLY_STEP : DAILY_STEP);

interface Props {
  name: string;
  value: number;
  onChange: (value: number) => void;
  label: string;
  error?: string;
  required?: boolean;
  showTooltip?: boolean;
  tooltipText?: string;
  disabled?: boolean;
}

export const ResourceCreateAndEditFormCancellationPermissionsNoticePeriod: React.FC<Props> = ({
  name,
  value,
  onChange,
  label,
  error,
  required,
  showTooltip,
  tooltipText,
  disabled,
}) => {
  const { t } = useTranslation();
  const { spacers } = useTheme(true);
  const [noticePeriod, setNoticePeriod] = useState<TimeWithUnits>(getTimeWithUnits(value));

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(e.target.value);
    const updatedNoticePeriod = { value: !isNaN(newValue) ? newValue : 0, unit: noticePeriod.unit };
    setNoticePeriod(updatedNoticePeriod);
    onChange(getTimeInMinutes(updatedNoticePeriod));
  };

  const handleBlurValue = (e: React.FocusEvent<HTMLInputElement>) => {
    const step = getNoticePeriodStep(noticePeriod.unit);
    const newValue = Math.round(parseFloat(e.target.value) / step) * step;
    const updatedNoticePeriod = { value: !isNaN(newValue) ? newValue : 0, unit: noticePeriod.unit };
    setNoticePeriod(updatedNoticePeriod);
    onChange(getTimeInMinutes(updatedNoticePeriod));
  };

  const handleUnitChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newUnit = e.target.value as Units;
    const upperLimit = getNoticePeriodUpperLimit(newUnit);
    const step = getNoticePeriodStep(newUnit);
    const newValue = Math.round(noticePeriod.value / step) * step;
    const updatedNoticePeriod = { value: newValue > upperLimit ? upperLimit : newValue, unit: newUnit };
    setNoticePeriod(updatedNoticePeriod);
    onChange(getTimeInMinutes(updatedNoticePeriod));
  };

  return (
    <>
      <Form.Group label={label} error={error} required={required} showTooltip={showTooltip} tooltipText={tooltipText}>
        <Field id={name} name={name}>
          {() => (
            <div className="form-field-with-units">
              <Form.Input
                name={`${name}Value`}
                min={0}
                max={getNoticePeriodUpperLimit(noticePeriod.unit)}
                step={getNoticePeriodStep(noticePeriod.unit)}
                type="number"
                disabled={disabled}
                value={noticePeriod.value}
                onChange={handleChangeValue}
                onBlur={handleBlurValue}
              />
              <Form.Select
                name={`${name}Unit`}
                disabled={disabled}
                value={noticePeriod.unit}
                onChange={handleUnitChange}
              >
                <option value={Units.HOURS}>{t("common.count_hour", { count: noticePeriod.value })}</option>
                <option value={Units.DAYS}>{t("common.count_day", { count: noticePeriod.value })}</option>
              </Form.Select>
            </div>
          )}
        </Field>
      </Form.Group>
      <style jsx>{`
        .form-field-with-units {
          display: flex;
          gap: ${spacers.s2};
        }
      `}</style>
    </>
  );
};
