import type { ReactNode } from "react";
import React, { useContext } from "react";

import type { MyProfileQuery, MyProfileQueryVariables } from "../generated/gateway-client";
import { MyProfileDocument } from "../generated/gateway-client";
import { Session } from "./SessionContext";
import { CurrentProfile } from "./ProfileContext";
import { usePersistedQuery } from "../usePersistedQuery";

export const ProfileProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { authenticated } = useContext(Session);

  const { data, loading } = usePersistedQuery<MyProfileQuery, MyProfileQueryVariables>(
    "MyProfileCache",
    MyProfileDocument,
    {
      skip: !authenticated,
      fetchPolicy: "cache-and-network",
    },
  );

  const siteProfile = data?.profile?.siteProfiles.edges[0]?.node;
  const canManageRegion = data?.viewer.currentRegion?.viewerRelations.canManage ?? false;

  const destinationViewerRelations = data?.viewer.currentDestination?.destination.viewerRelations;

  const canManageCurrentSite = destinationViewerRelations?.canManage ?? false;
  const canManageCurrentSiteCompany = destinationViewerRelations?.company.canManage ?? false;
  const canManageCurrentSiteSettings =
    destinationViewerRelations?.canManageSettings ?? destinationViewerRelations?.company.canManageSettings ?? false;
  const companyFlexTenants = data?.profile?.companyV2?.flexTenants ?? [];
  const isFlexRelated = data?.profile?.companyV2?.hasFlexOperator === true || companyFlexTenants.length > 0;
  const canManageFlexTenants = destinationViewerRelations?.canManageFlexTenants ?? false;
  const canManageCurrentSiteRequests = destinationViewerRelations?.canManageRequests ?? false;
  const canBeAssignedCurrentSiteRequests = destinationViewerRelations?.canBeAssignedRequests ?? false;
  const canManageCredits = destinationViewerRelations?.canManageCredits ?? false;

  return (
    <CurrentProfile.Provider
      value={{
        profile: data?.profile,
        siteProfile,
        canManageRegion,
        canManageCurrentSite,
        canManageCurrentSiteCompany,
        canManageCurrentSiteSettings,
        canManageCurrentSiteRequests,
        canBeAssignedCurrentSiteRequests,
        isReceptionist: siteProfile?.isReceptionist ?? false,
        receptionCount: siteProfile?.receptionCount ?? 0,
        companyIsFlexOperator: data?.profile?.companyV2?.hasFlexOperator ?? false,
        canManageFlexTenants,
        canManageCredits,
        companyFlexTenants,
        isFlexRelated,
        loading: loading && data == null,
      }}
    >
      {children}
    </CurrentProfile.Provider>
  );
};
