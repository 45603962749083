import React, { type FC, useEffect } from "react";
import { useFormikContext } from "formik";
import { Text, useToast } from "@equiem/react-admin-ui";
import { useTranslation } from "@equiem/localisation-eq1";
import { stringNotEmpty } from "@equiem/lib";

import type { FormValues } from "../../lib/formValidation";

export const ResourceCompanyChangeWarning: FC<{
  originalCompany: { uuid: string; name: string } | null | undefined;
}> = ({ originalCompany }) => {
  const fm = useFormikContext<FormValues>();
  const toast = useToast();
  const { t } = useTranslation();

  useEffect(() => {
    const hasChangedCompany =
      originalCompany != null &&
      stringNotEmpty(fm.values.ownerCompanyUuid) &&
      fm.values.ownerCompanyUuid !== originalCompany.uuid;
    if (hasChangedCompany) {
      const msg = toast.positive(
        <Text variant="text" size="small" className="resource-company-change-warning">
          <b>{t("common.note")}:</b>{" "}
          {t("bookings.resources.ownerCompanyChangeWarning", {
            originalCompanyName: originalCompany.name,
            resourceName: fm.values.title,
          })}
        </Text>,
      );
      return msg.remove;
    }
    return () => undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalCompany, fm.values.ownerCompanyUuid]);

  return null;
};
