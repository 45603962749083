import type { ReactNode } from "react";
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useRouter } from "next/router";

import { CurrentProfile, CurrentRole, notNullOrUndefined, Role, stringNotEmpty, UrlParams } from "@equiem/lib";
import type { FilterValue } from "@equiem/react-admin-ui";
import { FilterTextModifier, FilterType, useDebounced } from "@equiem/react-admin-ui";

import type { RequestFilterEntitiesQuery } from "../../../generated/requests-client";
import {
  type ReqMgtRequestsDateFilter,
  ReqMgtFiltersQueryRole,
  ReqMgtRequestsFilterScope,
  ReqMgtStatusType,
  useQueuesListQuery,
  useRequestFilterEntitiesQuery,
} from "../../../generated/requests-client";
import type { ShortQueue } from "../../settings/hooks/useQueueData";
import { ForcedRole } from "../hooks/useRequestBuildingPermission";
import {
  translateAssignee,
  translateBuilding,
  translateCategory,
  translateCreated,
  translateReporter,
  translateReporterCompany,
  translateScope,
  translateSearchTerm,
  translateStatus,
  translateUnassigned,
  translateWatching,
} from "../utils/translate";

import type { ActiveTab } from "./ActiveTab";
import { RequestsAccessContext } from "./RequestsAccessContext";
import { RequestsScopeContext } from "./RequestsScopeContext";

export type RequestFilterType =
  | "status"
  | "category"
  | "company"
  | "reporter"
  | "assignee"
  | "building"
  | "created"
  | "search"
  | "watchedByUuid";
export type RequestFiltersResult = Partial<Record<RequestFilterType, FilterValue>>;

interface FilterParams {
  searchTerm?: string | undefined;
  statusUuids?: string[] | undefined;
  reporterUuids?: string[] | undefined;
  watchedByUuid?: string | undefined;
  assigneeUuids?: string[] | undefined;
  buildingUuids?: string[] | undefined;
  categoryUuids?: string[] | undefined;
  queueUuids?: string[] | undefined;
  created?: ReqMgtRequestsDateFilter | undefined;
  reporterCompanyUuids?: string[] | undefined;
  scope?: ReqMgtRequestsFilterScope | undefined;
}

interface RequestsFilterContext {
  filters: RequestFiltersResult;
  allowedBuildings: string[];
  debouncedFilters: RequestFiltersResult;
  search?: string;
  tabs: {
    open: boolean;
    completed: boolean;
    all: boolean;
  };
  defaultCountFilters: {
    open: FilterParams;
    completed: FilterParams;
    all: FilterParams;
  };
  activeTab: ActiveTab;
  setActiveTab: React.Dispatch<React.SetStateAction<ActiveTab>>;
  clearSearch: () => void;
  handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setFilters: React.Dispatch<React.SetStateAction<Partial<Record<RequestFilterType, FilterValue>>>>;
  translatedFilters: FilterParams | undefined;
  entities?: RequestFilterEntitiesQuery["reqMgt"]["requestFilterEntities"];
}

export const RequestsFilterContext = createContext<RequestsFilterContext>({
  filters: {},
  allowedBuildings: [],
  debouncedFilters: {},
  translatedFilters: {},
  entities: undefined,
  tabs: {
    open: false,
    completed: false,
    all: false,
  },
  defaultCountFilters: {
    open: {},
    completed: {},
    all: {},
  },
  activeTab: "all",
  setActiveTab: () => {
    throw new Error("Not init");
  },
  search: undefined,
  clearSearch: () => {
    throw new Error("Not init");
  },
  handleSearch: () => {
    throw new Error("Not init");
  },
  setFilters: () => {
    throw new Error("Not init");
  },
});

const fakeUuid = "00000000-0000-0000-0000-000000000000";

export const RequestsFilterProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [filters, setFilters] = useState<RequestFiltersResult>({});
  const { setParam, deleteParam } = useContext(UrlParams);
  const [isForcedRole, setIsForcedRole] = useState(false);
  const { currentRole } = useContext(CurrentRole);
  const { currentScope } = useContext(RequestsScopeContext);
  const access = useContext(RequestsAccessContext);
  const router = useRouter();
  const { query } = useRouter();
  const [activeTab, setActiveTab] = useState<ActiveTab>(() => {
    if (query.tab != null) {
      return query.tab as ActiveTab;
    }

    return "open";
  });
  const searchFromQueryParams = router.query.search as string | undefined;
  const [search, setSearch] = useState<string | undefined>(searchFromQueryParams ?? "");
  const debouncedSearch = useDebounced(search, 500);
  const debouncedFilters = useDebounced(filters, 300);
  const { profile } = useContext(CurrentProfile);
  const { data: queuesForUserData } = useQueuesListQuery({
    fetchPolicy: "cache-and-network",
  });
  const queueUuids = useMemo(() => {
    const queues: ShortQueue[] = (queuesForUserData?.reqMgt.queues ?? []) as unknown as ShortQueue[];
    if (queues.length === 0) {
      return undefined;
    }
    return queues
      .map((queue: ShortQueue) => (queue.viewerRelations.requestManager ? queue.uuid : null))
      .filter(notNullOrUndefined);
  }, [queuesForUserData]);

  const isRequestManagerFromQueue = useMemo(
    () => queuesForUserData?.reqMgt.queues.some((queue) => queue.viewerRelations.requestManager) ?? false,
    [queuesForUserData],
  );
  const isRequestAssigneeFromQueue = useMemo(
    () => queuesForUserData?.reqMgt.queues.some((queue) => queue.viewerRelations.requestAssignee) ?? false,
    [queuesForUserData],
  );

  const myRoleForFilters: ReqMgtFiltersQueryRole | undefined = useMemo(() => {
    // In this case all tab means company requests
    if (currentScope === "all" && currentRole === Role.WorkplaceManager) {
      return ReqMgtFiltersQueryRole.WorkplaceManager;
    }

    // if a user is WorkplaceManager it has access to "requestManager" tab. On this tab it should see RequestManager's requests.
    if (currentScope === "requestManager" || currentScope === "all") {
      return ReqMgtFiltersQueryRole.RequestManager;
    }

    if (currentScope === "assigned") {
      return ReqMgtFiltersQueryRole.RequestAssignee;
    }

    // For default scenario, just in case all conditions above are not met
    switch (currentRole) {
      case Role.PropertyManager:
        return ReqMgtFiltersQueryRole.PropertyManager;
      case Role.FlexManager:
        return ReqMgtFiltersQueryRole.FlexManager;
      case Role.WorkplaceManager:
        return ReqMgtFiltersQueryRole.WorkplaceManager;
      default:
        if (isRequestManagerFromQueue) {
          return ReqMgtFiltersQueryRole.RequestManager;
        }
        if (isRequestAssigneeFromQueue) {
          return ReqMgtFiltersQueryRole.RequestAssignee;
        }
        return undefined;
    }
  }, [currentRole, currentScope, isRequestManagerFromQueue, isRequestAssigneeFromQueue]);

  const variables = useMemo(() => {
    if (currentScope === "my") {
      return undefined;
    }
    return {
      as: myRoleForFilters,
    };
  }, [currentScope, myRoleForFilters]);
  const { data: entitiesData } = useRequestFilterEntitiesQuery({ variables });
  const statuses = useMemo(() => entitiesData?.reqMgt.requestFilterEntities.statuses ?? [], [entitiesData]);
  // send queueUuids only if user is requestManager. So it's enduser with access to all requests, or it's workplace manager on "requestManager" tab.
  const isOkToSendQueueUuids =
    queueUuids != null &&
    queueUuids.length > 0 &&
    (currentScope === "requestManager" || (currentScope === "all" && Role.WorkplaceManager !== currentRole));

  // react on tab reset
  useEffect(() => {
    if (router.query.tab === undefined) {
      setActiveTab("open");
    }
  }, [router.query.tab]);

  // react on tab selection change
  useEffect(() => {
    if (router.query.tab !== activeTab) {
      setParam("tab", activeTab);
    }
  }, [activeTab]);

  useEffect(() => {
    if (stringNotEmpty(debouncedSearch)) {
      if (debouncedSearch !== router.query.search) {
        setParam("search", debouncedSearch);
      }
    } else if (router.query.search != null) {
      deleteParam("search");
    }
  }, [debouncedSearch]);

  useEffect(() => {
    if (access.setForcedRole != null) {
      if (currentRole === Role.WorkplaceManager && currentScope === "requestManager") {
        access.setForcedRole(ForcedRole.RequestManager);
        setIsForcedRole(true);
      } else if (isForcedRole) {
        access.setForcedRole(undefined);
        setIsForcedRole(false);
      }
    }
  }, [access, currentScope, currentRole, isForcedRole]);

  const tabs = {
    open: true,
    all: true,
    completed: true,
  };

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);
    },
    [setSearch],
  );

  const clearSearch = useCallback(() => {
    setSearch("");
  }, [setSearch]);

  const allowedBuildings = useMemo(() => access.buildings.map((b) => b.uuid), [access.buildings]);

  const translatedFilters = useMemo(() => {
    const filter = {
      queueUuids: isOkToSendQueueUuids ? queueUuids : undefined,
      assigneeUuids: translateAssignee(currentScope, filters, profile?.uuid),
      buildingUuids: translateBuilding(
        currentRole,
        currentScope,
        filters,
        !isOkToSendQueueUuids ? allowedBuildings : [],
      ),
      categoryUuids: translateCategory(filters),
      created: translateCreated(filters),
      reporterCompanyUuids: translateReporterCompany(
        activeTab,
        currentScope,
        filters,
        access.workplaceManager && currentScope !== "requestManager" ? profile?.companyV2?.uuid : undefined,
      ),
      reporterUuids: translateReporter(currentScope, filters, profile?.uuid),
      searchTerm: translateSearchTerm({
        search: {
          modifier: FilterTextModifier.includes,
          type: FilterType.text,
          value: debouncedSearch,
        },
      }),
      statusUuids: translateStatus(filters, activeTab, statuses),
      unassigned: translateUnassigned(activeTab),
      watchedByUuid: translateWatching(filters, profile?.uuid),
      scope: translateScope(currentScope),
    };

    const nonEmpty = Object.values(filter).some((v) => v != null);

    return nonEmpty ? filter : undefined;
  }, [
    access.workplaceManager,
    activeTab,
    statuses,
    queueUuids,
    allowedBuildings,
    debouncedSearch,
    filters,
    profile?.companyV2?.uuid,
    profile?.uuid,
    currentScope,
  ]);

  const defaultCountFilters = useMemo(() => {
    const companyFilter = ["all", "requestManager"].includes(currentScope)
      ? { reporterCompanyUuids: translatedFilters?.reporterCompanyUuids }
      : undefined;
    const openStatuses = statuses.filter((x) => x.type !== ReqMgtStatusType.Completed).map((x) => x.uuid);
    const completeStatuses = statuses.filter((x) => x.type === ReqMgtStatusType.Completed).map((x) => x.uuid);
    const myProfile = profile?.uuid != null ? [profile.uuid] : undefined;
    const buildingsToSend = () => {
      if (translatedFilters?.buildingUuids != null && translatedFilters.buildingUuids.length > 0) {
        return translatedFilters.buildingUuids;
      }
      // send allowed buildings only for "all requests" tabs
      if (!isOkToSendQueueUuids && ["all", "requestManager"].includes(currentScope)) {
        return allowedBuildings;
      }
      return undefined;
    };

    return {
      open: {
        ...companyFilter,
        queueUuids: isOkToSendQueueUuids ? queueUuids : undefined,
        buildingUuids: buildingsToSend(),
        reporterUuids: currentScope === "my" ? myProfile : undefined,
        statusUuids: openStatuses,
        assigneeUuids: currentScope === "assigned" ? myProfile : undefined,
        scope: currentScope === "my" ? ReqMgtRequestsFilterScope.My : undefined,
      } satisfies FilterParams,
      completed: {
        ...companyFilter,
        queueUuids: isOkToSendQueueUuids ? queueUuids : undefined,
        buildingUuids: buildingsToSend(),
        reporterUuids: currentScope === "my" ? myProfile : undefined,
        assigneeUuids: currentScope === "assigned" ? myProfile : undefined,
        statusUuids: completeStatuses.length === 0 ? [fakeUuid] : completeStatuses,
        scope: currentScope === "my" ? ReqMgtRequestsFilterScope.My : undefined,
      } satisfies FilterParams,
      all: {
        ...companyFilter,
        queueUuids: isOkToSendQueueUuids ? queueUuids : undefined,
        reporterUuids: currentScope === "my" ? myProfile : undefined,
        assigneeUuids: currentScope === "assigned" ? myProfile : undefined,
        statusUuids: statuses.map((x) => x.uuid),
        buildingUuids: buildingsToSend(),
        scope: currentScope === "my" ? ReqMgtRequestsFilterScope.My : undefined,
      } satisfies FilterParams,
    };
  }, [
    allowedBuildings,
    currentScope,
    profile?.uuid,
    translatedFilters?.reporterCompanyUuids,
    statuses,
    queueUuids,
    translatedFilters?.buildingUuids,
  ]);

  return (
    <RequestsFilterContext.Provider
      value={{
        activeTab,
        defaultCountFilters,
        allowedBuildings,
        clearSearch,
        debouncedFilters,
        filters,
        handleSearch,
        search,
        setFilters,
        setActiveTab,
        tabs,
        translatedFilters,
        entities: entitiesData?.reqMgt.requestFilterEntities,
      }}
    >
      {children}
    </RequestsFilterContext.Provider>
  );
};
