import React, { useContext, useState } from "react";
import { CreateRequest, LastRequests } from "@equiem/requests";
import { CurrentRole, Role } from "@equiem/lib";
import { Dropdown, Text, useTheme } from "@equiem/react-admin-ui";
import { RiQuestionLine, RiVideoLine, RiDashboard3Line } from "@equiem/react-admin-ui/icons";
import { useTranslation } from "@equiem/localisation-eq1";

import { FloatingActionButton } from "./FloatingActionButton";
import { ShortcutItem } from "./ShortcutItem";

type SupportPage = "home" | "new-request";

export const HelpSupportMenu: React.FC = () => {
  const { currentRole } = useContext(CurrentRole);
  const { t } = useTranslation();
  const theme = useTheme();

  const [supportPage, setSupportPage] = useState<SupportPage>("home");
  const handleOpenRequest = () => {
    setSupportPage("new-request");
  };
  const handleDropdown = (show: boolean) => {
    if (!show) {
      setSupportPage("home");
    }
  };

  return (
    <Dropdown.Dropdown placement="top-start" offset={8} strategy="fixed" onToggle={handleDropdown}>
      <FloatingActionButton />
      <Dropdown.Menu width={supportPage === "home" ? 280 : 360} maxHeight={560} mobileView="minimal" portalType="body">
        {supportPage === "home" && (
          <div className="home-container">
            <LastRequests handleOpenRequest={handleOpenRequest} />
            {process.env.equiemEssentialsEnabled === "true" && currentRole === Role.PropertyManager && (
              <div className="resources-container">
                <Text variant="label" size="small" className="mb-2 ml-3">
                  {t("home.helpSupport")}
                </Text>
                <ShortcutItem
                  icon={RiQuestionLine}
                  link="mailto:support@getequiem.com"
                  text={t("home.shortcuts.support")}
                />
                <ShortcutItem
                  icon={RiVideoLine}
                  link="https://help.getequiem.com/"
                  text={t("home.shortcuts.helpCentre")}
                />
                <ShortcutItem
                  icon={RiDashboard3Line}
                  link="https://status.getequiem.com/"
                  text={t("home.platformStatus")}
                />
              </div>
            )}
          </div>
        )}
        {supportPage === "new-request" && <CreateRequest />}
        <style jsx>{`
          .resources-container {
            display: flex;
            flex-direction: column;
            padding: 8px 8px 0px;
            border-top: 1px solid ${theme.colors.grayscale[10]};
          }

          .resources-container :global(*:last-child) {
            margin-bottom: 0px;
          }
        `}</style>
      </Dropdown.Menu>
    </Dropdown.Dropdown>
  );
};
