import React from "react";

import { useAuthorizationRoles, useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Form, Tag, Text, useConfirmer, useTheme, useToast } from "@equiem/react-admin-ui";
import { RiUserFollowLine, RiUserUnfollowLine } from "@equiem/react-admin-ui/icons";

import { VerifiedStatus } from "../../../components/VerifiedStatus";
import { AdminProfileStatus, ProfileStatus, useUpdateProfileStatusMutation } from "../../../generated/settings-client";

import type { UserListItem } from "./usePagedUserList";

const ActivateUser = () => {
  const { colors } = useTheme();
  const { t } = useTranslation();
  return (
    <span>
      <RiUserFollowLine color={colors.blue[60]} className="mb-1" />
      <Text variant="navigation" className="ml-3">
        {t("settings.profileStatus.activateUser")}
      </Text>
    </span>
  );
};

const DeactivateUser = () => {
  const { colors } = useTheme();
  const { t } = useTranslation();
  return (
    <span>
      <RiUserUnfollowLine color={colors.danger} className="mb-1" />
      <Text variant="navigation" className="ml-3">
        {t("settings.profileStatus.deactivateUser")}
      </Text>
    </span>
  );
};

const StatusTag = ({ status }: { status: ProfileStatus }) => {
  const { t } = useTranslation();
  switch (status) {
    case ProfileStatus.Active:
      return (
        <Tag variant="positive" className="ml-3">
          {t("settings.profileStatus.active")}
        </Tag>
      );
    case ProfileStatus.Deactivated:
      return (
        <Tag variant="negative" className="ml-3">
          {t("settings.profileStatus.deactivated")}
        </Tag>
      );
    case ProfileStatus.PendingApproval:
      return (
        <Tag variant="dark" className="ml-3">
          {t("settings.profileStatus.pendingApproval")}
        </Tag>
      );
    default:
      return null;
  }
};

export function useUserList(isFlexRelated = false) {
  const { colors } = useTheme();
  const [mutation] = useUpdateProfileStatusMutation();
  const site = useSiteContext();
  const { withConfirmation } = useConfirmer();
  const { t } = useTranslation();
  const toast = useToast();
  const roleNames = useAuthorizationRoles();

  const getColumns = ({ profile, authorizationRoles }: UserListItem, withCompany: boolean) => {
    if (profile == null) {
      return [];
    }

    const groups = profile.siteProfiles.edges.flatMap((edge) => edge.node?.groups ?? []);
    const { email, firstName, lastName, public: isPublic } = profile;

    const deactivateWithConfirmation = withConfirmation({
      title: t("settings.profileStatus.deactivateUser"),
      message: t("settings.profileStatus.deactivateConfirmation", { name: profile.displayName }),
      confirmButtonText: t("settings.deactivate"),
      cancelButtonText: t("common.cancel"),
      confirmButtonVariant: "danger",
      onConfirm: () => {
        // email, firstName, lastName, public are required field for the mutation so just supplying the values we got
        void mutation({
          variables: {
            status: AdminProfileStatus.Deactivated,
            uuid: profile.uuid,
            portalUuid: site.uuid,
            email: email,
            firstName: firstName ?? "",
            lastName: lastName ?? "",
            public: isPublic,
          },
        }).then((result) => {
          if (result.data?.updateUser.__typename === "UpdateUserSuccess") {
            toast.positive(t("settings.profileStatus.deactivateSuccess", { name: profile.displayName }));
          }
        });
      },
    });

    const updateStatus = (status?: string) => {
      if (status === ProfileStatus.Active) {
        // email, firstName, lastName, public are required field for the mutation so just supplying the values we got
        void mutation({
          variables: {
            status: AdminProfileStatus.Active,
            uuid: profile.uuid,
            portalUuid: site.uuid,
            email: email,
            firstName: firstName ?? "",
            lastName: lastName ?? "",
            public: isPublic,
          },
        }).then((result) => {
          if (result.data?.updateUser.__typename === "UpdateUserSuccess") {
            toast.positive(t("settings.profileStatus.activateSuccess", { name: profile.displayName }));
          }
        });
      } else if (status === ProfileStatus.Deactivated) {
        deactivateWithConfirmation();
      }
    };

    const columns = [
      {
        content: profile.displayName,
      },
      {
        content: (
          <>
            {profile.email}
            {profile.emailVerified && (
              <span className="ml-2">
                <VerifiedStatus color={colors.status.positive.primary} />
              </span>
            )}
          </>
        ),
      },
      {
        content: (
          <>
            {groups.map(({ name, uuid }) => (
              <Tag key={uuid} className="mr-2 group">
                {name}
              </Tag>
            ))}
          </>
        ),
      },
      {
        content: (
          <>
            {authorizationRoles.map((role) => (
              <Tag key={role} className="mr-2 role">
                {roleNames?.[role as keyof typeof roleNames] != null &&
                  t(`settings.roles.roleNames.${roleNames[role as keyof typeof roleNames]}`, {
                    count: 1,
                  })}
              </Tag>
            ))}
          </>
        ),
      },
      {
        content: isFlexRelated
          ? profile.flexMemberships.map((fm) => (
              <Tag key={fm.uuid} className="mr-2 role">
                {fm.flexTenant.name}
              </Tag>
            ))
          : null,
      },
      {
        content: withCompany ? profile.companyV2?.name ?? "" : null,
      },
      {
        content: (
          <div className="status">
            <Form.DynamicSelect
              name="status"
              value={profile.status}
              showChrome="onInteraction"
              mobileView="minimal"
              size="sm"
              onChange={({ target: { value } }) => {
                updateStatus(value);
              }}
              options={
                profile.status === ProfileStatus.PendingApproval
                  ? [
                      {
                        value: ProfileStatus.PendingApproval,
                        label: <StatusTag status={ProfileStatus.PendingApproval} />,
                        dropLabel: false,
                      },
                      {
                        value: ProfileStatus.Active,
                        label: <StatusTag status={ProfileStatus.Active} />,
                        dropLabel: <ActivateUser />,
                      },
                      {
                        value: ProfileStatus.Deactivated,
                        label: <StatusTag status={ProfileStatus.Deactivated} />,
                        dropLabel: <DeactivateUser />,
                      },
                    ]
                  : [
                      {
                        value: ProfileStatus.Active,
                        label: <StatusTag status={ProfileStatus.Active} />,
                        dropLabel: <ActivateUser />,
                      },
                      {
                        value: ProfileStatus.Deactivated,
                        label: <StatusTag status={ProfileStatus.Deactivated} />,
                        dropLabel: <DeactivateUser />,
                      },
                    ]
              }
            />
            <style jsx>{`
              .status {
                margin-left: -18px;
              }
            `}</style>
          </div>
        ),
      },
    ];

    return columns.filter((column) => column.content != null);
  };

  return { getColumns };
}
