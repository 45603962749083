import React from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { FilterTab, useTheme } from "@equiem/react-admin-ui";

import type { MyBookingsQueryVariables } from "../../../generated/gateway-client";

type Variables = Omit<MyBookingsQueryVariables, "sort" | "page">;

const tabOrder = ["all", "upcoming", "past", "cancelled"] as const;

export type Tab = (typeof tabOrder)[number];
export const isTab = (f: unknown): f is Tab => tabOrder.includes(f as Tab);
export const defaultTab: Tab = "all";

export const tabFilters: Record<Tab, Variables | null> = {
  all: null,
  upcoming: {
    after: Date.now(),
    filters: {
      cancelled: false,
    },
  },
  past: {
    before: Date.now(),
    filters: {
      cancelled: false,
    },
  },
  cancelled: {
    filters: {
      cancelled: true,
    },
  },
};

export const defaultTabFilter = tabFilters.all;

interface Props {
  onTabChanged: (tab: Tab) => void;
  activeTab: Tab;
  countLoading: boolean;
  totalCount?: number;
  upcomingCount?: number;
  pastCount?: number;
  cancelledCount?: number;
}

export const MyBookingsFilterTabs: React.FC<Props> = ({
  onTabChanged,
  activeTab,
  countLoading,
  totalCount,
  upcomingCount,
  pastCount,
  cancelledCount,
}) => {
  const { t } = useTranslation();
  const { spacers } = useTheme(true);

  const tabTitles: Record<Tab, string> = {
    all: t("common.all"),
    upcoming: t("bookings.operations.upcoming"),
    past: t("bookings.operations.past"),
    cancelled: t("bookings.operations.cancelled"),
  };

  const tabCounts: Record<Tab, number | undefined> = {
    all: totalCount,
    upcoming: upcomingCount,
    past: pastCount,
    cancelled: cancelledCount,
  };

  return (
    <div className="bookings-tabs-container">
      {tabOrder.map((tab) => (
        <FilterTab
          key={tab}
          title={tabTitles[tab]}
          value={tabCounts[tab]}
          valueLoading={countLoading}
          isActive={activeTab === tab}
          onClick={() => {
            onTabChanged(tab);
          }}
        />
      ))}
      <style jsx>{`
        .bookings-tabs-container {
          display: flex;
          gap: ${spacers.s3};
        }
        .bookings-tabs-container :global(.tab) {
          font-size: 14px;
          text-transform: capitalize;
        }
      `}</style>
    </div>
  );
};
