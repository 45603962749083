import type { PropsWithChildren } from "react";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import type { FormikHelpers, FormikProps } from "formik";
import { Field, Form, Formik } from "formik";

import { CurrentProfile, stringNotEmpty } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Form as EqForm, Modal, useConfirmer, useTheme, useToast } from "@equiem/react-admin-ui";

import { Modal as ModalContext } from "../../../contexts/ModalContext";
import { useBuildingData } from "../hooks/useBuildingData";
import type { BuildingLevelFormValues } from "../types";
import { getLevelValidationSchema } from "../utils/validationSchema";

interface Props extends PropsWithChildren {
  editingLevel?: {
    id?: string;
    title?: string;
    providerLevelName?: string;
  };
}

export const BuildingLevelWidget: React.FC<Props> = ({ editingLevel }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const modal = useContext(ModalContext);
  const { colors } = useTheme();
  const { withConfirmation } = useConfirmer();
  const formInnerRef = useRef<FormikProps<BuildingLevelFormValues>>(null);
  const isUpdateModal = editingLevel?.id != null;
  const { updateLevel, NEW_ID } = useBuildingData();
  const toast = useToast();
  const { canManageRegion } = useContext(CurrentProfile);

  useEffect(() => {
    if (modal.activeModal === "BuildingLevel") {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [modal.activeModal]);

  const initialValues: BuildingLevelFormValues = useMemo(
    () => ({
      uuid: editingLevel?.id,
      title: editingLevel?.title ?? "",
      providerLevelName: editingLevel?.providerLevelName ?? "",
    }),
    [editingLevel],
  );

  const onClose = useCallback(() => {
    setShowModal(false);
    modal.close();
  }, [setShowModal, modal]);

  const onCloseModal = useCallback(() => {
    formInnerRef.current?.dirty === true
      ? withConfirmation({
          title: t("common.areYouSure"),
          message: t("settings.build.cancelMessage"),
          confirmButtonText: t("common.yesCancel"),
          cancelButtonText: t("home.widgets.cancelNo"),
          confirmButtonVariant: "danger",
          onConfirm: onClose,
        })()
      : onClose();
  }, [withConfirmation, t, onClose, formInnerRef]);

  const handleSubmit = async (
    values: BuildingLevelFormValues,
    { setSubmitting }: FormikHelpers<BuildingLevelFormValues>,
  ) => {
    try {
      setSubmitting(true);
      await updateLevel({
        id: values.uuid ?? NEW_ID,
        title: values.title,
        providerLevelName: values.providerLevelName,
      });
      toast.positive(isUpdateModal ? t("settings.build.widget.levelUpdated") : t("settings.build.widget.levelAdded"));
    } finally {
      setSubmitting(false);
      setShowModal(false);
      modal.close();
    }
  };

  return (
    <>
      <Modal.Dialog
        title={isUpdateModal ? t("settings.build.widget.editLevel") : t("settings.build.widget.newLevel")}
        show={showModal}
        sideModal={true}
        onHide={onCloseModal}
        hideOnEsc={true}
        hideOnClick={true}
        size="md"
      >
        <Modal.Header closeButton onClose={onCloseModal} noBorder={false} />
        <Formik
          initialValues={initialValues}
          innerRef={formInnerRef}
          validationSchema={getLevelValidationSchema(t)}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ submitForm, isSubmitting, isValid, touched, errors }) => (
            <>
              <Modal.Body noPadding>
                <Modal.Body noMargin={true}>
                  <Form>
                    <EqForm.Group
                      label={t("settings.build.widget.levelName")}
                      required
                      error={touched.title != null && errors.title != null ? errors.title : undefined}
                    >
                      <Field
                        id="title"
                        name="title"
                        placeholder={t("settings.build.widget.levelNamePlaceholder")}
                        as={EqForm.Input}
                        maxLength={300}
                      />
                    </EqForm.Group>
                    <EqForm.Group
                      label={t("settings.build.widget.providerLevelName")}
                      error={
                        touched.providerLevelName != null && errors.providerLevelName != null
                          ? errors.providerLevelName
                          : undefined
                      }
                      required={false}
                      tooltipText={t("settings.build.widget.providerLevelTooltip")}
                      showTooltip={true}
                    >
                      <Field
                        id="providerLevelName"
                        name="providerLevelName"
                        disabled={!canManageRegion && isUpdateModal && stringNotEmpty(initialValues.providerLevelName)}
                        placeholder={t("settings.build.widget.providerLevelNamePlaceholder")}
                        as={EqForm.Input}
                        maxLength={255}
                      />
                    </EqForm.Group>
                  </Form>
                </Modal.Body>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="ghost" className="mr-4" onClick={onCloseModal}>
                  {t("common.cancel")}
                </Button>
                <Button
                  disabled={isSubmitting || !isValid}
                  type="submit"
                  variant="primary"
                  onClick={() => {
                    void submitForm();
                  }}
                >
                  {isUpdateModal ? t("common.saveChanges") : t("settings.build.createLevel")}
                </Button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </Modal.Dialog>

      <style jsx>{`
        .title {
          font-weight: 400;
          font-size: 18px;
          line-height: 28px;
        }
        hr {
          border: none;
          border-top: 1px solid ${colors.border};
          margin-bottom: 16px;
        }
      `}</style>
    </>
  );
};
