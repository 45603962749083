import type { Dispatch, SetStateAction } from "react";
import React, { createContext, useContext, useMemo, useState } from "react";
import type { CreditStatsQueryRole } from "../../../generated/gateway-client";
import {
  CreditTransactionsQueryRole,
  useFlexTenantsFiltersQuery,
  useGetCreditStatsQuery,
  useGetCreditTransactionsQuery,
  useMyManagedCreditAccountsQuery,
} from "../../../generated/gateway-client";
import { useRoleMapping } from "../hooks/useRoleMapping";
import { CurrentRole, notNullOrUndefined, Role } from "@equiem/lib";
import { useMonthFilterOptions } from "../hooks/useMonthFilterOptions";

const RECENT_TRANSACTION_COUNT = 10;
const TENANTS_PAGE_SIZE = 50;
const COMPANIES_PAGE_SIZE = 50;

export const CreditDashboardContext = createContext<{
  creditsActivity?: ReturnType<typeof useGetCreditTransactionsQuery>;
  creditStats?: ReturnType<typeof useGetCreditStatsQuery>;
  currentMonth?: { label: string; value: number };
  currentTenant?: string[];
  currentCompanies?: string[];
  setCurrentMonth: Dispatch<SetStateAction<{ label: string; value: number } | undefined>>;
  setCurrentTenant: Dispatch<SetStateAction<string[]>>;
  setCurrentCompanies: Dispatch<SetStateAction<string[]>>;
  months: Array<{ label: string; value: number }>;
  tenants: Array<{ label: string; value: string }>;
  companies: Array<{ label: string; value: string }>;
  flexTenants?: ReturnType<typeof useFlexTenantsFiltersQuery>;
  myManagedCreditAccounts?: ReturnType<typeof useMyManagedCreditAccountsQuery>;
}>({
  creditsActivity: undefined,
  creditStats: undefined,
  currentMonth: undefined,
  currentTenant: undefined,
  currentCompanies: undefined,
  setCurrentCompanies: () => undefined,
  setCurrentMonth: () => undefined,
  setCurrentTenant: () => undefined,
  flexTenants: undefined,
  myManagedCreditAccounts: undefined,
  months: [],
  companies: [],
  tenants: [],
});

export const CreditDashboardProvider = ({ children }: React.PropsWithChildren) => {
  const role = useRoleMapping();
  const { currentRole } = useContext(CurrentRole);
  const isFlexManager = currentRole === Role.FlexManager;
  const isCreditManager = role === CreditTransactionsQueryRole.CreditManager;

  const flexTenants = useFlexTenantsFiltersQuery({
    skip: !isFlexManager,
    variables: { first: TENANTS_PAGE_SIZE },
  });

  const myManagedCreditAccounts = useMyManagedCreditAccountsQuery({
    skip: isFlexManager || !isCreditManager,
    variables: {
      first: COMPANIES_PAGE_SIZE,
    },
  });

  const myManagedCreditAccountsOptions = useMemo(
    () =>
      myManagedCreditAccounts.data?.myManagedCreditAccounts.edges.filter(notNullOrUndefined).map((item) => ({
        name: item.node?.creditCustomer.company?.name as string,
        uuid: item.node?.uuid as string,
      })) ?? [],
    [myManagedCreditAccounts.data?.myManagedCreditAccounts.edges],
  );

  const flexTenantsOptions = useMemo(
    () =>
      flexTenants.data?.myFlexTenants.edges
        .filter(notNullOrUndefined)
        .map(({ node }) => ({ name: node?.name as string, uuid: node?.creditAccount?.uuid as string })) ?? [],
    [flexTenants.data?.myFlexTenants.edges],
  );

  const tenants = flexTenantsOptions.map((tenant) => ({ label: tenant.name, value: tenant.uuid }));
  const companies = myManagedCreditAccountsOptions.map((company) => ({ label: company.name, value: company.uuid }));
  const months = useMonthFilterOptions();

  const [currentMonth, setCurrentMonth] = useState(months.find((m) => m.value === 0));
  const [currentTenant, setCurrentTenant] = useState<string[]>([]);
  const [currentCompanies, setCurrentCompanies] = useState<string[]>([]);
  const flexManagerCreditAccountsUuids = currentTenant.length > 0 ? currentTenant : undefined;
  const creditManagerCreditAccountsUuids = currentCompanies.length > 0 ? currentCompanies : undefined;

  const creditStats = useGetCreditStatsQuery({
    variables: {
      monthOffset: currentMonth?.value ?? 0,
      as: role as unknown as CreditStatsQueryRole,
      creditAccountUuids: isFlexManager
        ? flexManagerCreditAccountsUuids
        : isCreditManager
        ? creditManagerCreditAccountsUuids
        : undefined,
    },
    fetchPolicy: "network-only",
  });

  const creditsActivity = useGetCreditTransactionsQuery({
    variables: { as: role, first: RECENT_TRANSACTION_COUNT },
    fetchPolicy: "network-only",
  });

  return (
    <CreditDashboardContext.Provider
      value={{
        creditsActivity,
        creditStats,
        currentMonth,
        currentTenant,
        flexTenants,
        setCurrentMonth,
        setCurrentTenant,
        currentCompanies,
        setCurrentCompanies,
        myManagedCreditAccounts,
        companies,
        tenants,
        months,
      }}
    >
      {children}
    </CreditDashboardContext.Provider>
  );
};

export const useCreditDashboardContext = () => {
  return useContext(CreditDashboardContext);
};
