import React from "react";
import { useTranslation } from "@equiem/localisation-eq1";
import { useTheme, Button } from "@equiem/react-admin-ui";
import { RiInformationLine } from "@equiem/react-admin-ui/icons";
import { useRouter } from "next/router";

export const SuccessfulAdjustmentToast = ({ tenantName }: { tenantName: string }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const router = useRouter();
  return (
    <>
      <div className="confirmation-toast">
        <RiInformationLine size={24} color={colors.white} />
        {t("credits.adjustments.successfullyAdded", { company: tenantName })}

        <Button
          variant="outline"
          inverted
          onClick={() => {
            void router.push("/credits/credits-activity").catch(console.log);
          }}
        >
          {t("credits.adjustments.goToActivity")}
        </Button>
      </div>
      <style jsx>{`
        :global(.confirmation-toast) {
          display: flex;
          align-items: center;
          gap: 8px;
          width: 100%;
          justify-content: space-between;
        }
      `}</style>
    </>
  );
};
