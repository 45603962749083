import React, { useState } from "react";
import { Field, Form as FormikForm, Formik } from "formik";

import { CKEditorNoSSR } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Form, Modal, useTheme } from "@equiem/react-admin-ui";

import type { CancellationPolicy } from "../../../../../lib/cancellationPolicy";
import { cancellationPolicyModalValidationSchema } from "../../../../../lib/validationSchema";
import { ResourceDivider } from "../ResourceDivider";

import type { CompanyOption } from "./ResourceCreateAndEditFormCancellationPermissions";
import { ResourceCreateAndEditFormCancellationPermissionsNoticePeriod } from "./ResourceCreateAndEditFormCancellationPermissionsNoticePeriod";

export type CancellationPolicyModalFormValues = {
  defaultPolicy: boolean;
  editBookingPolicy: CancellationPolicy;
};

interface Props {
  showModal: boolean;
  setShowModal: (_state: boolean) => void;
  modalIndex: number | null;
  defaultPolicyUsed: boolean;
  companyOptions: CompanyOption[];
  editBookingPolicy?: CancellationPolicy;
  onAdd: (_editBookingPolicy: CancellationPolicy) => void;
  onEdit: (_editBookingPolicy: CancellationPolicy, _index: number) => void;
}

export const ResourceCreateAndEditFormCancellationPolicyModal: React.FC<Props> = ({
  showModal,
  setShowModal,
  modalIndex,
  defaultPolicyUsed,
  companyOptions,
  editBookingPolicy,
  onAdd,
  onEdit,
}) => {
  const { t } = useTranslation();
  const { breakpoints, colors, spacers } = useTheme(true);

  const [assignmentCompaniesTouched, setAssignmentCompaniesTouched] = useState(false);

  return (
    <Modal.Dialog
      title={
        modalIndex != null
          ? t("bookings.resources.updateCancellationPolicy")
          : t("bookings.resources.addCancellationPolicy")
      }
      show={showModal}
      size="xl"
      onHide={() => {
        setShowModal(false);
      }}
      hideOnClick={false}
      hideOnEsc={false}
      supportsMobile={true}
      focusTrapOptions={{ initialFocus: false }}
      centered
    >
      <div className="cancellation-policy-modal">
        <Modal.Header supportsMobile={true} closeButton={true} noBorder={false} />
        <Formik<CancellationPolicyModalFormValues>
          initialValues={{
            defaultPolicy: editBookingPolicy != null ? editBookingPolicy.companies.length === 0 : !defaultPolicyUsed,
            editBookingPolicy: editBookingPolicy ?? {
              companies: [],
              termsAndConditions: "",
              noticePeriodInMinutes: 0,
            },
          }}
          validationSchema={cancellationPolicyModalValidationSchema(t)}
          validateOnMount={true}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            modalIndex != null ? onEdit(values.editBookingPolicy, modalIndex) : onAdd(values.editBookingPolicy);
            actions.setSubmitting(false);
            setAssignmentCompaniesTouched(false);
          }}
        >
          {({ isSubmitting, isValid, submitForm, setFieldValue, setFieldTouched, values, errors }) => {
            const assignmentCompaniesError = assignmentCompaniesTouched
              ? Array.isArray(errors.editBookingPolicy?.companies)
                ? errors.editBookingPolicy?.companies.join(", ")
                : errors.editBookingPolicy?.companies
              : undefined;
            return (
              <FormikForm>
                <Modal.Body>
                  <Form.Group label={t("bookings.resources.assignment")}>
                    <div className="assignment-container">
                      <div className="assignment-radio">
                        <Field
                          component={Form.EnclosedRadioButton}
                          id="assignment-default"
                          name="defaultPolicy"
                          label={t("bookings.resources.assignmentDefaultLabel")}
                          description={t("bookings.resources.assignmentDefaultDescriptionForRate")}
                          value={values.defaultPolicy}
                          disabled={isSubmitting || defaultPolicyUsed}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (e.currentTarget.checked) {
                              void setFieldValue("defaultPolicy", true).then(async () =>
                                setFieldValue("editBookingPolicy.companies", []),
                              );
                            }
                          }}
                        />
                      </div>
                      <div className="assignment-radio">
                        <Field
                          component={Form.EnclosedRadioButton}
                          id="assignment-companies"
                          name="assignment"
                          label={t("bookings.resources.assignmentSelectedLabel")}
                          description={t("bookings.resources.assignmentSelectedDescriptionForPolicy")}
                          value={!values.defaultPolicy}
                          disabled={isSubmitting}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (e.currentTarget.checked) {
                              void setFieldValue("defaultPolicy", false).then(async () =>
                                setFieldValue("editBookingPolicy.companies", []),
                              );
                            }
                          }}
                        />
                      </div>
                    </div>
                  </Form.Group>

                  <div className="form-group-container">
                    <div className="notice-period">
                      <ResourceCreateAndEditFormCancellationPermissionsNoticePeriod
                        name="editBookingPolicy.noticePeriodInMinutes"
                        value={Number(values.editBookingPolicy.noticePeriodInMinutes)}
                        onChange={(val) => {
                          void setFieldTouched("editBookingPolicy.noticePeriodInMinutes");
                          void setFieldValue("editBookingPolicy.noticePeriodInMinutes", val);
                        }}
                        label={t("bookings.resources.noticePeriod")}
                        showTooltip={true}
                        tooltipText={t("bookings.resources.noticePeriodHint")}
                        disabled={isSubmitting}
                      />
                    </div>

                    {!values.defaultPolicy && (
                      <Form.Group
                        label={t("bookings.resources.appliesToLabel")}
                        required
                        showTooltip
                        tooltipText={t("bookings.resources.appliesToTooltipForPolicy")}
                        error={assignmentCompaniesError}
                      >
                        <Field
                          name="editBookingPolicy.companies"
                          options={companyOptions}
                          as={Form.MultiSelect}
                          variant="wrap"
                          placeholder={t("bookings.resources.searchForCompanies")}
                          searchPlaceholder={t("bookings.resources.companyName")}
                          isMulti
                          enableSelectAll
                          disabled={isSubmitting}
                          onClose={() => {
                            setAssignmentCompaniesTouched(true);
                          }}
                        />
                      </Form.Group>
                    )}
                  </div>

                  <ResourceDivider />

                  <Form.Group
                    error={errors.editBookingPolicy?.termsAndConditions}
                    label={t("bookings.resources.editCancellationPolicy")}
                    showTooltip
                    tooltipText={t("bookings.resources.editCancellationPolicyHint")}
                  >
                    <Field
                      id="editBookingPolicy.termsAndConditions"
                      name="editBookingPolicy.termsAndConditions"
                      placeholder={t("bookings.resources.termsAndConditions")}
                      as={CKEditorNoSSR}
                      toolbar={[
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "|",
                        "link",
                        "|",
                        "bulletedList",
                        "numberedList",
                        "|",
                        "outdent",
                        "indent",
                        "|",
                        "undo",
                        "redo",
                      ]}
                      disabled={isSubmitting}
                    />
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="ghost"
                    type="button"
                    onClick={() => {
                      setShowModal(false);
                      setAssignmentCompaniesTouched(false);
                    }}
                  >
                    {t("common.cancel")}
                  </Button>
                  <Button
                    className="add-button"
                    type="submit"
                    variant="primary"
                    onSubmit={() => {
                      void submitForm();
                    }}
                    disabled={isSubmitting || !isValid}
                  >
                    {modalIndex != null ? t("common.saveChanges") : t("common.add")}
                  </Button>
                </Modal.Footer>
              </FormikForm>
            );
          }}
        </Formik>
      </div>
      <style jsx>
        {`
          .cancellation-policy-modal :global(.header) {
            padding: ${spacers.s6} !important;
          }
          .cancellation-policy-modal :global(.header .title-row) {
            margin: ${spacers.s0} !important;
          }
          .cancellation-policy-modal :global(.header .title) {
            color: ${colors.dark};
            font-size: 24px;
            font-weight: 700;
            line-height: 28px;
            text-transform: none;
            text-align: left;
            z-index: 1;
          }
          .cancellation-policy-modal :global(.modal-body) {
            display: flex;
            flex-direction: column;
            padding: ${spacers.s7} ${spacers.s6};
            margin: ${spacers.s0};
            gap: ${spacers.s7};
            overflow-y: auto;
            max-height: 400px;
          }
          .cancellation-policy-modal :global(.modal-body .assignment-container) {
            display: flex;
            gap: ${spacers.s3};
          }
          .cancellation-policy-modal :global(.modal-body .assignment-radio) {
            flex: 1;
          }
          .cancellation-policy-modal :global(.modal-body .assignment-radio label) {
            width: 100%;
            height: 100%;
          }
          .cancellation-policy-modal :global(.modal-body .assignment-container h1) {
            text-overflow: ellipsis;
            text-wrap: wrap;
          }
          .cancellation-policy-modal :global(.modal-body .assignment-container p) {
            text-wrap: wrap;
          }
          .cancellation-policy-modal :global(.modal-body .notice-period) {
            width: 33%;
            min-width: 200px;
          }
          .cancellation-policy-modal :global(.modal-body hr),
          .cancellation-policy-modal :global(.modal-body .form-group) {
            margin: ${spacers.s0};
          }
          .cancellation-policy-modal :global(.modal-body .form-group-container) {
            display: flex;
            gap: ${spacers.s7};
          }
          .cancellation-policy-modal :global(.modal-body .form-group .form-header label) {
            line-height: 14px;
          }
          .cancellation-policy-modal :global(.footer) {
            padding: ${spacers.s5} ${spacers.s7} !important;
            gap: ${spacers.s3};
            border-top: 1px solid ${colors.grayscale[10]};
          }
          .cancellation-policy-modal :global(.footer .add-button) {
            width: 200px;
          }
          @media screen and (max-width: ${breakpoints.md}px) {
            .cancellation-policy-modal :global(.header .title) {
              font-size: 20px;
            }
            .cancellation-policy-modal :global(.header button) {
              z-index: 2;
            }
            .cancellation-policy-modal :global(.modal-body) {
              padding: ${spacers.s5};
              gap: ${spacers.s5};
            }
            .cancellation-policy-modal :global(.modal-body .assignment-container) {
              flex-direction: column;
            }
            .cancellation-policy-modal :global(.modal-body .notice-period) {
              width: 100%;
            }
            .cancellation-policy-modal :global(.modal-body .form-group-container) {
              flex-direction: column;
              gap: ${spacers.s5};
            }
            .cancellation-policy-modal :global(.footer button) {
              flex: 1;
            }
          }
        `}
      </style>
    </Modal.Dialog>
  );
};
