import React, { useContext } from "react";
import { useTranslation } from "@equiem/localisation-eq1";
import { EmptyState } from "./EmptyState";
import { Button, useTheme } from "@equiem/react-admin-ui";
import { RiAddCircleLine } from "@equiem/react-admin-ui/icons";
import { ManageCompaniesCreditsTable } from "./ManageCompaniesCreditsTable";
import { useManagedCompaniesCreditsRequests } from "../../hooks/useManagedCompaniesCreditsRequests";
import { CompaniesCreditsTopSection } from "./CompaniesCreditsTopSection";
import { AddCompaniesCredits } from "./AddCompaniesCredits";
import { Modal as ModalContext } from "../../../../contexts/ModalContext";

export const CompaniesCredits = () => {
  const { t } = useTranslation();
  const { breakpoints } = useTheme();
  const result = useManagedCompaniesCreditsRequests();
  const isEmpty = !result.loading && result.totalCount === 0;
  const modal = useContext(ModalContext);
  const handleAddCompaniesCredits = () => {
    modal.open("AddCompaniesCredits");
  };

  return (
    <>
      <section className="container">
        {isEmpty ? (
          <>
            <EmptyState />
            <div>
              <Button variant="secondary" onClick={handleAddCompaniesCredits}>
                <RiAddCircleLine /> {t("common.add")}
              </Button>
            </div>
          </>
        ) : (
          <>
            <CompaniesCreditsTopSection />
            <ManageCompaniesCreditsTable />
          </>
        )}
        <AddCompaniesCredits />
      </section>
      <style jsx>{`
        .container {
          display: flex;
          padding: 16px;
          flex-direction: column;
          justify-content: ${isEmpty ? "center" : "flex-start"};
          align-items: center;
          height: 100%;
        }

        @media (min-width: ${breakpoints.sm}px) {
          .container {
            padding: 32px;
          }
        }
      `}</style>
    </>
  );
};
