import React, { useContext, useEffect } from "react";
import { Form as FormikForm, useFormikContext } from "formik";
import { Alert, useTheme } from "@equiem/react-admin-ui";
import { RiErrorWarningLine, RiInformationLine } from "@equiem/react-admin-ui/icons";
import { useTranslation } from "@equiem/localisation-eq1";
import { CurrentProfile } from "@equiem/lib";

import type { BookingFormValue } from "../models/BookingFormValue";
import { BookingModalInfo } from "../contexts/BookingModalInfoProvider";
import { BookingFormContext } from "../contexts/BookingFormProvider";
import { BookingModal } from "../contexts/BookingModalContext";
import { BookingFormTermsAndConditions } from "./BookingFormTermsAndConditions";
import { BookingFormSubmitButton } from "./BookingFormSubmitButton";
import { BookingResourceSummary } from "./BookingResourceSummary";
import { BookingPayment } from "./bookingPayment/BookingPayment";
import { BookingFormDate } from "./BookingFormDate";
import { BookingFormTime } from "./BookingFormTime";
import { BookingFormRoomConfig } from "./BookingFormRoomConfig";
import { BookingFormTitle } from "./BookingFormTitle";
import { BookingFormUser } from "./BookingFormUser";
import { BookingDiscountsDisplay } from "./BookingDiscountsDisplay";
import { BookingVisitors } from "./BookingVisitors";
import { Addons } from "./addOns/AddOns";
import { BookingPaymentSummary } from "./bookingPayment/BookingPaymentSummary";
import { BookingFormNote } from "./BookingFormNote";
import { useBookingModalEditResourceControl } from "./BookingFormResource";
import { BookingAdditionalDetails } from "./bookingPayment/BookingAdditionalDetails";

export const BookingFormContent: React.FC = () => {
  const { colors } = useTheme();
  const { profile } = useContext(CurrentProfile);
  const fm = useFormikContext<BookingFormValue>();
  const info = useContext(BookingFormContext);
  const modal = useContext(BookingModal);
  const { t } = useTranslation();
  const { booking, resource, autoApprove } = useContext(BookingModalInfo);

  const isHiddenCost = !autoApprove && resource.hiddenCost;
  const adminNoteMode = resource.viewerPermissions?.canManageBookings ?? false;

  // For proxy edit booking form, replace the modal title with the resource edit
  // control
  useBookingModalEditResourceControl();

  // We want to make sure that if the form is touched
  // display the confirmation before close.
  useEffect(() => {
    if (fm.dirty) {
      modal.setShowConfirmationBeforeClose(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fm.dirty]);

  return (
    <>
      {booking == null && !autoApprove && (
        <div className="booking-form-approval-alert pb-4">
          <Alert
            size="large"
            variant="gray"
            icon={<RiErrorWarningLine size={18} color={colors.grayscale[50]} />}
            message={
              resource.hiddenCost
                ? t("bookings.operations.approvalRequiredHiddenCostAlert")
                : t("bookings.operations.approvalRequiredAlert")
            }
          />
        </div>
      )}
      <div className={`display-${info.step}`}>
        <FormikForm autoComplete="off" className="end-user-booking-form">
          {info.step === "initial" && (
            <>
              <BookingResourceSummary className="mb-7" />
              <BookingDiscountsDisplay />
              <div className="booking-form-main-block pb-5">
                <h4 className="font-weight-bold mb-4">
                  {booking != null ? t("bookings.operations.editBooking") : t("bookings.operations.bookNow")}
                </h4>
                <BookingFormDate />
                <BookingFormTime />
                <BookingFormUser />
                <BookingFormRoomConfig />
                {(booking == null || booking.user.uuid === profile?.uuid) && <BookingFormTitle />}
                {adminNoteMode ? (
                  <BookingFormNote
                    fieldName="adminNote"
                    addButtonText={t("bookings.operations.addAdminNote")}
                    label={t("bookings.operations.adminNote")}
                    tooltipText={t("bookings.operations.adminNoteTooltip")}
                    placeholder={t("bookings.operations.adminNotePlaceholder")}
                  />
                ) : (
                  <BookingFormNote
                    fieldName="note"
                    addButtonText={t("bookings.operations.addNote")}
                    label={t("bookings.operations.bookingNote")}
                    tooltipText={t("bookings.operations.bookingNoteTooltip")}
                    placeholder={t("bookings.operations.bookingNotePlaceholder")}
                  />
                )}
              </div>
              <BookingVisitors
                hideMessage={
                  fm.values.recurringSettings == null ? undefined : (
                    <Alert
                      variant="gray"
                      size="large"
                      icon={<RiInformationLine size={18} color={colors.grayscale[50]} />}
                      message={t("bookings.repeat.inviteLater")}
                    />
                  )
                }
              />
              {resource.addOns.length > 0 && (
                <div className="booking-form-addons-block pb-5">
                  <h4 className="font-weight-bold mb-4">{t("bookings.operations.addOns")}</h4>
                  <Addons addOns={resource.addOns} hideCost={isHiddenCost} enableExpander={true} />
                </div>
              )}
              {fm.values.freeBookingMode ? (
                <BookingFormTermsAndConditions />
              ) : (
                !isHiddenCost && <BookingPaymentSummary className="booking-summary pb-3" />
              )}
            </>
          )}
          {info.step === "payment" && (
            <>
              <div className="payment-details">
                <BookingPayment />
                <BookingAdditionalDetails />
                <BookingFormTermsAndConditions />
              </div>
              {!isHiddenCost && <BookingPaymentSummary className="booking-summary pb-3" />}
            </>
          )}
          <BookingFormSubmitButton />
        </FormikForm>
      </div>
      <style jsx>{`
        .payment-cont :global(.booking-summary) {
          align-self: flex-end;
        }
        .display-payment,
        .display-payment :global(form) {
          height: 100%;
        }
        .display-payment :global(form) {
          display: flex;
          flex-direction: column;
        }
        .payment-details {
          flex-grow: 1;
        }
        .display-payment :global(.booking-summary) {
          margin-top: auto;
        }
      `}</style>
    </>
  );
};
