import { useCallback, useEffect, useState } from "react";
import axios, { type AxiosProgressEvent } from "axios";
import { fileToImageUrl } from "./fileToImageUrl";
import { IFileV2, LocalAttachedFile, PresignedUrl } from "./IFileV2";
import { useAbortController } from "./useAbortController";

type Params = {
  value: IFileV2[];
  onChange: (files: IFileV2[]) => void;
  onRemove: (allItems: IFileV2[]) => void;
  createPresignedUrl: () => Promise<PresignedUrl | undefined>;
  multipleUpload?: boolean;
  allowImages?: boolean;
};

export function useFilePreloadState({
  value,
  onChange,
  onRemove,
  createPresignedUrl,
  multipleUpload = false,
  allowImages = true,
}: Params) {
  const { signal, onAbortLoading, resetAbortController } = useAbortController();
  const [{ progress, uploading }, setProgressStatus] = useState({ progress: 0, uploading: false });
  const [localAttachedFile, setLocalAttachedFile] = useState<LocalAttachedFile>();
  const [attachedFiles, setAttachedFiles] = useState<IFileV2[]>(value ?? []);

  useEffect(() => {
    setAttachedFiles(value);
  }, [value]);

  const uploadImageToPresignedUrl = async (localFile?: LocalAttachedFile, presignedUrl?: PresignedUrl) => {
    const result = await axios.put(presignedUrl!.signedUrl, localFile!.file, {
      signal,
      onUploadProgress: (e: AxiosProgressEvent) => {
        const currentProgress = Math.ceil((e.loaded / (e.total ?? 1)) * 100);
        setProgressStatus({ progress: currentProgress, uploading: true });

        if (currentProgress === 100) {
          setTimeout(() => {
            setProgressStatus({ progress: 0, uploading: false });
          }, 750);
        }
      },
    });
    return result;
  };

  const getNewFiles = (localFile?: LocalAttachedFile, presignedUrl?: PresignedUrl) => {
    const newFile: IFileV2 = {
      value: {
        filename: (localFile as File).name ?? localFile?.file?.name ?? localAttachedFile?.file?.name ?? "Unknown",
        size: localFile?.size ?? localFile?.file?.size ?? 0,
        mimeType: localFile?.mimeType ?? localFile?.file?.type,
      },
      temporaryUuid: presignedUrl?.temporaryUuid,
    };

    return multipleUpload ? [...attachedFiles, newFile] : [newFile];
  };

  const onSave = async (localFile?: LocalAttachedFile) => {
    if (!localAttachedFile && !localFile) {
      return;
    }

    try {
      setProgressStatus({ progress: 3, uploading: true });
      const presignedUrl = await createPresignedUrl();
      const result = await uploadImageToPresignedUrl(localFile, presignedUrl);

      if (result.status === 200) {
        onChange(getNewFiles(localFile, presignedUrl));
      } else {
        throw new Error(`Upload error: ${result.statusText}`);
      }
    } catch (e) {
      console.log(e);
    } finally {
      resetAbortController();
      setProgressStatus({ progress: 0, uploading: false });
      setLocalAttachedFile({ file: undefined });
    }
  };

  const onFileChange = async (files: FileList) => {
    if (files.length !== 0) {
      if (!files[0].type.includes("image") || !allowImages) {
        setLocalAttachedFile({ file: files[0], size: files[0].size, mimeType: files[0].type });
        await onSave({ file: files[0] });
        return;
      }

      const img = (await fileToImageUrl(files[0])) as string;
      setLocalAttachedFile({ file: files[0], img, size: files[0].size, mimeType: files[0].type });
      await onSave({ file: files[0] });
    }
  };

  const removeImage = useCallback(
    (temporaryUuid: string) => {
      const newImages = attachedFiles.filter((img) => img.temporaryUuid !== temporaryUuid);
      onRemove(newImages);
    },
    [attachedFiles],
  );

  return {
    localAttachedFile,
    attachedFiles,
    uploading,
    progress,
    onFileChange,
    onSave,
    removeImage,
    setAttachedFiles,
    onAbortLoading,
  };
}
