import React, { useEffect, useMemo, useState } from "react";

import { stringNotEmpty } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Form, useTheme } from "@equiem/react-admin-ui";

import { useUsers } from "../contexts/UserContext";

import { AssigneeTag } from "./AssigneeTag";
import { ProfileTag } from "./ProfileTag";

interface P {
  queueUuid: string;
  setAssigneeUuid: (uuid: string) => void;
  assigneeUuid: string;
  label: string;
}
export const QueueAssigneeSelector: React.FC<P> = ({ setAssigneeUuid, assigneeUuid, label }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [selectedAssigneeUuid, setSelectedAssigneeUuid] = useState<string | undefined>(undefined);
  const { assignees } = useUsers();

  useEffect(() => {
    setAssigneeUuid(selectedAssigneeUuid ?? "");
  }, [selectedAssigneeUuid, setAssigneeUuid]);

  const options = useMemo(
    () =>
      assignees
        .filter((assignee) => assignee.uuid !== assigneeUuid)
        .flatMap((assignee) => {
          const params = {
            profileIconUrl: assignee.profileIconUrl ?? undefined,
            companyName: assignee.companyName ?? "",
            email: assignee.email,
            firstName: assignee.firstName ?? "",
            lastName: assignee.lastName ?? "",
          };

          return [
            {
              label: <ProfileTag {...params} />,
              facadeLabel: <ProfileTag {...params} hideDetails />,
              searchKeywords: [`${params.firstName} ${params.lastName}`, params.email],
              value: assignee.uuid,
            },
          ];
        }),
    [assignees, assigneeUuid],
  );

  return (
    <>
      <div>
        <Form.Group label={label} required={true} className="assignee-selector">
          <Form.DynamicSelect
            mobileView="minimal"
            size="sm"
            search
            className="assignee"
            searchPlaceholder={t("requests.reassign.assigneeSelector")}
            showSelectedOnList
            name="rassignee"
            showChrome
            value={selectedAssigneeUuid ?? ""}
            noneLabel={<AssigneeTag />}
            options={options}
            onChange={(event) => {
              const uuid = event.target.value;
              if (stringNotEmpty(uuid) && uuid !== selectedAssigneeUuid) {
                setSelectedAssigneeUuid(uuid);
              }
            }}
          />
        </Form.Group>
      </div>
      <style jsx>{`
        .assignee-selector label {
          margin-left: ${theme.spacers.s3};
        }
        div {
          margin-left: -${theme.spacers.s3};
        }
      `}</style>
    </>
  );
};
