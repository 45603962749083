import React, { useContext, useEffect, useMemo, useState } from "react";

import { CurrentProfile, CurrentRole, notNullOrUndefined, Role } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, ColumnView, Dropdown, Tooltip, useConfirmer, useTheme } from "@equiem/react-admin-ui";
import {
  RiAddCircleLine,
  RiAddLine,
  RiArrowDownLine,
  RiArrowUpLine,
  RiDeleteBinLine,
  RiEditLine,
  RiFileCopy2Line,
} from "@equiem/react-admin-ui/icons";

import { Modal as ModalContext } from "../../../contexts/ModalContext";
import { WidgetProvider } from "../../../contexts/WidgetContext";
import type { SpaceSiteAudience } from "../../../generated/settings-client";
import { SpaceVisibilityType, useCompaniesSpaceFilterQuery } from "../../../generated/settings-client";
import { useBuildingData } from "../hooks/useBuildingData";
import { translateVisibilityType } from "../utils/translateVisibilityType";
import { BuildingLevelWidget } from "../widgets/BuildingLevelWidget";
import { BuildingSpaceWidget } from "../widgets/BuildingSpaceWidget";
import { BulkUploadWidget } from "../widgets/BulkUploadWidget";

type Column = "levels" | "spaces" | "buildings";
type OrderDirection = "up" | "down";

type Space =
  | {
      id: string;
      title: string;
      ownerCompanyUuid: string;
      visibilityType?: SpaceVisibilityType;
      siteAudiences?: SpaceSiteAudience[] | null;
    }
  | undefined;

type Level =
  | {
      id: string;
      title: string;
      providerLevelName?: string;
    }
  | undefined;

export const BuildConfiguration: React.FC = () => {
  const { breakpoints, colors } = useTheme();
  const { withConfirmation } = useConfirmer();
  const { t } = useTranslation();
  const modal = useContext(ModalContext);
  const [newItemColumn, setNewItemColumn] = useState<Column>();
  const [editingSpace, setEditingSpace] = useState<Space>(undefined);
  const [editingLevel, setEditingLevel] = useState<Level>(undefined);
  const [spacersCompanyFilter, setSpacersCompanyFilter] = useState("");
  const [orderDirection, setOrderDirection] = useState<OrderDirection>();
  const { profile, canManageRegion } = useContext(CurrentProfile);
  const { currentRole } = useContext(CurrentRole);
  const isFlexManager = currentRole === Role.FlexManager;
  const isPropertyManagerButNotRegionalManager = currentRole === Role.PropertyManager && !canManageRegion;
  const {
    NEW_ID,
    levels: levelsData,
    spaces: spacesData,
    buildings,
    loading,
    selectedBuildingUuid,
    selectedLevelUuid,
    createLoading,
    updateLoading,
    orderLoading,
    duplicateLoading,
    setSelectedBuildingUuid,
    setSelectedLevelUuid,
    deleteSpace,
    deleteLevel,
    deleteBuilding,
    updateSpaceOrder,
    updateLevelOrder,
    updateBuildingOrder,
    duplicateLevel,
    duplicateBuilding,
  } = useBuildingData();
  const { data: companiesData } = useCompaniesSpaceFilterQuery({
    variables: {
      buildingUuids: buildings?.map((building) => building.id).filter(notNullOrUndefined) ?? [],
      first: 1000,
    },
    skip: buildings == null,
  });

  const handleBuildingDetails = (id?: string) => () => {
    modal.open("BuildingDetails", id);
  };

  const handleLevelDetails = (id?: string) => () => {
    modal.open("BuildingLevel", id);
  };

  const handleAddItem = (column: Column) => () => {
    switch (column) {
      case "buildings":
        handleBuildingDetails()();
        break;
      case "levels":
        setSelectedLevelUuid(undefined);
        setEditingLevel(undefined);
        handleLevelDetails()();
        break;
      default:
        break;
    }
  };

  const resetEditState = () => {
    setEditingLevel(undefined);
    setEditingSpace(undefined);
  };

  const handleReorder = (column: Column, direction: OrderDirection, id: string) => {
    setOrderDirection(direction);

    const orderUuids = (uuids: string[]): string[] => {
      const newUuids = [...uuids];
      const index = newUuids.indexOf(id);

      if (index === -1) {
        return [];
      }

      if (direction === "up") {
        newUuids.splice(index - 1, 0, newUuids.splice(index, 1)[0]);
      }

      if (direction === "down") {
        newUuids.splice(index + 1, 0, newUuids.splice(index, 1)[0]);
      }

      return newUuids;
    };

    if (column === "buildings") {
      const uuids = buildings?.map((building) => building.id).filter(notNullOrUndefined) ?? [];
      void updateBuildingOrder(orderUuids(uuids));
    }

    if (column === "levels") {
      const uuids = levelsData?.map((level) => level.id) ?? [];
      void updateLevelOrder(orderUuids(uuids));
    }

    if (column === "spaces") {
      const uuids = spacesData?.map((space) => space.id) ?? [];
      void updateSpaceOrder(orderUuids(uuids));
    }
  };

  const handleDuplicate = async (id: string, column: Column) => {
    if (column === "levels") {
      const result = await duplicateLevel(id);

      if (result != null) {
        setSelectedLevelUuid(result.uuid);
      }
    }

    if (column === "buildings") {
      const result = await duplicateBuilding(id);

      if (result != null) {
        setSelectedBuildingUuid(result.uuid);
      }
    }
  };

  const handleDelete = (id: string, column: Column) => () => {
    if (column === "levels") {
      void deleteLevel(id);
      setSelectedLevelUuid(undefined);
    }

    if (column === "buildings") {
      void deleteBuilding(id);
      setSelectedBuildingUuid(undefined);
    }

    if (column === "spaces") {
      void deleteSpace(id);
    }

    resetEditState();
  };

  const handleReset = (column: Column) => () => {
    resetEditState();
    setNewItemColumn(undefined);

    if (column === "levels") {
      setTimeout(() => {
        setSelectedLevelUuid(undefined);
      }, 0);
    }
  };

  useEffect(() => {
    // reset state after create/update
    if (!createLoading && !updateLoading) {
      setNewItemColumn(undefined);
      setOrderDirection(undefined);
      resetEditState();
    }
  }, [createLoading, updateLoading]);

  useEffect(() => {
    // clear new item state when changing item
    if (editingLevel != null || editingSpace != null) {
      setNewItemColumn(undefined);
    }
  }, [editingLevel, editingSpace]);

  useEffect(() => {
    // clear edit state when adding new item
    if (newItemColumn === "levels") {
      setEditingLevel(undefined);
      setSelectedLevelUuid(undefined);
    }
    if (newItemColumn === "spaces") {
      setEditingSpace(undefined);
    }
  }, [newItemColumn]);

  useEffect(() => {
    // clear add/edit state when changing building
    setNewItemColumn(undefined);
    setEditingLevel(undefined);
    setSelectedLevelUuid(undefined);
  }, [selectedBuildingUuid]);

  useEffect(() => {
    // clear add/edit state when changing level
    if (selectedLevelUuid !== editingLevel?.id) {
      setEditingLevel(undefined);
      setNewItemColumn(undefined);
    }
  }, [selectedLevelUuid, editingLevel]);

  const openLevelModal = (level?: Level) => {
    setEditingLevel(level);
    modal.open("BuildingLevel", undefined);
  };

  const openSpaceModal = (space?: Space) => {
    setEditingSpace(space);
    modal.open("BuildingSpace", undefined);
  };

  const spaces = useMemo(() => {
    const newItem = [];

    if (newItemColumn === "spaces" && profile?.companyV2?.uuid != null) {
      newItem.push({
        id: NEW_ID,
        title: "",
        visibilityType: SpaceVisibilityType.Custom,
        ownerCompanyUuid: profile.companyV2.uuid,
        providerSpaceName: undefined,
        siteAudiences: [],
      });
    }

    return spacesData != null ? spacesData.concat(newItem) : undefined;
  }, [NEW_ID, spacesData, newItemColumn, profile?.companyV2?.uuid]);

  const filteredSpaces = useMemo(
    () =>
      spaces != null
        ? spaces
            .filter((space) => {
              const noFilterOrSameCompany =
                spacersCompanyFilter === "" || space.ownerCompanyUuid === spacersCompanyFilter;
              const isAllowedToView =
                canManageRegion ||
                space.visibilityType === "PUBLIC" ||
                space.ownerCompanyUuid === profile?.companyV2?.uuid;

              return isAllowedToView && noFilterOrSameCompany;
            })
            .map((space) => {
              let canEdit = true;
              const isMyCompanySpace = space.ownerCompanyUuid === profile?.companyV2?.uuid;
              if (currentRole === Role.FlexManager) {
                canEdit = canManageRegion || (isMyCompanySpace && space.visibilityType !== SpaceVisibilityType.Public);
              }
              if (currentRole === Role.PropertyManager) {
                canEdit = canManageRegion || isMyCompanySpace;
              }

              return {
                ...space,
                canEdit: canEdit,
              };
            })
        : [],
    [spaces, spacersCompanyFilter, canManageRegion, profile?.companyV2?.uuid, currentRole],
  );

  const companies = useMemo(
    () =>
      companiesData?.companiesV2.edges.filter((company) =>
        spaces?.find((space) => {
          return space.ownerCompanyUuid === company.node?.uuid;
        }),
      ),
    [companiesData, spaces],
  );

  const levels = useMemo(() => {
    const newItem = [];

    if (newItemColumn === "levels") {
      newItem.push({
        id: NEW_ID,
        title: "",
        count: 0,
        providerLevelName: undefined,
        spaces: [], // Add an empty 'spaces' array
      });
    }

    return levelsData != null ? levelsData.concat(newItem) : undefined;
  }, [NEW_ID, levelsData, newItemColumn]);

  const emptySettings = (column: Column) => {
    const messages: Record<Column, string> = {
      levels: t("settings.build.noLevels"),
      spaces: t("settings.build.noSpaces"),
      buildings: t("settings.build.noBuildings"),
    };

    const actionTitle: Record<Column, string> = {
      levels: t("settings.build.addLevel"),
      spaces: t("settings.build.addSpace"),
      buildings: t("settings.build.addBuilding"),
    };

    return {
      message: messages[column],
      action: (
        <Button variant="secondary" onClick={handleAddItem(column)}>
          <RiAddLine /> {actionTitle[column]}
        </Button>
      ),
    };
  };

  const addItemAction = (column: Column) => {
    const titles: Record<Column, string> = {
      levels: t("settings.build.addLevel"),
      spaces: t("settings.build.addSpace"),
      buildings: t("settings.build.addBuilding"),
    };

    const disabled: Record<Column, boolean> = {
      levels: selectedBuildingUuid == null,
      spaces: selectedLevelUuid == null,
      buildings: false,
    };

    return (
      <Tooltip title={titles[column]} key="add" focusable={false}>
        <Button
          variant="ghost"
          onClick={handleAddItem(column)}
          aria-label={titles[column]}
          disabled={disabled[column]}
          round
        >
          <RiAddCircleLine size={16} />
        </Button>
      </Tooltip>
    );
  };

  return (
    <div className="container">
      <ColumnView.Container>
        <ColumnView.Column
          className="bc-column"
          id="buildings"
          title={t("settings.building")}
          loading={loading}
          empty={buildings?.length === 0}
          emptySettings={emptySettings("buildings")}
          actions={!isFlexManager && [addItemAction("buildings")]}
        >
          {buildings?.map((building, index, arr) => (
            <ColumnView.Item
              value={building.title ?? ""}
              key={building.id}
              onSelect={() => setSelectedBuildingUuid(building.id)}
              selected={selectedBuildingUuid === building.id}
              showActions={!isFlexManager}
              actions={[
                {
                  title: t("common.up"),
                  icon: RiArrowUpLine,
                  onClick: () => handleReorder("buildings", "up", building.id ?? ""),
                  disabled: index === 0 || updateLoading,
                  loading: orderLoading && orderDirection === "up",
                },
                {
                  title: t("common.down"),
                  icon: RiArrowDownLine,
                  onClick: () => handleReorder("buildings", "down", building.id ?? ""),
                  disabled: index === arr.length - 1 || updateLoading,
                  loading: orderLoading && orderDirection === "down",
                },
                {
                  title: t("common.edit"),
                  icon: RiEditLine,
                  onClick: handleBuildingDetails(building.id),
                },
                {
                  title: t("common.duplicate"),
                  icon: RiFileCopy2Line,
                  onClick: () => {
                    void handleDuplicate(building.id ?? "", "buildings");
                  },
                  loading: duplicateLoading,
                  disabled: updateLoading,
                },
                {
                  title: t("common.delete"),
                  icon: RiDeleteBinLine,
                  color: colors.status.danger.primary,
                  onClick: withConfirmation({
                    onConfirm: handleDelete(building.id ?? "", "buildings"),
                    title: t("common.deleteConfirmationTitleWithTitle", { title: building.title ?? "" }),
                    message: t("common.deleteConfirmationWithTitle", { title: building.title ?? "" }),
                    confirmButtonText: t("common.yesDelete"),
                    confirmButtonVariant: "danger",
                  }),
                },
              ]}
            />
          ))}
        </ColumnView.Column>
        <ColumnView.Column
          className="bc-column"
          id="levels"
          title={t("settings.levels")}
          loading={loading}
          empty={levels?.length === 0}
          emptySettings={emptySettings("levels")}
          actions={!isFlexManager && [addItemAction("levels"), <BulkUploadWidget type="levels" key="bulk" />]}
        >
          {levels?.map((level, index, arr) => (
            <ColumnView.Item
              value={level.title}
              count={level.count}
              key={level.id}
              onSelect={() => {
                setSelectedLevelUuid(level.id);
                setSpacersCompanyFilter("");
              }}
              onReset={handleReset("levels")}
              selected={selectedLevelUuid === level.id}
              isNew={level.id === NEW_ID}
              loading={createLoading || updateLoading}
              showActions={!isFlexManager}
              actions={[
                {
                  title: t("common.up"),
                  icon: RiArrowUpLine,
                  onClick: () => handleReorder("levels", "up", level.id),
                  disabled: index === 0 || updateLoading,
                  loading: orderLoading && orderDirection === "up",
                },
                {
                  title: t("common.down"),
                  icon: RiArrowDownLine,
                  onClick: () => handleReorder("levels", "down", level.id),
                  disabled: index === arr.length - 1 || updateLoading,
                  loading: orderLoading && orderDirection === "down",
                },
                {
                  title: t("common.edit"),
                  icon: RiEditLine,
                  onClick: () => openLevelModal(level),
                },
                {
                  title: t("common.duplicate"),
                  icon: RiFileCopy2Line,
                  onClick: () => {
                    void handleDuplicate(level.id, "levels");
                  },
                  loading: duplicateLoading,
                  disabled: updateLoading,
                },
                {
                  title: t("common.delete"),
                  icon: RiDeleteBinLine,
                  color: colors.status.danger.primary,
                  onClick: withConfirmation({
                    onConfirm: handleDelete(level.id, "levels"),
                    title: t("common.deleteConfirmationTitleWithTitle", { title: level.title }),
                    message: t("common.deleteConfirmationWithTitle", { title: level.title }),
                    confirmButtonText: t("common.yesDelete"),
                    confirmButtonVariant: "danger",
                  }),
                },
              ]}
            />
          ))}
        </ColumnView.Column>
        <ColumnView.Column
          className="bc-column"
          id="spaces"
          title={t("settings.spaces")}
          empty={spaces?.length === 0}
          emptySettings={{
            message: t("settings.build.noSpaces"),
            action: (
              <Button
                variant="secondary"
                onClick={() => {
                  openSpaceModal();
                }}
              >
                <RiAddLine /> {t("settings.build.addSpace")}
              </Button>
            ),
          }}
          actions={[
            isPropertyManagerButNotRegionalManager ? null : (
              <Dropdown.Button
                size="sm"
                variant="ghost"
                key="ownerCompany"
                title={
                  spacersCompanyFilter === ""
                    ? t("home.widgets.allCompanies")
                    : companies?.find((company) => {
                        return company.node?.uuid === spacersCompanyFilter;
                      })?.node?.name ?? ""
                }
                disabled={selectedLevelUuid == null || spaces?.length === 0}
              >
                <Dropdown.Item
                  onClick={() => {
                    setSpacersCompanyFilter("");
                  }}
                >
                  {t("home.widgets.allCompanies")}
                </Dropdown.Item>
                {companies?.map((company) => (
                  <Dropdown.Item
                    onClick={() => {
                      setSpacersCompanyFilter(company.node?.uuid ?? "");
                    }}
                    selected={spacersCompanyFilter === company.node?.uuid}
                    key={company.node?.uuid}
                  >
                    {company.node?.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Button>
            ),
            <Tooltip title={t("settings.build.addSpace")} key="add" focusable={false}>
              <Button
                variant="ghost"
                onClick={() => {
                  openSpaceModal();
                }}
                aria-label={t("settings.build.addSpace")}
                disabled={selectedLevelUuid == null}
                round
              >
                <RiAddCircleLine size={16} />
              </Button>
            </Tooltip>,
            <BulkUploadWidget type="spaces" key="bulk" />,
          ]}
        >
          {filteredSpaces.map((space, index, arr) => {
            const actions = space.canEdit
              ? [
                  {
                    title: t("common.up"),
                    icon: RiArrowUpLine,
                    onClick: () => handleReorder("spaces", "up", space.id),
                    disabled: index === 0 || updateLoading,
                    loading: orderLoading && orderDirection === "up",
                  },
                  {
                    title: t("common.down"),
                    icon: RiArrowDownLine,
                    onClick: () => handleReorder("spaces", "down", space.id),
                    disabled: index === arr.length - 1 || updateLoading,
                    loading: orderLoading && orderDirection === "down",
                  },
                  {
                    title: t("common.edit"),
                    icon: RiEditLine,
                    onClick: () => {
                      openSpaceModal(space);
                    },
                  },
                  {
                    title: t("common.delete"),
                    icon: RiDeleteBinLine,
                    color: colors.status.danger.primary,
                    onClick: withConfirmation({
                      onConfirm: handleDelete(space.id, "spaces"),
                      title: t("common.deleteConfirmationTitleWithTitle", { title: space.title }),
                      message: t("common.deleteConfirmationWithTitle", { title: space.title }),
                      confirmButtonText: t("common.yesDelete"),
                      confirmButtonVariant: "danger",
                    }),
                  },
                ]
              : undefined;
            return (
              <ColumnView.Item
                value={space.title}
                key={space.id}
                onReset={handleReset("spaces")}
                isNew={space.id === NEW_ID}
                alwaysShowActions={!space.canEdit}
                isDisabled={!space.canEdit}
                loading={createLoading || updateLoading}
                prefixTag={{
                  text: translateVisibilityType(t, space.visibilityType),
                  variant: [SpaceVisibilityType.Custom, SpaceVisibilityType.Private].includes(space.visibilityType)
                    ? "dark"
                    : "default",
                }}
                actions={actions}
                isLocked={!space.canEdit}
              />
            );
          })}
        </ColumnView.Column>
      </ColumnView.Container>

      {modal.activeModal === "BuildingSpace" && (
        <WidgetProvider>
          <BuildingSpaceWidget editingSpace={editingSpace} />
        </WidgetProvider>
      )}
      {modal.activeModal === "BuildingLevel" && (
        <WidgetProvider>
          <BuildingLevelWidget editingLevel={editingLevel} />
        </WidgetProvider>
      )}
      <style jsx>{`
        .container {
          width: 100%;
        }

        .container :global(.actions) {
          display: flex;
        }

        .container :global(.bc-column) {
          height: calc(100vh - 320px);
        }

        @media (max-width: ${breakpoints.lg}px) {
          .container :global(.bc-column) {
            height: calc(100vh - 250px);
          }
        }
      `}</style>
    </div>
  );
};
