import { CurrentProfile, Site } from "@equiem/lib";
import type { PropsWithChildren } from "react";
import React, { createContext, useContext, useMemo, useState, useEffect, useCallback } from "react";
import { isAutoApproveBookings } from "../libs/isAutoApproveBookings";
import type { BookableResourceQuery, BookableResourceQueryVariables } from "../../../generated/gateway-client";
import { type BookableResourceFragmentFragment, type BookingFragmentFragment } from "../../../generated/gateway-client";
import type { ApolloQueryResult } from "@apollo/client";

type Resource = BookableResourceFragmentFragment;
type Booking = BookingFragmentFragment;
type FetchIt = (
  variables?: BookableResourceQueryVariables,
) => Promise<ApolloQueryResult<BookableResourceQuery> | undefined>;
type RefetchCb = (resource: BookableResourceQuery["bookableResource"]) => void;

export interface BookingModalContentContext {
  resource: Resource;
  booking?: Booking;
  refetch?: (userUuid: string, callback?: RefetchCb) => void;
  resourceHasBeenChanged: boolean;
  timezone: string;
  buildingUuid?: string | undefined | null;
  autoApprove: boolean;
  setModalActiveResource: (resource: Resource) => void;
}

export const BookingModalInfo = createContext<BookingModalContentContext>({
  resource: {} as unknown as Resource,
  resourceHasBeenChanged: false,
  timezone: "Australia/Melbourne",
  autoApprove: false,
  setModalActiveResource: () => undefined,
});

interface P extends PropsWithChildren {
  resource?: Resource;
  booking?: Booking;
  refetch?: FetchIt;
}
export const BookingModalInfoProvider: React.FC<P> = ({ resource, booking, refetch, children }) => {
  const site = useContext(Site);
  const { profile } = useContext(CurrentProfile);

  if (booking?.resource == null && resource == null) {
    throw new Error("Missing a resource.");
  }

  const inputResource = booking?.resource ?? resource!;
  const [activeResource, _setActiveResource] = useState(inputResource);
  const [resourceHasBeenChanged, setResourceHasBeenChanged] = useState(false);
  const refetchAndUpdate =
    refetch != null
      ? (userUuid: string, callback?: RefetchCb) => {
          void refetch({ uuid: activeResource.uuid, paymentMethodUserUuid: userUuid }).then((v) => {
            if (v?.loading === false && v.error == null) {
              _setActiveResource(v.data.bookableResource);
              callback?.(v.data.bookableResource);
            }
          });
        }
      : undefined;
  const setActiveResource = useCallback((newResource: Resource) => {
    _setActiveResource(newResource);

    // if the resource ever gets changed, we'll reset the booking fields, so keep
    // showing the original details even if they change it back to the original
    setResourceHasBeenChanged(true);
  }, []);
  useEffect(() => {
    if (inputResource.uuid !== activeResource.uuid) {
      setActiveResource(inputResource);
    }
    // only run this effect on inputResource change, so the selected resource
    // can be restored by prop update!
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputResource]);

  const autoApprove = useMemo(
    () => isAutoApproveBookings(activeResource, profile?.companyV2?.uuid),
    [profile?.companyV2?.uuid, activeResource],
  );

  const bookingWithActiveResource = useMemo(
    () =>
      booking != null
        ? {
            ...booking,
            resource: activeResource,
            timezone: activeResource.building?.timezone ?? booking.timezone,
          }
        : booking,
    [booking, activeResource],
  );

  return (
    <BookingModalInfo.Provider
      value={{
        resource: activeResource,
        booking: bookingWithActiveResource,
        refetch: refetchAndUpdate,
        resourceHasBeenChanged,
        timezone: bookingWithActiveResource?.timezone ?? activeResource!.building?.timezone ?? site.timezone,
        buildingUuid: activeResource.building?.uuid ?? null,
        autoApprove,
        setModalActiveResource: setActiveResource,
      }}
    >
      {children}
    </BookingModalInfo.Provider>
  );
};
