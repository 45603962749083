import {
  type BookableResourceFragmentFragment,
  type BookingFragmentFragment,
  BookableResourcePaymentMethod,
} from "../../../generated/gateway-client";

export const getAvailablePaymentMethods = (
  resource: BookableResourceFragmentFragment,
  booking: BookingFragmentFragment | null | undefined,
  isProxyBooking: boolean,
) => {
  if (booking?.paymentMethod != null) {
    // cannot change a booking's paymnet method once set
    return [booking.paymentMethod];
  }

  // Credit card is not accepted for edit-booking or proxy booking.
  const nonCreditCard = resource.paymentMethods.filter((method) => method !== BookableResourcePaymentMethod.CreditCard);

  // When proxy booking we want to ALWAYS return invoice regardless backend setting.
  if (isProxyBooking) {
    return [...new Set([BookableResourcePaymentMethod.Invoice, ...nonCreditCard])];
  }

  return booking != null ? nonCreditCard : resource.paymentMethods;
};

export const getDefaultPaymentMethod = (
  resource: BookableResourceFragmentFragment,
  booking: BookingFragmentFragment | null | undefined,
  isProxy: boolean,
) => {
  if (booking?.paymentMethod != null) {
    return booking.paymentMethod;
  }
  const methods = getAvailablePaymentMethods(resource, booking, isProxy);
  if (methods.includes(BookableResourcePaymentMethod.Credits)) {
    return BookableResourcePaymentMethod.Credits;
  }
  if (methods.length > 0) {
    return methods[0];
  }
  return undefined;
};
