import { stringNotEmpty } from "@equiem/lib";
import React from "react";
import type { BookingFragmentFragment } from "../../../generated/gateway-client";
import { BookableResourcePaymentMethod } from "../../../generated/gateway-client";
import { ValuePills } from "../../../components/ValuePills";
import { DateTime, Text, useTheme } from "@equiem/react-admin-ui";
import { useTranslation } from "@equiem/localisation-eq1";
import { BookingPaymentStatus } from "../BookingPaymentStatus";
import { BookingAddOnsSummary } from "./addOns/BookingAddOnsSummary";
import { BookingActivityLog } from "./BookingActivityLog";
import { BookingDetailsAdminNote } from "./BookingDetailsAdminNote";

interface P {
  booking: BookingFragmentFragment;
}
export const BookingDetails: React.FC<P> = ({ booking }) => {
  const { t, i18n } = useTranslation();
  const { spacers } = useTheme();

  return (
    <>
      <h1 className="font-weight-bold">{t("bookings.operations.bookingDetailsTitle")}</h1>
      <div className="row location">
        <div className="mb-2 text-medium">
          <Text variant="label">{t("bookings.operations.location")}</Text>
        </div>
        <div className="value">
          <ValuePills>
            <span>{booking.resource.name}</span>
            {booking.resource.displayCapacity != null && <span>{booking.resource.displayCapacity} pax</span>}
          </ValuePills>
        </div>
        <div className="value">
          {booking.resource.building?.name}, {booking.resource.level?.name}, {booking.resource.building?.address}
        </div>
      </div>
      <div className="row date-time">
        <div className="mb-2 text-medium">
          <Text variant="label">{t("bookings.operations.dateAndTime")}</Text>
        </div>
        <div className="value">
          <ValuePills>
            <span>
              <DateTime.DateDisplay language={i18n.language} datetime={booking.startDate} timezone={booking.timezone} />
            </span>
            <span>
              <DateTime.TimeRange
                language={i18n.language}
                start={booking.startDate}
                end={booking.endDate}
                timezone={booking.timezone}
              />
            </span>
          </ValuePills>
        </div>
      </div>
      {booking.roomConfig != null && (
        <div className="row room-configuration">
          <div className="mb-2 text-medium">
            <Text variant="label">{t("bookings.operations.configuration")}</Text>
          </div>
          <div className="value">{booking.roomConfig.name}</div>
        </div>
      )}
      {booking.paymentMethod === BookableResourcePaymentMethod.CreditCard && (
        <div className="row">
          <div className="mb-2 text-medium">
            <Text variant="label">{t("bookings.operations.paymentStatus")}</Text>
          </div>
          <div className="value">
            <BookingPaymentStatus status={booking.paymentStatus} />
          </div>
        </div>
      )}
      <div className="row booked-by">
        <div className="mb-2 text-medium">
          <Text variant="label">{t("common.host")}</Text>
        </div>
        <div className="value">
          <ValuePills>
            <span>
              {booking.user.profile?.firstName} {booking.user.profile?.lastName}
            </span>
            {booking.user.profile?.companyV2?.name != null && <span>{booking.user.profile.companyV2.name}</span>}
          </ValuePills>
        </div>
        <div className="value">
          <ValuePills>
            <span>{booking.user.profile?.mobileNumber}</span>
            {booking.user.profile?.email != null && <span>{booking.user.profile.email}</span>}
          </ValuePills>
        </div>
      </div>
      <div className="row">
        <div className="mb-2 text-medium">
          <Text variant="label">{t("bookings.operations.addOns")}</Text>
        </div>
        <BookingAddOnsSummary bookingAddOns={booking.addOns} />
      </div>
      {stringNotEmpty(booking.note) && (
        <div className="row">
          <div className="mb-2 text-medium">
            <Text variant="label">{t("bookings.operations.hostNote")}</Text>
          </div>
          <div className="value" style={{ whiteSpace: "pre-line" }}>
            {booking.note}
          </div>
        </div>
      )}
      {booking.resource.viewerPermissions?.canObserveBookings === true && (
        <div className="row">
          <BookingDetailsAdminNote booking={booking} />
        </div>
      )}
      {booking.invoiceContact != null && (
        <div className="row">
          <div className="mb-2 text-medium">
            <Text variant="label">{t("bookings.operations.invoiceContract")}</Text>
          </div>
          <div>
            <strong>{t("common.name")}:</strong> {booking.invoiceContact.fullName}
          </div>
          <div>
            <strong>{t("common.email")}:</strong> {booking.invoiceContact.email}
          </div>
        </div>
      )}
      {stringNotEmpty(booking.additionalDetails?.additionalInvoiceDetails) && (
        <div className="row">
          <div className="mb-2 text-medium">
            <Text variant="label">{t("bookings.operations.additionalInvoiceDetails")}</Text>
          </div>
          <div>{booking.additionalDetails?.additionalInvoiceDetails}</div>
        </div>
      )}
      <div className="row activity-log">
        <div className="mb-2 text-medium">
          <Text variant="label">{t("bookings.operations.activityLog")}</Text>
        </div>
        <div className="value mt-4">
          <BookingActivityLog booking={booking} />
        </div>
      </div>
      <style jsx>{`
        .row {
          padding: ${spacers.s5} 0;
        }
        .value {
          margin-bottom: ${spacers.s2};
        }
      `}</style>
    </>
  );
};
