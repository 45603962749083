import { useSiteContext, stringIsEmpty } from "@equiem/lib";

import { useGetBuildingQuery } from "../../../generated/gateway-client";

export const useResourceBuildingInfo = (buildingUuid: string | null | undefined) => {
  const currentSite = useSiteContext();

  const { data, loading } = useGetBuildingQuery({
    variables: { uuid: buildingUuid ?? "" },
    skip: stringIsEmpty(buildingUuid),
  });

  const sumOfRates = (data?.buildingPublic?.taxes.taxRates ?? []).reduce((sum, { rate }) => sum + rate, 0);

  return {
    loading,
    timezone: data?.buildingPublic?.timezone ?? currentSite.timezone,
    taxRate: Number(sumOfRates.toFixed(2)),
  };
};
