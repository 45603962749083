import React, { useMemo } from "react";
import { useTranslation } from "@equiem/localisation-eq1";
import { EmptyState, InfiniteScrollTable, useTheme } from "@equiem/react-admin-ui";
import type { CreditAccount } from "../../../../generated/gateway-client";
import { CreditSubAccountType } from "../../../../generated/gateway-client";
import { useCompaniesCreditsContext } from "../../context/CompaniesCreditsContext";
import { convertCents } from "../../utils/convertCents";

interface RowP {
  item: CreditAccount;
}
const Row: React.FC<RowP> = ({ item: { subAccounts, creditCustomer } }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const monthlyCredits = useMemo(
    () => subAccounts.find(({ type }) => type === CreditSubAccountType.MonthlyRecurring)?.recurringCreditAllowance ?? 0,
    [subAccounts],
  );

  return (
    <>
      <tr className="row">
        <td width="33%">{creditCustomer.company?.name}</td>
        <td width="33%">{convertCents(monthlyCredits)}</td>
        <td width="33%">{t("common.all")}</td>
      </tr>
      <style jsx>{`
        .row {
          cursor: pointer;
        }
        .row:hover {
          background: ${theme.colors.grayscale_5};
        }
      `}</style>
    </>
  );
};

export const ManageCompaniesCreditsTable = () => {
  const { companiesCredits } = useCompaniesCreditsContext();
  const { loading, hasMoreData, fetchMore, data } = companiesCredits;
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <div className="list">
        <InfiniteScrollTable
          loading={loading}
          hasMoreData={hasMoreData}
          scrollDownToLoad={t("common.scrollDownToLoad")}
          rowHeaders={[
            t("common.company"),
            t("credits.companiesCredits.monthlyCredits"),
            t("credits.companiesCredits.whoCanUseCredits"),
          ]}
          rowCount={data.length}
          fetchMore={fetchMore}
        >
          {data.map((item) => (
            <Row key={item.uuid} item={item} />
          ))}
          {!loading && data.length === 0 && (
            <tr>
              <td colSpan={3} width="100%" className="text-center">
                <EmptyState />
              </td>
            </tr>
          )}
        </InfiniteScrollTable>
        <style jsx>{`
          .icon {
            display: inline-block;
            padding: ${theme.spacers.s5};
            background: ${theme.colors.grayscale[5]};
            border-radius: 50%;
          }

          .list {
            width: 100%;
            margin-top: 16px;
          }
        `}</style>
      </div>
    </>
  );
};
