import type { FC } from "react";
import { useCallback, useContext } from "react";
import { NewInvites } from "./NewInvitesContext";
import { Modal } from "@equiem/react-admin-ui";
import { InviteInner } from "./InviteInner";
import { BulkInner } from "./BulkInner";
import { useTranslation } from "@equiem/localisation-eq1";
import { ErrorInner } from "./ErrorInner";
import { Widget } from "./WidgetProvider";

export const InviteModalInner: FC = () => {
  const { t } = useTranslation();
  const { close, screen, setScreen, hasAnyFilledRow, submissionResponse } = useContext(NewInvites);
  const { confirmCancel, showModal, closeModal } = useContext(Widget);

  const cancel = useCallback(() => {
    if (hasAnyFilledRow) {
      confirmCancel(close);
    } else {
      closeModal();
    }
  }, [close, closeModal, confirmCancel, hasAnyFilledRow]);

  return (
    <>
      {screen === "table" && (
        <Modal.Dialog
          key="table"
          title={t("home.widgets.inviteModalTitle")}
          show={showModal}
          onHide={cancel}
          hideOnEsc={true}
          hideOnClick={true}
          size="auto"
          className="invite-modal"
          sideModal
        >
          <InviteInner />
        </Modal.Dialog>
      )}
      {screen === "bulk" && (
        <Modal.Dialog
          centered
          key="bulk"
          title={t("home.widgets.inviteModalTitle")}
          show={showModal}
          hideOnEsc={false}
          hideOnClick={false}
          scrollable={false}
          size="md"
          className="invite-modal"
          onBack={() => {
            setScreen("table");
          }}
        >
          <Modal.Header backButton noBorder={false} />
          <BulkInner />
        </Modal.Dialog>
      )}
      {screen === "error" && (
        <Modal.Dialog
          centered
          key="error"
          title={t("home.widgets.inviteModalTitle")}
          show={showModal}
          hideOnEsc={true}
          hideOnClick={false}
          scrollable={true}
          size="md"
          className="invite-modal"
        >
          <Modal.Header
            noBorder={true}
            intro={t("home.widgets.usersWereSuccessfullyInvited", {
              count: submissionResponse?.invitedCount ?? 0,
            })}
          />
          <ErrorInner />
        </Modal.Dialog>
      )}
    </>
  );
};
