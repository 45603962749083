import { stringNotEmpty } from "@equiem/lib";
import { formatters, useTranslation } from "@equiem/localisation-eq1";
import { Text, useTheme } from "@equiem/react-admin-ui";
import { RiCalendarLine, RiHashtag, RiTimeLine } from "@equiem/react-admin-ui/icons";
import { DateTime } from "luxon";
import React, { useContext } from "react";
import { BookingModalInfo } from "../contexts/BookingModalInfoProvider";

interface P {
  className?: string;
}
export const BookingViewBookingInfo: React.FC<P> = ({ className }) => {
  const { i18n, t } = useTranslation();
  const { booking } = useContext(BookingModalInfo);
  const { colors, spacers } = useTheme(true);

  if (booking == null) {
    return null;
  }

  return (
    <div className="view-booking-info">
      <div className="view-info pb-4">
        <RiHashtag size={16} color={colors.grayscale[50]} />
        <Text variant="text" component="label" size="small">
          {booking.reference}
        </Text>
      </div>
      <div className={`view-booking-time ${className}`}>
        <div className="view-info pb-4">
          <RiCalendarLine size={16} color={colors.grayscale[50]} />
          <Text variant="text" component="label" size="small">
            {formatters.datelong(booking.startDate, i18n.language, { timezone: booking.timezone })}
          </Text>
        </div>
        <div className="view-info">
          <RiTimeLine size={16} color={colors.grayscale[50]} />
          <Text variant="text" component="label" size="small">
            {formatters.timeshort(booking.startDate, i18n.language, { timezone: booking.timezone })}
            {" - "}
            {formatters.timeshort(booking.endDate, i18n.language, { timezone: booking.timezone })}
            {" ("}
            {formatters.durationshort(
              DateTime.fromMillis(booking.endDate).diff(DateTime.fromMillis(booking.startDate)).as("minutes"),
              i18n.language,
            )}
            {")"}
          </Text>
        </div>
      </div>
      {stringNotEmpty(booking.note) && (
        <div className={`view-note ${className}`}>
          <Text variant="label" color={colors.grayscale[60]}>
            {t("bookings.operations.bookingNote")}
          </Text>
          <Text variant="text" size="small" style={{ whiteSpace: "pre-line" }}>
            {booking.note}
          </Text>
        </div>
      )}
      {stringNotEmpty(booking.adminNote) && (
        <div className={`view-note ${className}`}>
          <Text variant="label" color={colors.grayscale[60]}>
            {t("bookings.operations.adminNote")}
          </Text>
          <Text variant="text" size="small" style={{ whiteSpace: "pre-line" }}>
            {booking.adminNote}
          </Text>
        </div>
      )}
      {stringNotEmpty(booking.additionalDetails?.additionalInvoiceDetails) && (
        <div className={`view-note ${className}`}>
          <Text variant="label" color={colors.grayscale[60]}>
            {t("bookings.operations.additionalInvoiceDetails")}
          </Text>
          <Text variant="text" size="small" style={{ whiteSpace: "pre-line" }}>
            {booking.additionalDetails?.additionalInvoiceDetails}
          </Text>
        </div>
      )}
      <style jsx>{`
        .view-info {
          display: flex;
          gap: ${spacers.s4};
        }
        .view-note {
          display: flex;
          flex-direction: column;
          gap: ${spacers.s2};
        }
      `}</style>
    </div>
  );
};
