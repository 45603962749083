import { useContext } from "react";
import { useFormikContext } from "formik";
import { toStartAndEndDate } from "./useBookResource";
import {
  BookableResourcePaymentMethod,
  useCreditAccountsQuery,
  type CreditAccountsQuery,
} from "../../../generated/gateway-client";
import { BookingModalInfo } from "../contexts/BookingModalInfoProvider";
import type { BookingFormValue } from "../models/BookingFormValue";

export type CreditAccount = CreditAccountsQuery["creditAccounts"][number];

const ONE_HOUR = 60 * 60 * 1000;

export const useCreditAccounts = (): { creditAccounts: CreditAccount[]; loading: boolean } => {
  const { resource, booking, timezone } = useContext(BookingModalInfo);
  const fm = useFormikContext<BookingFormValue>();

  const hostUuid = fm.values.host?.uuid;
  const providerUuid = resource.company.flexOperator?.uuid;
  const { startDate } = toStartAndEndDate(fm.values, timezone);

  const skip =
    booking != null
      ? booking.paymentMethod !== BookableResourcePaymentMethod.Credits
      : hostUuid == null ||
        providerUuid == null ||
        !resource.paymentMethods.includes(BookableResourcePaymentMethod.Credits);

  const { data: accountsData, loading } = useCreditAccountsQuery({
    variables: {
      hostUuid: hostUuid!,
      providerUuid: providerUuid ?? "",
      // balance checks will error if we ask for a time in the past
      // add a fudge factor for the user's local clock being slightly wrong
      balanceAsAt: startDate > Date.now() + ONE_HOUR ? startDate : null,
    },
    fetchPolicy: "cache-and-network",
    skip,
  });

  return {
    creditAccounts: accountsData?.creditAccounts ?? [],
    loading,
  };
};
