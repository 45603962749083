import { BookableResourcePaymentMethod } from "../../../generated/gateway-client";

// @todo: should be backend job!
export const canEditBooking = (booking: {
  paymentMethod?: BookableResourcePaymentMethod | null;
  isEditable: boolean;
  hasAnyPartialRefunds: boolean;
}) => {
  return (
    booking.isEditable &&
    !booking.hasAnyPartialRefunds &&
    booking.paymentMethod !== BookableResourcePaymentMethod.CreditCard
  );
};
