import { useContext, useEffect, useMemo, useState } from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import type { FilterItem } from "@equiem/react-admin-ui";
import { FilterOptionsModifier } from "@equiem/react-admin-ui";
import { RiAccountCircleLine } from "@equiem/react-admin-ui/icons";
import type { Person } from "../../utils/personType";
import { CreditTransactionsQueryRole, useCreditFilterUsersQuery } from "../../../generated/gateway-client";
import { useRoleMapping } from "./useRoleMapping";
import { CurrentRole, Role } from "@equiem/lib";

const mapUsers = (
  users?: Array<{
    __typename?: "User";
    uuid: string;
    profile?: { __typename?: "Profile"; firstName?: string | null; lastName?: string | null } | null;
  }>,
): Person[] => {
  if (users == null) {
    return [];
  }
  return users.map(({ uuid, profile }) => ({
    uuid,
    firstName: profile?.firstName ?? "",
    lastName: profile?.lastName ?? "",
  }));
};

export function useCreditsFilterUser(): { filter: FilterItem } {
  const { t } = useTranslation();
  const [users, setUsers] = useState<Person[]>([]);
  const role = useRoleMapping();
  const { currentRole } = useContext(CurrentRole);

  const { data } = useCreditFilterUsersQuery({
    variables: {
      as: role,
    },
    skip: role === CreditTransactionsQueryRole.CreditManager || currentRole === Role.FlexManager,
  });

  useEffect(() => {
    if (data?.creditTransactionsUsers != null) {
      setUsers(mapUsers(data.creditTransactionsUsers));
    }
  }, [data]);

  const options = useMemo(
    () =>
      users
        .map((r) => ({
          label: `${r.firstName} ${r.lastName}`,
          value: r.uuid,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [users],
  );

  const filter: FilterItem = {
    title: t("credits.user"),
    type: "options",
    options,
    icon: RiAccountCircleLine,
    modifiers: [FilterOptionsModifier.includes],
  };

  return {
    filter,
  };
}
