import type { Dispatch, SetStateAction } from "react";
import { createContext } from "react";
import type { Building } from "../components/lib/flattenLocations";
import { v4 as uuid } from "uuid";

export const getEmptyLocation = () => ({
  id: uuid(),
  uuid: "",
  name: "",
  levels: [],
  destination: {
    uuid: "",
    name: "",
  },
  isNew: true,
});

export type BuildingWithUniqueId = Building & { id: string };

export interface FlexTenantLocationContext {
  locations: BuildingWithUniqueId[];
  setLocations: Dispatch<SetStateAction<BuildingWithUniqueId[]>>;
  clearLocations: () => void;
  hasChanged: boolean;
}

export const FlexTenantLocationContext = createContext<FlexTenantLocationContext>({
  locations: [],
  setLocations: () => ({}),
  clearLocations: () => ({}),
  hasChanged: false,
});
