import { notNullOrUndefined } from "@equiem/lib";

import type { Space } from "../generated/requests-client";

import type { RequestValues } from "./types";
import { StepType } from "./types";

type MySpace = { uuid: string; name: string; ownerCompany?: { uuid?: string }; weight: number };

export const getUniqueArray = (spaces: Partial<Space[]> | undefined, values: RequestValues, type: StepType) => {
  if (spaces === undefined) {
    return [];
  }
  const uniqueBuildingsMap = new Map<string, MySpace>();
  spaces.filter(notNullOrUndefined).forEach((space) => {
    let key: MySpace = space.buildingLevel.building;
    if (type === StepType.SPACES) {
      if (
        values.buildingUuid !== space.buildingLevel.building.uuid ||
        values.buildingLevelUuid !== space.buildingLevel.uuid
      ) {
        return;
      }
      key = space;
    } else if (type === StepType.LEVELS) {
      if (values.buildingUuid !== space.buildingLevel.building.uuid) {
        return;
      }
      key = space.buildingLevel;
    }
    if (!uniqueBuildingsMap.has(key.uuid)) {
      uniqueBuildingsMap.set(key.uuid, key);
    }
  });
  return [...uniqueBuildingsMap.values()].sort((a, b) => a.weight - b.weight);
};
