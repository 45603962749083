import React, { useContext, useEffect, useMemo } from "react";

import * as yup from "yup";
import type { TFunction } from "@equiem/localisation-eq1";
import { useTranslation } from "@equiem/localisation-eq1";
import { Alert, Button, Form as EqForm, Modal, useTheme, Text, remainingCharacters } from "@equiem/react-admin-ui";
import { Field, Formik, Form } from "formik";
import {
  FlexTenantStatus,
  useMyFlexTenantsBalanceQuery,
  useMyFlexTenantsQuery,
  useMyManagedCreditAccountsQuery,
} from "../../../generated/gateway-client";
import { RiInformationLine } from "@equiem/react-admin-ui/icons";
import { CurrentProfile, CurrentRole, Role, useSaferFormikContext } from "@equiem/lib";
import { convertCents, convertToCents } from "../utils";

interface Props {
  showModal: boolean;
  onClose: () => void;
  onAgree: (args: { creditsAmount: number; accountUuid: string; tenantName: string; description?: string }) => void;
}

interface FormValues {
  accountUuid?: string;
  creditsAmount?: number;
  description?: string;
  tenantName?: string;
}

const MAX_SAFE_INTEGER_32 = Math.pow(2, 31) - 1;
const DESCRIPTION_MAX_LENGTH = 50;

const getValidationSchema = (t: TFunction, isFlexManager: boolean) =>
  yup.object().shape({
    accountUuid: yup
      .string()
      .required(
        t("common.validation.required", { path: isFlexManager ? t("credits.membership") : t("credits.company") }),
      ),
    creditsAmount: yup
      .number()
      .min(1, t("common.validation.numberMin", { path: t("credits.adjustments.amountOfCredits"), min: 1 }))
      .required(t("common.validation.required", { path: t("credits.adjustments.amountOfCredits") }))
      .max(MAX_SAFE_INTEGER_32 / 100),
    description: yup.string().max(DESCRIPTION_MAX_LENGTH),
  });

const CreditsAdjustmentsModal: React.FC<Omit<Props, "onAgree">> = ({ showModal, onClose }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { canManageCredits } = useContext(CurrentProfile);
  const { currentRole } = useContext(CurrentRole);
  const { values, touched, errors, isValid, submitForm, handleSubmit, setFieldValue, isSubmitting } =
    useSaferFormikContext<FormValues>();
  const isFlexManager = currentRole === Role.FlexManager;

  const { data: flexTenants } = useMyFlexTenantsQuery({
    fetchPolicy: "network-only",
    variables: { first: 100 },
    skip: !isFlexManager,
  });
  const { data: mytenantsBalance } = useMyFlexTenantsBalanceQuery({
    fetchPolicy: "network-only",
    skip: !isFlexManager || values.accountUuid == null,
  });
  const { data: myManagedCreditAccounts } = useMyManagedCreditAccountsQuery({
    fetchPolicy: "network-only",
    skip: !canManageCredits,
  });
  const tenantsBalance = useMemo(
    () =>
      isFlexManager
        ? mytenantsBalance?.myFlexTenants.edges.find(({ node }) => node?.creditAccount?.uuid === values.accountUuid)
            ?.node?.creditAccount?.balance
        : myManagedCreditAccounts?.myManagedCreditAccounts.edges.find(({ node }) => node?.uuid === values.accountUuid)
            ?.node?.balance,
    [values.accountUuid, mytenantsBalance?.myFlexTenants.edges, myManagedCreditAccounts, isFlexManager],
  );

  const tenantsOptions = useMemo(
    () =>
      isFlexManager
        ? flexTenants?.myFlexTenants.edges
            .filter(
              ({ node }) =>
                node?.status != null && [FlexTenantStatus.Active, FlexTenantStatus.NearingExpiry].includes(node.status),
            )
            .map(({ node }) => ({ label: node?.name, value: node?.creditAccount?.uuid }))
        : myManagedCreditAccounts?.myManagedCreditAccounts.edges.map(({ node }) => ({
            label: node?.creditCustomer.company?.name,
            value: node?.uuid,
          })),
    [flexTenants?.myFlexTenants.edges, myManagedCreditAccounts, isFlexManager],
  );

  const tenantName: string | undefined = useMemo(
    () =>
      isFlexManager
        ? tenantsOptions?.find(({ value }) => value === values.accountUuid)?.label ?? undefined
        : myManagedCreditAccounts?.myManagedCreditAccounts.edges.find(({ node }) => node?.uuid === values.accountUuid)
            ?.node?.creditCustomer.company?.name ?? undefined,
    [values.accountUuid, tenantsOptions, myManagedCreditAccounts, isFlexManager],
  );

  useEffect(() => {
    void setFieldValue("tenantName", tenantName).catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantName]);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Modal.Dialog
          centered
          title={t("credits.adjustments.modalTitle")}
          supportsMobile
          className="credits-adjustments"
          show={showModal}
          onHide={onClose}
          hideOnEsc
          hideOnClick={false}
          size="md"
          focusTrapOptions={{ allowOutsideClick: () => true }}
        >
          <Modal.Header noBorder closeButton>
            <h2 className="title">{t("credits.adjustments.title")}</h2>
          </Modal.Header>
          <Modal.Body>
            <div className="body">
              <EqForm.Group
                required
                error={touched.accountUuid === true ? errors.accountUuid : undefined}
                label={isFlexManager ? t("credits.membership") : t("credits.company")}
                hasError={touched.accountUuid === true ? errors.accountUuid != null : false}
              >
                <Field name="accountUuid" as={EqForm.Select}>
                  <option value="">
                    {isFlexManager ? t("credits.adjustments.selectMembership") : t("credits.adjustments.selectCompany")}
                  </option>
                  {tenantsOptions?.map((tenant) => (
                    <option key={tenant.value} value={tenant.value}>
                      {tenant.label}
                    </option>
                  ))}
                </Field>
                {tenantsBalance != null && (
                  <Text variant="text" size="extra-small" color={colors.grayscale[100]}>
                    {t("credits.adjustments.flexTenantBalance", {
                      amount: convertCents(tenantsBalance),
                    })}
                  </Text>
                )}
              </EqForm.Group>
              <EqForm.Group
                required
                error={touched.creditsAmount === true ? errors.creditsAmount : undefined}
                label={t("credits.adjustments.amountOfCredits")}
                hasError={touched.creditsAmount === true ? errors.creditsAmount != null : false}
              >
                <Field
                  name="creditsAmount"
                  as={EqForm.Input}
                  placeholder={t("credits.adjustments.amountOfCreditsPlaceholder")}
                  type="number"
                />
              </EqForm.Group>
              <EqForm.Group
                error={errors.description}
                label={t("credits.adjustments.description")}
                hint={t("common.remainingCharacters", {
                  count: remainingCharacters(DESCRIPTION_MAX_LENGTH, values.description?.length ?? 0),
                })}
              >
                <Field
                  name="description"
                  as={EqForm.Input}
                  placeholder={t("credits.adjustments.descriptionPlaceholder")}
                />
              </EqForm.Group>
              <div className="alert-container">
                <Alert
                  className="alert-adjustments"
                  icon={<RiInformationLine size={20} />}
                  variant="gray"
                  message={
                    <span className="alert-description">
                      {isFlexManager
                        ? t("credits.adjustments.alertMemberships")
                        : t("credits.adjustments.alertCompanies")}
                    </span>
                  }
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onClose} variant="ghost" size="md">
              {t("common.cancel")}
            </Button>
            <Button
              onClick={() => {
                void submitForm().catch(console.error);
              }}
              disabled={isSubmitting || !isValid}
              variant="primary"
              type="submit"
              className="ml-2"
              size="md"
            >
              {t("common.add")}
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      </Form>
      <style jsx>{`
        .title {
          font-weight: bold;
        }

        :global(.credits-adjustments .title-row) {
          margin-bottom: 0 !important;
        }

        :global(.credits-adjustments .footer) {
          border-top: 1px solid ${colors.grayscale[10]};
        }

        .body {
          margin: 24px 0;
        }

        .alert-container :global(.alert-adjustments.alert) {
          padding: 12px;
          height: auto;
        }

        .alert-container :global(.alert-adjustments .left-group) {
          gap: 12px;
        }

        .alert-container :global(.alert-adjustments.alert .alert-description) {
          font-size: 14px;
          line-height: 20px;
        }
      `}</style>
    </>
  );
};

export const CreditsAdjustemntsModalContainer = ({ onAgree, ...props }: Props) => {
  const { t } = useTranslation();
  const { currentRole } = useContext(CurrentRole);
  const isFlexManager = currentRole === Role.FlexManager;

  return (
    <Formik<FormValues>
      enableReinitialize
      validationSchema={getValidationSchema(t, isFlexManager)}
      initialValues={{
        accountUuid: undefined,
        creditsAmount: undefined,
        tenantName: undefined,
        description: undefined,
      }}
      onSubmit={(values) => {
        onAgree({
          creditsAmount: convertToCents(values.creditsAmount!),
          description: values.description,
          accountUuid: values.accountUuid!,
          tenantName: values.tenantName!,
        });
      }}
    >
      <CreditsAdjustmentsModal onClose={props.onClose} showModal={props.showModal} />
    </Formik>
  );
};
