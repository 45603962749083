import { useTranslation } from "@equiem/localisation-eq1";
import { FilterOptionsModifier, type FilterItem } from "@equiem/react-admin-ui";
import { RiGroup2Line } from "@equiem/react-admin-ui/icons";
import { useEffect, useMemo, useState } from "react";
import { useGetMyManagedCreditAccountsQuery } from "../../../generated/gateway-client";
import { notNullOrUndefined } from "@equiem/lib";

export function useCreditsFilterCompany() {
  const { t } = useTranslation();
  const [companies, setCompanies] = useState<Array<{ value: string; label: string }>>([]);

  const { data } = useGetMyManagedCreditAccountsQuery({
    variables: { first: 1000 },
  });

  useEffect(() => {
    if (data?.myManagedCreditAccounts != null) {
      setCompanies(
        data.myManagedCreditAccounts.edges
          .filter((edge) => notNullOrUndefined(edge.node))
          .map((account) => ({
            value: account.node?.uuid ?? "",
            label: account.node?.creditCustomer.company?.name ?? "",
          })),
      );
    }
  }, [data?.myManagedCreditAccounts]);

  const options = useMemo(
    () =>
      companies.map((r) => ({
        label: r.label,
        value: r.value,
      })),
    [companies],
  );

  const filter: FilterItem = {
    title: t("credits.company"),
    type: "options",
    options,
    icon: RiGroup2Line,
    modifiers: [FilterOptionsModifier.includes],
  };

  return {
    filter,
  };
}
