import React, { useContext, useMemo, useState } from "react";
import { Tag, Dropdown, useTheme, Tooltip } from "@equiem/react-admin-ui";
import {
  RiDeleteBinLine,
  RiEditLine,
  RiEyeLine,
  RiMoreLine,
  RiCheckboxCircleLine,
  RiExternalLinkLine,
} from "@equiem/react-admin-ui/icons";

import type { SiteBookingsQuery } from "../../../generated/gateway-client";
import { BookableResourcePaymentMethod, BookingStatus } from "../../../generated/gateway-client";
import { OperationsCancelBookingModal } from "./OperationsCancelBookingModal";
import { OperationsApproveBookingModal } from "./OperationsApproveBookingModal";
import { useTranslation } from "@equiem/localisation-eq1";
import { canEditBooking } from "../libs/canEditBooking";
import { canCancelBooking } from "../libs/canCancelBooking";
import { canApproveBooking } from "../libs/canApproveBooking";
import { BookingModal } from "../contexts/BookingModalContext";
import { DateTime } from "luxon";

export interface Props {
  booking: NonNullable<SiteBookingsQuery["siteBookingsList"]["edges"][number]["node"]>;
}
export const OperationActions: React.FC<Props> = ({ booking }) => {
  const theme = useTheme(true);
  const modal = useContext(BookingModal);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const { t } = useTranslation();

  const canEdit = canEditBooking(booking);
  const canCancel = canCancelBooking(booking);
  const { needsCreditsApproval, needsApproval, showApproveOrDeny } = canApproveBooking(booking);
  const isCreditCardBooking = booking.paymentMethod === BookableResourcePaymentMethod.CreditCard;

  const showEditButton = useMemo(() => {
    const now = DateTime.now().toMillis();
    const inProgress = now >= booking.startDate && now <= booking.endDate;

    return (canEdit || isCreditCardBooking) && !booking.isPast && !inProgress;
  }, [canEdit, isCreditCardBooking, booking]);

  const editBookingOption = (
    <Dropdown.Item
      className="edit-booking"
      disabled={isCreditCardBooking}
      onClick={() => {
        modal.open(booking.reference);
      }}
      showTick={false}
    >
      <RiEditLine size="16" color={theme.colors.primary} className="mr-3" />
      {t("common.edit")}
    </Dropdown.Item>
  );

  return (
    <div className="d-flex justify-content-end align-items-center action-menu-cont">
      {booking.status === BookingStatus.Cancelled && <Tag className="mr-2">{t("bookings.operations.cancelled")}</Tag>}
      {booking.status === BookingStatus.Declined && (
        <Tag className="mr-2">{t("bookings.operations.status.declined")}</Tag>
      )}
      {needsCreditsApproval && (
        <Tag className="mr-2" variant="dark">
          {t("bookings.operations.status.pendingWorkplaceManagerApproval")}
        </Tag>
      )}
      {needsApproval && (
        <Tag className="mr-2" variant="dark">
          {t("bookings.operations.status.pendingApproval")}
        </Tag>
      )}
      <Dropdown.Icon placement="left-end" size="sm" icon={RiMoreLine} className="action-menu">
        <Dropdown.Link target="_blank" href={`/bookings/operations/${booking.reference}`} icon={RiExternalLinkLine}>
          {t("bookings.operations.openInNewTab")}
        </Dropdown.Link>
        <Dropdown.Link className="view-booking" href={`/bookings/operations/${booking.reference}`} icon={RiEyeLine}>
          {t("bookings.operations.view")}
        </Dropdown.Link>
        {showApproveOrDeny && (
          <Dropdown.Item onClick={() => setShowApproveModal(true)}>
            <RiCheckboxCircleLine size="16" color={theme.colors.primary} className="mr-3" />
            {t("bookings.operations.approveOrDecline")}
          </Dropdown.Item>
        )}
        {showEditButton &&
          booking.status !== BookingStatus.Cancelled &&
          (isCreditCardBooking ? (
            <Tooltip title={t("bookings.operations.cannotBeEdited")} placement="left">
              {editBookingOption}
            </Tooltip>
          ) : (
            editBookingOption
          ))}
        {canCancel && (
          <Dropdown.Item onClick={() => setShowCancelModal(true)}>
            <RiDeleteBinLine color={theme.colors.danger} size="16" className="mr-3" />
            {t("bookings.operations.cancelBooking")}
          </Dropdown.Item>
        )}
      </Dropdown.Icon>
      <OperationsCancelBookingModal
        booking={booking}
        showModal={showCancelModal}
        onHide={() => setShowCancelModal(false)}
      />
      <OperationsApproveBookingModal
        booking={booking}
        showModal={showApproveModal}
        onHide={() => setShowApproveModal(false)}
      />
    </div>
  );
};
