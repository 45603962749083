/* eslint-disable complexity */
import type { PropsWithChildren } from "react";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import type { FormikHelpers, FormikProps } from "formik";
import { Field, Form, Formik } from "formik";

import { CurrentProfile, stringNotEmpty, useShowError, useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import {
  AdvancedSelectorButton,
  Button,
  Form as EqForm,
  Modal,
  ProgressCircle,
  useConfirmer,
  useTheme,
  useToast,
} from "@equiem/react-admin-ui";
import { RiBuilding4Line, RiTeamLine } from "@equiem/react-admin-ui/icons";

import { AddAudience } from "../../../components/audience/AddAudience";
import { ModalChild } from "../../../contexts/ModalChildContext";
import { Modal as ModalContext } from "../../../contexts/ModalContext";
import { useWidgetContext } from "../../../contexts/WidgetContext";
import type { SpaceSiteAudience, SpaceSiteAudienceInput } from "../../../generated/settings-client";
import {
  SpaceVisibilityType,
  useCompaniesV2WithFlexInfoLazyQuery,
  useSpacesSiteAudiencesQuery,
} from "../../../generated/settings-client";
import { useBuildingData } from "../hooks/useBuildingData";
import type { BuildingSpaceFormValues } from "../types";
import { getSpaceValidationSchema } from "../utils/validationSchema";

interface Props extends PropsWithChildren {
  editingSpace?: {
    id?: string;
    title?: string;
    ownerCompanyUuid?: string;
    visibilityType?: SpaceVisibilityType;
    providerSpaceName?: string;
    siteAudiences?: SpaceSiteAudience[] | null;
  };
}

export const BuildingSpaceWidget: React.FC<Props> = ({ editingSpace }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const showError = useShowError();
  const modal = useContext(ModalContext);
  const childModal = useContext(ModalChild);
  const { uuid } = useSiteContext();
  const { profile, canManageRegion } = useContext(CurrentProfile);
  const { spacers, colors } = useTheme();
  const toast = useToast();
  const { withConfirmation } = useConfirmer();
  const formInnerRef = useRef<FormikProps<BuildingSpaceFormValues>>(null);
  const { actionsState, setActionState } = useWidgetContext();
  const isNewSpace = editingSpace?.id == null;

  const { loading, updateSpace, isFlexManager } = useBuildingData();
  const { data: spaceSiteAudiences, loading: spaceSiteAudiencesLoading } = useSpacesSiteAudiencesQuery({
    fetchPolicy: "network-only",
    variables: {
      uuids: [editingSpace?.id ?? ""],
    },
    skip: isNewSpace,
  });

  const mapAudiences: SpaceSiteAudienceInput[] | undefined = useMemo(() => {
    const siteAudiences = spaceSiteAudiences?.spaces[0]?.siteAudiences;
    if (siteAudiences == null) {
      return undefined;
    }
    return siteAudiences.map((audience) => ({
      siteUuid: audience.destination.uuid,
      segmentIds: audience.segmentIds,
      segmentSummary: audience.segmentSummary,
    }));
  }, [spaceSiteAudiences]);

  const initialValues: BuildingSpaceFormValues = useMemo(
    () => ({
      uuid: editingSpace?.id,
      title: editingSpace?.title ?? "",
      providerSpaceName: editingSpace?.providerSpaceName ?? "",
      ownerCompanyUuid: isNewSpace ? undefined : editingSpace.ownerCompanyUuid,
      siteAudiences: mapAudiences,
      visibilityType: editingSpace?.visibilityType ?? (isFlexManager ? SpaceVisibilityType.Members : undefined),
    }),
    [mapAudiences, editingSpace, isNewSpace, isFlexManager],
  );

  const [companiesQuery, companiesQueryData] = useCompaniesV2WithFlexInfoLazyQuery({
    variables: {
      first: 1000,
      destinationUuid: uuid,
    },
  });
  const companies = useMemo(() => companiesQueryData.data?.companiesV2.edges ?? [], [companiesQueryData]);

  const onClose = useCallback(() => {
    setShowModal(false);
    modal.close();
    childModal.close();
  }, [modal, childModal]);

  const onCloseModal = useCallback(() => {
    formInnerRef.current?.dirty === true
      ? withConfirmation({
          title: t("common.areYouSure"),
          message: t("settings.editProfile.cancellingAgreement"),
          confirmButtonText: t("common.yesCancel"),
          cancelButtonText: t("home.widgets.cancelNo"),
          confirmButtonVariant: "danger",
          onConfirm: onClose,
        })()
      : onClose();
  }, [withConfirmation, t, onClose]);

  useEffect(() => {
    if (modal.activeModal === "BuildingSpace") {
      if (canManageRegion) {
        companiesQuery().catch((e) => {
          console.error(e);
        });
      }
      setShowModal(true);
    }
  }, [modal.activeModal]);

  const handleSubmit = async (
    values: BuildingSpaceFormValues,
    { setSubmitting }: FormikHelpers<BuildingSpaceFormValues>,
  ) => {
    try {
      setSubmitting(true);
      setActionState("onSave", "loading");
      await updateSpace(values);
      onClose();
      toast.positive(isNewSpace ? t("settings.build.widget.spaceAdded") : t("settings.build.widget.spacesUpdated"));
    } catch (e: unknown) {
      showError(e);
    }
    setSubmitting(false);
    setActionState("onSave", "normal");
  };

  return (
    <>
      <Modal.Dialog
        title={isNewSpace ? t("settings.build.widget.newSpace") : t("settings.build.widget.editSpace")}
        show={showModal}
        sideModal={true}
        onHide={onCloseModal}
        hideOnEsc={true}
        hideOnClick={true}
        size="md"
      >
        <Modal.Header closeButton onClose={onCloseModal} noBorder={false} />
        {!spaceSiteAudiencesLoading && profile != null && !loading ? (
          <Formik
            initialValues={initialValues}
            innerRef={formInnerRef}
            validationSchema={getSpaceValidationSchema(t, canManageRegion)}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, submitForm, isValid, dirty, isSubmitting, values, setFieldValue }) => (
              <>
                <Modal.Body>
                  <h1 className="title">{t("settings.build.widget.header")}</h1>
                  <Form>
                    <EqForm.Group
                      label={t("settings.build.widget.spaceName")}
                      required
                      error={touched.title != null && errors.title != null ? errors.title : undefined}
                    >
                      <Field
                        id="title"
                        name="title"
                        placeholder={t("settings.build.widget.spaceNamePlaceholder")}
                        as={EqForm.Input}
                        maxLength={300}
                      />
                    </EqForm.Group>
                    {canManageRegion && (
                      <EqForm.Group
                        label={t("settings.build.owner")}
                        required
                        error={
                          touched.ownerCompanyUuid != null && errors.ownerCompanyUuid != null
                            ? t("settings.build.ownerPlaceholder")
                            : undefined
                        }
                      >
                        <Field
                          id="ownerCompanyUuid"
                          name="ownerCompanyUuid"
                          as={EqForm.Select}
                          disabled={initialValues.uuid != null}
                        >
                          <option value="">{t("settings.build.ownerPlaceholder")}</option>
                          {companies.flatMap(({ node }) => (
                            <option key={node?.name} value={node?.uuid}>
                              {node?.name}
                            </option>
                          ))}
                        </Field>
                      </EqForm.Group>
                    )}

                    <EqForm.Group
                      label={t("settings.build.widget.integrationTargetSpaceName")}
                      error={
                        touched.providerSpaceName != null && errors.title != null ? errors.providerSpaceName : undefined
                      }
                      tooltipText={t("settings.build.widget.providerSpaceTooltip")}
                      showTooltip={true}
                      required={false}
                    >
                      <Field
                        id="providerSpaceName"
                        name="providerSpaceName"
                        placeholder={t("settings.build.widget.integrationTargetSpaceNamePlaceholder")}
                        as={EqForm.Input}
                        disabled={!canManageRegion && !isNewSpace && stringNotEmpty(initialValues.providerSpaceName)}
                        maxLength={255}
                      />
                    </EqForm.Group>

                    <EqForm.Group
                      label={t("settings.build.widget.permissionsAccess")}
                      required
                      showTooltip
                      tooltipText={t("settings.build.widget.permissionsTooltipv2")}
                    >
                      <div className="visibility-options">
                        {!isFlexManager && (
                          <AdvancedSelectorButton
                            title={t("settings.build.widget.building")}
                            icon={RiBuilding4Line}
                            className="mx-0 visibility-option"
                            onChange={() => {
                              void setFieldValue("visibilityType", SpaceVisibilityType.Public).catch(console.error);
                            }}
                            onClickContainer={() => {
                              void setFieldValue("visibilityType", SpaceVisibilityType.Public).catch(console.error);
                            }}
                            selected={values.visibilityType === SpaceVisibilityType.Public}
                            description={t("settings.build.widget.buildingSelectorDescription")}
                          />
                        )}
                        {isFlexManager && (
                          <AdvancedSelectorButton
                            title={t("settings.build.widget.members")}
                            icon={RiBuilding4Line}
                            className="mx-0 visibility-option"
                            onChange={() => {
                              void setFieldValue("visibilityType", SpaceVisibilityType.Members).catch(console.error);
                            }}
                            onClickContainer={() => {
                              void setFieldValue("visibilityType", SpaceVisibilityType.Members).catch(console.error);
                            }}
                            selected={values.visibilityType === SpaceVisibilityType.Members}
                            description={t("settings.build.widget.membersSelectorDescription")}
                          />
                        )}
                        {!isFlexManager && (
                          <AdvancedSelectorButton
                            title={t("settings.build.widget.custom")}
                            icon={RiTeamLine}
                            className="mx-0 visibility-option"
                            onChange={() => {
                              void setFieldValue("visibilityType", SpaceVisibilityType.Custom).catch(console.error);
                            }}
                            onClickContainer={() => {
                              void setFieldValue("visibilityType", SpaceVisibilityType.Custom).catch(console.error);
                            }}
                            selected={values.visibilityType === SpaceVisibilityType.Custom}
                            description={t("settings.build.widget.privateSelectorDescription")}
                          />
                        )}
                      </div>
                      {!isFlexManager &&
                        values.visibilityType != null &&
                        [SpaceVisibilityType.Private, SpaceVisibilityType.Custom].includes(values.visibilityType) && (
                          <div className="audience-wrapper">
                            <AddAudience />
                          </div>
                        )}
                    </EqForm.Group>
                  </Form>
                </Modal.Body>
                {actionsState.onSave !== "hidden" && (
                  <Modal.Footer>
                    <Button variant="ghost" className="mr-4" onClick={onCloseModal}>
                      {t("common.cancel")}
                    </Button>
                    <Button
                      type="submit"
                      disabled={!isValid || !dirty || isSubmitting || actionsState.onSave === "loading"}
                      variant="primary"
                      onClick={() => {
                        void submitForm();
                      }}
                    >
                      {actionsState.onSave === "loading" && <ProgressCircle size="xs" />}
                      {isNewSpace ? t("settings.build.widget.addSpace") : t("common.saveChanges")}
                    </Button>
                  </Modal.Footer>
                )}
              </>
            )}
          </Formik>
        ) : (
          <>
            <Modal.Body>
              <div className="text-center pt-8">
                {loading ? <ProgressCircle size="lg" /> : t("common.unknownErrorPleaseTryAgain")}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="ghost" className="mr-4" onClick={onClose}>
                {t("common.cancel")}
              </Button>
              <Button disabled type="submit" variant="primary">
                {t("settings.editProfile.saveChanges")}
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal.Dialog>

      <style jsx>{`
        .title {
          font-weight: 700;
          line-height: 24px;
          margin: 8px 0 24px;
        }
        .content {
          padding: 0 ${spacers.s6} 0;
        }
        hr {
          border: none;
          border-top: 1px solid ${colors.border};
          margin-bottom: 16px;
        }
        .visibility-options {
          display: flex;
          flex-direction: row;
          gap: 8px;
        }
        .audience-wrapper {
          margin-top: 24px;
        }
        :global(.visibility-option) {
          flex: 1 1 0 !important;
        }
      `}</style>
    </>
  );
};
