import React, { createContext } from "react";

import { useShowError } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { ProgressCircle, useTheme } from "@equiem/react-admin-ui";

import { type RequestsAccess, useRequestBuildingPermission } from "../hooks/useRequestBuildingPermission";

const defaultAccess: RequestsAccess = {
  setForcedRole: () => undefined,
  buildings: [],
  propertyManager: false,
  workplaceManager: false,
  requestManager: false,
  hasActiveQueueForRequestManager: false,
  requestAssignee: false,
  showAssignee: true,
  canReportRequests: false,
};

export const RequestsAccessContext = createContext<RequestsAccess>(defaultAccess);

interface P {
  children?: React.ReactNode;
}
export const RequestsAccessProvider: React.FC<P> = ({ children }) => {
  const showError = useShowError();
  const { accessInfo, loading, error, setForcedRole } = useRequestBuildingPermission();
  const { t } = useTranslation();
  const { colors } = useTheme();

  if (error != null) {
    showError(error);
    return null;
  }
  if (loading) {
    return (
      <>
        <div className="container">
          <ProgressCircle size="xs" className="mr-3" />
          {t("requests.list.checkingAccessToBuilding")}...
        </div>
        <style jsx>{`
          .container {
            display: flex;
            height: 100%;
            width: 100%;
            align-items: center;
            justify-content: center;
            background: ${colors.white};
          }
        `}</style>
      </>
    );
  }
  if (accessInfo == null) {
    return null;
  }

  return (
    <RequestsAccessContext.Provider value={{ ...accessInfo, setForcedRole }}>{children}</RequestsAccessContext.Provider>
  );
};
