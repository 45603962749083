import { CurrentProfile, CurrentRole, Role, useSiteContext } from "@equiem/lib";
import { useContext } from "react";
import {
  CreditProviderType,
  useDestinationDetailsQuery,
  useGetMyCreditAccountsQuery,
} from "../generated/gateway-client";

export const useCreditsSettingsAccess = () => {
  const { uuid: siteUuid } = useSiteContext();
  const { canManageCredits } = useContext(CurrentProfile);
  const { currentRole } = useContext(CurrentRole);
  const { data } = useDestinationDetailsQuery({
    variables: {
      uuid: siteUuid,
    },
  });
  const { data: myCreditAccounts } = useGetMyCreditAccountsQuery({
    variables: { providerUuid: siteUuid, providerType: CreditProviderType.Site },
    skip: currentRole !== Role.WorkplaceManager,
  });

  const isFlexSite = data?.destination.siteFlexOperator != null;
  const hasFlexManagerAccess = currentRole === Role.FlexManager && isFlexSite;
  const hasFlexWorkplaceManagerAccess = currentRole === Role.WorkplaceManager && isFlexSite;
  const hasCreditManagerAccess = ![Role.FlexManager, Role.WorkplaceManager].includes(currentRole) && canManageCredits;
  const hasNonFlexWorkplaceManagerAccess = (myCreditAccounts?.myCreditAccounts.length ?? 0) > 0;

  return {
    hasFlexManagerAccess,
    hasFlexWorkplaceManagerAccess,
    hasNonFlexWorkplaceManagerAccess,
    hasCreditManagerAccess,
  };
};
