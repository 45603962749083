import { useCallback, useMemo } from "react";
import { isEqual } from "lodash";
import pickBy from "lodash/pickBy";
import { DateTime } from "luxon";
import type { Runtype } from "runtypes";

import { notNullOrUndefined, stringNotEmpty, useSiteContext } from "@equiem/lib";
import type { TFunction } from "@equiem/localisation-eq1";
import { useTranslation } from "@equiem/localisation-eq1";
import type { FilterItem, FilterValue, FilterValueOptions } from "@equiem/react-admin-ui";
import {
  durationString,
  FilterDateModifier,
  FilterDurationModifier,
  FilterOptionsModifier,
  FilterSelectModifier,
  FilterTextModifier,
  FilterTimeModifier,
  FilterType,
} from "@equiem/react-admin-ui";
import {
  RiBuilding4Line,
  RiDoorOpenLine,
  RiGroupLine,
  RiHomeLine,
  RiInputMethodLine,
  RiMoneyDollarBoxLine,
  RiRepeat2Line,
  RiStore2Line,
  RiTable2,
  RiTeamLine,
  RiText,
  RiTv2Line,
  RiBankCardLine,
} from "@equiem/react-admin-ui/icons";

import type {
  BookingFilterOptionsQuery,
  SiteCompaniesQuery,
  BookingFilters as SchemaBookingFilters,
  BookableResourcePaymentMethod,
  BookingFilterOptionsQueryVariables,
} from "../generated/gateway-client";
import { BookingStatus, useBookingFilterOptionsQuery, useSiteCompaniesQuery } from "../generated/gateway-client";
import { resourceFeatureToString, resourceTypeToString } from "../lib/resourceTypeToString";
import type { Capacity } from "../models/CapacityFilter";
import { capacities } from "../models/CapacityFilter";
import { SavedBookingFilters } from "../models/SavedBookingFilters";
import { formatPaymentMethodLocalised } from "../pages/reports/utils/formatPaymentMethodLocalised";
import { useLocalStorageState } from "./useLocalStorageState";

type FilterOptionLists = BookingFilterOptionsQuery["bookingFilterOptions"];
type CompanyOptionList = NonNullable<SiteCompaniesQuery["destination"]["companiesV2"]>["edges"];

const MINUTES_IN_HOUR = 60;

export interface BookingsFilters {
  startDate?: number;
  endDate?: number;
  filters?: SchemaBookingFilters;
}

const filterKeys = {
  myBookingsGrid: [
    "site",
    "building",
    "level",
    "resourceOwnerCompany",
    "resourceName",
    "resourceTypeUuid",
    "feature",
    "sharedFacility",
    "date",
    "time",
    "duration",
    "showPaidBookings",
    "paymentMethod",
    "capacity",
    "bookingStatus",
  ],
  bookingsManagementList: [
    "site",
    "building",
    "level",
    "userCompany",
    "resourceOwnerCompany",
    "resourceName",
    "resourceTypeUuid",
    "date",
    "time",
    "duration",
    "showPaidBookings",
    "paymentMethod",
    "capacity",
    "bookingStatus",
  ],
  bookingsManagementCalendar: [
    "site",
    "building",
    "level",
    "userCompany",
    "resourceOwnerCompany",
    "resourceName",
    "resourceTypeUuid",
    "time",
    "duration",
    "showPaidBookings",
    "paymentMethod",
    "capacity",
  ],
} as const;

type View = keyof typeof filterKeys;
type FilterKey = (typeof filterKeys)[View][number] | (typeof filterKeys)[View][number];
type ConcreteFilterValues = Partial<Record<FilterKey, FilterValue>>;

const filterOptionVars: Record<
  View,
  Pick<BookingFilterOptionsQueryVariables, "permissionFilters" | "returnFullDays">
> = {
  myBookingsGrid: {
    permissionFilters: { isMyBooking: true },
  },
  bookingsManagementList: {
    permissionFilters: { canObserveBookings: true, isMadeByMyEmployee: true },
  },
  bookingsManagementCalendar: {
    returnFullDays: false,
    permissionFilters: { canObserveBookings: true, isMadeByMyEmployee: true },
  },
};

const getSelectValue = <T extends string = string>(v: FilterValue | undefined): T | undefined =>
  v?.type === FilterType.select && stringNotEmpty(v.value) ? (v.value as T) : undefined;
const getSelectBooleanValue = (v: FilterValue | undefined): boolean | undefined =>
  v?.type === FilterType.select && stringNotEmpty(v.value) ? { true: true, false: false }[v.value] : undefined;
const getOptionValue = <T extends string = string>(v: FilterValue | undefined): T[] | undefined =>
  v?.type === FilterType.options && v.value != null && v.value.length > 0
    ? v.value.map(({ value }) => value as T)
    : undefined;
const getTextValue = (v: FilterValue | undefined) =>
  v?.type === FilterType.text && stringNotEmpty(v.value) ? v.value.trim() : undefined;
const getTimeValue = (v: FilterValue | undefined) => {
  if (v?.type === FilterType.time && typeof v.value === "string") {
    const dt = DateTime.fromFormat(v.value, "HH:mm");
    return dt.hour * MINUTES_IN_HOUR + dt.minute;
  }
  return undefined;
};

const getFilters = (
  values: ConcreteFilterValues,
  view: View,
  timezone: string,
  onlyCancelled?: boolean | null,
): BookingsFilters => {
  // ComplexeFilter remembers filters for other views that might not be relevant
  // to this view. That's useful for the user, but we should exclude them from
  // the query filters.
  const viewValues: ConcreteFilterValues = pickBy(values, (_, key) =>
    (filterKeys[view] as ReadonlyArray<string>).includes(key),
  );

  let startDate: number | undefined = undefined;
  let endDate: number | undefined = undefined;

  if (viewValues.date?.type === FilterType.date && viewValues.date.value != null) {
    const [startDateValue, endDateValue] = Array.isArray(viewValues.date.value)
      ? viewValues.date.value
      : ([viewValues.date.value, viewValues.date.value] as const);

    startDate = DateTime.fromFormat(startDateValue, "yyyy-MM-dd", { zone: timezone }).startOf("day").toMillis();
    endDate = DateTime.fromFormat(endDateValue, "yyyy-MM-dd", { zone: timezone }).endOf("day").toMillis();
  }

  const featureValues = getOptionValue(viewValues.feature) ?? [];
  const facilityValues = getOptionValue(viewValues.sharedFacility) ?? [];

  let minimumDurationMinutes: number | undefined = undefined;
  let maximumDurationMinutes: number | undefined = undefined;

  if (viewValues.duration?.type === FilterType.duration && viewValues.duration.value != null) {
    const [minDurationValue, maxDurationValue] = Array.isArray(viewValues.duration.value)
      ? viewValues.duration.value
      : ([viewValues.duration.value, viewValues.duration.value] as const);

    minimumDurationMinutes = durationString.toMinutes(minDurationValue) ?? undefined;
    maximumDurationMinutes = durationString.toMinutes(maxDurationValue) ?? undefined;
  }

  const capacity = getSelectValue<Capacity>(values.capacity);
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const capacityFilter = capacity != null ? capacities[capacity].filter ?? {} : {};

  return {
    startDate,
    endDate,
    filters: {
      minutesAfterMidnight: getTimeValue(viewValues.time),
      siteUuids: getOptionValue(viewValues.site),
      buildingUuids: getOptionValue(viewValues.building),
      levelUuids: getOptionValue(viewValues.level),
      userCompanyUuids: getOptionValue(viewValues.userCompany),
      ownerCompanyUuids: getOptionValue(viewValues.resourceOwnerCompany),
      resourceName: getTextValue(viewValues.resourceName),
      resourceTypeUuid: getOptionValue(viewValues.resourceTypeUuid),
      resourceFeatureUuid:
        featureValues.length === 0 && facilityValues.length === 0 ? undefined : [...featureValues, ...facilityValues],
      minimumDurationMinutes,
      maximumDurationMinutes,
      paymentMethods: getOptionValue<BookableResourcePaymentMethod>(viewValues.paymentMethod),
      showPaidBookings: getSelectBooleanValue(viewValues.showPaidBookings),
      statuses: getOptionValue<BookingStatus>(viewValues.bookingStatus)?.filter(
        (bs) =>
          onlyCancelled !== true ||
          [BookingStatus.Cancelled, BookingStatus.Declined, BookingStatus.PaymentFailed].includes(bs),
      ),
      ...capacityFilter,
    },
  };
};

const toFilterOption = (val: { uuid: string; name: string }, contextTokens: Array<string | undefined> = []) => {
  const context = contextTokens.filter(stringNotEmpty).join(", ");
  return { value: val.uuid, label: `${val.name}${stringNotEmpty(context) ? ` (${context})` : ""}` };
};

const getFilterOptions = (
  filterOptionLists: FilterOptionLists | undefined,
  userCompanyOptionList: CompanyOptionList | undefined,
  currentFilterValues: BookingsFilters,
  t: TFunction,
  view: View,
  onlyCancelled?: boolean | null,
): Partial<Record<FilterKey, FilterItem>> => {
  const siteOptions =
    filterOptionLists?.sites.map((s) => toFilterOption(s)).sort((a, b) => a.label.localeCompare(b.label)) ?? [];
  const singleSite = siteOptions.length === 1 || (currentFilterValues.filters?.siteUuids ?? []).length === 1;

  const buildingOptions = (filterOptionLists?.buildings ?? [])
    .filter(
      (b) =>
        (currentFilterValues.filters?.siteUuids ?? []).length === 0 ||
        currentFilterValues.filters?.siteUuids?.some((s) => b.destination?.uuid === s),
    )
    .map((b) => toFilterOption(b, [!singleSite ? b.destination?.name : undefined]));
  const singleBuilding =
    filterOptionLists?.buildings.length === 1 || (currentFilterValues.filters?.buildingUuids ?? []).length === 1;

  const levelOptions = (filterOptionLists?.levels ?? [])
    .filter(
      (l) =>
        (currentFilterValues.filters?.siteUuids ?? []).length === 0 ||
        currentFilterValues.filters?.siteUuids?.some((s) => l.building.destination?.uuid === s),
    )
    .filter(
      (l) =>
        (currentFilterValues.filters?.buildingUuids ?? []).length === 0 ||
        currentFilterValues.filters?.buildingUuids?.some((b) => l.building.uuid === b),
    )
    .map((l) =>
      toFilterOption(l, [
        !singleBuilding || !singleSite ? l.building.name : undefined,
        !singleSite ? l.building.destination?.name : undefined,
      ]),
    );

  const resourceOwnerCompanyOptions =
    filterOptionLists?.resourceOwnerCompanies
      .map((c) => toFilterOption(c))
      .sort((a, b) => a.label.localeCompare(b.label)) ?? [];

  const featureOptions =
    filterOptionLists?.features
      .map(({ uuid, name }) => ({ uuid, name: resourceFeatureToString(name, t) }))
      .map((val) => toFilterOption(val)) ?? [];

  const sharedFacilityOptions =
    filterOptionLists?.sharedFacilities
      .map(({ uuid, name }) => ({ uuid, name: resourceFeatureToString(name, t) }))
      .map((val) => toFilterOption(val)) ?? [];

  const paymentMethodOptions = (filterOptionLists?.paymentMethods ?? [])
    .map((pm) => ({ value: pm, label: formatPaymentMethodLocalised(pm, t) }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const userCompanyOptions =
    userCompanyOptionList
      ?.map((edge) => edge.node)
      .filter(notNullOrUndefined)
      .map((c) => toFilterOption(c))
      .sort((a, b) => a.label.localeCompare(b.label)) ?? [];

  const bookingStatusOptions = [
    { value: BookingStatus.Approved, label: t("bookings.operations.status.approved") },
    { value: BookingStatus.Cancelled, label: t("bookings.reports.cancelled") },
    { value: BookingStatus.Declined, label: t("bookings.operations.status.declined") },
    { value: BookingStatus.PaymentFailed, label: t("bookings.operations.status.paymentFailed") },
    { value: BookingStatus.PendingApproval, label: t("bookings.operations.status.pendingApproval") },
    { value: BookingStatus.PendingPayment, label: t("bookings.operations.status.pendingPayment") },
    {
      value: BookingStatus.PendingWorkplaceManagerApproval,
      label: t("bookings.operations.status.pendingWorkplaceManagerApproval"),
    },
  ]
    .filter(
      (opt) =>
        onlyCancelled !== true ||
        [BookingStatus.Cancelled, BookingStatus.Declined, BookingStatus.PaymentFailed].includes(opt.value),
    )
    .sort((a, b) => a.label.localeCompare(b.label));

  const site: FilterItem = {
    title: t("common.site"),
    icon: RiHomeLine,
    type: FilterType.options,
    modifiers: [FilterOptionsModifier.includes],
    disabled: siteOptions.length <= 1,
    options: siteOptions,
  };
  const building: FilterItem = {
    title: t("bookings.resources.resourceBuilding"),
    icon: RiBuilding4Line,
    type: FilterType.options,
    modifiers: [FilterOptionsModifier.includes],
    disabled: buildingOptions.length <= 1,
    options: buildingOptions,
  };
  const level: FilterItem = {
    title: t("bookings.resources.resourceLevel"),
    icon: RiTable2,
    type: FilterType.options,
    modifiers: [FilterOptionsModifier.includes],
    disabled: levelOptions.length <= 1,
    options: levelOptions,
  };
  const userCompany: FilterItem = {
    title: t("bookings.operations.hostCompany"),
    icon: RiStore2Line,
    type: FilterType.options,
    modifiers: [FilterOptionsModifier.includes],
    disabled: userCompanyOptions.length <= 1,
    options: userCompanyOptions,
  };
  const resourceOwnerCompany: FilterItem = {
    title: t("bookings.resources.ownerCompany"),
    icon: RiTeamLine,
    type: FilterType.options,
    modifiers: [FilterOptionsModifier.includes],
    disabled: resourceOwnerCompanyOptions.length <= 1,
    options: resourceOwnerCompanyOptions,
  };
  const resourceName: FilterItem = {
    title: t("bookings.resources.resourceName"),
    icon: RiText,
    type: FilterType.text,
    modifiers: [FilterTextModifier.includes],
  };
  const resourceTypeUuid: FilterItem = {
    title: t("bookings.resources.resourceTypeFull"),
    icon: RiInputMethodLine,
    type: FilterType.options,
    modifiers: [FilterOptionsModifier.includes],
    disabled: (filterOptionLists?.resourceTypes.length ?? 0) <= 1,
    options: (filterOptionLists?.resourceTypes ?? [])
      .map((rt) => ({
        value: rt.uuid,
        label: resourceTypeToString(rt.name, t),
      }))
      .sort((a, b) => a.label.localeCompare(b.label)),
  };
  const feature: FilterItem = {
    title: t("bookings.resources.features"),
    icon: RiTv2Line,
    type: FilterType.options,
    modifiers: [FilterOptionsModifier.includes],
    disabled: featureOptions.length <= 1,
    options: featureOptions,
  };
  const sharedFacility: FilterItem = {
    title: t("bookings.settings.sharedFacilities"),
    icon: RiDoorOpenLine,
    type: FilterType.options,
    modifiers: [FilterOptionsModifier.includes],
    disabled: sharedFacilityOptions.length <= 1,
    options: sharedFacilityOptions,
  };
  const date: FilterItem = {
    title: t("common.date"),
    type: FilterType.date,
    modifiers: [FilterDateModifier.is, FilterDateModifier.between],
  };
  const time: FilterItem = {
    title: t("common.time"),
    type: FilterType.time,
    modifiers: [FilterTimeModifier.is],
  };
  const duration: FilterItem = {
    title: t("bookings.operations.duration"),
    type: FilterType.duration,
    modifiers: [FilterDurationModifier.is, FilterDurationModifier.between],
  };
  const paymentMethod: FilterItem = {
    title: t("bookings.reports.paymentMethod"),
    icon: RiBankCardLine,
    type: FilterType.options,
    modifiers: [FilterOptionsModifier.includes],
    disabled: paymentMethodOptions.length <= 1,
    options: paymentMethodOptions,
  };
  const showPaidBookings: FilterItem = {
    title: t("bookings.operations.showPaidBookings"),
    icon: RiMoneyDollarBoxLine,
    type: FilterType.select,
    modifiers: [FilterSelectModifier.is],
    options: [
      { value: "false", label: t("common.free") },
      { value: "true", label: t("bookings.resources.feeApplies") },
    ],
  };
  const capacity: FilterItem = {
    title: t("bookings.resources.resouceCapacity"),
    icon: RiGroupLine,
    type: FilterType.select,
    modifiers: [FilterSelectModifier.is],
    options: Object.values(capacities).map((c) => c.option),
  };
  const bookingStatus: FilterItem = {
    title: t("common.status"),
    icon: RiRepeat2Line,
    type: FilterType.options,
    options: bookingStatusOptions,
    modifiers: [FilterOptionsModifier.includes],
  };

  const items: Record<View, Partial<Record<FilterKey, FilterItem>>> = {
    myBookingsGrid: {
      site,
      building,
      level,
      resourceOwnerCompany,
      resourceName,
      resourceTypeUuid,
      feature,
      sharedFacility,
      date,
      time,
      duration,
      showPaidBookings,
      paymentMethod,
      capacity,
      bookingStatus,
    },
    bookingsManagementList: {
      site,
      building,
      level,
      userCompany,
      resourceOwnerCompany,
      resourceName,
      resourceTypeUuid,
      date,
      time,
      duration,
      showPaidBookings,
      paymentMethod,
      capacity,
      bookingStatus,
    },
    bookingsManagementCalendar: {
      site,
      building,
      level,
      userCompany,
      resourceOwnerCompany,
      resourceName,
      resourceTypeUuid,
      time,
      duration,
      showPaidBookings,
      paymentMethod,
      capacity,
    },
  };

  return items[view];
};

export const useBookingFilters = (
  view: View,
  pageName: string,
  onlyCancelled?: boolean | null,
  defaultFilterOptionsDateRange?: Pick<BookingFilterOptionsQueryVariables, "date" | "endDate">,
) => {
  const currentSite = useSiteContext();
  const { t } = useTranslation();

  const defaultValues = useMemo<Record<string, FilterValue>>(
    () => ({
      date: {
        type: FilterType.date,
        modifier: FilterDateModifier.between,
        value: [
          DateTime.now().setZone(currentSite.timezone).toFormat("yyyy-MM-dd"),
          DateTime.now().setZone(currentSite.timezone).plus({ months: 1 }).toFormat("yyyy-MM-dd"),
        ],
      },
    }),
    [currentSite.timezone],
  );

  const [{ state: filterValuesState, loading: filterValuesLoading }, setFilterValues] = useLocalStorageState<
    Record<string, FilterValue>
  >(`${pageName}-filters`, {}, SavedBookingFilters as Runtype<Record<string, FilterValue>>);

  const filters = useMemo(
    () => getFilters(filterValuesState, view, currentSite.timezone, onlyCancelled),
    [currentSite.timezone, filterValuesState, onlyCancelled, view],
  );

  const optionsDate =
    filters.startDate ??
    defaultFilterOptionsDateRange?.date ??
    getFilters(defaultValues, view, currentSite.timezone, onlyCancelled).startDate!;
  const optionsEndDate = filters.endDate ?? defaultFilterOptionsDateRange?.endDate;
  const filterOptionsQuery = useBookingFilterOptionsQuery({
    variables: {
      date: optionsDate,
      endDate: optionsEndDate,
      ...filterOptionVars[view],
    },
    fetchPolicy: "cache-and-network",
  });
  // We'll re-request filter options any time the date range changes. Make sure
  // we handle the loading state correctly
  const filterOptionsData = filterOptionsQuery.data ?? filterOptionsQuery.previousData;
  const filterOptionsLoading = filterOptionsQuery.loading && filterOptionsData == null;

  const { data: companiesData, loading: companiesLoading } = useSiteCompaniesQuery({
    variables: { destinationUuid: currentSite.uuid },
  });

  const filterOptions = useMemo<Record<string, FilterItem>>(
    () =>
      getFilterOptions(
        filterOptionsData?.bookingFilterOptions,
        companiesData?.destination.companiesV2?.edges,
        filters,
        t,
        view,
        onlyCancelled,
      ),
    [
      companiesData?.destination.companiesV2?.edges,
      filterOptionsData?.bookingFilterOptions,
      filters,
      onlyCancelled,
      t,
      view,
    ],
  );

  const filterValues = useMemo<Record<string, FilterValue>>(() => {
    const newFilterValues = { ...filterValuesState };

    if (newFilterValues.bookingStatus?.value != null) {
      newFilterValues.bookingStatus.value = (newFilterValues.bookingStatus as FilterValueOptions).value?.filter(
        (bs) =>
          onlyCancelled !== true ||
          [BookingStatus.Cancelled, BookingStatus.Declined, BookingStatus.PaymentFailed].includes(
            bs.value as BookingStatus,
          ),
      );
    }

    return newFilterValues;
  }, [filterValuesState, onlyCancelled]);

  const onFilterChange = useCallback(
    (values: Record<string, FilterValue>): void => {
      if (!filterOptionsLoading && !isEqual(values, filterValuesState)) {
        setFilterValues({ ...values } as ConcreteFilterValues);
      }
    },
    [filterOptionsLoading, filterValuesState, setFilterValues],
  );

  return {
    filtersLoading: filterOptionsLoading || companiesLoading || filterValuesLoading,
    filtersUpdating: filterOptionsQuery.loading && !filterOptionsLoading,
    multiSiteUser: (filterOptionsData?.bookingFilterOptions.sites ?? []).length > 1,
    filterOptions,
    defaultValues,
    filterValues,
    filters,
    onFilterChange,
  };
};
