import { Calendar as CalendarView, Text, useDebounced, useTheme } from "@equiem/react-admin-ui";
import { DateTime, Interval } from "luxon";
import React, { useCallback, useContext } from "react";
import { useBookableResourceAvailabilityCalendarLazyQuery } from "../../../generated/gateway-client";
import { BookingModalInfo } from "../contexts/BookingModalInfoProvider";
import { useTranslation } from "@equiem/localisation-eq1";
import { BookingCalendarContext } from "../contexts/BookingCalendarContext";
import { dateFormat } from "../libs/formatSelectedTime";
import { BookingModal } from "../contexts/BookingModalContext";

const debounceTimeout = 500;

interface P {
  className?: string;
}
export const BookingCalendar: React.FC<P> = ({ className = "" }) => {
  const { t } = useTranslation();
  const theme = useTheme(true);
  const modal = useContext(BookingModal);
  const { resource, booking, timezone } = useContext(BookingModalInfo);
  const { selectedDate, setSelectedDate, selectedTime } = useContext(BookingCalendarContext);

  const selectedTimeDebounced = useDebounced(selectedTime, debounceTimeout);
  const target = DateTime.fromFormat(selectedDate, dateFormat, { zone: timezone });
  const [timesQuery] = useBookableResourceAvailabilityCalendarLazyQuery({ fetchPolicy: "network-only" });

  const getEventsCb = useCallback(
    async (originalTimeOfTheWeek: DateTime) => {
      const start = originalTimeOfTheWeek.startOf("week").toMillis();
      const end = originalTimeOfTheWeek.endOf("week").toMillis();

      try {
        const { data } = await timesQuery({
          variables: {
            uuid: resource.uuid,
            start,
            end,
            excludeBooking: booking?.uuid,
          },
        });

        const intervalSort = (a: Interval<true>, b: Interval<true>) => a.start.toMillis() - b.start.toMillis();

        const splitByDay = (time: { startTime: number; endTime: number }) => {
          const tStart = DateTime.fromMillis(time.startTime, { zone: timezone });
          const tEnd = DateTime.fromMillis(time.endTime, { zone: timezone });

          // The calendar can't handle taken times that cover multiple days.
          // This can happen if e.g. a booking starts at 12:30am and has 1hr
          // prep time before the booking.
          //
          // Split the taken times by day boundary
          const separatedByDay: Array<Interval<true>> = [];
          let dayStart = tStart;
          while (dayStart < tEnd) {
            const dayEnd = DateTime.min(dayStart.endOf("day"), tEnd);
            const dayInterval = Interval.fromDateTimes(dayStart, dayEnd);
            if (dayInterval.isValid) {
              separatedByDay.push(dayInterval);
            }
            dayStart = dayStart.endOf("day").plus({ milliseconds: 1 });
          }

          return separatedByDay;
        };

        const toEvent = (type: "taken" | "available" | "unavailable", interval: Interval<true>) => ({
          type,
          start: interval.start.toMillis(),
          end: interval.end.toMillis(),
        });

        const takenTimes = (data?.bookableResource.availabilityCalendar ?? [])
          .filter((time) => time.__typename === "BookableResourceCalendarTaken")
          .flatMap(splitByDay);

        const availableTimes = (data?.bookableResource.availabilityCalendar ?? [])
          .filter((time) => time.__typename === "BookableResourceCalendarAvailable")
          .flatMap(splitByDay)
          .sort(intervalSort)
          .reduce<Array<Interval<true>>>((acc, curr) => {
            // exclude overlapping (but not adjacent!) availabilities
            const uniqueAvailability = curr.difference(...acc).filter((x): x is Interval<true> => x.isValid);
            return [...acc, ...uniqueAvailability];
          }, []);

        const rawUnavailableTimes: Array<{ startTime: number; endTime: number }> = [];
        let currStart = start;
        for (const time of [...takenTimes, ...availableTimes].sort(intervalSort)) {
          if (currStart < time.start.toMillis()) {
            rawUnavailableTimes.push({ startTime: currStart, endTime: time.start.toMillis() });
          }
          currStart = Math.max(currStart, time.end.toMillis());
        }
        if (currStart < end) {
          rawUnavailableTimes.push({ startTime: currStart, endTime: end });
        }
        const unavailableTimes = rawUnavailableTimes.flatMap(splitByDay).sort(intervalSort);

        return [
          ...unavailableTimes.map((interval) => toEvent("unavailable", interval)),
          ...availableTimes.map((interval) => toEvent("available", interval)),
          ...takenTimes.map((interval) => toEvent("taken", interval)),
        ];
      } catch (e: unknown) {
        console.log(e instanceof Error ? e.message : "Unknown error on refetching events.");

        return [];
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSelectedDate, modal.displayMode, timesQuery, resource.uuid, selectedTime],
  );

  return (
    <div className={`calendar-outer-cont ${className}`}>
      <div className="pb-3">
        <Text variant="label" color={theme.colors.grayscale[60]}>
          {t("bookings.operations.availability")}
        </Text>
      </div>
      <div className="calendar-cont">
        <CalendarView
          getTimesCb={getEventsCb}
          timezone={timezone}
          selectedDate={target}
          selectedTime={selectedTimeDebounced}
        />
      </div>
      <style jsx>{`
        .calendar-cont {
          background: #fff;
          padding: ${theme.spacers.s5};
          border-radius: ${theme.borderRadius};
        }
      `}</style>
    </div>
  );
};
