import React, { createContext, useContext } from "react";
import { useManagedCompaniesCreditsRequests } from "../hooks/useManagedCompaniesCreditsRequests";

type CompaniesCreditsContextType = {
  companiesCredits: ReturnType<typeof useManagedCompaniesCreditsRequests>;
};

export const CompaniesCreditsContext = createContext<{
  companiesCredits: ReturnType<typeof useManagedCompaniesCreditsRequests>;
}>({} as CompaniesCreditsContextType);

export const CompaniesCreditsProvider = ({ children }: React.PropsWithChildren) => {
  const companiesCredits = useManagedCompaniesCreditsRequests();

  return (
    <CompaniesCreditsContext.Provider
      value={{
        companiesCredits,
      }}
    >
      {children}
    </CompaniesCreditsContext.Provider>
  );
};

export const useCompaniesCreditsContext = () => {
  return useContext(CompaniesCreditsContext);
};
