import React, { useContext, useEffect } from "react";
import { Form } from "formik";

import { AppointmentsMenuContext, ButtonTray, useSaferFormikContext, useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Dropdown, useIsMobileWidth, useTheme } from "@equiem/react-admin-ui";
import { RiDeleteBin6Line, RiMoreFill } from "@equiem/react-admin-ui/icons";

import { type VisitorRole, useVisitorManagementSiteSettingsQuery } from "../../generated/visitors-client";
import { useWarningLimitReachVisitorsAlert } from "../../hooks/useWarningLimitReachVisitorsAlert";

import { AppointmentForm } from "./components/AppointmentForm";
import { HostAndVisitors } from "./components/AppointmentMembers";
import { EditAppointmentContext } from "./context/EditAppointmentContext";
import { useIsExpiredAppointmentByDateAndTime } from "./hooks/useIsExpiredAppointmentByDateAndTime";
import { AppointmentRecurringSettingsWidget } from "./widgets/AppointmentRecurringSettingsWidget";
import { AppointmentRecurringUpdateWidget } from "./widgets/AppointmentRecurringUpdateWidget";
import { CancelFormWidget } from "./widgets/CancelFormWidget";
import { ChangeHostWidget } from "./widgets/ChangeHostWidget";
import { VisitorsBulkUploadWidget } from "./widgets/VisitorsBulkUploadWidget";
import { AddVisitorWidget } from "./widgets/VisitorWidget";
import type { FormValues } from "./types";

export const Appointment = ({
  uuid,
  handleCancel,
  submitForm,
  isSubmitting,
  receptionUuid,
  as: role,
  isReceptionView,
  isAllBuildingsReceptionsView,
}: {
  uuid?: string;
  handleCancel: () => void;
  submitForm: () => Promise<void>;
  isSubmitting: boolean;
  receptionUuid?: string;
  as?: VisitorRole;
  isReceptionView?: boolean;
  isAllBuildingsReceptionsView?: boolean;
}) => {
  const { t } = useTranslation();
  const { values, isValid, setSubmitting, setTouched } = useSaferFormikContext<FormValues>();
  const isMobile = useIsMobileWidth();
  const { isDisabled } = useWarningLimitReachVisitorsAlert(values.visitors);
  const {
    cacheAppointmentForm,
    clearState,
    isPreview,
    hasEditableAccess,
    isEndUserHasEditableAccess,
    isSiteBookingAchievable,
  } = useContext(AppointmentsMenuContext);
  const { onDeleteAppointment, isEditView } = useContext(EditAppointmentContext);
  const { colors } = useTheme();
  const { uuid: siteUuid } = useSiteContext();
  const { data: vmSiteSettings } = useVisitorManagementSiteSettingsQuery({ variables: { siteUuid } });

  const isAppointmentExpired = useIsExpiredAppointmentByDateAndTime(values.startTime, values.date);
  const isReceptionViewTypePage = isReceptionView === true || isAllBuildingsReceptionsView === true;

  const isControlsVisible =
    isEditView &&
    (isReceptionViewTypePage || isSiteBookingAchievable || hasEditableAccess || isEndUserHasEditableAccess);

  const onSubmit = async () => {
    setSubmitting(true);
    setTouched(
      { location: true, visitors: [], title: true, duration: true, date: true, startTime: true, holdInLobby: true },
      true,
    ).catch(console.error);

    try {
      if (isValid) {
        await submitForm();
      }
    } catch (e: unknown) {
      console.error(e);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    cacheAppointmentForm(values);
  }, [values]);

  useEffect(() => {
    return () => {
      if (isReceptionViewTypePage) {
        clearState();
      }
    };
  }, []);

  return (
    <>
      <div className="main-container-inner">
        <Form className="form-appointment">
          <div className="grid create-visit">
            <div>
              <AppointmentForm
                type={uuid == null ? "create" : "update"}
                showAdditionalDates={uuid == null}
                receptionUuid={receptionUuid}
                as={role}
                uuid={uuid}
                isReceptionView={isReceptionViewTypePage}
                isAllBuildingsReceptionsView={isAllBuildingsReceptionsView}
              />
            </div>
            <div>
              <HostAndVisitors />
            </div>
          </div>
        </Form>
      </div>
      {isControlsVisible && (
        <ButtonTray>
          <div className="primary-actions">
            {!isMobile && (
              <Button variant="ghost" size="md" onClick={handleCancel}>
                {t("common.cancel")}
              </Button>
            )}
            <Button
              variant="primary"
              size="md"
              className="save-button-appointment"
              disabled={isSubmitting || isDisabled}
              onClick={() => {
                void onSubmit();
              }}
            >
              {t("common.save")}
            </Button>

            {!isMobile && !isPreview && (
              <Dropdown.Container
                sameWidth={false}
                placement="bottom-end"
                trigger={
                  <Button variant="outline" round size="md">
                    <RiMoreFill size={16} />
                  </Button>
                }
              >
                <Button
                  variant="ghost"
                  size="sm"
                  disabled={isAppointmentExpired}
                  onClick={() => onDeleteAppointment(uuid as string)}
                >
                  <RiDeleteBin6Line size={16} color={colors.danger} />
                  <span className="cancel-text">{t("common.delete")}</span>
                </Button>
              </Dropdown.Container>
            )}
          </div>
        </ButtonTray>
      )}

      <AddVisitorWidget mode="default" />
      <VisitorsBulkUploadWidget />
      <AppointmentRecurringSettingsWidget />
      <ChangeHostWidget
        mode="default"
        isEmailRequired={vmSiteSettings?.visitorManagementSiteSettings.isHostEmailMandatory === true}
        isNewAppointment={uuid == null}
        receptionUuid={receptionUuid}
      />
      {uuid != null ? <AppointmentRecurringUpdateWidget uuid={uuid} isReceptionView={false} /> : null}
      <CancelFormWidget
        isReceptionView={isReceptionView === true}
        receptionUuid={receptionUuid}
        isAllBuildingsReceptionsView={isAllBuildingsReceptionsView === true}
      />
      <style jsx>{`
        :global(.primary-actions button.save-button-appointment) {
          width: ${isMobile ? "100%" : "auto"};
        }
        .main-container-inner {
          padding-right: 0px !important;
          height: 100%;
        }
        .cancel-text {
          text-transform: capitalize;
        }
        .create-visit {
          height: 100%;
        }

        :global(.form-appointment) {
          height: 100%;
        }

        .primary-actions {
          display: flex;
          gap: 8px;
          width: 100%;
          justify-content: flex-end;
        }

        .grid {
          display: flex;
          flex-direction: row;
        }

        .grid > div:first-child {
          flex: ${isMobile ? "auto" : 55};
        }

        .grid > div:last-child {
          flex: 45;
        }
        @media all and (max-width: 1000px) {
          .grid {
            flex-direction: column;
            min-height: 0;
          }
        }
      `}</style>
    </>
  );
};
