import { useContext } from "react";
import { Site, stringIsEmpty } from "@equiem/lib";
import { Literal, Never, Union } from "runtypes";

interface EnvConfig {
  adminPanelApplicationLink: string;
  storeApplicationLink: string;
  reportingApplicationLink: string;
}

const getEnv = (env: "local" | "staging" | "production"): Record<string, EnvConfig> => {
  switch (env) {
    case "local":
    case "staging": {
      const usEast = {
        adminPanelApplicationLink: "https://us.admin.staging.getequiem.com",
        storeApplicationLink: "https://us.vendor.staging.getequiem.com",
        reportingApplicationLink: "https://us.reporting.staging.getequiem.com",
      };

      const stage2 = {
        adminPanelApplicationLink: "https://au.admin.staging.getequiem.com",
        storeApplicationLink: "https://au.vendor.staging.getequiem.com",
        reportingApplicationLink: "https://au.reporting.staging.getequiem.com",
      };

      return {
        "default": usEast,
        "ap-southeast-2-01": usEast, // Not a mistake... legacy stuff here.
        "stage2": stage2,
      };
    }

    case "production": {
      const apSouthEast = {
        adminPanelApplicationLink: "https://au.admin.getequiem.com",
        storeApplicationLink: "https://au.vendor.getequiem.com",
        reportingApplicationLink: "https://au.reporting.getequiem.com",
      };
      const euWest = {
        adminPanelApplicationLink: "https://eu.admin.getequiem.com",
        storeApplicationLink: "https://eu.vendor.getequiem.com",
        reportingApplicationLink: "https://eu.reporting.getequiem.com",
      };
      const usEast = {
        adminPanelApplicationLink: "https://us.admin.getequiem.com",
        storeApplicationLink: "https://us.vendor.getequiem.com",
        reportingApplicationLink: "https://us.reporting.getequiem.com",
      };
      return {
        "default": usEast,
        "ap-southeast-2-01": apSouthEast,
        "eu-west-1-01": euWest,
        "us-east-1-01": usEast,
      };
    }

    default:
      return Never.check(env);
  }
};

export const useRegionSpecificAppUrls = () => {
  // process.env[regionName] isn't supported so the env file
  // has to be called manually.

  const buildEnv = Union(Literal("production"), Literal("staging"), Literal("local")).guard(
    process.env.NEXT_PUBLIC_BUILD_ENV,
  )
    ? process.env.NEXT_PUBLIC_BUILD_ENV
    : "local";
  const env = getEnv(buildEnv);

  const { regionName } = useContext(Site);

  return env[stringIsEmpty(regionName) ? "default" : regionName];
};
