import React, { useMemo } from "react";
import { useRouter } from "next/router";

import { useDisabledSiteSwitcher, useQueryState } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, EmptyState, useTheme } from "@equiem/react-admin-ui";
import { RiAddLine } from "@equiem/react-admin-ui/icons";

import { BookingsTab } from "../../components/BookingsTab";
import { ScrollBottomObserverWrapper } from "../../components/ScrollBottomObserverWrapper";
import { BookableResourceStatus, useResourceCountsQuery } from "../../generated/gateway-client";
import { usePagedResources } from "../../hooks/usePagedResources";
import { useResourceFilters } from "../../hooks/useResourceFilters";

import { ResourceCard, ResourceCardLoading } from "./components/ResourceCard";
import { type Tab, defaultTab, isTab, ResourcesListStatusTabs } from "./ResourcesListStatusTabs";

const tabFilters: Record<Tab, BookableResourceStatus | null> = {
  all: null,
  published: BookableResourceStatus.Published,
  draft: BookableResourceStatus.Draft,
};

export const ResourcesList = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { breakpoints, spacers } = useTheme(true);

  const [{ status: tab }, setQueryState] = useQueryState({
    initial: { status: defaultTab },
    parse: { status: (f) => (isTab(f) ? f : defaultTab) },
    clearQueryOnChange: true,
    rememberLastState: true,
  });

  useDisabledSiteSwitcher();

  const {
    loading: filtersLoading,
    filterOptions,
    filterValues,
    filters: { managedBy: _unused, ...filters },
    onFilterChange,
  } = useResourceFilters({ canManageBookings: true }, "resource-management");

  const complexFilters = useMemo(
    () => ({
      loading: filtersLoading,
      items: filterOptions,
      initialValues: filterValues,
      setValues: onFilterChange,
    }),
    [filterValues, filtersLoading, filterOptions, onFilterChange],
  );

  const { resources, name, searchText, setSearchText, error, loading, hasMoreData, fetchMore, refetch } =
    usePagedResources({
      filters: { ...filters, status: tabFilters[tab] },
      permissionFilters: { canManageBookings: true },
    });
  const tabResources = resources.filter((r) => tabFilters[tab] == null || r.status === tabFilters[tab]);

  const counts = useResourceCountsQuery({
    variables: {
      filters: { ...filters, name },
      permissionFilters: { canManageBookings: true },
    },
  });

  const handleTabFilterChange = (newTab: Tab) => {
    setQueryState({ status: newTab });
  };

  const isEmpty = !loading && !hasMoreData && resources.length === 0;

  return (
    <>
      <BookingsTab
        title={
          <ResourcesListStatusTabs
            onTabChanged={handleTabFilterChange}
            activeTab={tab}
            countLoading={counts.loading}
            totalCount={counts.data?.bookableResourceCounts.all}
            publishedCount={counts.data?.bookableResourceCounts.published}
            draftCount={counts.data?.bookableResourceCounts.draft}
          />
        }
        search={{ searchText, setSearchText }}
        filters={complexFilters}
        button={
          <Button
            className="main-button"
            variant="primary"
            size="md"
            onClick={() => {
              router.push("/bookings/resources/new-resource").catch(console.error);
            }}
          >
            <RiAddLine size={16} />
            {t("bookings.resources.newResource")}
          </Button>
        }
      >
        <ScrollBottomObserverWrapper onBottomVisible={fetchMore}>
          {error != null || !isEmpty ? (
            <div className="listing">
              {error != null && <div>{error.message}</div>}
              {tabResources.map((resource) => (
                <ResourceCard key={resource.uuid} {...resource} triggerRefresh={refetch} />
              ))}
              {loading && Array.from({ length: 6 }).map((_, index) => <ResourceCardLoading key={index} />)}
            </div>
          ) : (
            <div className="empty-listing">
              <EmptyState />
            </div>
          )}
        </ScrollBottomObserverWrapper>
      </BookingsTab>
      <style jsx>{`
        .listing {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(307px, 1fr));
          justify-items: center;
          grid-gap: ${spacers.s7} ${spacers.s5};
          padding: ${spacers.s4} ${spacers.s7} ${spacers.s7};
        }
        .empty-listing {
          padding: ${spacers.s7};
        }
        .main-button {
          text-transform: uppercase;
        }
        @media screen and (max-width: ${breakpoints.md}px) {
          .listing {
            grid-template-columns: 1fr;
          }
        }
        @media screen and (max-width: ${breakpoints.sm}px) {
          .listing {
            padding: 16px;
          }
        }
      `}</style>
    </>
  );
};
