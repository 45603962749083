/* eslint-disable react/no-unescaped-entities */
import React from "react";

import { Table, useTheme } from "@equiem/react-admin-ui";

export const CookiePolicy: React.FC = () => {
  const { spacers } = useTheme();

  return (
    <>
      <div>
        <h2>INFORMATION ABOUT OUR USE OF COOKIES</h2>
        <p>
          Our Website and Platform uses cookies (and other similar technologies) to distinguish you from other users of
          our Website and our Platform. This helps us to provide you with a good experience when you browse our Website,
          use our Platform and also allows us to improve our site. By continuing to browse or use the site, you are
          agreeing to our use of cookies.
        </p>
        <p>
          A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your
          computer if you agree. Cookies contain information that can be transferred to your computer’s hard drive.
        </p>

        <h2>EXERCISING YOUR COOKIE CHOICES</h2>
        <h4>Website Cookie Control:</h4>
        <p>
          The cookie control pop-up on our Website provides you with the ability to accept or reject the optional
          cookies used by our Website and Platform that can be placed on your browser or device. Please note that ‘
          <em>strictly necessary</em>’ cookies are not subject to the same control as these are required for the website
          to function in the required manner. The cookies used and which category they fall into are detailed below.
        </p>
        <h4>Website Browser Opt-Out:</h4>
        <p>
          You can also block cookies by activating the setting on your browser that allows you to refuse the setting of
          all or some cookies. To do so, please follow the instructions provided by your browser (these are usually
          located within the "Help" or "Tools" section). However, if you use your browser settings to block all cookies
          (including strictly necessary cookies) you may not be able to access and use all or parts of our Website or
          Platform.
        </p>

        <h2>COOKIE DETAILS</h2>

        <h4>Necessary Cookies:</h4>

        <p>
          These Necessary cookies are necessary for the proper functioning of the Website and our services. They enable
          you to navigate the Website and use its features. Without these cookies, certain services may not be provided.
          They may include, for example, cookies that enable you to log into secure areas of our Website, use a shopping
          cart or make use of e-billing services. As they are required for the Website and our services to operate
          correctly, they do not require your consent. You must accept these essential cookies to be able to make use of
          our online systems.
        </p>

        <Table.Table>
          <tr>
            <Table.Header label="Cookie" />
            <Table.Header label="Name" />
            <Table.Header label="Purpose" />
            <Table.Header label="Category" />
            <Table.Header label="Expiry period" />
          </tr>
          <tr>
            <td>Session Token</td>
            <td>auth:session / auth__session</td>
            <td>Authentication: The user's encrypted session data</td>
            <td>Essential/ Strictly Necessary</td>
            <td>30 days</td>
          </tr>
          <tr>
            <td>Authenticated Flag</td>
            <td>auth</td>
            <td>Authentication: used to indicate that you have been authenticated as a user</td>
            <td>Essential/ Strictly Necessary</td>
            <td>30 days</td>
          </tr>
          <tr>
            <td>Authentication State</td>
            <td>auth:state / auth__state</td>
            <td>
              Authentication Security: a temporary cookie used during the login process, to securely redirect you once
              you have been authenticated.
            </td>
            <td>Essential/ Strictly Necessary</td>
            <td>1 hour</td>
          </tr>
          <tr>
            <td>Cookie acceptance</td>
            <td>localStorage</td>
            <td>Stores whether you have opted in for "optional" cookies</td>
            <td>Essential/ Strictly Necessary</td>
            <td>Indefinite</td>
          </tr>
          <tr>
            <td>Auth0 Session</td>
            <td>auth0, auth0_compat</td>
            <td>
              Allows us to authenticate a user during signup and grant access to their account. ‘auth0_compat’ is a
              fallback cookie for ‘auth0’ for legacy browsers which don't support the ‘auth0’ cookie. These cookies are
              necessary to authenticate your identity and enable secure access to our services. They allow you to log
              in, maintain your session, and access restricted areas.
            </td>
            <td>Essential/ Strictly Necessary</td>
            <td>1 week</td>
          </tr>
          <tr>
            <td>Last Site</td>
            <td>auth:last_site / auth__last_site</td>
            <td>
              Stores the most recent Equiem site accessed by the user. If the user logs in via a default Equiem URL,
              this allows redirection to their most relevant site.
            </td>
            <td>Essential/ Strictly Necessary</td>
            <td>30 days</td>
          </tr>
          <tr>
            <td>Application Mode</td>
            <td>app:mode / app__mode</td>
            <td>Provides a consistent user experience for customers using Bookings across different parts of Equiem</td>
            <td>Essential/ Strictly Necessary</td>
            <td>At end of session</td>
          </tr>
          <tr>
            <td>Auth0 Device ID</td>
            <td>did, did_compat</td>
            <td>
              Set by Auth0 - device identification for the purposes of attack protection. 'did_compat' is a fallback
              cookie for 'did' in the event a legacy browser does not support the attack prevention of the 'did' cookie.
            </td>
            <td>Essential/ Strictly Necessary</td>
            <td>1 month</td>
          </tr>
          <tr>
            <td>Stripe mid</td>
            <td>__stripe_mid</td>
            <td>
              Part of Stripe's (payment gateway) fraud prevention. See{" "}
              <a href="https://stripe.com/docs/disputes/prevention/advanced-fraud-detection">
                https://stripe.com/docs/disputes/prevention/advanced-fraud-detection
              </a>
            </td>
            <td>Essential/ Strictly Necessary</td>
            <td>1 year</td>
          </tr>
          <tr>
            <td>Stripe sid</td>
            <td>__stripe_sid</td>
            <td>
              Part of Stripe's (payment gateway) fraud prevention. See{" "}
              <a href="https://stripe.com/docs/disputes/prevention/advanced-fraud-detection">
                https://stripe.com/docs/disputes/prevention/advanced-fraud-detection
              </a>
            </td>
            <td>Essential/ Strictly Necessary</td>
            <td>30 minutes</td>
          </tr>
          <tr>
            <td>Google Recaptcha</td>
            <td>_GRECAPTCHA</td>
            <td>Used to provide spam protection</td>
            <td>Essential/ Strictly Necessary</td>
            <td>~6 months</td>
          </tr>
        </Table.Table>

        <h4>Optional Cookies:</h4>

        <ul>
          <li>
            <strong>Performance cookies.</strong> They allow us to recognise and count the number of visitors and to see
            how visitors move around our website when they are using it. All information these cookies collect is
            aggregated and therefore anonymous. This helps us to improve the way our website works, for example, by
            ensuring that users are finding what they are looking for easily.
          </li>
          <li>
            <strong>Functionality cookies.</strong> These are used to recognise you and remember your choices when you
            return to our website. This enables us to personalise our content for you, greet you by name and remember
            your preferences (for example, your choice of language or region).
          </li>
        </ul>

        <Table.Table>
          <tr>
            <Table.Header label="Cookie" />
            <Table.Header label="Name" />
            <Table.Header label="Purpose" />
            <Table.Header label="Category" />
            <Table.Header label="Expiry period" />
          </tr>
          <tr>
            <td>Usetiful Visitor Identity</td>
            <td>usetiful-visitor-ident</td>
            <td>Stores a unique identifier for usetiful, to allow determine if you have completed a product tour</td>
            <td>Functionality</td>
            <td>1 year</td>
          </tr>
          <tr>
            <td>HotJar</td>
            <td>_hjSessionUser_</td>
            <td>
              Collects statistics on your visits to the website, such as the number of visits, average time spent on the
              website and what pages have been read or elements interacted with. Hotjar does not track users across
              different sites.
            </td>
            <td>Performance</td>
            <td>1 year</td>
          </tr>
          <tr>
            <td>HotJar</td>
            <td>_hjSession_</td>
            <td>
              A cookie that holds the current session data. This ensures that subsequent requests within the session
              window will be attributed to the same session.
            </td>
            <td>Performance</td>
            <td>30 minutes</td>
          </tr>
          <tr>
            <td>HotJar</td>
            <td>_hjHasCachedUserAttributes</td>
            <td>Enables HotJar to determine whether the data stored in hjUserAttributes local storage is up to date</td>
            <td>Performance</td>
            <td>At end of session</td>
          </tr>
          <tr>
            <td>HotJar</td>
            <td>_hjUserAttributes</td>
            <td>Stores User Attributes sent through the HotJar Identity API</td>
            <td>Performance</td>
            <td>No explicit expiration</td>
          </tr>
          <tr>
            <td>HotJar</td>
            <td>_hjUserAttributesHash</td>
            <td>Enables HotJar to detect if any user attribute has changed and requires updating.</td>
            <td>Performance</td>
            <td>2 minutes but is extended every 30 seconds</td>
          </tr>
          <tr>
            <td>HotJar</td>
            <td>hjViewportID</td>
            <td>Stores user viewpoint details such as size and dimensions.</td>
            <td>Performance</td>
            <td>At end of session</td>
          </tr>
          <tr>
            <td>HotJar</td>
            <td>hjActiveViewportIds</td>
            <td>Stores user active viewport IDs</td>
            <td>Performance</td>
            <td>When browser page is closed</td>
          </tr>
          <tr>
            <td>HotJar</td>
            <td>hjCookieTest</td>
            <td>Checks to see if cookies can be used</td>
            <td>Performance</td>
            <td>At end of session</td>
          </tr>
          <tr>
            <td>HotJar</td>
            <td>hjLocalStorageTest</td>
            <td>Checks if local storage can be used</td>
            <td>Performance</td>
            <td>No expiration time but deleted almost immediately after creation</td>
          </tr>
          <tr>
            <td>HotJar</td>
            <td>hjSessionStorageTest</td>
            <td>Checks if Session Storage can be used.</td>
            <td>Performance</td>
            <td>No expiration time but deleted almost immediately after creation</td>
          </tr>
          <tr>
            <td>HotJar</td>
            <td>hjTLDTest</td>
            <td>Enables to determine most generic cookie path to use</td>
            <td>Performance</td>
            <td>At end of session</td>
          </tr>
          <tr>
            <td>New Relic</td>
            <td>JSESSIONID</td>
            <td>Stores a session ID so New Relic can monitor session counts for an application.</td>
            <td>Performance</td>
            <td>At end of session</td>
          </tr>
        </Table.Table>

        <p>
          Please note that third parties (including, for example, advertising networks and providers of external
          services like web traffic analysis services) may also use cookies, over which we have no control.
        </p>

        <h2>UPDATES TO THIS POLICY</h2>
        <p>
          We may update this Cookie Policy from time to time to reflect changes in our practices or legal requirements.
          We encourage you to review this policy periodically for any updates.
        </p>

        <h2>CONTACT US</h2>
        <p>
          Questions, comments and requests regarding this cookie policy are welcomed and should be addressed to
          privacy@getequiem.com.
        </p>
        <p>
          If you would like more information about how we use your personal data, please see our privacy policy at our{" "}
          <a href="/privacy">Privacy Policy</a>.
        </p>
        <p>
          More general information about cookies can be found here:{" "}
          <a href="https://allaboutcookies.org/">https://allaboutcookies.org/</a>
        </p>
      </div>
      <style jsx>{`
        h4 {
          margin-top: ${spacers.s4};
        }
        li {
          margin-bottom: ${spacers.s3};
          line-height: 24px;
        }
        a {
          text-decoration: underline;
        }
      `}</style>
    </>
  );
};
