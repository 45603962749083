import React, { useCallback, useEffect, useMemo, useState } from "react";
import getSymbolFromCurrency from "currency-symbol-map";
import { Field, useFormikContext } from "formik";

import { formatters, useTranslation } from "@equiem/localisation-eq1";
import { Alert, Button, Form, ProgressCircle, Table, Text, useTheme } from "@equiem/react-admin-ui";
import { RiCloseLine, RiEditLine, RiErrorWarningLine } from "@equiem/react-admin-ui/icons";

import {
  BookableResourceBookingApprovalType,
  useDestinationDetailsQuery,
} from "../../../../../generated/gateway-client";
import { useCurrencyCode } from "../../../../../hooks/useCurrency";
import { useSiteAudiencesCompanyOptions } from "../../../../../hooks/useSiteAudiencesCompanyOptions";
import type { AudienceValues, FormValues } from "../../../../../lib/formValidation";
import { audienceCanBeTaxExempt } from "../../../../../lib/taxExemption";
import { isVisitorManagementEnabled } from "../../../hooks/useIsVisitorManagementEnabled";
import { AudienceInfo } from "../../../widgets/AudienceInfo";

import { ResourceCreateAndEditFormPermissionsModal } from "./ResourceCreateAndEditFormPermissionsModal";

const MAX_SITE_AUDIENCES_NUMBER = 20;

const hasPaymentRates = (audience: AudienceValues) => {
  return (
    (audience.paymentRateHourly != null && audience.paymentRateHourly > 0) ||
    (audience.paymentRateHalfDay != null && audience.paymentRateHalfDay > 0) ||
    (audience.paymentRateFullDay != null && audience.paymentRateFullDay > 0) ||
    (audience.paymentRateHourlyAfterHours != null && audience.paymentRateHourlyAfterHours > 0) ||
    (audience.paymentRateHourlyWeekend != null && audience.paymentRateHourlyWeekend > 0)
  );
};

export const ResourceCreateAndEditFormPermissions: React.FC = () => {
  const { i18n, t } = useTranslation();
  const fm = useFormikContext<FormValues>();
  const [manualApprovalCompaniesTouched, setManualApprovalCompaniesTouched] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalIndex, setModalIndex] = useState<number | null>(null);
  const { breakpoints, colors, spacers } = useTheme(true);

  const { data, loading } = useDestinationDetailsQuery({ variables: { uuid: fm.values.site } });
  const currencyCode = useCurrencyCode(fm.values.building);

  const { companyOptions, companiesLoading } = useSiteAudiencesCompanyOptions(
    fm.values.site,
    fm.values.siteAudiences,
    fm.values.bookingApprovalType === BookableResourceBookingApprovalType.ManualForCompanies,
  );

  const defaultAudience = useMemo(
    () => ({
      site: fm.values.site,
      siteName: data?.destination.name ?? "",
      segmentIds: [],
      segmentSummary: "",
      taxExempt: false,
    }),
    [fm.values.site, data?.destination.name],
  );

  const numOwnerSiteAudiences = useMemo(() => {
    return fm.values.siteAudiences.reduce(
      (count, audience) => (audience.site === fm.values.site ? count + 1 : count),
      0,
    );
  }, [fm.values.siteAudiences, fm.values.site]);

  const addSiteAudience = useCallback(
    (siteAudience: AudienceValues) => {
      fm.setFieldValue("siteAudiences", [...fm.values.siteAudiences, siteAudience]).catch(console.error);

      setShowModal(false);
    },
    [fm],
  );

  const editSiteAudience = useCallback(
    (siteAudience: AudienceValues, index: number) => {
      const newSiteAudiences = [...fm.values.siteAudiences];
      newSiteAudiences[index] = siteAudience;
      fm.setFieldValue("siteAudiences", newSiteAudiences).catch(console.error);

      setShowModal(false);
      setModalIndex(null);
    },
    [fm],
  );

  const removeSiteAudience = useCallback(
    (index: number) => {
      const newSiteAudiences = [...fm.values.siteAudiences];
      newSiteAudiences.splice(index, 1);
      fm.setFieldValue("siteAudiences", newSiteAudiences).catch(console.error);
    },
    [fm],
  );

  useEffect(() => {
    if (!companiesLoading) {
      const companyOptionValues = new Set(companyOptions.map((opt) => opt.value));
      const filteredCompanies =
        fm.values.manualApprovalCompanies?.filter((company) => companyOptionValues.has(company)) ?? [];
      if (filteredCompanies.length !== fm.values.manualApprovalCompanies?.length) {
        const timer = setTimeout(() => {
          fm.setFieldValue("manualApprovalCompanies", filteredCompanies).catch(console.error);
        }, 0);
        return () => clearTimeout(timer);
      }
    }
    return () => undefined;
  }, [companiesLoading, companyOptions, fm]);

  if (loading) {
    return <ProgressCircle size="md" />;
  }

  const manualApprovalCompaniesError = manualApprovalCompaniesTouched ? fm.errors.manualApprovalCompanies : undefined;

  return (
    <>
      <div className="booking-approvals-container">
        <Form.Group
          error={fm.errors.bookingApprovalType}
          label={t("bookings.resources.bookingApprovals")}
          required
          showTooltip
          tooltipText={t("bookings.resources.bookingApprovalsTooltip")}
        >
          <div className="radio-container">
            <Form.RadioButton
              id="auto-approve-all"
              disabled={fm.values.allowRecurringBooking === true || fm.isSubmitting}
              label={t("bookings.resources.automaticallyApproveAllBookings")}
              checked={fm.values.bookingApprovalType === BookableResourceBookingApprovalType.AutoForAll}
              onChange={() => {
                void fm.setValues({
                  ...fm.values,
                  bookingApprovalType: BookableResourceBookingApprovalType.AutoForAll,
                  manualApprovalCompanies: [],
                });
              }}
            />
            <Form.RadioButton
              id="manual-approve-all"
              disabled={fm.values.allowRecurringBooking === true || fm.isSubmitting}
              label={t("bookings.resources.manuallyApproveAllBookings")}
              checked={fm.values.bookingApprovalType === BookableResourceBookingApprovalType.ManualForAll}
              onChange={() => {
                void fm.setValues({
                  ...fm.values,
                  bookingApprovalType: BookableResourceBookingApprovalType.ManualForAll,
                  manualApprovalCompanies: [],
                });
              }}
            />
            <Form.RadioButton
              id="manual-approve-for-selected-companies"
              disabled={fm.values.allowRecurringBooking === true || fm.isSubmitting}
              label={t("bookings.resources.manuallyApproveBookingsForSelectedCompanies")}
              checked={fm.values.bookingApprovalType === BookableResourceBookingApprovalType.ManualForCompanies}
              onChange={() => {
                fm.setFieldValue("bookingApprovalType", BookableResourceBookingApprovalType.ManualForCompanies).catch(
                  console.error,
                );
                setManualApprovalCompaniesTouched(false);
              }}
            />
          </div>
          {fm.values.allowRecurringBooking === true && (
            <Alert
              className="mt-4"
              size="large"
              variant="gray"
              icon={<RiErrorWarningLine size={18} color={colors.grayscale[50]} />}
              message={t("bookings.resources.allowRecurringBookingInfo")}
            />
          )}
        </Form.Group>
        {fm.values.bookingApprovalType === BookableResourceBookingApprovalType.ManualForCompanies &&
          !companiesLoading &&
          (fm.errors.bookingApprovalType == null || fm.errors.bookingApprovalType.length === 0) && (
            <Form.Group error={manualApprovalCompaniesError}>
              <Field
                name="manualApprovalCompanies"
                className="mt-4"
                options={companyOptions}
                as={Form.MultiSelect}
                variant="wrap"
                placeholder={t("bookings.resources.searchForCompanies")}
                searchPlaceholder={t("bookings.resources.companyName")}
                isMulti
                enableSelectAll
                disabled={fm.isSubmitting}
                onClose={() => {
                  setManualApprovalCompaniesTouched(true);
                }}
              />
            </Form.Group>
          )}
      </div>

      {fm.values.bookingApprovalType !== BookableResourceBookingApprovalType.AutoForAll && (
        <Form.Group
          label={t("bookings.resources.bookingCostVisibility")}
          showTooltip
          tooltipText={t("bookings.resources.hiddenCostTooltip")}
        >
          <Field
            id="hiddenCost"
            name="hiddenCost"
            label={t("bookings.resources.hiddenCostLabel")}
            as={Form.Checkbox}
            disabled={fm.isSubmitting}
          />
        </Form.Group>
      )}

      {isVisitorManagementEnabled(data) && (
        <Form.Group
          error={fm.errors.allowVisitorInvites}
          label={t("visitors.common.visitors")}
          showTooltip
          tooltipText={t("bookings.resources.allowVisitorInvitesTooltip")}
        >
          <Field
            id="allowVisitorInvites"
            name="allowVisitorInvites"
            label={t("bookings.resources.allowVisitorInvites")}
            as={Form.Checkbox}
            disabled={fm.isSubmitting}
          />
        </Form.Group>
      )}

      <Form.Group
        label={t("bookings.resources.whoCanBookThisResource")}
        required
        showTooltip
        tooltipText={t("bookings.resources.whoCanBookHint")}
      >
        <div className="radio-container">
          <Form.RadioButton
            id="site-audiences-all"
            disabled={fm.isSubmitting}
            label={t("bookings.resources.whoCanBookAll")}
            checked={fm.values.siteAudiences.length === 0}
            onChange={() => {
              fm.setFieldValue("siteAudiences", []).catch(console.error);
            }}
          />
          <Form.RadioButton
            id="site-audiences-selected"
            disabled={fm.isSubmitting}
            label={t("bookings.resources.whoCanBookGroup")}
            checked={fm.values.siteAudiences.length > 0}
            onChange={() => {
              fm.setFieldValue("siteAudiences", [defaultAudience]).catch(console.error);
            }}
          />
        </div>
      </Form.Group>

      {fm.values.siteAudiences.length > 0 && (
        <div className="site-audiences-table-container">
          <Table.Table className="w-100">
            <thead>
              <tr>
                <Table.Header label={t("common.site")} />
                <Table.Header label={t("bookings.resources.audience")} style={{ width: "35%" }} />
                <Table.Header label={t("bookings.resources.rates")} style={{ width: "35%" }} />
                <Table.Header label="" />
                <Table.Header label="" />
              </tr>
            </thead>
            <tbody>
              {fm.values.siteAudiences.map((audience, i) => (
                <tr key={i}>
                  <td>
                    <Text variant="text" component="span" size="small">
                      {audience.siteName}
                    </Text>
                  </td>
                  <td>
                    <Text variant="text" component="span" size="small">
                      <AudienceInfo audience={audience} />
                    </Text>
                  </td>
                  <td>
                    {hasPaymentRates(audience) ? (
                      <>
                        {audience.paymentRateHourly != null && audience.paymentRateHourly > 0 && (
                          <div>
                            <Text variant="text" component="span" size="small">
                              {`${t("bookings.resources.hourly")} - ${formatters.currency(
                                Number(audience.paymentRateHourly),
                                i18n.language,
                                {
                                  currency: currencyCode,
                                },
                              )}`}
                            </Text>
                          </div>
                        )}
                        {audience.paymentRateHalfDay != null && audience.paymentRateHalfDay > 0 && (
                          <div>
                            <Text variant="text" component="span" size="small">
                              {`${t("bookings.resources.halfDay")} - ${formatters.currency(
                                Number(audience.paymentRateHalfDay),
                                i18n.language,
                                {
                                  currency: currencyCode,
                                },
                              )}`}
                            </Text>
                          </div>
                        )}
                        {audience.paymentRateFullDay != null && audience.paymentRateFullDay > 0 && (
                          <div>
                            <Text variant="text" component="span" size="small">
                              {`${t("bookings.resources.fullDay")} - ${formatters.currency(
                                Number(audience.paymentRateFullDay),
                                i18n.language,
                                {
                                  currency: currencyCode,
                                },
                              )}`}
                            </Text>
                          </div>
                        )}
                        {audience.paymentRateHourlyAfterHours != null && audience.paymentRateHourlyAfterHours > 0 && (
                          <div>
                            <Text variant="text" component="span" size="small">
                              {`${t("bookings.resources.hourlyAfterHours")} - ${formatters.currency(
                                Number(audience.paymentRateHourlyAfterHours),
                                i18n.language,
                                {
                                  currency: currencyCode,
                                },
                              )}`}
                            </Text>
                          </div>
                        )}
                        {audience.paymentRateHourlyWeekend != null && audience.paymentRateHourlyWeekend > 0 && (
                          <div>
                            <Text variant="text" component="span" size="small">
                              {`${t("bookings.resources.hourlyWeekend")} - ${formatters.currency(
                                Number(audience.paymentRateHourlyWeekend),
                                i18n.language,
                                {
                                  currency: currencyCode,
                                },
                              )}`}
                            </Text>
                          </div>
                        )}
                      </>
                    ) : (
                      <Text variant="text" component="span" size="small">
                        {t("bookings.resources.defaultSiteAudienceRates")}
                      </Text>
                    )}
                  </td>
                  <td>
                    <RiEditLine
                      className="edit-audience-button"
                      size="16"
                      color={colors.primary}
                      cursor="pointer"
                      onClick={() => {
                        setModalIndex(i);
                        setShowModal(true);
                      }}
                    />
                  </td>
                  <td>
                    {(audience.site !== fm.values.site || numOwnerSiteAudiences > 1) && (
                      <RiCloseLine
                        size="16"
                        color={colors.danger}
                        cursor="pointer"
                        onClick={() => removeSiteAudience(i)}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table.Table>

          {fm.values.siteAudiences.length < MAX_SITE_AUDIENCES_NUMBER && (
            <Button
              type="button"
              variant="secondary"
              size="lg"
              style={{ width: "100%" }}
              onClick={() => setShowModal(true)}
            >
              {t("bookings.resources.addAnotherAudience")}
            </Button>
          )}
        </div>
      )}

      <ResourceCreateAndEditFormPermissionsModal
        showModal={showModal}
        setShowModal={setShowModal}
        modalIndex={modalIndex}
        setModalIndex={setModalIndex}
        resourceOwnerSite={fm.values.site}
        resourceBuilding={fm.values.building}
        audienceCanBeTaxExempt={(audienceValues: AudienceValues) => audienceCanBeTaxExempt(audienceValues, fm.values)}
        siteAudience={modalIndex != null ? fm.values.siteAudiences[modalIndex] : undefined}
        theOnlyDomesticAudience={
          modalIndex != null &&
          fm.values.siteAudiences[modalIndex].site === fm.values.site &&
          numOwnerSiteAudiences === 1
        }
        segmentsMap={fm.values.siteAudiences
          .filter((_, index) => index !== modalIndex)
          .map(({ site, segmentIds }) => ({
            site,
            segmentIds: segmentIds ?? [],
          }))}
        currency={getSymbolFromCurrency(currencyCode)}
        onAdd={addSiteAudience}
        onEdit={editSiteAudience}
      />

      <style jsx>{`
        .radio-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: auto;
        }
        .radio-container :global(.info) {
          text-wrap: wrap;
        }
        .site-audiences-table-container {
          display: flex;
          flex-direction: column;
          gap: ${spacers.s7};
        }
        .site-audiences-table-container :global(th),
        .site-audiences-table-container :global(td) {
          padding: ${spacers.s3};
        }
        @media screen and (max-width: ${breakpoints.md}px) {
          .site-audiences-table-container {
            gap: ${spacers.s5};
          }
        }
      `}</style>
    </>
  );
};
