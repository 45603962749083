import { BookableResourcePaymentMethod } from "../generated/gateway-client";
import type { FormValues, AudienceValues } from "./formValidation";

export type TaxExemptionStatus = { allowed: true } | { allowed: false; displayValue: boolean };

export const resourceCanBeTaxExempt = (resource: FormValues): TaxExemptionStatus => {
  // consider the full set of resource payment methods for the resource tax
  // exempt flag
  const paymentMethods = [
    ...(resource.paymentMethods ?? []),
    ...resource.siteAudiences.flatMap((sa) => sa.paymentMethods ?? []),
  ];
  if (paymentMethods.length === 0) {
    return { allowed: false, displayValue: false };
  }
  const hasTaxablePaymentMethod = paymentMethods.some((pm) => pm !== BookableResourcePaymentMethod.Credits);
  if (!hasTaxablePaymentMethod) {
    return { allowed: false, displayValue: true };
  }

  return { allowed: true };
};

export const transformResourceTaxExempt = (resource: FormValues): boolean => {
  // if we disabled the control to prevent the user from configuring the
  // resource's tax exempt status, reset it to its default value of `false` so
  // managers don't have to worry about stale resource config if they later
  // reconfigure the resource in a way that allows tax exemption
  if (!resourceCanBeTaxExempt(resource).allowed) {
    return false;
  }

  return resource.taxExempt === true;
};

export const audienceCanBeTaxExempt = (audience: AudienceValues, resource: FormValues): TaxExemptionStatus => {
  // if the resource setting is enabled then all bookings are tax exempt, so
  // don't allow per-audience config
  const resourceIsTaxExempt = resource.taxExempt === true;
  if (resourceIsTaxExempt) {
    return { allowed: false, displayValue: true };
  }

  // audience-level tax exemption only applies to the current audience's payment
  // methods
  const paymentMethods = audience.paymentMethods ?? [];
  if (paymentMethods.length === 0) {
    return { allowed: false, displayValue: false };
  }
  const hasTaxablePaymentMethod = paymentMethods.some((pm) => pm !== BookableResourcePaymentMethod.Credits);
  if (!hasTaxablePaymentMethod) {
    return { allowed: false, displayValue: true };
  }

  return { allowed: true };
};

export const transformAudienceTaxExempt = (audience: AudienceValues, resource: FormValues): boolean => {
  // if we disabled the control to prevent the user from configuring the
  // audience's tax exempt status, reset it to its default value of `false` so
  // managers don't have to worry about stale resource config if they later
  // reconfigure the resource in a way that allows audience-specific tax exemption
  if (!audienceCanBeTaxExempt(audience, resource).allowed) {
    return false;
  }

  return audience.taxExempt === true;
};
