import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { Button, useConfirmer, useToast } from "@equiem/react-admin-ui";
import { RiAddLine } from "@equiem/react-admin-ui/icons";

import { CurrentProfile, notNullOrUndefined, Site } from "@equiem/lib";
import { Formik } from "formik";
import { useRouter } from "next/router";
import * as yup from "yup";
import { Modal as ModalContext } from "../../../contexts/ModalContext";
import { CreditsAdjustmentsWidget } from "../CreditsOverview/CreditsAdjustmentsWidget";
import {
  FlexTenantStatus,
  useCreditAccountPurchaseCreditsMutation,
  useGetCreditProviderLazyQuery,
  useUserMembershipsForFlexSiteQuery,
} from "../../../generated/gateway-client";
import type { Membership, PurchaseCreditsFormInput } from "./FormValues";
import { PurchaseCreditsView } from "./PurchaseCreditsView";
import { useCreditDashboardContext } from "../context/CreditDashboardContext";
import { useCreditsSettingsAccess } from "../../../hooks/useCreditsSettingsAccess";

const validationSchema = () =>
  yup.object().shape({
    purchasableItemUuid: yup.string().required(),
    accountUuid: yup.string().required(),
    taxRateUuid: yup.string().required(),
  });

const initialValues: PurchaseCreditsFormInput = {
  accountUuid: "",
  purchasableItemUuid: "",
  taxRateUuid: "",
};

const getTaxRates = (
  locations: Array<{
    building: {
      taxes: {
        taxRates: Array<{ uuid: string; rate: number }>;
      };
    };
  }>,
): { rate: number; uuid: string } => {
  const res = { rate: 0, uuid: "" };
  // find highest rate tax. It's better to refund the money to the user it the tax is too high, than trying to charge user more because it's too low.
  locations.forEach((location) => {
    location.building.taxes.taxRates.forEach((taxRate: { rate: number; uuid: string }) => {
      if (taxRate.rate > res.rate) {
        res.rate = taxRate.rate;
        res.uuid = taxRate.uuid;
      }
    });
  });
  return res;
};

export const PurchaseCreditsWidget: React.FC = () => {
  const modal = useContext(ModalContext);
  const site = useContext(Site);
  const { profile } = useContext(CurrentProfile);
  const [showModal, setShowModal] = useState(false);
  const [memberships, setMemberships] = useState<Membership[]>([]);
  const [flexOperatorUuid, setFlexOperatorUuid] = useState("");
  const router = useRouter();
  const toast = useToast();
  const { withConfirmation } = useConfirmer();
  const { t } = useTranslation();
  const { creditStats, creditsActivity } = useCreditDashboardContext();
  const { hasCreditManagerAccess, hasFlexManagerAccess, hasFlexWorkplaceManagerAccess } = useCreditsSettingsAccess();

  const shouldWeAllowAdjustmentCredits = hasFlexManagerAccess || hasCreditManagerAccess;
  const { data: membershipsForFlexSite } = useUserMembershipsForFlexSiteQuery({
    fetchPolicy: "network-only",
    skip: !hasFlexManagerAccess && !hasFlexWorkplaceManagerAccess,
    variables: { userUuid: profile?.uuid ?? "", siteUuid: site.uuid },
  });

  const [creditProviderQuery, { data: creditProvider, error }] = useGetCreditProviderLazyQuery({
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (membershipsForFlexSite != null) {
      const membershipsFiltered: Membership[] = membershipsForFlexSite.userMembershipsForFlexSite
        .flatMap(({ flexTenant }) => {
          const tax = getTaxRates(flexTenant.locations);
          return flexTenant.creditAccount != null &&
            tax.rate !== 0 &&
            [FlexTenantStatus.Active, FlexTenantStatus.NearingExpiry].includes(flexTenant.status)
            ? {
                accountUuid: flexTenant.creditAccount.uuid,
                name: flexTenant.name,
                uuid: flexTenant.uuid,
                flexOperatorUuid: flexTenant.flexOperator.uuid,
                tax,
              }
            : [];
        })
        .filter(notNullOrUndefined);

      setMemberships(membershipsFiltered);
      // set values if there is only one membership
      if (membershipsFiltered.length === 1) {
        setFlexOperatorUuid(membershipsFiltered[0].flexOperatorUuid);
      }
    }
  }, [membershipsForFlexSite, creditProviderQuery]);

  useEffect(() => {
    if (flexOperatorUuid.length > 0) {
      void creditProviderQuery({
        variables: { providerUuid: flexOperatorUuid },
      });
    }
  }, [flexOperatorUuid, creditProviderQuery]);

  const handlePurchaseCredits = () => {
    modal.open("PurchaseCredits");
  };

  const [mutation] = useCreditAccountPurchaseCreditsMutation();

  const onClose = useCallback(() => {
    modal.close();
    setShowModal(false);
  }, [setShowModal, modal]);

  const handleSubmit = async (values: PurchaseCreditsFormInput): Promise<boolean> => {
    await mutation({
      variables: {
        input: values,
      },
    });
    const creditsToast = toast.positive(t("credits.purchaseWidget.toast.success"), {
      primaryAction: {
        title: t("credits.purchaseWidget.toast.goToActivityReport"),
        onClick: () => {
          void router.push("/credits/credits-activity");
          creditsToast.remove();
        },
      },
    });
    onClose();
    return true;
  };

  useEffect(() => {
    if (modal.activeModal === "PurchaseCredits") {
      setShowModal(true);
    }
  }, [modal.activeModal, setShowModal]);

  const confirmCancel = useCallback(
    (onConfirm?: () => void) => {
      withConfirmation({
        title: t("common.areYouSure"),
        message: t("home.widgets.inviteCancelModal"),
        confirmButtonText: t("common.yesCancel"),
        cancelButtonText: t("home.widgets.cancelNo"),
        confirmButtonVariant: "danger",
        onConfirm: () => {
          onConfirm?.();
          onClose();
        },
      })();
    },
    [onClose, t, withConfirmation],
  );

  const shouldWeAllowPurchaseCredits: boolean = useMemo(() => {
    if (error != null) {
      return false;
    }
    if (creditProvider?.creditProvider == null) {
      return memberships.length > 1;
    }
    if (!creditProvider.creditProvider.creditsPurchaseEnabled) {
      return false;
    }
    return true;
  }, [memberships, creditProvider?.creditProvider, error]);

  return (
    <div className="button-container">
      {shouldWeAllowAdjustmentCredits ? (
        <CreditsAdjustmentsWidget
          onUpdate={() => {
            creditsActivity?.refetch().catch(console.error);
            creditStats?.refetch().catch(console.error);
          }}
        />
      ) : (
        shouldWeAllowPurchaseCredits && (
          <Button variant="secondary" onClick={handlePurchaseCredits}>
            <RiAddLine size={16} />
            {t("credits.overview.addCredits")}
          </Button>
        )
      )}
      <Formik<PurchaseCreditsFormInput>
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema()}
        onSubmit={handleSubmit}
        isInitialValid={false}
      >
        <PurchaseCreditsView
          showModal={showModal}
          onClose={() => confirmCancel()}
          setFlexOperatorUuid={setFlexOperatorUuid}
          creditProvider={error != null ? null : creditProvider}
          memberships={memberships}
        />
      </Formik>
    </div>
  );
};
