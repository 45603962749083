import React, { useState } from "react";

import { stringNotEmpty } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Text } from "@equiem/react-admin-ui";
import type { IconType } from "@equiem/react-admin-ui/icons";
import { RiLoader4Line } from "@equiem/react-admin-ui/icons";

interface Props {
  title: string;
  icon: IconType;
  description: string;
  generateReportUrlFn: () => Promise<string | null | undefined>;
  disabled: boolean;
  url?: string | undefined;
  setUrl: (value: React.SetStateAction<string | undefined>) => void;
}

export const GenerateReportPlaque: React.FC<Props> = ({
  title,
  disabled,
  description,
  generateReportUrlFn,
  icon: Icon,
  url,
  setUrl,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onClick = async () => {
    if (stringNotEmpty(url)) {
      window.open(url, "_blank");
    }
    setIsLoading(true);
    const generatedUrlToReport = await generateReportUrlFn();
    if (stringNotEmpty(generatedUrlToReport)) {
      setUrl(generatedUrlToReport);
    }
    setIsLoading(false);
  };

  return (
    <>
      <div className="gen-rt-plaque">
        <div className="left-gen-rt-plaque-container">
          <div className="left-gen-rt-plaque-icon">
            <Icon size={24} />
          </div>
          <div className=".left-gen-rt-plaque-text">
            <Text
              variant="heading"
              style={{
                fontSize: "16px",
              }}
            >
              {title}
            </Text>
            <Text
              variant="text"
              style={{
                color: "rgba(102, 102, 102, 1)",
                marginTop: "4px",
                fontSize: "12px",
                margin: 0,
              }}
            >
              {description}
            </Text>
          </div>
        </div>
        <div className="right-gen-rt-plaque-container">
          <Button
            disabled={disabled || isLoading}
            variant={stringNotEmpty(url) ? "primary" : isLoading ? "ghost" : "outline"}
            onClick={() => {
              void (async () => {
                await onClick();
              })();
            }}
          >
            {stringNotEmpty(url) ? (
              t("common.download")
            ) : isLoading ? (
              <div>
                <RiLoader4Line />
                {` ${t("common.generating")}`}
              </div>
            ) : (
              t("common.generate")
            )}
          </Button>
        </div>
      </div>
      <style jsx>{`
        .gen-rt-plaque {
          border: 1px solid rgba(0, 0, 0, 0.1);
        }
        .gen-rt-plaque {
          display: flex;
          align-items: center;
        }
        .left-gen-rt-plaque-container {
          display: flex;
          align-items: center;
          margin: 16px 8px 16px 16px;
        }
        .right-gen-rt-plaque-container {
          display: flex;
          align-items: center;
          margin-right: 16px;
        }
        .left-gen-rt-plaque-icon {
          flex: "15%";
          margin-right: 10px;
        }
        .left-gen-rt-plaque-text {
          flex: "85%";
        }
      `}</style>
    </>
  );
};
