import { SubTopMenu } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import type { TabItem } from "@equiem/react-admin-ui";
import { ButtonLink, Tabs, Text } from "@equiem/react-admin-ui";
import { RiSettings3Line } from "@equiem/react-admin-ui/icons";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useMemo } from "react";
import { useCreditsSettingsAccess } from "../../hooks/useCreditsSettingsAccess";

interface Props {
  title?: string;
}

export const Menu: React.FC<Props> = () => {
  const router = useRouter();
  const { hasCreditManagerAccess, hasFlexManagerAccess } = useCreditsSettingsAccess();
  const { t } = useTranslation();
  const isSettingsVisible = hasCreditManagerAccess || hasFlexManagerAccess;

  const tabs = useMemo<TabItem[]>(
    () => [
      {
        title: t("credits.dashboard"),
        key: "/credits/dashboard",
      },
      {
        title: t("credits.activityPageTitle"),
        key: "/credits/credits-activity",
      },
    ],
    [t],
  );

  const handleTabSelect = (key: string) => {
    void router.push({
      pathname: key,
      query: router.query,
    });
  };

  return (
    <>
      <SubTopMenu className="title-container mb-0">
        <Text variant="heading" size="large">
          {t("credits.pageTitle")}
        </Text>
        {isSettingsVisible && (
          <Link href="/credits/settings" passHref legacyBehavior>
            <ButtonLink variant="ghost" size="md">
              <RiSettings3Line size={16} />
              {t("common.settings")}
            </ButtonLink>
          </Link>
        )}
      </SubTopMenu>
      <SubTopMenu btmPadding={false} topPadding={false} minHeight={false} alignItems="flex-end" sticky>
        <Tabs items={tabs} selected={router.pathname} tabLinks onSelect={handleTabSelect} />
      </SubTopMenu>
    </>
  );
};
