import type { BookableResourceEditQuery } from "../generated/gateway-client";

export interface CancellationPolicy {
  companies: string[];
  noticePeriodInMinutes: number | null;
  termsAndConditions: string | null;
}

export function getCancellationPoliciesForForm(
  resource: BookableResourceEditQuery["bookableResource"],
): CancellationPolicy[] {
  return resource.editBookingPolicies.map(({ noticePeriodInMinutes, termsAndConditions, companies }) => ({
    noticePeriodInMinutes: noticePeriodInMinutes ?? null,
    termsAndConditions: termsAndConditions ?? null,
    companies: companies.map((company) => company.uuid),
  }));
}
