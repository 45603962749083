import type { ReactNode } from "react";
import React, { useState, createContext } from "react";

export type PurchaseCreditsModal = "PurchaseCredits" | "AddCompaniesCredits";

export interface ModalContext {
  activeModal?: PurchaseCreditsModal;
  id?: string;
  options?: unknown;
  open: (type: PurchaseCreditsModal, id?: string, opt?: unknown) => void;
  close: () => void;
}

/* istanbul ignore next */
export const Modal = createContext<ModalContext>({
  open: (_type: PurchaseCreditsModal) => undefined,
  close: () => undefined,
  options: {},
});

export const ModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [activeModal, setActiveModal] = useState<PurchaseCreditsModal>();
  const [id, setId] = useState<string>();
  const [options, setOptions] = useState<unknown>();

  const open = (type: PurchaseCreditsModal, _id?: string, opt?: unknown) => {
    setActiveModal(type);
    setId(_id);
    setOptions(opt);
  };

  const close = () => {
    setActiveModal(undefined);
    setId(undefined);
  };

  return (
    <Modal.Provider
      value={{
        open,
        close,
        activeModal,
        options,
        id,
      }}
    >
      {children}
    </Modal.Provider>
  );
};
