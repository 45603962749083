import React from "react";
import { useTheme } from "@equiem/react-admin-ui";
import { CreditsSettingsMenu } from "../components/credits/CreditsSettings";
import { CompaniesCredits } from "../components/credits/CreditsSettings/CompaniesCredits";
import { CompaniesCreditsProvider } from "../components/credits/context/CompaniesCreditsContext";
import { ModalProvider } from "../contexts/ModalContext";

export const CompaniesCreditsSettingsPage = () => {
  const { colors } = useTheme();
  return (
    <>
      <ModalProvider>
        <CompaniesCreditsProvider>
          <CreditsSettingsMenu />
          <div className="credits d-flex flex-column flex-grow-1 flex-shrink-1 flex-basis-0">
            <CompaniesCredits />
          </div>
        </CompaniesCreditsProvider>
      </ModalProvider>
      <style jsx>
        {`
          .credits {
            background: ${colors.white};
          }
        `}
      </style>
    </>
  );
};
