import type { Stripe } from "@stripe/stripe-js";
import { useContext, useEffect } from "react";
import { guardStripe, stringIsEmpty, stringNotEmpty } from "@equiem/lib";
import { DateTime } from "luxon";
import type {
  BookingCreditCardInput,
  BookingCreditsInput,
  BookingInvoiceContactInput,
  BookableResourceFragmentFragment,
  BookResourceMutationMutation,
  BookingFragmentFragment,
} from "../../../generated/gateway-client";
import {
  BookableResourceAddOnType,
  BookableResourcePaymentMethod,
  PaymentIntentStatus,
  useBookResourceMutationMutation,
  useUpdateResourceBookingMutationMutation,
  BookableResourceAvailabilityCalendarDocument,
  useMultipleBookingChargesQuery,
  ValidateBookingTimesStatus,
  useSiteBookingByReferenceQuery,
  useBookingChargesLazyQuery,
  useBookMultipleResourceMutationMutation,
  ExistingBookingChargesDocument,
  MultipleBookingChargesDocument,
} from "../../../generated/gateway-client";
import { convertInputAddOnsToBookingAddOnInput } from "../libs/convertInputAddOnsToBookingAddOnInput";
import type { BookingFormValue } from "../models/BookingFormValue";
import { BookingModalInfo } from "../contexts/BookingModalInfoProvider";
import { BookingModal } from "../contexts/BookingModalContext";
import { useErrorTranslation, useServerMessageTranslation, useTranslation } from "@equiem/localisation-eq1";
import { useStripe } from "@stripe/react-stripe-js";
import { useToast } from "@equiem/react-admin-ui";
import { useLazyBookingAppointment } from "./useLazyBookingAppointment";
import { convertBookingAddOnsToInputAddOns } from "../libs/convertBookingAddOnsToInputAddOns";
import { RecurringBookingCreationLoaderModal } from "../contexts/RecurringBookingCreationLoaderModalContext";

export const queriesToRefetchOnBookingChargesChange = [ExistingBookingChargesDocument, MultipleBookingChargesDocument];

export const queriesToRefetchOnBookingChage = [
  ...queriesToRefetchOnBookingChargesChange,
  BookableResourceAvailabilityCalendarDocument,
];

const getTimeMillis = (date: string, time: string, timezone: string) =>
  DateTime.fromFormat(`${date} ${time}`, "yyyy-MM-dd HH:mm", {
    zone: timezone,
  }).toMillis();

const toInvoiceContact = (values: BookingFormValue, isHiddenCost = false): BookingInvoiceContactInput | undefined => {
  const contact = values.contact;
  if (
    contact == null ||
    !stringNotEmpty(contact.type) ||
    ((values.totalCharges == null || values.totalCharges === 0) && !isHiddenCost) ||
    values.paymentMethod !== BookableResourcePaymentMethod.Invoice
  ) {
    return undefined;
  }

  return {
    contactType: contact.type,
    contactUuid: contact.uuid,
    billingCustomerId: contact.billingCustomerId,
    email: contact.email,
    fullName: contact.fullName,
    saveDetails: contact.saveDetails ?? undefined,
  };
};

const toCreditCard = (values: BookingFormValue, isHiddenCost = false): BookingCreditCardInput | undefined => {
  if (
    values.creditcard?.paymentGatewayPaymentMethodId == null ||
    ((values.totalCharges == null || values.totalCharges === 0) && !isHiddenCost) ||
    values.paymentMethod !== BookableResourcePaymentMethod.CreditCard
  ) {
    return undefined;
  }

  return values.creditcard;
};

const toCreditsInput = (values: BookingFormValue, isHiddenCost = false): BookingCreditsInput | undefined => {
  if (
    values.creditAccount?.uuid == null ||
    ((values.totalCharges == null || values.totalCharges === 0) && !isHiddenCost) ||
    values.paymentMethod !== BookableResourcePaymentMethod.Credits
  ) {
    return undefined;
  }

  return {
    accountUuid: values.creditAccount.uuid,
  };
};

export function toPaymentInput(values: BookingFormValue, isHiddenCost = false, isUpdate = false) {
  const invoiceContact = toInvoiceContact(values, isHiddenCost);
  const creditCard = toCreditCard(values, isHiddenCost);
  const creditAccount = toCreditsInput(values, isHiddenCost);
  const paymentMethod =
    values.paymentMethod != null && (invoiceContact != null || creditCard != null || creditAccount != null)
      ? values.paymentMethod
      : undefined;

  return {
    paymentMethod,
    invoiceContact,
    creditCard,
    creditAccount: isUpdate ? undefined : creditAccount,
  };
}

export function toStartAndEndDate(values: BookingFormValue, timezone: string) {
  return {
    startDate: getTimeMillis(values.date, values.start!, timezone),
    endDate: getTimeMillis(values.date, values.end!, timezone),
  };
}

export function toGeneralBookingInput(values: BookingFormValue) {
  return {
    roomConfiguration: stringNotEmpty(values.roomConfiguration) ? values.roomConfiguration : null,
    title: stringNotEmpty(values.title) ? values.title.trim() : null,
    note: stringNotEmpty(values.note) ? values.note.trim().replace(/\n{2,}/gu, "\n") : null,
    adminNote: stringNotEmpty(values.adminNote) ? values.adminNote.trim().replace(/\n{2,}/gu, "\n") : null,
  };
}

export function toAdditionalDetailsInput(values: BookingFormValue) {
  return stringNotEmpty(values.additionalDetails?.additionalInvoiceDetails)
    ? {
        additionalDetails: {
          additionalInvoiceDetails: values.additionalDetails?.additionalInvoiceDetails,
        },
      }
    : undefined;
}

const toBookingInput = (
  timezone: string,
  resource: BookableResourceFragmentFragment,
  values: BookingFormValue,
  isHiddenCost: boolean,
) => {
  return {
    resourceUuid: resource.uuid,
    proxyBookingforUser: values.isProxyBooking ? values.host?.uuid : undefined,
    acceptTerms: values.termsAccepted,
    addOns: convertInputAddOnsToBookingAddOnInput(resource.addOns, values.addOns),
    ...toStartAndEndDate(values, timezone),
    ...toGeneralBookingInput(values),
    ...toPaymentInput(values, isHiddenCost),
    ...toAdditionalDetailsInput(values),
  };
};

// All taken statuses.
export const notAvailableStatuses = (hasSuperPower = false) =>
  hasSuperPower
    ? [ValidateBookingTimesStatus.Taken]
    : [ValidateBookingTimesStatus.Taken, ValidateBookingTimesStatus.OutOfAvailability];

// All available statuses.
export const availableStatuses = (hasSuperPower = false) =>
  hasSuperPower
    ? [ValidateBookingTimesStatus.Available, ValidateBookingTimesStatus.OutOfAvailability]
    : [ValidateBookingTimesStatus.Available];

const getAvailableValidatedDates = (values: BookingFormValue) => {
  const allowedStatues = availableStatuses(values.hasSuperPower);

  return (
    values.recurringSettings?.validatedRecurringDates.flatMap((r) =>
      allowedStatues.includes(r.status) ? { startTime: r.startTime, endTime: r.endTime } : [],
    ) ?? []
  );
};

export const useMultipleBookingCreate = () => {
  const [mutation, { loading }] = useBookMultipleResourceMutationMutation();
  const { resource, timezone, autoApprove } = useContext(BookingModalInfo);
  const { t } = useTranslation();

  const isHiddenCost = !autoApprove && resource.hiddenCost;

  return {
    loading,
    book: async (values: BookingFormValue) => {
      const dates = getAvailableValidatedDates(values);
      if (dates.length === 0) {
        throw new Error(t("common.unknownError"));
      }

      const result = await mutation({
        variables: {
          input: dates.map((d) => ({
            ...toBookingInput(timezone, resource, values, isHiddenCost),
            startDate: d.startTime,
            endDate: d.endTime,
          })),
        },
        refetchQueries: queriesToRefetchOnBookingChage,
      });

      return result.data?.bookMultipleResource;
    },
  };
};

export const useBookingCreate = () => {
  const [mutation, { loading }] = useBookResourceMutationMutation();
  const { resource, timezone, autoApprove } = useContext(BookingModalInfo);

  const isHiddenCost = !autoApprove && resource.hiddenCost;

  return {
    loading,
    book: async (values: BookingFormValue) => {
      const result = await mutation({
        variables: { input: toBookingInput(timezone, resource, values, isHiddenCost) },
        refetchQueries: queriesToRefetchOnBookingChage,
      });

      return result.data?.bookResource;
    },
  };
};

export const useBookingEdit = () => {
  const { t } = useTranslation();
  const [mutation, { loading }] = useUpdateResourceBookingMutationMutation();
  const { resource, booking, timezone, autoApprove } = useContext(BookingModalInfo);

  const isHiddenCost = !autoApprove && resource.hiddenCost;

  return {
    loading,
    book: async (values: BookingFormValue) => {
      if (booking == null || stringIsEmpty(values.start) || stringIsEmpty(values.end)) {
        throw new Error(t("bookings.operations.bookingNotFound"));
      }
      const result = await mutation({
        variables: {
          uuid: booking.uuid,
          input: {
            addOns: convertInputAddOnsToBookingAddOnInput(resource.addOns, values.addOns),
            ...toStartAndEndDate(values, timezone),
            ...toGeneralBookingInput(values),
            customPrice: values.customPrice,
            ...toPaymentInput(values, isHiddenCost, true),
            ...toAdditionalDetailsInput(values),
            resourceUuid: resource.uuid,
          },
        },
        refetchQueries: queriesToRefetchOnBookingChage,
      });

      return result.data?.updateResourceBooking;
    },
  };
};

const handleEndBookingPaymentIntentResult = async (
  result: BookResourceMutationMutation["bookResource"] | undefined | null,
  stripe: Stripe,
) => {
  if (result == null || result.__typename !== "BookingSuccessResponse") {
    return;
  }
  if (result.paymentIntent != null && result.paymentIntent.status === PaymentIntentStatus.RequiresAction) {
    const confirmation = await stripe.confirmCardPayment(result.paymentIntent.clientSecret);
    if (confirmation.error != null) {
      console.error(confirmation.error);
      throw new Error(confirmation.error.message);
    }
  }
};

const hasPendingStatus = (booking: BookingFragmentFragment) =>
  ["PENDING_APPROVAL", "PENDING_WORKPLACE_MANAGER_APPROVAL"].includes(booking.status);

export const useHandleMultipleBooking = () => {
  const { t } = useTranslation();
  const { tError } = useErrorTranslation();
  const { tServer } = useServerMessageTranslation();
  const toast = useToast();
  const modal = useContext(BookingModal);
  const loadingModal = useContext(RecurringBookingCreationLoaderModal);
  const stripe = useStripe();
  const { book, loading: submitting } = useMultipleBookingCreate();

  return async (values: BookingFormValue) => {
    try {
      if (submitting || !modal.canSubmitForm) {
        return;
      }
      modal.setCanCloseModal(false);
      modal.setCanSubmitForm(false);

      guardStripe(t, stripe);

      const dates = getAvailableValidatedDates(values);
      if (dates.length > 1) {
        loadingModal.show({ numberOfBookings: dates.length });
      }

      const min3SecondWait = async () => {
        return new Promise((resolve) => {
          // eslint-disable-next-line @typescript-eslint/no-magic-numbers
          setTimeout(resolve, 3_000);
        });
      };

      /**
       * Currently recurring bookings have some performance concerns that we don't
       * have the capacity to look into. Until performance is sorted, we're showing
       * an un-closeable loading screen. It's meant to be shown for minumum 3 seconds
       * to avoid flashing of the loading screen in case the mutation does return
       * results fast.
       *
       * Promise.all will take minimum 3 seconds to fulfill due to the min3SecondWait
       * call.
       */
      const [result] = await Promise.all([book(values), min3SecondWait()]);
      if (result == null) {
        throw new Error(t("bookings.operations.unableMakeBooking"));
      }
      if (result.__typename === "MultipleBookingFailureResponse") {
        throw new Error(tServer(result.reason));
      }

      // Handle payment intent.
      if (result.paymentIntent != null && result.paymentIntent.status === PaymentIntentStatus.RequiresAction) {
        const confirmation = await stripe.confirmCardPayment(result.paymentIntent.clientSecret);
        if (confirmation.error != null) {
          console.error(confirmation.error);
          throw new Error(confirmation.error.message);
        }
      }

      loadingModal.hide();

      toast.positive(
        result.booking.some(hasPendingStatus)
          ? t("bookings.operations.bookingsHaveBeenRequested")
          : t("bookings.operations.bookingsHaveBeenConfirmed"),
      );
      modal.close(true);
    } catch (e: unknown) {
      toast.negative(tError(e));
      loadingModal.hide();
    }
    modal.setCanCloseModal(true);
    modal.setCanSubmitForm(true);
  };
};

export const useHandleSingleBooking = () => {
  const { t } = useTranslation();
  const { tError } = useErrorTranslation();
  const { tServer } = useServerMessageTranslation();
  const { booking } = useContext(BookingModalInfo);
  const toast = useToast();
  const modal = useContext(BookingModal);
  const stripe = useStripe();
  const { goToAppointment } = useLazyBookingAppointment();
  const { book: create, loading: cLoading } = useBookingCreate();
  const { book: edit, loading: eLoading } = useBookingEdit();
  const { book, submitting } =
    booking != null ? { book: edit, submitting: eLoading } : { book: create, submitting: cLoading };

  return async (values: BookingFormValue) => {
    try {
      if (submitting || !modal.canSubmitForm) {
        return;
      }
      modal.setCanCloseModal(false);
      modal.setCanSubmitForm(false);

      guardStripe(t, stripe);
      const result = await book(values);
      if (result == null) {
        throw new Error(
          booking == null ? t("bookings.operations.unableMakeBooking") : t("bookings.operations.unableEditBooking"),
        );
      }
      if (result.__typename === "BookingFailureResponse") {
        throw new Error(tServer(result.localisedReason));
      }
      await handleEndBookingPaymentIntentResult(result, stripe);

      const isPending = hasPendingStatus(result.booking);

      if (booking != null) {
        toast.positive(t("bookings.operations.bookingReferenceUpdated", { bookingReference: booking.reference }));
      } else if (isPending) {
        toast.positive(t("bookings.operations.bookingHasBeenRequested"));
      } else {
        toast.positive(
          t("bookings.operations.bookingHasBeenConfirmed", { bookingReference: result.booking.reference }),
        );
      }

      let skipNavigation = false;
      if (!isPending && values.createAppointmentOnSubmit) {
        skipNavigation = await goToAppointment(result.booking);
      }

      modal.close(true, skipNavigation);
    } catch (e: unknown) {
      toast.negative(tError(e));
    }
    modal.setCanCloseModal(true);
    modal.setCanSubmitForm(true);
  };
};

export const useBookingFormSubmit = () => {
  const submitSingle = useHandleSingleBooking();
  const submitMultiple = useHandleMultipleBooking();

  return async (values: BookingFormValue) =>
    getAvailableValidatedDates(values).length > 1 ? submitMultiple(values) : submitSingle(values);
};

export const useBookingCharges = ({
  resource,
  timezone,
  values,
  bookingUuid,
  skip = false,
}: {
  resource: BookableResourceFragmentFragment;
  timezone: string;
  values: BookingFormValue;
  bookingUuid?: string;
  skip?: boolean;
}) => {
  // Default to displaying charges in Credits if the resource supports credits
  const paymentMethod =
    values.paymentMethod ??
    (resource.paymentMethods.includes(BookableResourcePaymentMethod.Credits)
      ? BookableResourcePaymentMethod.Credits
      : null);

  const times =
    values.recurringSettings == null
      ? [toStartAndEndDate(values, timezone)]
      : getAvailableValidatedDates(values).map((d) => ({ startDate: d.startTime, endDate: d.endTime }));

  return useMultipleBookingChargesQuery({
    fetchPolicy: "no-cache",
    variables: {
      existingBookingUuid: bookingUuid,
      input: times.map((t) => ({
        resourceUuid: resource.uuid,
        userUuid: values.host?.uuid,
        ...t,
        ...toGeneralBookingInput(values),
        ...toPaymentInput(values),
        customPrice: values.customPrice,
        paymentMethod,
        // Don't need to request for free text changes.
        addOns: convertInputAddOnsToBookingAddOnInput(resource.addOns, values.addOns, [
          BookableResourceAddOnType.FreeText,
        ]),

        // don't re-request for input fields that won't affect the booking charges
        title: null,
        note: null,
        adminNote: null,
        roomConfiguration: null,
        acceptTerms: false,
        invoiceContact: null,
        creditAccount: null,
        creditCard: null,
      })),
    },
    skip,
  });
};

export const useCancelBookingCharges = ({
  bookingReference,
  skip = false,
}: {
  bookingReference: string;
  skip?: boolean;
}) => {
  const booking = useSiteBookingByReferenceQuery({ variables: { reference: bookingReference }, skip });
  const [loadCharges, charges] = useBookingChargesLazyQuery();

  useEffect(() => {
    if (skip || booking.loading || booking.data?.siteBookingByReference == null || booking.error != null) {
      return;
    }

    const { resource, startDate, endDate, addOns } = booking.data.siteBookingByReference;

    void loadCharges({
      variables: {
        input: {
          resourceUuid: resource.uuid,
          userUuid: booking.data.siteBookingByReference.user.uuid,
          startDate,
          endDate,
          addOns: convertInputAddOnsToBookingAddOnInput(
            resource.addOns,
            convertBookingAddOnsToInputAddOns(resource.addOns, addOns),
          ),

          // don't re-request for input fields that won't affect the booking charges
          roomConfiguration: null,
          acceptTerms: false,
        },
        existingBookingUuid: booking.data.siteBookingByReference.uuid,
      },
    });
  }, [skip, booking, loadCharges]);

  return {
    data: charges.data,
    error: booking.error ?? charges.error,
    loading: booking.loading || charges.loading,
  };
};
