import { useRouter } from "next/router";
import type { ReactNode } from "react";
import React, { createContext, useEffect, useMemo, useState } from "react";

import { useDebounced, type FilterValue } from "@equiem/react-admin-ui";
import { transformToQuery } from "../components/utils/mapping";
import type { CreditTransactionFilter } from "../generated/gateway-client";

export type CreditFilterType = "date" | "user" | "type" | "datemonth" | "tenants" | "companies";
export type CreditFiltersResult = Partial<Record<CreditFilterType, FilterValue>>;

export interface CreditsFilterContext {
  filters: CreditFiltersResult;
  creditsFilters: CreditTransactionFilter;
  setFilters?: (filters: CreditFiltersResult) => void;
  search?: string;
  debouncedSearch?: string;
  setSearch?: (search: string) => void;
}
export const CreditsFilterContext = createContext<CreditsFilterContext>({
  filters: {},
  creditsFilters: {},
  setFilters: undefined,
  search: undefined,
  debouncedSearch: undefined,
  setSearch: undefined,
});

export const CreditsFilterProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const router = useRouter();
  const saveWaitTimeMs = 500;
  const searchFromQueryParams = router.query.search as string | undefined;

  const [filters, setFilters] = useState<CreditFiltersResult>({});
  const [search, setSearch] = useState<string>(searchFromQueryParams ?? "");
  const debouncedSearch = useDebounced(search, saveWaitTimeMs);
  const debouncedFilters = useDebounced(filters, saveWaitTimeMs);

  const creditsFilters = useMemo(() => {
    const transformedFilters = transformToQuery(debouncedFilters, debouncedSearch);

    return {
      ...transformedFilters,
    };
  }, [debouncedFilters, debouncedSearch]);

  useEffect(() => {
    setSearch(searchFromQueryParams ?? "");
  }, [searchFromQueryParams]);

  return (
    <CreditsFilterContext.Provider
      value={{
        filters,
        setFilters,
        search,
        debouncedSearch,
        creditsFilters,
        setSearch,
      }}
    >
      {children}
    </CreditsFilterContext.Provider>
  );
};
