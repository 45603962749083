import { stringNotEmpty, StripeProvider } from "@equiem/lib";
import React, { useContext, useEffect } from "react";
import { BookingForm } from "./BookingForm";
import { useBookableResourceQuery } from "../../../generated/gateway-client";
import { BookingModal } from "../contexts/BookingModalContext";
import { BookingModalInfoProvider } from "../contexts/BookingModalInfoProvider";
import { BookingModalContentLoading } from "./BookingModalContentLoading";
import { BookingModalContentError } from "./BookingModalContentError";
import { RecurringBookingCreationLoaderModalProvider } from "../contexts/RecurringBookingCreationLoaderModalContext";

export const BookingCreateNewModalContent: React.FC = () => {
  const modal = useContext(BookingModal);
  const uuid = modal.id;

  const { data, loading, error, refetch } = useBookableResourceQuery({
    variables: { uuid: uuid ?? "" },
    fetchPolicy: "network-only",
    skip: uuid == null,
  });

  const resource = data?.bookableResource;
  const resourceName = resource?.name;

  // Users can fetch resource details if they have ever had a booking for the
  // resource (e.g. via a proxy booking), and they should be able to edit those
  // bookings if allowed, but they still shouldn't be able to load the booking
  // *create* form (e.g. via a QR code).
  const canBookResource =
    resource?.viewerPermissions?.canManageBookings === true ||
    resource?.viewerPermissions?.isSegmentedToViewer === true;

  useEffect(() => {
    if (stringNotEmpty(resourceName)) {
      modal.setTitle(resourceName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceName]);

  if (loading) {
    return <BookingModalContentLoading />;
  }

  if (uuid == null || error != null || resource == null || !canBookResource) {
    return <BookingModalContentError error={error} canBookResource={canBookResource} />;
  }

  return (
    <BookingModalInfoProvider refetch={refetch} resource={resource}>
      <RecurringBookingCreationLoaderModalProvider>
        <StripeProvider>
          <BookingForm />
        </StripeProvider>
      </RecurringBookingCreationLoaderModalProvider>
    </BookingModalInfoProvider>
  );
};
