import { CurrentProfile } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, ProgressCircle } from "@equiem/react-admin-ui";
import { useFormikContext } from "formik";
import React, { useCallback, useContext, useMemo } from "react";
import { BookingModal } from "../contexts/BookingModalContext";
import { createPortal } from "react-dom";
import { BookingModalInfo } from "../contexts/BookingModalInfoProvider";
import type { BookingFormValue } from "../models/BookingFormValue";
import { isAutoApproveBookings } from "../libs/isAutoApproveBookings";
import { BookingFormContext } from "../contexts/BookingFormProvider";
import { BookingOriginalDetails } from "./BookingOriginalDetails";

const BackButton: React.FC = () => {
  const { t } = useTranslation();
  const { booking } = useContext(BookingModalInfo);
  const modal = useContext(BookingModal);
  const info = useContext(BookingFormContext);

  const onBack = useCallback(() => {
    if (modal.displayMode === "selfForm" && booking != null) {
      modal.setDisplayMode("view");
      return;
    }

    if (info.step === "payment") {
      info.setStep("initial");
    } else {
      modal.close();
    }
  }, [booking, info, modal]);

  if (modal.displayMode === "adminForm" && info.step === "initial") {
    return null;
  }

  return (
    <Button variant="outline" onClick={onBack}>
      {t("common.back")}
    </Button>
  );
};

const ContinueButton: React.FC = () => {
  const { t } = useTranslation();
  const { booking, resource } = useContext(BookingModalInfo);
  const { profile, canManageCurrentSiteCompany } = useContext(CurrentProfile);
  const fm = useFormikContext<BookingFormValue>();
  const modal = useContext(BookingModal);
  const info = useContext(BookingFormContext);

  const onClick = useCallback(() => {
    if (fm.values.freeBookingMode || info.step === "payment") {
      fm.submitForm().catch(console.log);
    } else {
      info.setStep("payment");
    }
  }, [fm, info]);

  const submitText = useMemo(() => {
    if (!fm.values.freeBookingMode && info.step === "initial") {
      return t("bookings.operations.goToPayment");
    }

    const isWorkplaceManagerForHost =
      canManageCurrentSiteCompany && fm.values.host?.companyUuid === profile?.companyV2?.uuid;
    const needsCreditsApproval =
      fm.values.creditAccountRequiresApproval && fm.values.paymentMethod === "Credits" && !isWorkplaceManagerForHost;

    if (booking != null) {
      return t("bookings.operations.confirmChanges");
    } else if (isAutoApproveBookings(resource, profile?.companyV2?.uuid) && !needsCreditsApproval) {
      return t("bookings.operations.confirmBooking");
    } else {
      return t("bookings.operations.requestBooking");
    }
  }, [
    booking,
    fm.values.freeBookingMode,
    info.step,
    profile?.companyV2?.uuid,
    fm.values.host?.companyUuid,
    fm.values.creditAccountRequiresApproval,
    fm.values.paymentMethod,
    canManageCurrentSiteCompany,
    resource,
    t,
  ]);

  const disabled = useMemo(() => {
    if (fm.values.freeBookingMode || info.step === "payment") {
      return !modal.canSubmitForm || Object.keys(fm.errors).length > 0 || fm.isSubmitting;
    }

    // We don't need terms and condition on first step.
    const { termsAccepted: _, ...existingErrors } = fm.errors;

    return !modal.canSubmitForm || Object.keys(existingErrors).length > 0;
  }, [fm.errors, fm.isSubmitting, fm.values.freeBookingMode, info.step, modal.canSubmitForm]);

  return (
    <Button disabled={disabled} onClick={onClick}>
      {fm.isSubmitting && <ProgressCircle size="xs" className="mr-2" />}
      {submitText}
    </Button>
  );
};

const Local: React.FC = () => (
  <>
    <BookingOriginalDetails />
    <div className="primary-actions">
      <BackButton />
      <ContinueButton />
    </div>
    <style jsx>{`
      .primary-actions {
        display: flex;
        gap: 8px;
      }
      .primary-actions :global(button) {
        width: 100%;
      }
    `}</style>
  </>
);

export const BookingFormSubmitButton: React.FC = () => {
  const modal = useContext(BookingModal);

  return modal.footerRef?.current == null ? null : createPortal(<Local />, modal.footerRef.current);
};
