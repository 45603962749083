import React, { useContext } from "react";
import { Role, Site, useRequestEnabled } from "@equiem/lib";
import {
  RiBuildingLine,
  RiCalendarCheckLine,
  RiEditLine,
  RiFileChartLine,
  RiHomeLine,
  RiQuestionnaireLine,
  RiSettings3Line,
  RiStore3Line,
  RiTeamLine,
  RiDashboardLine,
  RiWalletLine,
} from "@equiem/react-admin-ui/icons";
import { ShortcutsMenu } from "./ShortcutsMenu";
import { ShortcutItem } from "../ShortcutItem";
import { SideNav } from "@equiem/react-admin-ui";
import { useRegionSpecificAppUrls } from "../hooks/useRegionSpecificAppUrls";
import { useTranslation } from "@equiem/localisation-eq1";
import { useBookingsEnabled } from "@equiem/bookings";
import { useCreditsModuleEnabled } from "../../../lib/useCreditsModuleEnabled";

export const PropertyManagerSideMenu = () => {
  const { adminPanelApplicationLink, storeApplicationLink, reportingApplicationLink } = useRegionSpecificAppUrls();
  const showBookings = useBookingsEnabled();
  const showRequestManagement = useRequestEnabled();
  const site = useContext(Site);
  const { t } = useTranslation();
  const hasCreditModuleEnabled = useCreditsModuleEnabled();

  return (
    <>
      <SideNav isExpandable={false}>
        {site.tierLevel === "Essentials" && (
          <ShortcutItem icon={RiHomeLine} routerLink={"/"} text={t("home.sideMenu.home")} />
        )}
        <ShortcutItem icon={RiDashboardLine} routerLink={"/insights"} text={t("home.sideMenu.insights")} />
        {site.tierLevel === "Essentials" ? (
          <ShortcutItem
            icon={RiEditLine}
            routerLink="/content-management"
            text={t("home.sideMenu.contentManagement")}
          />
        ) : (
          <ShortcutItem
            icon={RiEditLine}
            link={process.env.irisApplicationLink!}
            text={t("home.sideMenu.contentManagement")}
          />
        )}
        {site.tierLevel !== "Essentials" && (
          <ShortcutItem icon={RiStore3Line} link={storeApplicationLink} text={t("home.sideMenu.vendorManagement")} />
        )}
        {showBookings && (
          <ShortcutItem icon={RiCalendarCheckLine} routerLink="/bookings" text={t("home.sideMenu.bookings")} />
        )}
        {showRequestManagement && (
          <ShortcutItem
            icon={RiQuestionnaireLine}
            routerLink="/request-management"
            text={t("home.sideMenu.requestManagement")}
          />
        )}
        {site.featureModules.visitors.enabled && (
          <ShortcutItem
            icon={RiTeamLine}
            routerLink="/visitor-management"
            text={t("home.shortcuts.visitorManagement")}
          />
        )}
        {site.tierLevel !== "Essentials" && (
          <ShortcutItem icon={RiFileChartLine} link={reportingApplicationLink} text={t("home.sideMenu.reporting")} />
        )}
        {hasCreditModuleEnabled && (
          <ShortcutItem icon={RiWalletLine} routerLink={"/credits"} text={t("home.sideMenu.credits")} />
        )}
        <ShortcutItem icon={RiSettings3Line} routerLink="/settings" text={t("common.settings")} />
        {site.tierLevel == null && (
          <ShortcutItem
            icon={RiBuildingLine}
            link={adminPanelApplicationLink}
            text={t("home.sideMenu.usersSiteSettings")}
          />
        )}
      </SideNav>
      <ShortcutsMenu currentRole={Role.PropertyManager} className="mt-5" />
    </>
  );
};
