import React, { useContext } from "react";
import { Modal, useTheme } from "@equiem/react-admin-ui";
import { BookingModal } from "../contexts/BookingModalContext";
import { BookingCreateNewModalContent } from "./BookingCreateNewModalContent";
import { BookingResourceDetails } from "./BookingResourceDetails";
import { BookingCalendarContextProvider } from "../contexts/BookingCalendarContext";

export const BookingCreateNewModal: React.FC = () => {
  const modal = useContext(BookingModal);
  const theme = useTheme(true);

  if (modal.id == null) {
    return null;
  }

  return (
    <BookingCalendarContextProvider>
      <Modal.Dialog
        focusTrapOptions={{ initialFocus: false }}
        show
        sideModal
        expansion={modal.showExpansion != null ? <BookingResourceDetails /> : undefined}
        expansionBackground={theme.colors.grayscale_5}
        key={modal.id}
        title={modal.title}
        onHide={() => modal.close()}
        hideOnEsc={true}
        hideOnClick={true}
        containerClassName="end-user-booking-modal"
        size="md"
      >
        <Modal.Header closeButton noBorder={true} onClose={() => modal.close()} />
        <Modal.Body>
          <BookingCreateNewModalContent />
        </Modal.Body>
        {modal.showFooter && (
          <Modal.Footer>
            <div className="w-100" ref={modal.footerRef}></div>
          </Modal.Footer>
        )}
      </Modal.Dialog>
    </BookingCalendarContextProvider>
  );
};
