import { Button, Dropdown, useIsMobileWidth, useTheme, useStickyElement } from "@equiem/react-admin-ui";
import type { FC } from "react";
import React, { useContext } from "react";
import {
  RiDashboard3Line,
  RiMailLine,
  RiQuestionLine,
  RiVideoLine,
  RiArrowLeftLine,
} from "@equiem/react-admin-ui/icons";
import { GradientContext, MainContainer, Role, CurrentRole, TopMenuBar, useSiteContext } from "@equiem/lib";
import { MyProfileModalProvider } from "@equiem/settings";
import { useTranslation } from "@equiem/localisation-eq1";
import { NotificationDropdown } from "../notifications/NotificationDropdown";
import { Breadcrumbs as BreadcrumbsContext } from "../../contexts/BreadcrumbsContext";
import { Breadcrumbs } from "./Breadcrumbs";
import { HamburgerButton } from "./HamburgerMenu";
import { Greeting } from "./layouts/legacy/Greeting";
import { TopMenuDropdown } from "./TopMenuDropdown";

interface Props {
  AdditionalMenu?: FC;
  toggleMenu: () => void;
}

const ButtonBack: FC<{ variant: "secondary" | "ghost"; onClick: () => void }> = ({ variant, onClick }) => (
  <div style={{ paddingRight: 15 }}>
    <Button type="button" size="sm" round className="button-back mr-1" variant={variant} onClick={onClick}>
      <RiArrowLeftLine size={20} />
    </Button>
  </div>
);

export const TopMenu: FC<Props> = ({ toggleMenu }) => {
  const isMobile = useIsMobileWidth();
  const { currentRole } = useContext(CurrentRole);
  const { colors, topMenuHeight } = useTheme();
  const gradientContext = useContext(GradientContext);
  const breadcrumbsContext = useContext(BreadcrumbsContext);
  const { tierLevel } = useSiteContext();
  const { ref, top, isStuck } = useStickyElement<HTMLDivElement>();
  const showGradient = gradientContext.showGradient && !isStuck;
  const { t } = useTranslation();

  return (
    <div
      ref={ref}
      className="top-menu"
      style={{
        background: showGradient ? undefined : colors.white,
        borderBottom: isStuck ? "1px solid #E6E6E6" : "1px solid transparent",
        position: "sticky",
        top,
        transition: isStuck ? "background 200ms, border-bottom 400ms" : "background 200ms",
        zIndex: 1000,
        height: isStuck ? topMenuHeight : topMenuHeight - 1,
      }}
    >
      <GradientContext.Provider value={{ ...gradientContext, showGradient }}>
        <MyProfileModalProvider>
          <MainContainer>
            <TopMenuBar
              style={{
                padding: 0,
                color: showGradient ? undefined : colors.dark,
              }}
            >
              {isMobile && <HamburgerButton toggleMenu={toggleMenu} />}
              {gradientContext.showGradient && isStuck && (
                <Greeting
                  style={{
                    fontSize: "13px",
                    textTransform: "uppercase",
                    color: "#666",
                    width: "100%",
                  }}
                />
              )}
              {!gradientContext.showGradient && !isMobile && (
                <>
                  <ButtonBack
                    variant={showGradient ? "secondary" : "ghost"}
                    onClick={() => breadcrumbsContext.back()}
                  />
                  <Breadcrumbs />
                </>
              )}
              <div className="d-flex align-items-center justify-content-end w-100">
                {currentRole !== Role.Unknown && (
                  <>
                    {process.env.equiemEssentialsEnabled !== "true" && tierLevel !== "Essentials" && (
                      <div>
                        <Dropdown.Icon icon={RiQuestionLine} placement="bottom-end" className="mr-2">
                          <Dropdown.Header>{t("home.helpSupport")}</Dropdown.Header>
                          {currentRole === Role.WorkplaceManager && (
                            <>
                              <Dropdown.Link
                                href="mailto:support@getequiem.com?subject=Report a problem on Equiem One for Workplace Managers"
                                target="_blank"
                                rel="noreferrer"
                                icon={RiQuestionLine}
                              >
                                {t("home.equiemSupport")}
                              </Dropdown.Link>
                              <Dropdown.Link
                                href="mailto:feedback.equiemone@getequiem.com?subject=Provide feedback about Equiem One for Workplace Managers"
                                target="_blank"
                                rel="noreferrer"
                                icon={RiMailLine}
                              >
                                {t("home.feedback")}
                              </Dropdown.Link>
                              <Dropdown.Link
                                href="https://equip.getequiem.com/c/5cbbad5ddcf46d9ee16aabc9eca665a4d0ef8fd2"
                                target="_blank"
                                rel="noreferrer"
                                icon={RiVideoLine}
                              >
                                {t("home.learning")}
                              </Dropdown.Link>
                            </>
                          )}
                          {currentRole === Role.PropertyManager && (
                            <>
                              <Dropdown.Link
                                href="mailto:support@getequiem.com?subject=Report a problem on Equiem One for Property Managers"
                                target="_blank"
                                rel="noreferrer"
                                icon={RiQuestionLine}
                              >
                                {t("home.equiemSupport")}
                              </Dropdown.Link>
                              <Dropdown.Link
                                href="mailto:feedback.equiemone@getequiem.com?subject=Provide feedback about Equiem One for Property Managers"
                                target="_blank"
                                rel="noreferrer"
                                icon={RiMailLine}
                              >
                                {t("home.feedback")}
                              </Dropdown.Link>
                              <Dropdown.Link
                                href="https://equip.getequiem.com/c/0299a7c31955875c141749a40910b3b3d882f4b2"
                                target="_blank"
                                rel="noreferrer"
                                icon={RiVideoLine}
                              >
                                {t("home.equiemOneLearning")}
                              </Dropdown.Link>
                              <Dropdown.Link
                                href="https://equip.getequiem.com/catalog"
                                target="_blank"
                                rel="noreferrer"
                                icon={RiVideoLine}
                              >
                                {t("home.allLearning")}
                              </Dropdown.Link>
                              <Dropdown.Link
                                href="https://status.getequiem.com/"
                                target="_blank"
                                rel="noreferrer"
                                icon={RiDashboard3Line}
                              >
                                {t("home.platformStatus")}
                              </Dropdown.Link>
                            </>
                          )}
                        </Dropdown.Icon>
                      </div>
                    )}
                    <div className="mr-2">
                      <NotificationDropdown />
                    </div>
                  </>
                )}
                <TopMenuDropdown />
              </div>
            </TopMenuBar>
          </MainContainer>
        </MyProfileModalProvider>
      </GradientContext.Provider>
    </div>
  );
};
