import { CurrentProfile, useViewerTokens, Role, Site } from "@equiem/lib";
import { Shortcuts as TrendingContentShortcuts } from "../../../widgets/trending_content_widget/Shortcuts";
import { Shortcuts as ManageContentShortcuts } from "../../../widgets/manage_content_widget/Shortcuts";
import { Shortcuts as ManageUsersShortcuts } from "../../../widgets/manage_users_widget/Shortcuts";
import { Shortcuts as WelcomeUsersShortcuts } from "../../../widgets/welcome_widget/Shortcuts";
import { Shortcuts as CreateBookableResourceShortcuts } from "@equiem/bookings";
import { Shortcuts as CreateVisitorAppointment } from "@equiem/visitors";
import type { ComponentProps, FC, SyntheticEvent } from "react";
import { useContext, useMemo, useState } from "react";
import type { ShortcutItemProps } from "../ShortcutItem";
import { ShortcutItem } from "../ShortcutItem";
import { Dropdown, Modal, Tooltip, useTheme } from "@equiem/react-admin-ui";
import {
  RiArrowDownSLine,
  RiArrowUpSLine,
  RiAddCircleLine,
  RiLogoutCircleRLine,
  RiCloseFill,
  RiStore3Line,
} from "@equiem/react-admin-ui/icons";
import { ShortcutModal } from "./ShortcutModal";
import { dynamicIcon } from "../dynamicIcon";
import type { ShortcutInput } from "../../../generated/gateway-client";
import { ShortcutDeleteModal } from "./ShortcutDeleteModal";
import { useTranslation } from "@equiem/localisation-eq1";
import { useRegionSpecificAppUrls } from "../hooks/useRegionSpecificAppUrls";

interface Props extends ComponentProps<"div"> {
  currentRole: Role;
}

const ActionButton: FC<{ shortcuts: ShortcutInput[]; i: number }> = ({ shortcuts, i }) => {
  const [showModal, setShowModal] = useState(false);
  const { colors } = useTheme();
  const { t } = useTranslation();

  const handleClick = (e: SyntheticEvent) => {
    e.preventDefault();
    setShowModal(true);
  };
  return (
    <>
      <span className={`nav-switch shortcut-${i}`} onClick={handleClick}>
        <RiCloseFill size={14} />
      </span>
      <Modal.Dialog
        title={t("home.shortcuts.deleteShortcut")}
        show={showModal}
        onHide={() => setShowModal(false)}
        withFooter={true}
        hideOnEsc={true}
        size="sm"
        className="shortcutmodal"
      >
        <ShortcutDeleteModal shortcuts={shortcuts} close={() => setShowModal(false)} i={i} />
      </Modal.Dialog>
      <style jsx>{`
        .nav-switch {
          cursor: pointer;
          border-radius: 50%;
          padding: 2px;
        }
        .nav-switch:hover {
          background: ${colors.grayscale[10]};
          color: ${colors.grayscale[60]};
        }
      `}</style>
    </>
  );
};

export const ShortcutsMenu: FC<Props> = ({ currentRole }) => {
  const site = useContext(Site);
  const { storeApplicationLink } = useRegionSpecificAppUrls();
  const { siteProfile } = useContext(CurrentProfile);
  const { colors, spacers } = useTheme();
  const [open, setOpen] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const viewerTokens = useViewerTokens();
  const { t } = useTranslation();

  const existingShortcuts = useMemo(() => siteProfile?.equiemOneShortcuts ?? [], [siteProfile]);

  const currentRoleShortcuts = useMemo(() => {
    return existingShortcuts.filter((sc) => sc.role === currentRole);
  }, [existingShortcuts, currentRole]);

  const personalShortcuts = useMemo((): ShortcutItemProps[] => {
    const addProtocol = (url: string) => (!/^(?:f|ht)tps?:\/\//u.test(url) ? `//${url}` : url);

    return currentRoleShortcuts.map((sc, i) => {
      const isUrlAbsolute = sc.url.indexOf("://") > 0 || sc.url.startsWith("//") || sc.url.startsWith("www.");
      const replacedUrl = viewerTokens(sc.url);

      return {
        link: isUrlAbsolute ? addProtocol(replacedUrl) : undefined,
        routerLink: isUrlAbsolute ? undefined : replacedUrl,
        icon: dynamicIcon(sc.icon),
        text: sc.title,
        actionButton: <ActionButton shortcuts={currentRoleShortcuts} i={i} />,
      };
    });
  }, [currentRoleShortcuts, viewerTokens]);

  const shortcuts: ShortcutItemProps[] =
    site.tierLevel !== "Essentials"
      ? [
          ...ManageUsersShortcuts(site.name, currentRole),
          ...WelcomeUsersShortcuts(site.name, currentRole),
          ...TrendingContentShortcuts(currentRole),
          ...ManageContentShortcuts(currentRole),
          ...CreateBookableResourceShortcuts(currentRole),
          ...CreateVisitorAppointment(currentRole),
          ...personalShortcuts,
        ]
      : [...WelcomeUsersShortcuts(site.name, currentRole), ...personalShortcuts];

  const handleDropdownClick = () => {
    setOpen(!open);
  };

  const handleAddClick = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <Dropdown.Dropdown>
        <div className="title">
          <h2>{t("home.shortcuts.myShortcuts")}</h2>
          <div className="d-flex">
            <Tooltip title={t("home.shortcuts.addYourOwnShortcut")} placement="left">
              <div className="nav-switch add-shortcut" onClick={handleAddClick}>
                <RiAddCircleLine />
              </div>
            </Tooltip>
            {shortcuts.length > 0 && (
              <Tooltip title={t("home.shortcuts.collapseShortcuts")}>
                <div className="nav-switch" onClick={handleDropdownClick}>
                  {open ? <RiArrowUpSLine /> : <RiArrowDownSLine />}
                </div>
              </Tooltip>
            )}
          </div>
        </div>
        {open && (
          <div>
            {site.tierLevel !== "Essentials" ? (
              <>
                <ShortcutItem
                  icon={RiLogoutCircleRLine}
                  text={t("home.shortcuts.goTo", { siteName: site.name })}
                  link={`https://${site.webAppUrl}`}
                />
                {currentRole === Role.WorkplaceManager ? (
                  <ShortcutItem
                    link={storeApplicationLink}
                    text={t("home.sideMenu.goToStoreManagement")}
                    icon={RiStore3Line}
                  />
                ) : null}
              </>
            ) : null}
            {shortcuts.map((shortcut, index) => (
              <ShortcutItem
                icon={shortcut.icon}
                text={shortcut.text}
                onClick={shortcut.onClick}
                link={shortcut.link}
                routerLink={shortcut.routerLink}
                key={index}
                actionButton={shortcut.actionButton}
              />
            ))}
          </div>
        )}
      </Dropdown.Dropdown>
      <Modal.Dialog
        title={t("home.shortcuts.createShortcut")}
        show={showModal}
        onHide={handleAddClick}
        hideOnEsc={true}
        size="md"
        className="shortcutmodal"
        scrollable
        centered
      >
        <ShortcutModal close={handleAddClick} shortcuts={currentRoleShortcuts} />
      </Modal.Dialog>
      <style jsx>{`
        .title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: ${colors.medium};
          margin: ${spacers.s2} ${spacers.s3};
        }
        .nav-switch {
          height: 24px;
          width: 24px;
          display: flex;
          border-radius: 50%;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
        .nav-switch:hover {
          background: ${colors.grayscale[5]};
          color: ${colors.grayscale[60]};
        }
        h2 {
          text-transform: uppercase;
          font-weight: 500;
          font-size: 10px;
          line-height: 16px;
          margin: 0;
        }
      `}</style>
    </>
  );
};
