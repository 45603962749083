import { useState } from "react";

import { notNullOrUndefined, stringNotEmpty } from "@equiem/lib";
import { useDebounced } from "@equiem/react-admin-ui";

import type { MyBookingsQueryVariables } from "../generated/gateway-client";
import { useMyBookingsQuery } from "../generated/gateway-client";

type Variables = Omit<MyBookingsQueryVariables, "page">;

const SEARCH_TEXT_DEBOUNCE_MS = 500;
export const DEFAULT_PAGE_SIZE = 24;

export function usePagedMyBookings(variables: Variables, pageSize = DEFAULT_PAGE_SIZE) {
  const [searchTextRaw, setSearchTextRaw] = useState("");
  const searchTextDebounced = useDebounced(searchTextRaw.trim(), SEARCH_TEXT_DEBOUNCE_MS);
  const searchText = stringNotEmpty(searchTextDebounced) ? searchTextDebounced : null;

  const result = useMyBookingsQuery({
    variables: {
      ...variables,
      filters: {
        ...(variables.filters ?? {}),
        searchText,
      },
      page: { first: pageSize },
    },
    fetchPolicy: "network-only",
  });
  const [loadingMore, setLoadingMore] = useState(false);

  const hasMoreData = result.data?.myBookingsList.pageInfo.hasNextPage ?? false;
  const endCursor = result.data?.myBookingsList.pageInfo.endCursor;

  const bookings = (result.data?.myBookingsList.edges ?? []).map((edge) => edge.node).filter(notNullOrUndefined);

  const fetchMore = () => {
    if (result.loading || loadingMore) {
      return;
    }

    setLoadingMore(true);

    result
      .fetchMore({
        variables: {
          ...variables,
          filters: { ...(variables.filters ?? {}), searchText },
          page: {
            first: pageSize,
            after: endCursor,
          },
        },
        updateQuery(prev, { fetchMoreResult }) {
          return {
            ...fetchMoreResult,
            myBookingsList: {
              ...fetchMoreResult.myBookingsList,
              edges: [...prev.myBookingsList.edges, ...fetchMoreResult.myBookingsList.edges],
            },
          };
        },
      })
      .catch((e) => console.error(e))
      .finally(() => setLoadingMore(false));
  };

  return {
    bookings,
    error: result.error,

    searchTextRaw,
    setSearchTextRaw,

    searchText,

    loading: result.loading,
    loadingMore,
    hasMoreData,

    fetchMore,
  };
}
