import React, { useContext, useEffect, useMemo, useState } from "react";
import { Field, Form, useFormikContext } from "formik";

import { CurrentProfile, useShowError, useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Alert, Button, Form as EqForm, Material, Modal, useTheme } from "@equiem/react-admin-ui";
import { RiErrorWarningLine } from "@equiem/react-admin-ui/icons";

import { useCompaniesV2LazyQuery, useQueuesQuery } from "../generated/requests-client";
import { useCategoryData } from "../pages/settings/category/hooks/useCategoryData";

import type { CategoryFormValues, CategoryPreset } from "./utils/categoryFormTypes";
import { BuildingsSelect } from "./BuildingsSelect";
import { CreatableSelect } from "./CreatableSelect";
import { Subcategories } from "./Subcategories";

interface Props {
  showTypeField: boolean;
  selectedPreset?: CategoryPreset;
  onNameChange?: (newName: string) => void;
  onCloseModal: () => void;
}

export const CategoryCreateForm: React.FC<Props> = ({ showTypeField, selectedPreset, onNameChange, onCloseModal }) => {
  const {
    buildings,
    typeOptions,
    presets,
    createCategoryLoading,
    subCategories,
    handleSubcategoriesActions,
    refreshCategoriesList,
    selectedCategoryUuid,
  } = useCategoryData();
  const showError = useShowError();
  const [currentStep, setCurrentStep] = useState<number>(1);
  const { t } = useTranslation();
  const { values, touched, errors, setFieldValue, dirty, isValid, submitForm, isSubmitting, setTouched } =
    useFormikContext<CategoryFormValues>();
  const { canManageRegion, profile } = useContext(CurrentProfile);
  const { uuid } = useSiteContext();
  const { colors } = useTheme();
  const [currentSubcategories, setCurrentSubcategories] = useState<undefined | Array<{ name: string; uuid: string }>>(
    undefined,
  );

  const [companiesQuery, companiesQueryData] = useCompaniesV2LazyQuery({
    variables: {
      first: 1000,
      destinationUuid: uuid,
    },
  });
  const ownerCompanyUuid = useMemo(
    () => (canManageRegion ? values.ownerCompanyUuid : profile?.companyV2?.uuid),
    [canManageRegion, values.ownerCompanyUuid, profile?.companyV2?.uuid],
  );
  const { data: queuesData } = useQueuesQuery({
    variables: {
      ownerCompanyUuid,
    },
    skip: ownerCompanyUuid == null,
  });
  const queues = useMemo(() => queuesData?.reqMgt.queues ?? [], [queuesData]);
  const companies = useMemo(() => companiesQueryData.data?.companiesV2.edges ?? [], [companiesQueryData]);

  useEffect(() => {
    if (queues != null) {
      setFieldValue("queueUuid", queues.length === 1 ? queues[0].uuid : undefined).catch(console.error);
    }
  }, [setFieldValue, queues]);

  useEffect(() => {
    if (selectedCategoryUuid != null) {
      setCurrentSubcategories(subCategories);
    }
  }, [selectedCategoryUuid, subCategories]);

  useEffect(() => {
    onNameChange?.(values.name);
  }, [onNameChange, values.name]);

  useEffect(() => {
    if (values.name !== "" && touched.iconName == null) {
      // setTimeout fix flickering error message issue when user picks a preset category.
      setTimeout(() => {
        setTouched({ ...touched, iconName: true }).catch(console.error);
      }, 300);
    }
  }, [values.name, touched, setTouched, selectedPreset]);

  useEffect(() => {
    if (canManageRegion) {
      companiesQuery().catch((e) => {
        console.error(e);
      });
    }
    const iconName = selectedPreset?.iconName ?? "";
    setFieldValue("iconName", iconName).catch(console.error);
  }, [selectedPreset?.iconName, setFieldValue, companiesQuery, canManageRegion]);

  const renderProgressBar = useMemo(() => {
    return (
      <>
        <div>
          <div className="step">{t("requests.category.step", { step: currentStep })}</div>
          <div className="title">{t(`requests.category.stepTitle${currentStep}` as keyof typeof t)}</div>
        </div>
        <div className="progress-bar">
          {Array.from({ length: 2 }).map((_, index) => {
            return <div key={index} className={`progress-bar-inner ${currentStep > index ? "filled" : ""}`} />;
          })}
        </div>
        <style jsx>{`
          .step {
            font-size: 12px;
            line-height: 16px;
            font-weight: 500;
            color: ${colors.grayscale[60]};
            text-transform: uppercase;
          }
          .title {
            font-size: 20px;
            line-height: 24px;
            font-weight: 700;
          }
          .progress-bar {
            display: flex;
            justify-content: space-between;
            margin: 16px 0 24px;
          }
          .progress-bar-inner {
            flex-grow: 1;
            margin: 0 2px;
            height: 4px;
            background-color: ${colors.grayscale[5]};
          }
          .filled {
            background-color: ${colors.primary};
          }
        `}</style>
      </>
    );
  }, [currentStep, colors, t]);

  const renderSteps = useMemo(() => {
    if (currentStep === 2) {
      return (
        <Subcategories
          isEditing={true}
          currentSubcategories={currentSubcategories}
          setCurrentSubcategories={setCurrentSubcategories}
        />
      );
    }
    return (
      <>
        <Form>
          <div className="d-flex">
            <div className="flex-grow-0 iconNameGroup">
              <EqForm.Group
                label={t("requests.icon")}
                required
                error={touched.iconName != null && errors.iconName != null ? errors.iconName : undefined}
              >
                <Material.Provider>
                  <Field
                    name="iconName"
                    as={Material.Selector}
                    size="lg"
                    selectedIcon={values.iconName !== "" ? values.iconName : undefined}
                    onSelect={async (name: string) => setFieldValue("iconName", name)}
                    className="icon-type"
                    error={errors.iconName}
                  />
                </Material.Provider>
              </EqForm.Group>
            </div>
            <div className="flex-grow-1 pl-5">
              <EqForm.Group
                label={t("requests.category.categoryName")}
                required
                error={touched.name != null && errors.name != null ? errors.name : undefined}
              >
                <Field
                  name="name"
                  value={values.name}
                  as={CreatableSelect}
                  items={presets.map((preset) => ({
                    value: preset.categoryName,
                  }))}
                />
              </EqForm.Group>
            </div>
          </div>
          {showTypeField ? (
            <div>
              <EqForm.Group
                label={t("requests.category.categoryTypeLabel")}
                required
                error={touched.type != null && errors.type != null ? errors.type : undefined}
              >
                <Field
                  name="type"
                  as={EqForm.DynamicSelect}
                  options={typeOptions}
                  noneLabel={t("requests.category.categoryTypePlaceholder")}
                />
              </EqForm.Group>
            </div>
          ) : null}
          <div>
            <EqForm.Group
              label={t("common.buildings")}
              required
              onChange={() => {
                if (touched.buildings == null) {
                  void setTouched({ ...touched, buildings: true }).catch(console.error);
                }
              }}
              error={touched.buildings != null && errors.buildings != null ? errors.buildings.toString() : undefined}
            >
              <Field
                name="buildings"
                as={BuildingsSelect}
                buildings={buildings}
                noneLabel={t("requests.category.buildingsPlaceholder")}
              />
            </EqForm.Group>
          </div>
          {canManageRegion && (
            <EqForm.Group
              label={t("requests.category.owner")}
              required
              error={
                touched.ownerCompanyUuid != null && errors.ownerCompanyUuid != null
                  ? t("requests.category.ownerPlaceholder")
                  : undefined
              }
            >
              <Field id="ownerCompanyUuid" name="ownerCompanyUuid" as={EqForm.Select}>
                <option value="">{t("requests.category.ownerPlaceholder")}</option>
                {companies.flatMap(({ node }) => (
                  <option key={node?.name} value={node?.uuid}>
                    {node?.name}
                  </option>
                ))}
              </Field>
            </EqForm.Group>
          )}
          <div className="separator">
            <Alert
              size="large"
              variant="gray"
              icon={<RiErrorWarningLine size={18} color={colors.grayscale[50]} />}
              message={t("requests.category.queueDescription")}
            />
          </div>
          <EqForm.Group
            label={t("requests.category.queue")}
            required
            error={
              touched.ownerCompanyUuid != null && errors.ownerCompanyUuid != null
                ? t("requests.category.selectQueue")
                : undefined
            }
          >
            <Field id="queueUuid" name="queueUuid" as={EqForm.Select} disabled={queues.length === 0}>
              <option value="">{t("requests.category.selectQueue")}</option>
              {queues.map((queue) => (
                <option key={queue.name} value={queue.uuid}>
                  {queue.name}
                </option>
              ))}
            </Field>
          </EqForm.Group>
        </Form>
        <style jsx>{`
          .separator {
            border-top: 1px solid ${colors.grayscale[10]};
            padding: 24px 0;
          }
          .iconNameGroup :global(p) {
            margin-right: -10rem;
            margin-bottom: -1rem;
          }
        `}</style>
      </>
    );
  }, [
    currentStep,
    currentSubcategories,
    values,
    buildings,
    typeOptions,
    showTypeField,
    presets,
    companies,
    canManageRegion,
    touched,
    errors,
    queues,
    isValid,
    dirty,
    setTouched,
    setFieldValue,
    t,
  ]);

  return (
    <>
      <Modal.Body>
        {renderProgressBar}
        {renderSteps}
      </Modal.Body>
      <Modal.Footer>
        <div className="primary-actions">
          <Button
            variant="secondary"
            disabled={isSubmitting}
            onClick={() => {
              if (currentStep > 1) {
                setCurrentStep((prev) => prev - 1);
                return;
              }
              onCloseModal();
            }}
          >
            {currentStep > 1 ? t("common.back") : t("common.cancel")}
          </Button>
          <Button
            variant="primary"
            disabled={!dirty || !isValid || isSubmitting || createCategoryLoading}
            onClick={() => {
              if (currentStep < 2) {
                setCurrentStep((prev) => prev + 1);
                return;
              }
              submitForm()
                .then(async (newCategoryId) => {
                  if (currentSubcategories !== undefined) {
                    await handleSubcategoriesActions(currentSubcategories, newCategoryId as unknown as string);
                    setCurrentSubcategories(undefined);
                  } else {
                    await refreshCategoriesList({ categoryUuid: newCategoryId as unknown as string });
                  }
                })
                .catch(showError);
            }}
          >
            {t(`requests.category.nextStepButton${currentStep}` as keyof typeof t)}
          </Button>
        </div>
      </Modal.Footer>
      <style jsx>{`
        .primary-actions {
          display: flex;
          gap: 8px;
          width: 100%;
        }
        .primary-actions :global(> button) {
          width: 50%;
        }
      `}</style>
    </>
  );
};
