import React, { useContext } from "react";

import { CurrentProfile } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Avatar } from "@equiem/react-admin-ui";

import { HumanTag } from "./HumanTag";

interface P {
  profile?: {
    uuid: string;
    firstName?: string | null;
    lastName?: string | null;
    avatar?: string | null;
  } | null;
  size?: string | number;
  isAssigneeRemoved?: boolean;
}
export const AssigneeTag: React.FC<P> = ({ profile, size, isAssigneeRemoved = false }) => {
  const { t } = useTranslation();
  const me = useContext(CurrentProfile);

  return profile == null ? (
    <HumanTag size={size} text={t("requests.unassigned")} />
  ) : (
    <div className="assignee">
      <Avatar size="small" imageUrl={profile.avatar} firstName={profile.firstName} lastName={profile.lastName} />
      <span className="ml-2">
        {profile.uuid === me.profile?.uuid ? (
          <>{t("common.me")}</>
        ) : (
          <div className="assignee-name">
            {profile.firstName} {profile.lastName}
            <span>{isAssigneeRemoved && ` (${t("activity.removed")})`}</span>
          </div>
        )}
      </span>
      <style jsx>{`
        .assignee-name span {
          text-transform: capitalize;
        }
      `}</style>
    </div>
  );
};
