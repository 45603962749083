import React, { useContext, useMemo } from "react";
import { useTheme, Alert } from "@equiem/react-admin-ui";
import { RiErrorWarningLine } from "@equiem/react-admin-ui/icons";
import { useTranslation } from "@equiem/localisation-eq1";

import type { BookingFragmentFragment } from "../../../generated/gateway-client";
import { BookingModalInfo } from "../contexts/BookingModalInfoProvider";
import { flatExistingBookingAddOns } from "../libs/groupExistingBookingAddOns";
import { resourceConfigurationToString } from "../../../lib/resourceTypeToString";
import { BookingFormContext } from "../contexts/BookingFormProvider";

const Details: React.FC<{ booking: BookingFragmentFragment }> = ({ booking }) => {
  const { spacers } = useTheme();
  const { t } = useTranslation();

  const addOns = useMemo(
    () =>
      flatExistingBookingAddOns(booking.addOns, t, true).map((ao) => ({
        key: ao.uuid,
        label: `${ao.quantity > 1 ? `${ao.quantity} x ` : ""} ${ao.description}`,
        title: ao.fullDescription,
      })),
    [booking.addOns, t],
  );

  return (
    <>
      <div className="booking-original-details">
        <div>{t("bookings.operations.originalBookingSelections")}</div>
        {booking.roomConfig != null && (
          <div>
            <strong>{t("bookings.operations.configuration")}:</strong>{" "}
            {resourceConfigurationToString(booking.roomConfig.name, t)}
          </div>
        )}
        {addOns.length > 0 && (
          <>
            <div title={addOns.length === 1 ? addOns[0].title ?? undefined : undefined}>
              <strong>{t("bookings.resources.addOns")}:</strong> {addOns.length === 1 && addOns[0].label}
            </div>
            {addOns.length > 1 &&
              addOns.map(({ key, label, title }) => (
                <div key={key} title={title}>
                  {label}
                </div>
              ))}
          </>
        )}
      </div>
      <style jsx>{`
        .booking-original-details {
          display: flex;
          flex-direction: column;
          gap: ${spacers.s3};
          max-height: 25vh;
          overflow-y: auto;
        }
      `}</style>
    </>
  );
};

export const BookingOriginalDetails: React.FC = () => {
  const { colors } = useTheme();
  const { resourceHasBeenChanged, booking } = useContext(BookingModalInfo);

  const info = useContext(BookingFormContext);
  if (info.step !== "initial") {
    return null;
  }

  const hasResourceConfig = booking != null && (booking.roomConfig != null || booking.addOns.length > 0);
  if (!resourceHasBeenChanged || booking == null || !hasResourceConfig) {
    return null;
  }

  return (
    <Alert
      className="mb-3"
      size="large"
      variant="gray"
      icon={<RiErrorWarningLine size={18} color={colors.grayscale[50]} />}
      message={<Details booking={booking} />}
    />
  );
};
