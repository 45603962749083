import type { ReactNode } from "react";
import React, { useEffect, useState, type FC } from "react";
import type { BuildingWithUniqueId } from "./FlexTenantLocationContext";
import { FlexTenantLocationContext } from "./FlexTenantLocationContext";

interface Props {
  children: ReactNode;
  defaultLocations?: BuildingWithUniqueId[];
}

export const FlexTenantLocationProvider: FC<Props> = ({ children, defaultLocations = [] }) => {
  const [locations, setLocations] = useState<BuildingWithUniqueId[]>(defaultLocations);
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    if (locations !== defaultLocations) {
      setHasChanged(true);
    }
  }, [locations, defaultLocations]);

  const clearLocations = () => {
    setLocations([]);
    setHasChanged(true);
  };

  return (
    <>
      <FlexTenantLocationContext.Provider
        value={{
          locations,
          setLocations,
          clearLocations,
          hasChanged,
        }}
      >
        {children}
      </FlexTenantLocationContext.Provider>
    </>
  );
};
