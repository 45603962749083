import { useState } from "react";
import type { CreditAccountEdge } from "../../../generated/gateway-client";
import { type CreditAccount, useGetMyManagedCreditAccountsQuery } from "../../../generated/gateway-client";
import { notNullOrUndefined } from "@equiem/lib";

const DEFAULT_PAGE_SIZE = 1000;

export const useManagedCompaniesCreditsRequests = (first = DEFAULT_PAGE_SIZE) => {
  const [loadingMore, setLoadingMore] = useState(false);
  const result = useGetMyManagedCreditAccountsQuery({
    fetchPolicy: "network-only",
  });

  const fetchMoreAsync = async (latestData = result.data) =>
    result.fetchMore({
      variables: {
        first,
        after: latestData?.myManagedCreditAccounts.pageInfo.endCursor,
      },
      updateQuery(prev, { fetchMoreResult }) {
        return {
          ...fetchMoreResult,
          myManagedCreditAccounts: {
            ...fetchMoreResult.myManagedCreditAccounts,
            edges: [
              ...prev.myManagedCreditAccounts.edges,
              ...fetchMoreResult.myManagedCreditAccounts.edges,
            ] as CreditAccountEdge[],
          },
        };
      },
    });

  const fetchMore = () => {
    if (result.loading || loadingMore) {
      return;
    }

    setLoadingMore(true);
    fetchMoreAsync()
      .catch((e) => console.error(e))
      .finally(() => setLoadingMore(false));
  };

  return {
    data: (result.data?.myManagedCreditAccounts.edges.map((edge) => edge.node).filter(notNullOrUndefined) ??
      []) as CreditAccount[],
    hasMoreData: result.data?.myManagedCreditAccounts.pageInfo.hasNextPage ?? false,
    error: result.error,
    loading: result.loading || loadingMore,
    fetchMore,
    refetch: async () => result.refetch(),
    totalCount: result.data?.myManagedCreditAccounts.totalCount ?? 0,
  };
};
