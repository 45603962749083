import { useContext, useMemo, useState } from "react";
import { uniqBy } from "lodash";

import { CurrentRole, notNullOrUndefined, Role, sortByAlphabet, useSiteContext } from "@equiem/lib";

import { useDestinationBuildingsAccessQuery, useQueuesListQuery } from "../../../generated/requests-client";

export enum ForcedRole {
  RequestManager = "REQUEST_MANAGER",
}

export interface RequestsAccess {
  setForcedRole?: (role: ForcedRole | undefined) => void;
  buildings: Array<{ uuid: string; name: string }>;
  propertyManager: boolean;
  workplaceManager: boolean;
  requestManager: boolean;
  hasActiveQueueForRequestManager: boolean;
  requestAssignee: boolean;
  showAssignee: boolean;
  canReportRequests: boolean;
}

export function useRequestBuildingPermission() {
  const { uuid } = useSiteContext();
  const { currentRole } = useContext(CurrentRole);
  const [forcedRole, setForcedRole] = useState<ForcedRole | undefined>(undefined);
  const { data, loading, error } = useDestinationBuildingsAccessQuery({
    variables: { uuid },
  });
  const { data: queuesForUserData } = useQueuesListQuery({
    fetchPolicy: "cache-and-network",
  });
  const isRequestManagerFromQueue = useMemo(
    () => queuesForUserData?.reqMgt.queues.some((queue) => queue.viewerRelations.requestManager) ?? false,
    [queuesForUserData],
  );
  const hasActiveQueueForRequestManager = useMemo(() => {
    return (
      queuesForUserData?.reqMgt.queues.some((queue) => queue.viewerRelations.requestManager && queue.active) ?? false
    );
  }, [queuesForUserData]);

  const isRequestAssigneeFromQueue = useMemo(
    () => queuesForUserData?.reqMgt.queues.some((queue) => queue.viewerRelations.requestAssignee) ?? false,
    [queuesForUserData],
  );
  const queuesBuildings = useMemo(
    () =>
      sortByAlphabet(
        uniqBy(
          queuesForUserData?.reqMgt.queues
            .flatMap((queue) => {
              if (queue.viewerRelations.requestManager || queue.viewerRelations.requestAssignee) {
                return queue.categories.flatMap((category) =>
                  category.buildings.flatMap((building) => ({
                    uuid: building?.uuid ?? "",
                    name: building?.name ?? "",
                  })),
                );
              }
              return null;
            })
            .filter(notNullOrUndefined),
          "uuid",
        ),
        "name",
      ),

    [queuesForUserData],
  );

  const accessInfo = useMemo<RequestsAccess | null>(() => {
    if (data?.destination == null) {
      return null;
    }

    const buildings = data.destination.buildings ?? [];

    const access = {
      propertyManager: currentRole === Role.PropertyManager,
      workplaceManager: currentRole === Role.WorkplaceManager,
      requestManager: buildings.some((b) => b.viewerRelations.requestManager) || isRequestManagerFromQueue,
      requestAssignee: buildings.some((b) => b.viewerRelations.requestAssignee) || isRequestAssigneeFromQueue,
      hasActiveQueueForRequestManager,
      canReportRequests: data.destination.viewerRelations.company.canReportRequests,
    };

    const info = {
      ...access,
      showAssignee: access.propertyManager || (!access.workplaceManager && access.requestManager),
    };

    if (forcedRole === ForcedRole.RequestManager) {
      return {
        buildings: buildings.filter((b) => b.viewerRelations.requestManager || b.viewerRelations.requestAssignee),
        ...info,
      };
    }

    if (access.propertyManager || access.workplaceManager) {
      return { buildings: buildings, ...info };
    }

    if (isRequestManagerFromQueue || isRequestAssigneeFromQueue) {
      return {
        buildings: queuesBuildings,
        ...info,
      };
    }

    if (access.requestManager || access.requestAssignee) {
      return {
        buildings: buildings.filter((b) => b.viewerRelations.requestManager || b.viewerRelations.requestAssignee),
        ...info,
      };
    }

    return { buildings: [], ...info };
  }, [
    currentRole,
    data?.destination,
    isRequestManagerFromQueue,
    forcedRole,
    isRequestAssigneeFromQueue,
    queuesBuildings,
    hasActiveQueueForRequestManager,
  ]);

  return {
    loading,
    error,
    accessInfo,
    setForcedRole,
  };
}
