import { useFormikContext } from "formik";
import type { BookingFormValue } from "../../models/BookingFormValue";
import { BookableResourcePaymentMethod } from "../../../../generated/gateway-client";
import { Form, remainingCharacters } from "@equiem/react-admin-ui";
import { useTranslation } from "@equiem/localisation-eq1";
import React, { useContext } from "react";
import { BookingChargesContext } from "../../contexts/BookingChargesProvider";
import { BookingModalInfo } from "../../contexts/BookingModalInfoProvider";

const MAX_ADDITIONAL_INVOICE_DETAILS_LENGTH = 25;

export const BookingAdditionalDetails: React.FC = () => {
  const fm = useFormikContext<BookingFormValue>();
  const { t } = useTranslation();
  const { resource, autoApprove } = useContext(BookingModalInfo);
  const { charges, totalCharges } = useContext(BookingChargesContext);

  const additionalDetailsError = fm.touched.additionalDetails === true ? fm.errors.additionalDetails : undefined;

  if (
    fm.values.paymentMethod !== BookableResourcePaymentMethod.Invoice ||
    fm.values.freeBookingMode ||
    ((totalCharges === 0 || charges.length === 0) && !(!autoApprove && resource.hiddenCost))
  ) {
    if (fm.values.additionalDetails != null) {
      fm.setFieldValue("additionalDetails", undefined).catch(console.error);
    }
    return null;
  }

  return (
    <>
      <Form.Group
        error={additionalDetailsError}
        label={t("bookings.operations.additionalInvoiceDetails")}
        showTooltip={true}
        tooltipText={t("bookings.operations.additionalInvoiceDetailsTooltip")}
        hint={t("common.remainingCharacters", {
          count: remainingCharacters(
            MAX_ADDITIONAL_INVOICE_DETAILS_LENGTH,
            fm.values.additionalDetails?.additionalInvoiceDetails?.length ?? 0,
          ),
        })}
      >
        <Form.Input
          name="title"
          value={fm.values.additionalDetails?.additionalInvoiceDetails ?? undefined}
          maxLength={25}
          onChange={(e) => {
            fm.setFieldValue("additionalDetails", { additionalInvoiceDetails: e.target.value }).catch(console.error);
            fm.setFieldTouched("additionalDetails").catch(console.error);
          }}
        />
      </Form.Group>
    </>
  );
};
