/* eslint-disable @typescript-eslint/no-explicit-any */
import { DateTime } from "luxon";
import * as yup from "yup";

import { stringIsEmpty, stringNotEmpty } from "@equiem/lib";
import type { TFunction } from "@equiem/localisation-eq1";

import type { IVideo } from "../components/VideoLinkInputNew";

import { convertInputNumberToNumber } from "./convertNumberStringToNumber";
import type { FormValues } from "./formValidation";

export type SegmentMapItem = {
  site: string;
  segmentIds: string[];
};

export const validationSchema = (t: TFunction) =>
  yup.object<{ [key in keyof FormValues]: any }>().shape<Partial<{ [key in keyof FormValues]: any }>>({
    title: yup
      .string()
      .required()
      .min(2, t("bookings.lib.titleMinimumLength", { length: 2 })),
    steps: yup.number(),
    availability: yup.array().when("steps", {
      is: (val: number) => val >= 2,
      then: yup.array().min(1, t("bookings.lib.provideAvailability")),
    }),
    externalSyncEmailAddress: yup.string().email(t("bookings.lib.mustBeValidEmail")).nullable(),
  });

export const resourceCreateAndEditFormvalidationSchema = (t: TFunction) =>
  yup.object<{ [key in keyof FormValues]: any }>().shape<Partial<{ [key in keyof FormValues]: any }>>({
    title: yup
      .string()
      .required()
      .min(2, t("bookings.lib.titleMinimumLength", { length: 2 })),
    site: yup.string().uuid().required(),
    building: yup.string().uuid().required(),
    availability: yup.array().min(1, t("bookings.lib.provideAvailability")),
    externalSyncEmailAddress: yup.string().email(t("bookings.lib.mustBeValidEmail")).nullable(),
    ownerCompanyUuid: yup
      .string()
      .uuid(t("bookings.resources.invalidCompany"))
      .required(t("bookings.resources.invalidCompany")),
  });

export const galleryModalValidationSchema = (t: TFunction) =>
  yup
    .object()
    .shape({
      images: yup.array(),
      video: yup
        .mixed<IVideo | null>()
        .nullable()
        .test({
          name: "thumbnailUrl",
          message: t("bookings.resources.videoLinkError"),
          test: function (value) {
            if (value != null && stringIsEmpty(value.thumbnailUrl)) {
              return false;
            }
            return true;
          },
        }),
    })
    .test({
      name: "atLeastOneImageOrVideoRequired",
      test: function (values) {
        const { images, video } = values;
        if (images?.length === 0 && video == null) {
          return this.createError({
            message: t("bookings.resources.galleryModalError"),
            path: "images | video",
          });
        }
        return true;
      },
    });

export const availabilityModalValidationSchema = (t: TFunction) =>
  yup.object().shape({
    availability: yup
      .object()
      .shape({
        days: yup.array().min(1, t("bookings.lib.requiredMinimumWeek")),
        durationInMinutes: yup.number().when("isFullSession", {
          is: false,
          then: yup.number().required(t("bookings.lib.pleaseSpecifyDuration")),
          otherwise: yup.number(),
        }),
      })
      .test({
        name: "startBeforeEnd",
        test: function (value) {
          if (stringNotEmpty(value.start) && stringNotEmpty(value.end)) {
            const startLx = DateTime.fromFormat(value.start, "kk:mm");
            const endLx = DateTime.fromFormat(value.end, "kk:mm");
            if (startLx >= endLx) {
              return this.createError({
                message: t("bookings.lib.startBeforeEndTime"),
                path: "availability.start",
              });
            }
          }
          return true;
        },
      })
      .test({
        name: "minimumLessThanMaximum",
        test: function (value) {
          const minTime = convertInputNumberToNumber(value.minTimeInMinutes);
          const maxTime = convertInputNumberToNumber(value.maxTimeInMinutes);
          if (minTime != null && maxTime != null && minTime >= maxTime) {
            return this.createError({
              message: t("bookings.lib.minimumTimeMustBeLess"),
              path: "availability.minTimeInMinutes",
            });
          }
          return true;
        },
      }),
  });

export const extrasModalValidationSchema = (t: TFunction) =>
  yup.object().shape({
    extras: yup.object().shape({
      name: yup.string().required(t("common.validation.required", { path: t("bookings.resources.extrasTitle") })),
      options: yup
        .array()
        .nullable()
        .of(
          yup.object().shape({
            name: yup.string().required(t("common.validation.required", { path: t("bookings.resources.extrasName") })),
          }),
        ),
    }),
  });

export const permissionsModalValidationSchema = (segmentsMap: SegmentMapItem[], t: TFunction) =>
  yup.object().shape({
    siteAudience: yup.object().shape({
      site: yup.string().required(t("common.validation.required", { path: t("common.site") })),
      segmentIds: yup
        .array()
        .nullable()
        .test("unique-segments", t("bookings.resources.uniqueSiteAudiencesError"), function (value?: string[] | null) {
          const { site } = this.parent as { site: string };
          const siteSegments = segmentsMap.filter((item) => item.site === site);

          if (siteSegments.length === 0 || value == null) {
            return true;
          }
          if (value.length === 0 && siteSegments.some((segment) => segment.segmentIds.length === 0)) {
            return false;
          }

          const noIntersection =
            siteSegments.every((segment) => segment.segmentIds.every((segmentId) => !value.includes(segmentId))) &&
            value.every((segmentId) => siteSegments.every((segment) => !segment.segmentIds.includes(segmentId)));

          return noIntersection;
        }),
    }),
  });

export const cancellationPolicyModalValidationSchema = (t: TFunction) =>
  yup.object().shape({
    editBookingPolicy: yup.object().shape({
      companies: yup.array().when("$defaultPolicy", {
        is: false,
        then: (schema) =>
          schema
            .min(1, t("bookings.resources.appliesToValidation"))
            .required(t("common.validation.required", { path: t("bookings.resources.appliesToValidation") })),
        otherwise: (schema) => schema.notRequired(),
      }),
    }),
  });

export const cancellationRateModalValidationSchema = (
  mergeCompaniesByMinutesBefore: Record<number, Set<string | null>>,
  t: TFunction,
) =>
  yup
    .object()
    .shape({
      defaultRate: yup.boolean().required(),
      paymentRateCancellation: yup.object().shape({
        amount: yup
          .number()
          .required(t("common.validation.required", { path: t("common.amount") }))
          .typeError(t("common.mustBeNumber"))
          .positive(t("common.mustBeGreaterThanZero")),
        minutesBefore: yup
          .number()
          .required(t("common.validation.required", { path: t("bookings.resources.noticePeriod") }))
          .typeError(t("common.mustBeNumber"))
          .positive(t("common.mustBeGreaterThanZero")),
        companies: yup.array().when("$defaultRate", {
          is: false,
          then: (schema) =>
            schema
              .min(1, t("bookings.resources.appliesToValidation"))
              .required(t("common.validation.required", { path: t("bookings.resources.appliesToValidation") })),
          otherwise: (schema) => schema.notRequired(),
        }),
      }),
    })
    .test({
      name: "unique-minutes-before",
      message: t("bookings.resources.uniqueCancellationRatesError"),
      test: function (value) {
        const defaultRate = value.defaultRate;
        const minutesBefore = value.paymentRateCancellation.minutesBefore;

        if (defaultRate != null && minutesBefore != null) {
          const companies = value.paymentRateCancellation.companies ?? [];
          const companySet = mergeCompaniesByMinutesBefore[minutesBefore];
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          if (companySet == null) {
            return true;
          }

          if (defaultRate ? companySet.has(null) : companies.some((company) => companySet.has(company))) {
            return this.createError({
              message: t("bookings.resources.uniqueCancellationRatesError"),
              path: "paymentRateCancellation",
            });
          }
        }

        return true;
      },
    });
