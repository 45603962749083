import React, { useContext, useEffect, useState } from "react";
import { Card, Dropdown, useTheme } from "@equiem/react-admin-ui";
import { OverviewHeader } from "./OverviewHeader";
import { OverviewContainerTitle } from "./OverviewContainerTitle";
import { OverviewWidgets } from "./OverviewWidgets";
import { Modal as ModalContext } from "../../../contexts/ModalContext";
import { CurrentRole, Role, stringIsEmpty } from "@equiem/lib";
import { useCreditDashboardContext } from "../context/CreditDashboardContext";
import { AllMembershipFilter } from "./AllMembershipFilter";
import { useRoleMapping } from "../hooks/useRoleMapping";
import { CreditTransactionsQueryRole } from "../../../generated/gateway-client";
import { AllCompaniesFilter } from "./AllCompaniesFilter";

export const CreditsOverview: React.FC<unknown> = () => {
  const { colors } = useTheme();
  const modal = useContext(ModalContext);
  const { currentRole } = useContext(CurrentRole);
  const role = useRoleMapping();
  const isFlexManager = currentRole === Role.FlexManager;
  const isCreditManager = role === CreditTransactionsQueryRole.CreditManager;
  const [wasPurchaseCreditsModalOpened, setWasPurchaseCreditsModalOpened] = useState(false);
  const {
    currentMonth,
    creditStats,
    tenants,
    months,
    setCurrentMonth,
    flexTenants,
    myManagedCreditAccounts,
    setCurrentCompanies,
    companies,
    setCurrentTenant,
  } = useCreditDashboardContext();
  const { loading, data: creditStatsData, refetch } = creditStats ?? {};
  const { fetchMore, variables } = flexTenants ?? {};
  const [loadingMore, setLoadingMore] = useState(false);

  const isLoading = flexTenants?.loading === true || loadingMore;

  const handleNextPage = () => {
    if (isLoading || flexTenants?.data?.myFlexTenants.pageInfo.hasNextPage !== true) {
      return;
    }

    setLoadingMore(true);
    fetchMore?.({
      variables: {
        ...variables,
        after: flexTenants.data.myFlexTenants.pageInfo.endCursor,
      },
      updateQuery(prev, { fetchMoreResult }) {
        return {
          ...fetchMoreResult,
          myFlexTenants: {
            ...fetchMoreResult.myFlexTenants,
            edges: [...prev.myFlexTenants.edges, ...fetchMoreResult.myFlexTenants.edges],
          },
        };
      },
    }).finally(() => {
      setLoadingMore(false);
    });
  };

  const handleMyManagedCreditAccountsNextPage = () => {
    if (isLoading || myManagedCreditAccounts?.data?.myManagedCreditAccounts.pageInfo.hasNextPage !== true) {
      return;
    }

    setLoadingMore(true);
    myManagedCreditAccounts
      .fetchMore({
        variables: {
          ...myManagedCreditAccounts.variables,
          after: myManagedCreditAccounts.data.myManagedCreditAccounts.pageInfo.endCursor,
        },
        updateQuery(prev, { fetchMoreResult }) {
          return {
            ...fetchMoreResult,
            myManagedCreditAccounts: {
              ...fetchMoreResult.myManagedCreditAccounts,
              edges: [...prev.myManagedCreditAccounts.edges, ...fetchMoreResult.myManagedCreditAccounts.edges],
            },
          };
        },
      })
      .finally(() => {
        setLoadingMore(false);
      });
  };

  const handleSearch = (search: string) => {
    setLoadingMore(true);
    flexTenants
      ?.refetch({ ...variables, search: stringIsEmpty(search) ? undefined : search })
      .finally(() => {
        setLoadingMore(false);
      })
      .catch(console.error);
  };

  useEffect(() => {
    // This code refresh dashboard widgets on modal close action because the purchase could have been made.
    if (modal.activeModal === "PurchaseCredits") {
      setWasPurchaseCreditsModalOpened(true);
    } else {
      setWasPurchaseCreditsModalOpened(false);
    }
    if (refetch != null && modal.activeModal === undefined && wasPurchaseCreditsModalOpened) {
      void refetch();
    }
  }, [modal.activeModal, refetch, wasPurchaseCreditsModalOpened]);

  return (
    <Card.Card
      style={{
        border: "1px solid #e6e6e6",
        padding: "1rem 0",
      }}
      className="credits-overview"
    >
      <OverviewHeader />
      <OverviewContainerTitle />
      <div className="separator"></div>
      <div className="content">
        <div className="container">
          <div className="filters">
            <div className="dropdowns">
              <Dropdown.Button size="sm" title={currentMonth?.label} variant="ghost">
                {months.map((item) => (
                  <Dropdown.Item
                    key={item.label}
                    onClick={() => setCurrentMonth(item)}
                    selected={currentMonth?.value === item.value}
                  >
                    {item.label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Button>
              {isFlexManager && (
                <AllMembershipFilter
                  handleNext={handleNextPage}
                  onChange={(option: Array<{ label: string; value: string }>) =>
                    setCurrentTenant(option.map(({ value }) => value))
                  }
                  options={tenants}
                  hasNextPage={flexTenants?.data?.myFlexTenants.pageInfo.hasNextPage ?? false}
                  loading={isLoading}
                  onSearch={handleSearch}
                />
              )}
              {isCreditManager && !isFlexManager && (
                <AllCompaniesFilter
                  handleNext={handleMyManagedCreditAccountsNextPage}
                  onChange={(option: Array<{ label: string; value: string }>) =>
                    setCurrentCompanies(option.map(({ value }) => value))
                  }
                  options={companies}
                  hasNextPage={myManagedCreditAccounts?.data?.myManagedCreditAccounts.pageInfo.hasNextPage ?? false}
                  loading={isLoading}
                />
              )}
            </div>
          </div>
          <div className="scroll-container">
            <div className="stats">
              <OverviewWidgets loading={loading === true} creditStats={creditStatsData} currentMonth={currentMonth} />
            </div>
          </div>
        </div>
      </div>
      <style jsx>
        {`
          .separator {
            height: 1px;
            width: 100%;
            background: ${colors.grayscale[10]};
            margin: 0.75rem 0 1rem 0;
          }

          .empty {
            flex-grow: 1;
          }

          .container {
            display: flex;
            flex-direction: column;
            padding-bottom: 0;
            align-items: flex-start;
            overflow-x: hidden;
          }

          .filters {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
          }

          .dropdowns {
            display: flex;
            align-items: center;
            gap: 0.25rem;
          }

          .stats {
            margin-top: 0.5rem;
            display: grid;
            grid-template-columns: repeat(2, minmax(200px, 1fr));
            grid-gap: 0.5rem;
            width: 100%;
          }

          .scroll-container {
            overflow-x: auto;
            width: 100%;
            scroll-snap-type: x mandatory;
          }

          .scroll-container::-webkit-scrollbar {
            display: none;
          }

          .content {
            padding: 0 16px;
          }
        `}
      </style>
    </Card.Card>
  );
};
