import type { MyBookingChargesQuery, ExistingBookingChargesQuery } from "../../../generated/gateway-client";

type Booking = NonNullable<
  MyBookingChargesQuery["myBookingByReference"] | ExistingBookingChargesQuery["siteBookingByReference"]
>;

export const shouldShowCancellationFeeBreakdown = (info: Booking) =>
  info.status === "CANCELLED" &&
  ((info.cancellationPrice ?? 0) > 0 ||
    (info.cancellationTaxes?.length ?? 0) > 0 ||
    (info.cancellationTotalPrice ?? 0) > 0 ||
    info.taxes.length > 0); // still show full breakdown if the booking originally had taxes charged

export const shouldShowRefundAmount = (info: Booking) =>
  info.status === "CANCELLED" && (info.paymentMethod === "Credits" || info.paymentMethod === "CreditCard");
