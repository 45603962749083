import { formatters, useTranslation } from "@equiem/localisation-eq1";
import type { FlexTenantFragmentFragment } from "../../generated/gateway-client";
import {
  useExportViewerCompanyFlexTenantsMutation,
  useMyCompaniesFlexTenantsQuery,
} from "../../generated/gateway-client";
import type { FilterValueOptions } from "@equiem/react-admin-ui";
import { Avatar, Button, Dropdown, ProgressCircle, Table, Tooltip, useToast } from "@equiem/react-admin-ui";
import type { FC } from "react";
import React, { useContext, useMemo } from "react";
import { DateTime } from "luxon";
import { SideModalContext } from "../../contexts/SideModalContext";
import { StatusTag } from "../StatusTag";
import { RiFileDownloadLine, RiMoreLine } from "@equiem/react-admin-ui/icons";

const BlockTd: React.FC<{ tenant: FlexTenantFragmentFragment }> = ({ tenant }) => {
  const { openTab } = useContext(SideModalContext);
  const { t, i18n } = useTranslation();
  const uniqueBuildings = [...new Set(tenant.locations.map((l) => l.building.name))];

  const moveInDate = useMemo(
    () => formatters.dateshort(DateTime.fromMillis(tenant.moveInDate), i18n.language),
    [tenant, i18n.language],
  );

  const expiryDate = useMemo(
    () => formatters.dateshort(DateTime.fromMillis(tenant.expirationDate), i18n.language),
    [tenant, i18n.language],
  );

  return (
    <tr>
      <td className="d-flex align-items-center">
        <div>
          <Avatar firstName={tenant.name} className="mr-4" />
        </div>
      </td>
      <td>{tenant.name}</td>
      <td>{uniqueBuildings.join(", ")}</td>
      <td>
        {tenant.membershipCount} / {tenant.memberLimit}
      </td>
      <td>{tenant.monthlyCreditAllowance}</td>
      <td>{moveInDate}</td>
      <td>{expiryDate}</td>
      <td>
        <StatusTag status={tenant.status} />
      </td>
      <td>
        <Dropdown.Icon size="sm" placement="right-end" icon={RiMoreLine} className="action-menu">
          <Dropdown.Item onClick={() => openTab("viewFlexTenant", tenant)}>{t("common.quickView")}</Dropdown.Item>
        </Dropdown.Icon>
      </td>
    </tr>
  );
};

export type Filters = Record<string, FilterValueOptions>;

export const MembershipsTable: FC = () => {
  const { t } = useTranslation();
  const { data, loading } = useMyCompaniesFlexTenantsQuery({});

  const tenants = data?.myCompaniesFlexTenants ?? [];
  const toaster = useToast();
  const [exportViewerCompanyFlexTenantsMutation, { loading: exportLoading }] =
    useExportViewerCompanyFlexTenantsMutation();

  return (
    <>
      <section className="top-head">
        <Tooltip title={t("flex.csv.csvExport")} placement="bottom">
          <Button
            variant="ghost"
            round
            disabled={exportLoading}
            onClick={() => {
              exportViewerCompanyFlexTenantsMutation()
                .then(() => {
                  toaster.positive(t("flex.csv.csvExportSuccess"), { autoDismiss: true });
                })
                .catch(console.error);
            }}
            className="export-csv"
          >
            {exportLoading ? <ProgressCircle size={16} /> : <RiFileDownloadLine size={16} />}
          </Button>
        </Tooltip>
      </section>
      <Table.Table className="w-100 mt-2">
        <thead className="table-head">
          <tr>
            <Table.Header label={t("flex.members.form.company")} />
            <Table.Header label={t("flex.members.form.membershipName")} />
            <Table.Header label={t("flex.members.form.buildings")} />
            <Table.Header label={t("flex.members.form.teamSize")} />
            <Table.Header label={t("flex.members.form.creditBalance")} />
            <Table.Header label={t("flex.members.form.moveInDate")} />
            <Table.Header label={t("flex.members.form.expirationDate")} />
            <Table.Header label={t("flex.members.form.status")} />
            <Table.Header label="" />
          </tr>
        </thead>
        <tbody>
          {loading && (
            <tr>
              <td colSpan={8}>
                <div className="mt-6 mb-6 d-flex justify-content-center align-items-center">
                  <ProgressCircle size="md" />
                  <span className="ml-4">{`${t("common.loading")}...`}</span>
                </div>
              </td>
            </tr>
          )}
          {tenants.length === 0 && !loading && (
            <tr>
              <td colSpan={8}>
                <div className="mt-6 mb-6 d-flex justify-content-center align-items-center">
                  <span className="ml-4">{`${t("common.noResults")}...`}</span>
                </div>
              </td>
            </tr>
          )}
          {tenants.map((edge, i) => (
            <BlockTd key={i} tenant={edge} />
          ))}
        </tbody>
      </Table.Table>
      <style jsx>
        {`
          .top-head {
            display: flex;
            justify-content: flex-end;
          }
        `}
      </style>
    </>
  );
};
