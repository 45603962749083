import type { ReactNode } from "react";
import React from "react";

import { Tooltip } from "@equiem/react-admin-ui";

export const UserNotificationFieldTooltipWrapper = ({ title, children }: { title: string; children: ReactNode }) => (
  <div className="mb-4">
    <Tooltip placement="bottom-end" title={title}>
      <div>{children}</div>
    </Tooltip>
  </div>
);
