import { useCallback, useRef, useState } from "react";
import axios, { type AxiosProgressEvent } from "axios";
import { fileToImageUrl } from "./fileToImageUrl";
import { IFileV2 } from "./IFileV2";

const defaultWidth = 500;
const defaultHeight = 280;

interface LocalAttachedFile {
  file?: File;
  img?: string;
}

export function useUploaderState(
  value: IFileV2[],
  onChange: (files: IFileV2[]) => void,
  createPresignedUrl: () => Promise<{
    temporaryUuid: string;
    signedUrl: string;
  } | undefined>,
  multipleUpload = false,
  allowImages = true,
) {
  const [progress, setProgress] = useState<number>(0);
  const [dragover, setDragover] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [imgHeight, setImgHeight] = useState(defaultHeight);
  const [imgWidth, setImgWidth] = useState(defaultWidth);
  const cropperRef = useRef<HTMLImageElement>(null);
  const [localAttachedFile, setLocalAttachedFile] = useState<LocalAttachedFile>();
  const [localCroppedImage, setLocalCroppedImage] = useState<string>();
  const [attachedFiles, setAttachedFiles] = useState<IFileV2[]>(value ?? []);

  const onCrop = useCallback(() => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    const canvas = cropper.getCroppedCanvas();

    setImgWidth(canvas.width);
    setImgHeight(canvas.height);
    setLocalCroppedImage(canvas.toDataURL());
  }, [cropperRef, setLocalCroppedImage, setImgWidth, setImgHeight]);

  const onSaveCropped = async (file?: LocalAttachedFile) => {
    readAndUpload(file);
  };

  const clearCropper = () => {
    setLocalAttachedFile(undefined);
    setLocalCroppedImage(undefined);
  };

  const readAndUpload = async (localFile?: LocalAttachedFile) => {
    if (!localAttachedFile && !localFile) {
      return;
    }

    try {
      setProgress(3);
      setUploading(true);
      const presignedUrl = await createPresignedUrl();
      const blob = await (await fetch(localCroppedImage as any)).blob();
      const result = await axios.put(presignedUrl?.signedUrl!, localFile?.file ?? blob, {
        onUploadProgress: (e: AxiosProgressEvent) => {
          const p = Math.ceil((e.loaded / (e.total ?? 1)) * 100);
          setProgress(p);

          if (p === 100) {
            setTimeout(() => {
              setProgress(0);
            }, 750);
          }
        },
      });

      if (result.status === 200) {
        const newFile: IFileV2 = {
          value: {
            filename: localFile?.file?.name ?? localAttachedFile?.file?.name ?? "Unknown",
          },
          temporaryUuid: presignedUrl?.temporaryUuid,
          temporaryImage: localCroppedImage,
        };
        clearCropper();
        const newFiles = multipleUpload
          ? [...attachedFiles, newFile]
          : [newFile];
          setAttachedFiles(newFiles);
        onChange(newFiles);
      } else {
        throw new Error(`Upload error: ${result.statusText}`);
      }
    } finally {
      setUploading(false);
      setProgress(0);
    }
  };

  const sortOrder = useCallback(
    (fromIndex: number, toIndex: number) => {
      const newArray = [...attachedFiles];
      const startIndex =
        fromIndex < 0 ? newArray.length + fromIndex : fromIndex;

      if (startIndex >= 0 && startIndex < newArray.length) {
        const endIndex = toIndex < 0 ? newArray.length + toIndex : toIndex;

        const [item] = newArray.splice(fromIndex, 1);
        newArray.splice(endIndex, 0, item);

        setAttachedFiles(newArray);
        onChange(newArray);
      }
    },
    [attachedFiles]
  );

  const onFileChange = async (files: FileList) => {
    if (files.length !== 0) {
      if (!files[0].type.includes("image") || !allowImages) {
        setLocalAttachedFile({ file: files[0] });
        await onSaveCropped({ file: files[0] });
        return;
      }

      const img = (await fileToImageUrl(files[0])) as string;
      setLocalAttachedFile({ file: files[0], img });
    }
  };

  const removeImage = useCallback(
    (imageKey: number) => {
      const newImages = attachedFiles.filter((_img, ix) => ix !== imageKey);
      setAttachedFiles(newImages);
      onChange(newImages);
    },
    [attachedFiles]
  );

  const removeImages = () => {
    onChange([
      {
        temporaryUuid: undefined,
        temporaryImage: undefined,
        key: undefined,
        value: undefined,
      },
    ]);
    setAttachedFiles([]);
  };

  return {
    localAttachedFile,
    attachedFiles,
    dragover,
    uploading,
    setDragover,
    onFileChange,
    progress,
    onCrop,
    onSaveCropped,
    cropperRef,
    clearCropper,
    sortOrder,
    removeImage,
    removeImages,
    setAttachedFiles,
    imgHeight,
    imgWidth,
  };
}
