import type { FC } from "react";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { Menu } from "./Menu";
import { SubTopMenu, notNullOrUndefined, stringNotEmpty } from "@equiem/lib";
import type { FilterItem, FilterValue, FilterValueOptions } from "@equiem/react-admin-ui";
import {
  Button,
  ComplexFilter,
  Form,
  ProgressCircle,
  Tooltip,
  useDebounced,
  useTheme,
  useToast,
} from "@equiem/react-admin-ui";
import { useTranslation } from "@equiem/localisation-eq1";
import { SideModalContext } from "../../contexts/SideModalContext";
import {
  RiAddLine,
  RiBarcodeBoxLine,
  RiBuilding4Line,
  RiFileDownloadLine,
  RiHomeLine,
  RiSearchLine,
  RiTeamLine,
} from "@equiem/react-admin-ui/icons";
import {
  FlexTenantStatus,
  useExportMyFlexTenantsMutation,
  useMyFlexBuildingsQuery,
  useMyFlexCompaniesQuery,
} from "../../generated/gateway-client";
import { MembersList } from "./MembersList";

const debounceMs = 500;
type Filters = Record<string, FilterValueOptions>;

export const MembersPageInner: FC = () => {
  const { i18n, t } = useTranslation();
  const { openTab } = useContext(SideModalContext);
  const toaster = useToast();
  const [searchTerm, setSearchTerm] = useState<string>();
  const debouncedSearch = useDebounced(searchTerm, debounceMs);
  const searchFilter = useMemo(() => (stringNotEmpty(debouncedSearch) ? debouncedSearch : null), [debouncedSearch]);
  const [showFilterRow, setShowFilterRow] = useState(false);
  const { spacers, breakpoints } = useTheme(true);
  const [filters, setFilters] = useState<Filters>({});
  const { data: buildingData } = useMyFlexBuildingsQuery();
  const { data: companyData } = useMyFlexCompaniesQuery();

  const onFilterChange = (values: Record<string, FilterValue>) => {
    setFilters(values as Filters);
    setShowFilterRow(Object.keys(values).length > 0);
  };

  const [exportMyFlexTenantsMutation, { loading: exportLoading }] = useExportMyFlexTenantsMutation();

  const exportMyFlexTenants = useCallback(async () => {
    return exportMyFlexTenantsMutation({
      variables: {
        search: searchFilter,
        filters: {
          sites: filters.sites.value?.map((v) => v.value) ?? [],
          buildings: filters.buildings.value?.map((v) => v.value) ?? [],
          companies: filters.companies.value?.map((v) => v.value) ?? [],
          statuses: filters.statuses.value?.map((v) => v.value as FlexTenantStatus) ?? [],
        },
      },
    });
  }, [filters, searchFilter]);

  const companies = companyData?.myFlexCompanies ?? [];
  const buildings = buildingData?.myFlexBuildings.edges.map((edge) => edge.node).filter(notNullOrUndefined) ?? [];
  const sites = buildings.map((b) => b.destination).filter(notNullOrUndefined);
  const uniqueSites = [...new Map(sites.map((s) => [s.uuid, s])).values()];

  const searchFilters: Record<string, FilterItem> = {
    sites: {
      type: "options",
      title: t("common.site"),
      options: uniqueSites.map((site) => ({
        label: site.name,
        value: site.uuid,
      })),
      icon: RiHomeLine,
    },
    buildings: {
      type: "options",
      title: t("common.building"),
      options: buildings.map((b) => ({
        label: b.name,
        value: b.uuid,
      })),
      icon: RiBuilding4Line,
    },
    companies: {
      type: "options",
      title: t("common.company"),
      options: companies.map((b) => ({
        label: b.name,
        value: b.uuid,
      })),
      icon: RiTeamLine,
    },
    statuses: {
      type: "options",
      title: t("common.status"),
      icon: RiBarcodeBoxLine,
      options: [
        {
          label: t("flex.memberStatus.pending"),
          value: FlexTenantStatus.PreMoveIn,
        },
        {
          label: t("flex.memberStatus.active"),
          value: FlexTenantStatus.Active,
        },
        {
          label: t("flex.memberStatus.expiringTitle"),
          value: FlexTenantStatus.NearingExpiry,
        },
        {
          label: t("flex.memberStatus.expired"),
          value: FlexTenantStatus.Expired,
        },
      ],
    },
  };

  return (
    <>
      <Menu />
      <ComplexFilter filters={searchFilters} language={i18n.language} autoShow onChange={onFilterChange}>
        {({ renderFilterButton, renderChips, renderClearButton }) => (
          <>
            <SubTopMenu className="mt-5" border={false}>
              <h2 className="title">{t("flex.members.title")}</h2>
              <span className="controls">
                <div className="search-container">
                  <Form.InputExtended
                    className="search-input"
                    icon={RiSearchLine}
                    placeholder={`${t("common.search")}...`}
                    variant="sm"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onClear={() => setSearchTerm("")}
                    clearable
                  />
                </div>
                <span className="buttons my-2">
                  {renderFilterButton("primary", "bottom-end")}
                  <Button onClick={() => openTab("addFlexTenant")}>
                    <RiAddLine size={16} /> {t("flex.members.addButtonTitle")}
                  </Button>
                  <Tooltip title={t("flex.csv.csvExport")} placement="bottom">
                    <Button
                      variant="ghost"
                      round
                      disabled={exportLoading}
                      onClick={() => {
                        exportMyFlexTenants()
                          .then(() => {
                            toaster.positive(t("flex.csv.csvExportSuccess"), { autoDismiss: true });
                          })
                          .catch(console.error);
                      }}
                      className="export-csv"
                    >
                      {exportLoading ? <ProgressCircle size={16} /> : <RiFileDownloadLine size={16} />}
                    </Button>
                  </Tooltip>
                </span>
              </span>
            </SubTopMenu>
            {showFilterRow && (
              <div className="filters mb-6 px-6">
                <div>{renderChips()}</div>
                <div>{renderClearButton()}</div>
              </div>
            )}
          </>
        )}
      </ComplexFilter>

      <div className="p-6">
        <MembersList search={searchFilter} filters={filters} />
      </div>
      <style jsx>{`
        .title {
          font-weight: bold;
        }
        .controls,
        .buttons {
          display: flex;
          gap: ${spacers.s3};
          align-items: center;
        }
        .controls .search-container {
          width: 270px;
        }
        .filters {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        @media (max-width: ${breakpoints.lg}px) {
          .controls .search-container {
            width: 100%;
          }
        }
      `}</style>
    </>
  );
};
