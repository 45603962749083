import { CurrentProfile, CurrentRole, Role, useSiteContext } from "@equiem/lib";
import { useContext } from "react";
import {
  DestinationDetailsQuery,
  useDestinationDetailsQuery,
  useGetMyCreditAccountsQuery,
  useUserMembershipForCreditsMenuQuery,
} from "../generated/gateway-client";
import { CreditProviderType } from "@equiem/flex/generated/gateway-client";

const useWorkplaceManagerAccess = ({ data }: { data?: DestinationDetailsQuery }) => {
  const { uuid: siteUuid } = useSiteContext();
  const { companyFlexTenants, profile } = useContext(CurrentProfile);
  const { currentRole } = useContext(CurrentRole);
  const { data: myCreditAccounts } = useGetMyCreditAccountsQuery({
    variables: { providerUuid: siteUuid, providerType: CreditProviderType.Site },
    skip: currentRole !== Role.WorkplaceManager,
  });
  const isFlexSite = data?.destination.siteFlexOperator != null;

  const { data: membershipsForFlexSite } = useUserMembershipForCreditsMenuQuery({
    variables: { userUuid: profile?.uuid ?? "", siteUuid },
    skip: profile?.uuid == null || !isFlexSite || currentRole !== Role.WorkplaceManager,
  });

  if (currentRole !== Role.WorkplaceManager) {
    return false;
  }

  const hasFlexMembershipAtSite = isFlexSite
    ? Boolean(companyFlexTenants.find((ft) => ft.flexOperator.uuid === data.destination.siteFlexOperator?.uuid))
    : false;
  const hasFlexMembershipAtProfile = (membershipsForFlexSite?.userMembershipsForFlexSite ?? []).length > 0;
  const hasWorkplaceMangerCreditAccount = (myCreditAccounts?.myCreditAccounts?.length ?? 0) > 0;

  return hasFlexMembershipAtSite ? hasFlexMembershipAtProfile : hasWorkplaceMangerCreditAccount;
};

export const useCreditsModuleEnabled = () => {
  const { uuid: siteUuid } = useSiteContext();
  const { canManageCredits } = useContext(CurrentProfile);
  const { currentRole } = useContext(CurrentRole);
  const { data } = useDestinationDetailsQuery({
    variables: {
      uuid: siteUuid,
    },
  });
  const isFlexSite = data?.destination.siteFlexOperator != null;

  const hasWorkplaceManagerAccess = useWorkplaceManagerAccess({ data });

  const hasCreditManagerAccessOnFlexSite = isFlexSite && currentRole === Role.FlexManager && canManageCredits;
  const hasPropertyManagerAccess = isFlexSite ? false : currentRole === Role.PropertyManager && canManageCredits;
  const hasEndUserAccess = isFlexSite ? false : currentRole === Role.Unknown && canManageCredits;
  const hasFlexManagerAccess = currentRole === Role.FlexManager;

  return (
    hasCreditManagerAccessOnFlexSite ||
    hasWorkplaceManagerAccess ||
    hasPropertyManagerAccess ||
    hasEndUserAccess ||
    hasFlexManagerAccess
  );
};
