import React from "react";
import { useTheme } from "@equiem/react-admin-ui";
import { useTranslation } from "@equiem/localisation-eq1";

export const EmptyIcon = () => {
  const { colors } = useTheme();

  return (
    <>
      <div className="icon-container">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M21 21H3C2.44772 21 2 20.5523 2 20V12.4868C2 12.1978 2.12501 11.9229 2.34282 11.733L6 8.54435V4C6 3.44772 6.44772 3 7 3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21ZM9 19H12V12.9416L8 9.45402L4 12.9416V19H7V15H9V19ZM14 19H20V5H8V7.12729C8.23444 7.12729 8.46888 7.20938 8.65718 7.37355L13.6572 11.733C13.875 11.9229 14 12.1978 14 12.4868V19ZM16 11H18V13H16V11ZM16 15H18V17H16V15ZM16 7H18V9H16V7ZM12 7H14V9H12V7Z"
            fill="#999999"
          />
        </svg>
      </div>
      <style jsx>{`
        .icon-container {
          display: inline-flex;
          padding: 16px;
          border-radius: 50%;
          background: ${colors.grayscale[5]};
        }
      `}</style>
    </>
  );
};

export const EmptyState = () => {
  const { colors } = useTheme();
  const { t } = useTranslation();

  return (
    <div className="container">
      <EmptyIcon />
      <p className="m-5">{t("credits.companiesCredits.emptyState")}</p>
      <style jsx>{`
        .container {
          cursor: default;
          text-align: center;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          color: ${colors.medium};
        }
      `}</style>
    </div>
  );
};
