import type { AppContext } from "next/app";
import { getCookieJar } from "./getCookieJar";
import { Literal, Union } from "runtypes";
import { APP_LOCALE_PARAM_NAME, APP_MODE_PARAM_NAME } from "./auth/cookies/constants";
import { readModeCookie } from "./auth/modeCookie";

const Layout = Union(Literal("mobile"), Literal("webng"), Literal("default"));

export const getModeProps = (appContext: AppContext) => {
  const cookies = getCookieJar(appContext);
  const hasAuthCookie = cookies.unwrap("auth") === "1";
  const cookieMode = readModeCookie(cookies);
  const queryMode = appContext.router.query[APP_MODE_PARAM_NAME];
  const localeArg = appContext.router.query[APP_LOCALE_PARAM_NAME];
  const preferredLanguage = typeof localeArg === "string" ? localeArg : undefined;

  if (Layout.guard(queryMode)) {
    return {
      source: "query" as const,
      mode: queryMode,
      hasAuthCookie,
      preferredLanguage,
    };
  } else if (Layout.guard(cookieMode)) {
    return {
      source: "cookies" as const,
      mode: cookieMode,
      hasAuthCookie,
      preferredLanguage,
    };
  } else {
    return {
      source: "default" as const,
      mode: "default" as const,
      hasAuthCookie,
      preferredLanguage,
    };
  }
};
