import React, { useEffect } from "react";
import { notNullOrUndefined } from "@equiem/lib";
import { Alert, Form, Skeleton, useTheme } from "@equiem/react-admin-ui";
import { RiErrorWarningLine } from "@equiem/react-admin-ui/icons";
import { useTranslation } from "@equiem/localisation-eq1";
import { useFormikContext } from "formik";

import { useBookingBillingCustomersQuery, BookingInvoiceContactTypeInput } from "../../../../generated/gateway-client";
import type { BookingFormValue } from "../../models/BookingFormValue";
import { BookingPaymentBillingCustomer } from "./BookingPaymentBillingCustomer";

export const BookingPaymentBillingCustomers: React.FC<{
  setLoading: (loading: boolean) => void;
}> = ({ setLoading }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const fm = useFormikContext<BookingFormValue>();

  const { data, loading } = useBookingBillingCustomersQuery({
    fetchPolicy: "network-only",
    variables: { userUuid: fm.values.host?.uuid ?? "" },
    skip: fm.values.host == null,
  });
  const billingCustomers = [
    ...(data?.profile?.companyV2?.billingCustomers ?? []),
    ...(data?.profile?.flexMemberships.map((m) => m.flexTenant.billingCustomer) ?? []),
  ].filter(notNullOrUndefined);

  useEffect(() => setLoading(loading), [setLoading, loading]);
  useEffect(() => {
    if (!loading && fm.values.contact == null && billingCustomers.length > 0) {
      void fm.setFieldValue("contact", {
        type: BookingInvoiceContactTypeInput.BillingCustomer,
        billingCustomerId: billingCustomers[0].id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingCustomers, fm.values.contact, loading]);

  if (loading) {
    return (
      <>
        <Skeleton.Line width="25%" height="25px" className="mb-1" />
        <Skeleton.Line width="100%" height="60px" className="d-block mb-3 rounded" />
      </>
    );
  }

  if (billingCustomers.length === 0) {
    return (
      <Alert
        className="mb-4"
        size="large"
        variant="gray"
        icon={<RiErrorWarningLine size={18} color={colors.grayscale[50]} />}
        message={t("bookings.operations.noBillingCustomers")}
      />
    );
  }

  return (
    <div className="invoice-lists">
      <Form.Group className="mb-4" label={"Billing details"}>
        {billingCustomers.map((billingCustomer) => (
          <BookingPaymentBillingCustomer key={billingCustomer.id} billingCustomer={billingCustomer} />
        ))}
      </Form.Group>
    </div>
  );
};
