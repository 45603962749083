import React, { useState } from "react";
import { Field, Form as FormikForm, Formik } from "formik";

import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Form, Modal, useTheme } from "@equiem/react-admin-ui";

import { BookableResourceCancellationRateType as RateType } from "../../../../../generated/gateway-client";
import { useCurrency } from "../../../../../hooks/useCurrency";
import type { CancellationRate } from "../../../../../lib/cancellationRate";
import { cancellationRateModalValidationSchema } from "../../../../../lib/validationSchema";
import { ResourceDivider } from "../ResourceDivider";

import type { CompanyOption } from "./ResourceCreateAndEditFormCancellationPermissions";
import { ResourceCreateAndEditFormCancellationPermissionsNoticePeriod } from "./ResourceCreateAndEditFormCancellationPermissionsNoticePeriod";

export type CancellationRateModalFormValues = {
  defaultRate: boolean;
  paymentRateCancellation: CancellationRate;
};

export type CompanyEntry = {
  minutesBefore: number | null;
  companies: string[];
};

interface Props {
  showModal: boolean;
  setShowModal: (_state: boolean) => void;
  modalIndex: number | null;
  building?: string;
  companiesGroupedByNoticePeriod: Record<number, Set<string | null>>;
  companyOptions: CompanyOption[];
  paymentRateCancellation?: CancellationRate;
  onAdd: (_paymentRateCancellation: CancellationRate) => void;
  onEdit: (_paymentRateCancellation: CancellationRate, _index: number) => void;
}

export const ResourceCreateAndEditFormCancellationRateModal: React.FC<Props> = ({
  showModal,
  setShowModal,
  modalIndex,
  building,
  companiesGroupedByNoticePeriod,
  companyOptions,
  paymentRateCancellation,
  onAdd,
  onEdit,
}) => {
  const { t } = useTranslation();
  const { breakpoints, colors, spacers } = useTheme(true);
  const { currency } = useCurrency(building);

  const [assignmentCompaniesTouched, setAssignmentCompaniesTouched] = useState(false);

  return (
    <Modal.Dialog
      title={
        modalIndex != null ? t("bookings.resources.editCancellationRate") : t("bookings.resources.addCancellationRate")
      }
      show={showModal}
      size="xl"
      onHide={() => {
        setShowModal(false);
      }}
      hideOnClick={false}
      hideOnEsc={false}
      supportsMobile={true}
      focusTrapOptions={{ initialFocus: false }}
      centered
    >
      <div className="cancellation-rate-modal">
        <Modal.Header supportsMobile={true} closeButton={true} noBorder={false} />
        <Formik<CancellationRateModalFormValues>
          initialValues={{
            defaultRate: paymentRateCancellation != null ? paymentRateCancellation.companies.length === 0 : true,
            paymentRateCancellation: paymentRateCancellation ?? {
              type: RateType.FixedRate,
              amount: "0",
              minutesBefore: 0,
              companies: [],
            },
          }}
          validationSchema={cancellationRateModalValidationSchema(companiesGroupedByNoticePeriod, t)}
          validateOnMount={true}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            modalIndex != null
              ? onEdit(values.paymentRateCancellation, modalIndex)
              : onAdd(values.paymentRateCancellation);
            actions.setSubmitting(false);
            setAssignmentCompaniesTouched(false);
          }}
        >
          {({ isSubmitting, isValid, submitForm, setFieldValue, setFieldTouched, touched, values, errors }) => {
            const assignmentCompaniesError = assignmentCompaniesTouched
              ? Array.isArray(errors.paymentRateCancellation?.companies)
                ? errors.paymentRateCancellation?.companies.join(", ")
                : errors.paymentRateCancellation?.companies
              : undefined;
            return (
              <FormikForm>
                <Modal.Body>
                  <Form.Group label={t("bookings.resources.assignment")}>
                    <div className="assignment-container">
                      <div className="assignment-radio">
                        <Field
                          component={Form.EnclosedRadioButton}
                          id="assignment-default"
                          name="defaultRate"
                          label={t("bookings.resources.assignmentDefaultLabel")}
                          description={t("bookings.resources.assignmentDefaultDescriptionForRate")}
                          value={values.defaultRate}
                          disabled={isSubmitting} // || defaultRateUsed}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (e.currentTarget.checked) {
                              void setFieldValue("defaultRate", true).then(async () =>
                                setFieldValue("paymentRateCancellation.companies", []),
                              );
                            }
                          }}
                        />
                      </div>
                      <div className="assignment-radio">
                        <Field
                          component={Form.EnclosedRadioButton}
                          id="assignment-companies"
                          name="assignment"
                          label={t("bookings.resources.assignmentSelectedLabel")}
                          description={t("bookings.resources.assignmentSelectedDescriptionForRate")}
                          value={!values.defaultRate}
                          disabled={isSubmitting}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (e.currentTarget.checked) {
                              void setFieldValue("defaultRate", false).then(async () =>
                                setFieldValue("paymentRateCancellation.companies", []),
                              );
                            }
                          }}
                        />
                      </div>
                    </div>
                  </Form.Group>

                  <Form.Group label={t("bookings.resources.rateType")} error={errors.paymentRateCancellation?.type}>
                    <Field id="type" name="type">
                      {() => (
                        <div className="rate-type-radio">
                          <Form.RadioButton
                            name="type"
                            id="fixedRate"
                            disabled={isSubmitting}
                            label={t("bookings.resources.fixedRate")}
                            checked={values.paymentRateCancellation.type === RateType.FixedRate}
                            onChange={() => {
                              void setFieldValue("paymentRateCancellation.type", RateType.FixedRate);
                            }}
                          />
                          <Form.RadioButton
                            name="type"
                            id="percentage"
                            disabled={isSubmitting}
                            label={t("common.percentage")}
                            checked={values.paymentRateCancellation.type === RateType.Percentage}
                            onChange={() => {
                              void setFieldValue("paymentRateCancellation.type", RateType.Percentage);
                            }}
                          />
                        </div>
                      )}
                    </Field>
                  </Form.Group>

                  <ResourceDivider />

                  <div className="form-group-container">
                    <Form.Group
                      label={t("common.amount")}
                      required
                      showTooltip
                      tooltipText={t("bookings.resources.cancelationRatesAmountHint")}
                      error={
                        touched.paymentRateCancellation?.amount === true
                          ? errors.paymentRateCancellation?.amount
                          : undefined
                      }
                    >
                      {values.paymentRateCancellation.type === RateType.FixedRate ? (
                        <Field
                          name="paymentRateCancellation.amount"
                          as={Form.Money}
                          currency={currency}
                          min={0}
                          max={9999.99}
                        />
                      ) : (
                        <Field name="paymentRateCancellation.amount" as={Form.Percentage} />
                      )}
                    </Form.Group>
                    <ResourceCreateAndEditFormCancellationPermissionsNoticePeriod
                      name="paymentRateCancellation.minutesBefore"
                      value={Number(values.paymentRateCancellation.minutesBefore)}
                      onChange={(val) => {
                        void setFieldTouched("paymentRateCancellation.minutesBefore");
                        void setFieldValue("paymentRateCancellation.minutesBefore", val);
                      }}
                      label={t("bookings.resources.noticePeriod")}
                      error={
                        touched.paymentRateCancellation?.minutesBefore === true
                          ? errors.paymentRateCancellation?.minutesBefore
                          : undefined
                      }
                      required={true}
                      showTooltip={true}
                      tooltipText={t("bookings.resources.cancellationNoticePeriodHint")}
                      disabled={isSubmitting}
                    />
                  </div>

                  {!values.defaultRate && (
                    <>
                      <ResourceDivider />

                      <Form.Group
                        label={t("bookings.resources.appliesToLabel")}
                        required
                        showTooltip
                        tooltipText={t("bookings.resources.appliesToTooltipForRate")}
                        error={assignmentCompaniesError}
                      >
                        <Field
                          name="paymentRateCancellation.companies"
                          options={companyOptions}
                          as={Form.MultiSelect}
                          variant="wrap"
                          placeholder={t("bookings.resources.searchForCompanies")}
                          searchPlaceholder={t("bookings.resources.companyName")}
                          isMulti
                          enableSelectAll
                          disabled={isSubmitting}
                          onClose={() => {
                            setAssignmentCompaniesTouched(true);
                          }}
                        />
                      </Form.Group>
                    </>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <div className="footer-container">
                    {errors.paymentRateCancellation != null && typeof errors.paymentRateCancellation === "string" && (
                      <div className="error-text">{errors.paymentRateCancellation}</div>
                    )}
                    <div className="footer-buttons">
                      <Button
                        variant="ghost"
                        type="button"
                        onClick={() => {
                          setShowModal(false);
                          setAssignmentCompaniesTouched(false);
                        }}
                      >
                        {t("common.cancel")}
                      </Button>
                      <Button
                        className="add-button"
                        type="submit"
                        variant="primary"
                        onSubmit={() => {
                          void submitForm();
                        }}
                        disabled={isSubmitting || !isValid}
                      >
                        {modalIndex != null ? t("common.save") : t("common.add")}
                      </Button>
                    </div>
                  </div>
                </Modal.Footer>
              </FormikForm>
            );
          }}
        </Formik>
      </div>
      <style jsx>
        {`
          .cancellation-rate-modal :global(.header) {
            padding: ${spacers.s6} !important;
          }
          .cancellation-rate-modal :global(.header .title-row) {
            margin: ${spacers.s0} !important;
          }
          .cancellation-rate-modal :global(.header .title) {
            color: ${colors.dark};
            font-size: 24px;
            font-weight: 700;
            line-height: 28px;
            text-transform: none;
            text-align: left;
            z-index: 1;
          }
          .cancellation-rate-modal :global(.modal-body) {
            display: flex;
            flex-direction: column;
            padding: ${spacers.s7} ${spacers.s6};
            margin: ${spacers.s0};
            gap: ${spacers.s7};
            overflow-y: auto;
            max-height: 400px;
          }
          .cancellation-rate-modal :global(.modal-body .assignment-container) {
            display: flex;
            gap: ${spacers.s3};
          }
          .cancellation-rate-modal :global(.modal-body .assignment-radio) {
            flex: 1;
          }
          .cancellation-rate-modal :global(.modal-body .assignment-radio label) {
            width: 100%;
            height: 100%;
          }
          .cancellation-rate-modal :global(.modal-body .assignment-container h1) {
            text-overflow: ellipsis;
            text-wrap: wrap;
          }
          .cancellation-rate-modal :global(.modal-body .assignment-container p) {
            text-wrap: wrap;
          }
          .cancellation-rate-modal :global(.modal-body hr),
          .cancellation-rate-modal :global(.modal-body .form-group) {
            margin: ${spacers.s0};
          }
          .cancellation-rate-modal :global(.modal-body .rate-type-radio) {
            display: flex;
            gap: ${spacers.s5};
          }
          .cancellation-rate-modal :global(.modal-body .form-group-container) {
            display: flex;
            gap: ${spacers.s7};
          }
          .cancellation-rate-modal :global(.modal-body .form-group .form-header label) {
            line-height: 14px;
          }
          .cancellation-rate-modal :global(.footer) {
            padding: ${spacers.s5} ${spacers.s7} !important;
            gap: ${spacers.s3};
            border-top: 1px solid ${colors.grayscale[10]};
          }
          .cancellation-rate-modal :global(.footer .footer-container) {
            display: flex;
            align-items: center;
            gap: ${spacers.s5};
          }
          .cancellation-rate-modal :global(.footer .footer-container .footer-buttons) {
            display: flex;
            gap: ${spacers.s3};
          }
          .cancellation-rate-modal :global(.footer .footer-container .footer-buttons .add-button) {
            width: 200px;
          }
          .cancellation-rate-modal :global(.footer .footer-container .error-text) {
            font-size: 13px;
            color: ${colors.danger};
          }
          @media screen and (max-width: ${breakpoints.md}px) {
            .cancellation-rate-modal :global(.header .title) {
              font-size: 20px;
            }
            .cancellation-rate-modal :global(.header button) {
              z-index: 2;
            }
            .cancellation-rate-modal :global(.modal-body) {
              padding: ${spacers.s5};
              gap: ${spacers.s5};
            }
            .cancellation-rate-modal :global(.modal-body .assignment-container) {
              flex-direction: column;
            }
            .cancellation-rate-modal :global(.modal-body .form-group-container) {
              flex-direction: column;
              gap: ${spacers.s5};
            }
            .cancellation-rate-modal :global(.footer .footer-container) {
              flex-direction: column;
              gap: ${spacers.s3};
              width: 100%;
            }
            .cancellation-rate-modal :global(.footer .footer-container .footer-buttons button) {
              flex: 1;
            }
          }
        `}
      </style>
    </Modal.Dialog>
  );
};
