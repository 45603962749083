import { CurrentProfile, CurrentRole, Role } from "@equiem/lib";
import { useContext } from "react";
import { CreditTransactionsQueryRole } from "../../../generated/gateway-client";

export const useRoleMapping = (): CreditTransactionsQueryRole => {
  const { currentRole } = useContext(CurrentRole);
  const { canManageCredits } = useContext(CurrentProfile);
  const roleMapping: Record<Role, CreditTransactionsQueryRole> = {
    [Role.WorkplaceManager]: CreditTransactionsQueryRole.WorkplaceManager,
    [Role.FlexManager]: CreditTransactionsQueryRole.FlexManager,
    [Role.PropertyManager]: CreditTransactionsQueryRole.WorkplaceManager,
    [Role.Unknown]: CreditTransactionsQueryRole.FlexManager,
  };

  if ([Role.WorkplaceManager, Role.FlexManager].includes(currentRole)) {
    return roleMapping[currentRole];
  }

  if (canManageCredits) {
    return CreditTransactionsQueryRole.CreditManager;
  }

  return roleMapping[currentRole];
};
