import { DateTime } from "luxon";

import type { FilterValue } from "@equiem/react-admin-ui";
import { FilterDateModifier, FilterDateMonthModifier, FilterType } from "@equiem/react-admin-ui";
import type { CreditFiltersResult } from "../../contexts/CreditsFilterContext";
import type { CreditTransactionFilter, CreditTransactionType } from "../../generated/gateway-client";

const DATE_FORMAT = "yyyy-LL-dd";
const DATE_MONTH_FORMAT = "yyyy-LL";

export const translateUser = (filters: CreditFiltersResult): string[] | undefined => {
  if (filters.user?.type === FilterType.options) {
    const values = filters.user.value ?? [];
    if (values.length > 0) {
      return values.map((v) => v.value);
    }
  }
  return undefined;
};

export const translateType = (filters: CreditFiltersResult): CreditTransactionType[] | undefined => {
  if (filters.type?.type === FilterType.options) {
    const values = filters.type.value ?? [];
    if (values.length > 0) {
      return values.map((v) => v.value as CreditTransactionType);
    }
  }
  return undefined;
};

export const translateDate = (date: FilterValue): { start: number; end: number } | undefined => {
  if (date?.type === FilterType.date) {
    if (date.modifier === FilterDateModifier.is && date.value != null) {
      return {
        start: DateTime.fromFormat(date.value, DATE_FORMAT).startOf("day").toMillis(),
        end: DateTime.fromFormat(date.value, DATE_FORMAT).endOf("day").toMillis(),
      };
    }
    if (date.modifier === FilterDateModifier.between && date.value?.[0] != null) {
      return {
        start: DateTime.fromFormat(date.value[0], DATE_FORMAT).startOf("day").toMillis(),
        end: DateTime.fromFormat(date.value[1], DATE_FORMAT).endOf("day").toMillis(),
      };
    }
  }
  return undefined;
};

export const translateDateMonth = (date: FilterValue): { start: number; end: number } | undefined => {
  if (date?.type === FilterType.datemonth) {
    if (date.modifier === FilterDateMonthModifier.is && date.value != null) {
      const startOfMonth = DateTime.fromFormat(date.value, DATE_MONTH_FORMAT).startOf("month");
      const endOfMonth = DateTime.fromFormat(date.value, DATE_MONTH_FORMAT).endOf("month");
      return {
        start: startOfMonth.toMillis(),
        end: endOfMonth.toMillis(),
      };
    }
    if (date.modifier === FilterDateMonthModifier.between && date.value?.[0] != null) {
      const startOfStartMonth = DateTime.fromFormat(date.value[0], DATE_MONTH_FORMAT).startOf("month");
      const endOfEndMonth = DateTime.fromFormat(date.value[1], DATE_MONTH_FORMAT).endOf("month");
      return {
        start: startOfStartMonth.toMillis(),
        end: endOfEndMonth.toMillis(),
      };
    }
  }
  return undefined;
};

export const translateTenants = (tenants: FilterValue): string[] | undefined => {
  if (tenants?.type === FilterType.infiniteOptions) {
    const values = tenants.value ?? [];
    if (values.length > 0) {
      return values.map((v) => v.value);
    }
  }

  return undefined;
};

export const translateCompanies = (companies: FilterValue): string[] | undefined => {
  if (companies?.type === FilterType.options) {
    const values = companies.value ?? [];
    if (values.length > 0) {
      return values.map((v) => v.value);
    }
  }
  return undefined;
};

export const transformToQuery = (filters: CreditFiltersResult, search: string) => {
  const resultFilters: CreditTransactionFilter = {};
  const dateFilters = translateDate(filters.date);
  const monthFilters = translateDateMonth(filters.datemonth);
  const types = translateType(filters);
  const tenants = translateTenants(filters.tenants);
  const companies = translateCompanies(filters.companies);

  if (dateFilters != null) {
    resultFilters.created = { start: dateFilters.start, end: dateFilters.end };
  }

  if (monthFilters != null) {
    resultFilters.transactionDetailDate = { start: monthFilters.start, end: monthFilters.end };
  }

  if (types != null) {
    resultFilters.types = types;
  }

  if (tenants != null) {
    resultFilters.creditAccountUuids = tenants;
  }

  if (companies != null) {
    resultFilters.creditAccountUuids = companies;
  }

  resultFilters.userUuids = translateUser(filters);
  resultFilters.search = search === "" ? undefined : search;

  return resultFilters;
};
