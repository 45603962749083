import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { ErrorMessage, Field, FieldArray, Form, getIn, useFormikContext } from "formik";
import { get } from "lodash";

import { UserSearchHOC, useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Form as EqForm, MemberCard } from "@equiem/react-admin-ui";

import { useUsers } from "../contexts/UserContext";
import { RegistrationType, useRequestsAssignedLazyQuery } from "../generated/requests-client";
import { ModalContext } from "../pages/settings/contexts/ModalContext";

import type { Reassignment } from "./QueueModal";

interface QueueUserProfile {
  uuid: string;
  firstName?: string | null | undefined;
  lastName?: string | null;
  email: string;
  companyUuid?: string | undefined;
  companyName?: string | undefined;
  profileIconUrl?: string | null | undefined;
}
export interface QueueFormValues {
  uuid?: string;
  name: string;
  ownerCompanyUuid: string;
  requestManagers: QueueUserProfile[];
  requestAssignees: QueueUserProfile[];
}

interface Props {
  queueUuid?: string;
  loading: boolean;
  companies: Array<{ uuid: string; name: string }>;
  canCreateQueues: boolean;
  allReassignments: Reassignment[];
}

export const QueueForm: React.FC<Props> = ({ loading, companies, canCreateQueues, allReassignments, queueUuid }) => {
  const { uuid: siteUuid } = useSiteContext();
  const modal = useContext(ModalContext);
  const { t } = useTranslation();
  const { values, touched, errors, setFieldValue, dirty, isSubmitting } = useFormikContext<QueueFormValues>();
  const [requestsAssignedQuery] = useRequestsAssignedLazyQuery();
  const isEditing = useMemo(() => values.uuid != null, [values.uuid]);
  const { assignees, setAssignees, requestManagers, setRequestManagers } = useUsers();

  const getFieldError = (field: string): string | undefined =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    get(touched, field.split(".")[0]) === true || Array.isArray(get(touched, field)) || (field === "iconName" && dirty)
      ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        get(errors, field)?.toString()
      : undefined;

  const isUsersArrayFieldInvalid = (type: "requestManagers" | "requestAssignees") => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return getIn(errors, `${type}[0]`)?.companyUuid as string | undefined;
  };

  const userIsInTheList = (user: { uuid: string }, currentUsersList: Array<{ uuid: string }>) => {
    return currentUsersList.some((u) => u.uuid === user.uuid);
  };

  const setAssigneesInFormAndContext = useCallback(
    async (assigneesList: QueueUserProfile[]) => {
      setAssignees(assigneesList);
      await setFieldValue("requestAssignees", assigneesList);
    },
    [setAssignees, setFieldValue],
  );

  const setRequestManagersInFormAndContext = useCallback(
    async (managersList: QueueUserProfile[]) => {
      setRequestManagers(managersList);
      await setFieldValue("requestManagers", managersList);
    },
    [setRequestManagers, setFieldValue],
  );

  const handleRemoveAssignee = useCallback(
    (assigneeUuid: string) => {
      if (queueUuid != null) {
        requestsAssignedQuery({
          variables: { assigneeUuid, queueUuid },
        })
          .then((requestsAssigned) => {
            const requestsAssignedCount = requestsAssigned.data?.reqMgt.assignedRequests ?? 0;
            if (requestsAssignedCount > 0) {
              modal.open(
                "QueueRemoveAssigneeModal",
                JSON.stringify({
                  assigneeUuid,
                  queueUuid,
                  requestsAssignedCount,
                }),
              );
            } else {
              void setAssigneesInFormAndContext(assignees.filter((x) => x.uuid !== assigneeUuid));
            }
          })
          .catch(console.error);
      } else {
        void setAssigneesInFormAndContext(assignees.filter((x) => x.uuid !== assigneeUuid));
      }
    },
    [requestsAssignedQuery, modal, assignees, queueUuid, setAssigneesInFormAndContext],
  );

  useEffect(() => {
    if (allReassignments.length > 0) {
      const assignedUuids = allReassignments
        .filter((reassignment) => reassignment.assignedTo)
        .map((reassignment) => reassignment.assignedTo);
      void setAssigneesInFormAndContext(assignees.filter((x) => !assignedUuids.includes(x.uuid)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allReassignments]);

  useEffect(() => {
    if (assignees.length === 0 && values.requestAssignees.length > 0) {
      setAssignees(values.requestAssignees);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (requestManagers.length === 0 && values.requestManagers.length > 0) {
      setRequestManagers(values.requestManagers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Form>
        <div className="queue-header">
          <span className="queue-header">{t("requests.queues.queueInformation")}</span>
        </div>
        <EqForm.Group label={t("common.name")} required error={getFieldError("name")}>
          <Field
            as={EqForm.Input}
            id="name"
            name="name"
            disabled={!canCreateQueues}
            placeholder={t("requests.queues.addName")}
          />
        </EqForm.Group>

        {canCreateQueues && (
          <EqForm.Group label={t("requests.queues.owner")} required error={getFieldError("ownerCompanyUuid")}>
            <Field
              name="ownerCompanyUuid"
              as={EqForm.Select}
              placeholder={t("requests.queues.selectCompany")}
              disabled={loading || companies.length === 0 || isEditing}
            >
              <option value="" disabled>
                {loading
                  ? `${t("common.loading")}...`
                  : companies.length === 0
                  ? t("requests.queues.noCompaniesFound")
                  : t("requests.queues.selectCompany")}
              </option>
              {companies.map(({ uuid, name }) => (
                <option key={uuid} value={uuid}>
                  {name}
                </option>
              ))}
            </Field>
          </EqForm.Group>
        )}
        <div className="queue-header">
          <span>{t("requests.queues.queueAgents")}</span>
        </div>
        <>
          <EqForm.Group required={true} label={t("requests.queues.requestManagers")}>
            <UserSearchHOC
              siteUuids={[siteUuid]}
              disabled={isSubmitting}
              filter={{ registrationType: RegistrationType.Commercial }}
              placeholder={`${t("common.searchUsers")}...`}
              buttonText={(user) => (userIsInTheList(user, requestManagers) ? t("common.remove") : t("common.add"))}
              onSelect={(user) => {
                void setRequestManagersInFormAndContext(
                  userIsInTheList(user, requestManagers)
                    ? requestManagers.filter((x) => x.uuid !== user.uuid)
                    : [
                        ...requestManagers,
                        {
                          uuid: user.uuid,
                          firstName: user.firstName,
                          lastName: user.lastName,
                          email: user.email,
                          companyUuid: user.companyV2?.uuid,
                          companyName: user.companyV2?.name,
                          profileIconUrl: user.avatar,
                        },
                      ],
                );
              }}
            />
            {requestManagers.length > 0 && (
              <FieldArray
                name="requestManagers"
                render={() => (
                  <>
                    {requestManagers.map((reqMgr) => (
                      <EqForm.Group key={`rq-m${reqMgr.uuid}`} className="user-form-group">
                        <MemberCard.Card
                          className={"selected-member mt-2"}
                          showButton={true}
                          buttonVariant="outline"
                          buttonText={t("common.remove")}
                          onButtonClick={() => {
                            void setRequestManagersInFormAndContext(
                              requestManagers.filter((x) => x.uuid !== reqMgr.uuid),
                            );
                          }}
                          email={reqMgr.email}
                          companyName={reqMgr.companyName ?? ""}
                          lastName={reqMgr.lastName ?? ""}
                          firstName={reqMgr.firstName ?? ""}
                          profileIconUrl={reqMgr.profileIconUrl ?? undefined}
                        />
                      </EqForm.Group>
                    ))}
                  </>
                )}
              />
            )}
            <ErrorMessage
              render={() => {
                const err = isUsersArrayFieldInvalid("requestManagers");
                return err != null ? <p className="error-text">{err}</p> : <></>;
              }}
              name={""}
            />
          </EqForm.Group>
          <EqForm.Group required={true} label={t("requests.queues.requestAssignees")}>
            <UserSearchHOC
              siteUuids={[siteUuid]}
              disabled={isSubmitting}
              placeholder={`${t("common.searchUsers")}...`}
              filter={{ registrationType: RegistrationType.Commercial }}
              buttonText={(user) => (userIsInTheList(user, assignees) ? t("common.remove") : t("common.add"))}
              onSelect={(user) => {
                void setAssigneesInFormAndContext(
                  userIsInTheList(user, assignees)
                    ? assignees.filter((x) => x.uuid !== user.uuid)
                    : [
                        ...assignees,
                        {
                          uuid: user.uuid,
                          firstName: user.firstName,
                          lastName: user.lastName,
                          email: user.email,
                          companyUuid: user.companyV2?.uuid,
                          companyName: user.companyV2?.name,
                          profileIconUrl: user.avatar,
                        },
                      ],
                );
              }}
            />
            {assignees.length > 0 && (
              <FieldArray
                name="requestAssignees"
                render={() => (
                  <>
                    {assignees.map((reqMgr) => (
                      <EqForm.Group key={`rq-a${reqMgr.uuid}`} className="user-form-group">
                        <MemberCard.Card
                          className={"selected-member mt-2"}
                          showButton={true}
                          buttonVariant="outline"
                          buttonText={t("common.remove")}
                          onButtonClick={() => {
                            handleRemoveAssignee(reqMgr.uuid);
                          }}
                          email={reqMgr.email}
                          companyName={reqMgr.companyName ?? ""}
                          lastName={reqMgr.lastName ?? ""}
                          firstName={reqMgr.firstName ?? ""}
                          profileIconUrl={reqMgr.profileIconUrl ?? undefined}
                        />
                      </EqForm.Group>
                    ))}
                  </>
                )}
              />
            )}
            <ErrorMessage
              render={() => {
                const err = isUsersArrayFieldInvalid("requestAssignees");
                return err != null ? <p className="error-text">{err}</p> : <></>;
              }}
              name={""}
            />
          </EqForm.Group>
        </>
      </Form>
      <style jsx>{`
        :global(.error-user-card) {
          border-color: #e6000e !important;
        }
        :global(.user-form-group) {
          margin: 0px !important;
        }
        :global(.error-text) {
          color: #e6000e !important;
          font-size: 13px;
        }
        :global(.queue-header) {
          font-weight: 700;
          font-size: 20px;
          padding-bottom: 20px;
        }
      `}</style>
    </>
  );
};
