import React, { useState } from "react";
import { BookingPaymentNewContactForm } from "./BookingPaymentNewContactForm";
import { BookingPaymentContacts } from "./BookingPaymentContacts";
import { useFormikContext } from "formik";
import type { BookingFormValue } from "../../models/BookingFormValue";
import { BookingPaymentBillingCustomers } from "./BookingPaymentBillingCustomers";
import { useTranslation } from "@equiem/localisation-eq1";
import { BookingInvoiceContactTypeInput } from "../../../../generated/gateway-client";
import { Form } from "@equiem/react-admin-ui";
import { stringNotEmpty } from "@equiem/lib";

export const BookingPaymentInvoice: React.FC<{
  requireBillingCustomerForInvoices: boolean;
  setLoading: (loading: boolean) => void;
}> = ({ requireBillingCustomerForInvoices, setLoading }) => {
  const { t } = useTranslation();
  const fm = useFormikContext<BookingFormValue>();
  const [showForm, setShowForm] = useState(fm.values.contact?.type === BookingInvoiceContactTypeInput.NewContact);

  if (requireBillingCustomerForInvoices) {
    return (
      <Form.Group
        error={stringNotEmpty(fm.errors.contact) ? fm.errors.contact : ""}
        className="booking-checkout-invoice"
      >
        <BookingPaymentBillingCustomers setLoading={setLoading} />
      </Form.Group>
    );
  } else {
    return (
      <Form.Group
        error={stringNotEmpty(fm.errors.contact) ? fm.errors.contact : ""}
        className="booking-checkout-invoice"
      >
        <BookingPaymentContacts setLoading={setLoading} />
        <div className="text-muted pt-1 pb-3">
          <u
            className="cursor-pointer"
            onClick={() => {
              setShowForm(true);
            }}
          >
            {t("bookings.operations.orAddInvoiceManually")}
          </u>
        </div>
        {showForm && <BookingPaymentNewContactForm />}
      </Form.Group>
    );
  }
};
