import { centsToDollar, dollarToCents } from "@equiem/lib";

import type { BookableResourceEditQuery, BookableResourceInput } from "../generated/gateway-client";
import { BookableResourceCancellationRateType as RateType } from "../generated/gateway-client";

import { convertInputMoneyToNumber } from "./convertInputMoneyToNumber";

export interface CancellationRate {
  companies: string[];
  type: RateType;
  amount: string;
  minutesBefore: number | null;
}

export function getCancellationRatesForForm(
  resource: BookableResourceEditQuery["bookableResource"],
): CancellationRate[] {
  return resource.paymentRateCancellation.map(({ type, amount, minutesBefore, companies }) => ({
    type,
    amount: String(type === RateType.Percentage ? Math.round(amount * 100) : centsToDollar(amount)),
    minutesBefore: minutesBefore ?? null,
    companies: companies.map((company) => company.uuid),
  }));
}

export function toInputCancellationRates(
  rates: CancellationRate[],
): NonNullable<BookableResourceInput["paymentRateCancellation"]> {
  return rates.map(({ type, amount, minutesBefore, companies }) => ({
    type,
    amount:
      type === RateType.Percentage
        ? Number(amount) / 100
        : dollarToCents(convertInputMoneyToNumber(amount, false)) ?? 0,
    minutesBefore,
    companies,
  }));
}
