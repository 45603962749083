import { formatters, useTranslation } from "@equiem/localisation-eq1";
import React, { type ReactElement } from "react";

const RenderPrice = ({
  price,
  currency,
  className = "",
}: {
  price?: number | null;
  currency: string;
  className?: string;
}): ReactElement => {
  const { i18n } = useTranslation();
  if (price == null) {
    return <></>;
  }
  return <div className={className}>{formatters.currency(price, i18n.language, { currency })}</div>;
};

export default RenderPrice;
