/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable complexity */
import type { FormikHelpers } from "formik";
import { Field, FieldArray, Form, Formik, getIn } from "formik";
import React, { useCallback, useContext, useMemo, useState } from "react";
import {
  Button,
  Form as EqForm,
  EntitySearch,
  Modal,
  remainingCharacters,
  SteppedProgressBar,
  Text,
  useTheme,
  ProgressCircle,
} from "@equiem/react-admin-ui";
import type { BillingCustomerInput, CompanyFragmentFragment, FlexTenantInput } from "../../generated/gateway-client";
import {
  MyFlexTenantsDocument,
  useCreateFlexTenantMutation,
  useSaveFlexTenantBillingCustomerMutation,
} from "../../generated/gateway-client";
import type { TFunction } from "@equiem/localisation-eq1";
import { useTranslation } from "@equiem/localisation-eq1";
import { SideModalContext } from "../../contexts/SideModalContext";
import { CompanySearchHOC, stringIsEmpty, stringNotEmpty } from "@equiem/lib";
import { LocationSelect } from "../lib/LocationSelect";
import { flattenLocations } from "../lib/flattenLocations";
import { RiAddLine, RiDeleteBinLine } from "@equiem/react-admin-ui/icons";
import type { BuildingWithUniqueId } from "../../contexts/FlexTenantLocationContext";
import { FlexTenantLocationContext, getEmptyLocation } from "../../contexts/FlexTenantLocationContext";
import { MembershipDateField } from "./MembershipDateField";
import * as yup from "yup";
import { billingCustomerValidation } from "../lib/billingCustomerValidation";
import { DateTime } from "luxon";

export const detailsValidation = (t: TFunction) => ({
  name: yup.string().required(t("common.validation.required", { path: t("flex.members.form.membershipName") })),
  moveInDate: yup.number().required(t("common.validation.required", { path: t("flex.members.form.moveInDate") })),
  expirationDate: yup
    .number()
    .min(
      yup.ref("moveInDate"),
      t("common.validation.dateMin", {
        path: t("flex.members.form.expirationDate"),
        min: t("flex.members.form.moveInDate"),
      }),
    )
    .required(t("common.validation.required", { path: t("flex.members.form.expirationDate") })),
  monthlyCreditAllowance: yup
    .number()
    .required(t("common.validation.required", { path: t("flex.members.form.creditAllowance") })),
  unlimitedMembers: yup.boolean(),
  memberLimit: yup
    .number()
    .when("unlimitedMembers", {
      is: false,
      then: yup
        .number()
        .min(1, t("common.validation.numberMin", { path: t("flex.members.form.teamSize"), min: 1 }))
        .required(t("common.validation.required", { path: t("flex.members.form.teamSize") })),
    })
    .when("unlimitedMembers", {
      is: true,
      then: yup.number().nullable(),
    }),
});

export const CreateFlexTenantForm = () => {
  const { closeModal, stepCount, setStepCount } = useContext(SideModalContext);
  const [flexTenantMutation] = useCreateFlexTenantMutation();
  const [billingMutation] = useSaveFlexTenantBillingCustomerMutation();
  const [company, setCompany] = useState<CompanyFragmentFragment | null>();
  const { locations, setLocations, clearLocations } = useContext(FlexTenantLocationContext);
  const [unlimitedMembers, setUnlimitedMembers] = useState(true);
  const { t } = useTranslation();
  const { colors } = useTheme(true);

  const validationSchema = () =>
    yup.object().shape({
      ...detailsValidation(t),
      defaultWorkplaceManagerEmails: yup
        .array()
        .of(yup.string().email(t("common.invalidEmailAddress")))
        .required(t("common.validation.required", { path: t("flex.members.form.workplaceManager") })),
      billingDetails: yup.object().shape({
        ...billingCustomerValidation(t),
      }),
    });

  const billingInitialValues: BillingCustomerInput = {
    flexTenantUuid: "",
    email: "",
    contactName: "",
    address: {},
  };

  const initialValues: FlexTenantInput & {
    billingDetails: BillingCustomerInput;
    unlimitedMembers: boolean;
  } = {
    companyUuid: "",
    name: "",
    locations: [],
    moveInDate: DateTime.now().toMillis(),
    expirationDate: DateTime.now().plus({ years: 1 }).toMillis(),
    monthlyCreditAllowance: 0,
    memberLimit: 1,
    defaultWorkplaceManagerEmails: [""],
    billingDetails: billingInitialValues,
    unlimitedMembers: true,
  };

  type formValueType = typeof initialValues & { billingDetails: typeof billingInitialValues };

  const handleSubmit = async (
    values: formValueType,
    { setSubmitting }: FormikHelpers<formValueType>,
  ): Promise<boolean> => {
    if (company == null) {
      return false;
    }
    setSubmitting(true);

    const { billingDetails, unlimitedMembers: _, ...rest } = values;

    const result = await flexTenantMutation({
      variables: {
        input: {
          ...rest,
          memberLimit: unlimitedMembers ? null : values.memberLimit,
          defaultWorkplaceManagerEmails: values.defaultWorkplaceManagerEmails?.filter(stringNotEmpty),
          companyUuid: company.uuid,
          locations: flattenLocations(locations).map((l) => ({
            destination: l.destination.uuid,
            buildingLevels: l.levels.map((bl) => bl.uuid),
          })),
        },
      },
      refetchQueries: [
        {
          query: MyFlexTenantsDocument,
          variables: {
            search: null,
            first: 20,
            filters: {
              sites: [],
              buildings: [],
            },
          },
        },
      ],
    });

    if (result.data?.createFlexTenant != null) {
      await billingMutation({
        variables: {
          input: {
            ...billingDetails,
            flexTenantUuid: result.data.createFlexTenant.uuid,
          },
        },
      });
    }
    setCompany(null);
    clearLocations();
    setUnlimitedMembers(true);
    setSubmitting(false);
    setStepCount(1);
    closeModal();
    return true;
  };

  const intro = useMemo(() => {
    switch (stepCount) {
      case 1:
        return t("flex.members.form.titles.addMembership");
      case 2:
        return t("flex.members.form.titles.addBuildings");
      case 3:
        return t("flex.members.form.titles.addCompany");
      default:
        return "";
    }
  }, [stepCount, t]);

  const localSetCompany = (selectedCompany: CompanyFragmentFragment) => {
    setCompany(selectedCompany);
  };

  const removeLocation = useCallback(
    (location: BuildingWithUniqueId) => {
      if (locations.length === 1) {
        setLocations([getEmptyLocation()]);
      } else {
        setLocations((prev) => prev.filter((l) => l.id !== location.id));
      }
    },
    [locations.length, setLocations],
  );

  const hasEmptyLevels = Boolean(locations.find((l) => l.levels.length === 0));

  return (
    <>
      <Modal.Header closeButton={true} backButton={stepCount > 1} />
      <Formik<formValueType> initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ values, errors, isSubmitting, submitForm, touched, isValid, dirty }) => {
          const canTab =
            company != null &&
            values.name.length > 0 &&
            values.monthlyCreditAllowance != null &&
            errors.expirationDate == null;
          const canTab2 = locations.length > 0 && !hasEmptyLevels;
          const isAnotherBuildingDisabled = locations.some((l) => stringIsEmpty(l.uuid) || l.levels.length === 0);

          return (
            <>
              <Modal.Body>
                <Form>
                  <Text variant="label" color={colors.grayscale[60]}>
                    Step {stepCount}
                  </Text>
                  <Text variant="heading" className="mb-4 mt-2">
                    {intro}
                  </Text>
                  <SteppedProgressBar className="mb-4" currentStep={stepCount} steps={3} />
                  <div className={stepCount === 1 ? "" : "disabled"}>
                    <Text variant="heading" size="small" weight="bold" className="mb-2">
                      {t("flex.members.form.generalInformation")}
                    </Text>
                    <EqForm.Group label={t("flex.members.form.company")} required>
                      {company != null ? (
                        <EntitySearch.EntityCard
                          className="selected-member mt-2"
                          buttonVariant="outline"
                          buttonText={t("common.remove")}
                          onButtonClick={() => {
                            setCompany(null);
                            setLocations([]);
                          }}
                          label={company.name}
                        />
                      ) : (
                        <CompanySearchHOC onSelect={localSetCompany} allowCreate={true} />
                      )}
                    </EqForm.Group>
                    <EqForm.Group
                      label={t("flex.members.form.membershipName")}
                      hint={t("common.remainingCharacters", {
                        count: remainingCharacters(200, values.name.length),
                      })}
                      error={touched.name ? errors.name : undefined}
                      required
                    >
                      <Field
                        placeholder={company?.name ?? t("flex.members.form.membershipName")}
                        type="text"
                        name="name"
                        as={EqForm.Input}
                        maxLength={200}
                        autoComplete="off"
                      />
                    </EqForm.Group>
                    <div className="d-flex">
                      <EqForm.Group
                        error={errors.moveInDate}
                        className="mr-4"
                        label={t("flex.members.form.moveInDate")}
                        required
                      >
                        <MembershipDateField name="moveInDate" />
                      </EqForm.Group>
                      <EqForm.Group
                        error={errors.expirationDate}
                        label={t("flex.members.form.expirationDate")}
                        required
                      >
                        <MembershipDateField name="expirationDate" />
                      </EqForm.Group>
                    </div>
                    <EqForm.Group label={t("flex.members.form.teamSize")} required>
                      <div className="mb-2">
                        <input
                          type="radio"
                          id="team-size-all"
                          checked={unlimitedMembers}
                          onChange={() => setUnlimitedMembers(true)}
                        />
                        <label htmlFor="team-size-all" className="mx-3 text-dark">
                          {t("flex.members.form.teamSizeAll")}
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="team-size-limited"
                          checked={!unlimitedMembers}
                          onChange={() => setUnlimitedMembers(false)}
                        />
                        <label htmlFor="team-size-limited" className="mx-3 text-dark">
                          {t("flex.members.form.teamSizeLimited")}
                        </label>
                      </div>
                    </EqForm.Group>
                    {!unlimitedMembers && (
                      <EqForm.Group error={errors.memberLimit} label={t("flex.members.form.teamSize")} required>
                        <Field type="number" name="memberLimit" as={EqForm.Input} />
                      </EqForm.Group>
                    )}
                    <Text variant="heading" size="small" weight="bold" className="mb-2">
                      {t("flex.members.form.creditAllowance")}
                    </Text>
                    <EqForm.Group
                      error={touched.monthlyCreditAllowance ? errors.monthlyCreditAllowance : undefined}
                      label={t("flex.members.form.creditAllowanceMonthly")}
                      required
                    >
                      <Field type="number" name="monthlyCreditAllowance" as={EqForm.Input} />
                    </EqForm.Group>
                  </div>
                  <div className={stepCount === 2 ? "" : "disabled"}>
                    <Text variant="heading" size="small" weight="bold" className="mb-2">
                      {t("flex.members.form.buildings")}
                    </Text>
                    <Text variant="text" size="small">
                      {t("flex.members.form.locationsHelpText")}
                    </Text>
                    <>
                      {locations.map((location, i) => (
                        <EqForm.Group
                          style={{ position: "relative" }}
                          key={location.id}
                          label={t("flex.members.form.location")}
                        >
                          <>
                            {stringNotEmpty(locations[0].uuid) && (
                              <Button
                                variant="ghost"
                                id={`remove-location-${i}`}
                                style={{ position: "absolute", right: 0, top: 0 }}
                                size="sm"
                                onClick={() => removeLocation(location)}
                                round
                              >
                                <RiDeleteBinLine size={16} />
                              </Button>
                            )}
                            <Field
                              placeholder={t("flex.members.form.buildings")}
                              as={LocationSelect}
                              selectedCompanyUuid={company?.uuid}
                              locationIndex={i}
                            />
                          </>
                        </EqForm.Group>
                      ))}
                    </>
                    <Button
                      disabled={!canTab || isAnotherBuildingDisabled}
                      variant="secondary"
                      className="w-100"
                      onClick={() => setLocations((prev) => [...prev, getEmptyLocation()])}
                    >
                      <RiAddLine /> {t("flex.members.form.addBuildingButton")}
                    </Button>
                  </div>
                  <div className={stepCount === 3 ? "" : "disabled"}>
                    {company != null && (
                      <>
                        <Text variant="heading" size="small" weight="bold" className="mb-2">
                          {t("flex.members.form.contactInformation")}
                        </Text>
                        <EqForm.Group label={t("flex.members.form.workplaceManager")} required>
                          <FieldArray
                            name="defaultWorkplaceManagerEmails"
                            render={(arrayHelpers) => (
                              <>
                                {values.defaultWorkplaceManagerEmails?.map((email, i) => (
                                  <EqForm.Group
                                    error={(() => {
                                      const isTouched = getIn(
                                        touched,
                                        `defaultWorkplaceManagerEmails[${i}]`,
                                      ) as boolean;
                                      const err = getIn(errors, `defaultWorkplaceManagerEmails[${i}]`) as string;
                                      return isTouched && err ? err : null;
                                    })()}
                                    key={i}
                                  >
                                    <Field
                                      type="email"
                                      name={`defaultWorkplaceManagerEmails[${i}]`}
                                      as={EqForm.Input}
                                      placeholder={t("common.email")}
                                    />
                                  </EqForm.Group>
                                ))}
                                <Button
                                  variant="secondary"
                                  disabled={errors.defaultWorkplaceManagerEmails != null}
                                  className="w-100 add-wm"
                                  onClick={() => arrayHelpers.push("")}
                                >
                                  <RiAddLine /> {t("common.addAnother")}
                                </Button>
                              </>
                            )}
                          />
                        </EqForm.Group>
                        <Text variant="heading" size="small" weight="bold" className="mb-2">
                          {t("flex.members.form.address")}
                        </Text>
                        <EqForm.Group
                          error={
                            touched.billingDetails?.address?.line1 ? errors.billingDetails?.address?.line1 : undefined
                          }
                          label={t("flex.members.form.address")}
                          required
                        >
                          <Field type="text" name="billingDetails.address.line1" as={EqForm.Input} />
                        </EqForm.Group>
                        <EqForm.Group
                          error={
                            touched.billingDetails?.address?.line2 ? errors.billingDetails?.address?.line2 : undefined
                          }
                          label={t("flex.members.form.address2")}
                        >
                          <Field type="text" name="billingDetails.address.line2" as={EqForm.Input} />
                        </EqForm.Group>
                        <div className="d-flex">
                          <EqForm.Group
                            error={
                              touched.billingDetails?.address?.city ? errors.billingDetails?.address?.city : undefined
                            }
                            className="mr-4"
                            label={t("flex.members.form.city")}
                            required
                          >
                            <Field type="text" name="billingDetails.address.city" as={EqForm.Input} />
                          </EqForm.Group>
                          <EqForm.Group
                            error={
                              touched.billingDetails?.address?.postalCode
                                ? errors.billingDetails?.address?.postalCode
                                : undefined
                            }
                            label={t("flex.members.form.postalCode")}
                            required
                          >
                            <Field type="text" name="billingDetails.address.postalCode" as={EqForm.Input} />
                          </EqForm.Group>
                        </div>
                        <div className="d-flex">
                          <EqForm.Group
                            error={
                              touched.billingDetails?.address?.state ? errors.billingDetails?.address?.state : undefined
                            }
                            className="mr-4"
                            label={t("flex.members.form.state")}
                          >
                            <Field type="text" name="billingDetails.address.state" as={EqForm.Input} />
                          </EqForm.Group>
                          <EqForm.Group
                            error={
                              touched.billingDetails?.address?.country
                                ? errors.billingDetails?.address?.country
                                : undefined
                            }
                            label={t("flex.members.form.country")}
                            required
                          >
                            <Field type="text" name="billingDetails.address.country" as={EqForm.Input} />
                          </EqForm.Group>
                        </div>
                        <Text variant="heading" size="small" weight="bold" className="mb-2">
                          {t("flex.members.form.billingInformation")}
                        </Text>
                        <EqForm.Group
                          error={touched.billingDetails?.email ? errors.billingDetails?.email : undefined}
                          label={t("flex.members.form.contactEmail")}
                          required
                        >
                          <Field type="email" name="billingDetails.email" as={EqForm.Input} />
                        </EqForm.Group>
                        <EqForm.Group
                          error={touched.billingDetails?.contactName ? errors.billingDetails?.contactName : undefined}
                          label={t("flex.members.form.companyBusinessName")}
                          required
                        >
                          <Field type="text" name="billingDetails.contactName" as={EqForm.Input} />
                        </EqForm.Group>
                      </>
                    )}
                  </div>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                {stepCount === 1 ? (
                  <Button onClick={closeModal} variant="ghost">
                    {t("common.cancel")}
                  </Button>
                ) : (
                  <Button onClick={() => setStepCount(stepCount - 1)} variant="ghost">
                    {t("common.back")}
                  </Button>
                )}
                {stepCount === 1 && (
                  <Button className="ml-2" disabled={!canTab || isSubmitting} onClick={() => setStepCount(2)}>
                    {t("flex.members.form.titles.addBuildings")}
                  </Button>
                )}
                {stepCount === 2 && (
                  <Button className="ml-2" disabled={!canTab2 || isSubmitting} onClick={() => setStepCount(3)}>
                    {t("flex.members.form.titles.addCompany")}
                  </Button>
                )}
                {stepCount === 3 && (
                  <Button
                    className="ml-2"
                    disabled={!isValid || isSubmitting || !dirty}
                    onClick={() => {
                      void submitForm();
                    }}
                  >
                    {isSubmitting && <ProgressCircle size="xs" />} {t("flex.members.addButtonTitle")}
                  </Button>
                )}
              </Modal.Footer>
            </>
          );
        }}
      </Formik>
      <style jsx>{`
        .disabled {
          display: none;
        }
      `}</style>
    </>
  );
};
