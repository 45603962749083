import { stringNotEmpty } from "@equiem/lib";
import { parse, serialize } from "cookie";

const lastSiteCookieName = "auth__last_site";

export const setLastSiteCookie = (uuid?: string | null) => {
  if (stringNotEmpty(uuid)) {
    document.cookie = serialize(lastSiteCookieName, uuid, {
      domain: process.env.defaultEquiemOneHost,
      path: "/",
      maxAge: 30 * 24 * 60 * 60,
    });
  }
};

export const getLastSiteUuid = (cookies: string | Partial<Record<string, string>>) => {
  if (typeof cookies !== "string") {
    return cookies[lastSiteCookieName] ?? cookies["auth:last_site"];
  }

  const lastSite = parse(document.cookie)[lastSiteCookieName];
  if (stringNotEmpty(lastSite)) {
    return lastSite;
  }

  // We used to use the cookie name `auth:last_site`, but `:` is not a valid
  // character in cookie names per RFC6265 and `cookie` now bans this. Parse
  // legacy cookies manually.
  //
  // Ref: https://github.com/jshttp/cookie/pull/167
  const legacyLastSite = document.cookie.match(
    /\bauth:last_site=([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/iu,
  );
  return legacyLastSite?.[1];
};
