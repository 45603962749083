import React from "react";

import { formatters, useTranslation } from "@equiem/localisation-eq1";
import { useTheme } from "@equiem/react-admin-ui";
import { stringIsEmpty } from "@equiem/lib";

export const NetPrice = ({
  netPrice,
  discountedNetPrice,
  discount,
  currency,
}: {
  netPrice: number;
  discount?: number | string;
  discountedNetPrice: number;
  currency: string;
}) => {
  const { i18n } = useTranslation();
  const { colors } = useTheme();

  if (discount == null || (typeof discount === "string" && stringIsEmpty(discount))) {
    return (
      <>
        <span className="final-price">{formatters.currency(Number(netPrice), i18n.language, { currency })}</span>
        <style jsx>{`
          .final-price {
            color: ${colors.grayscale[60]};
          }
        `}</style>
      </>
    );
  }

  return (
    <>
      <div className="container">
        <span className="final-price">{formatters.currency(discountedNetPrice, i18n.language, { currency })}</span>

        <span className="discount-price">{formatters.currency(Number(netPrice), i18n.language, { currency })}</span>
      </div>
      <style jsx>{`
        .container {
          display: flex;
          gap: 4px;
        }

        .final-price {
          color: ${colors.grayscale[60]};
        }

        .discount-price {
          color: ${colors.grayscale[40]};
          text-decoration: line-through;
        }
      `}</style>
    </>
  );
};
