import { Array, Literal, Null, Number, Record, String } from "runtypes";

export const profile = Record({
  uuid: String,
  firstName: String,
  lastName: String,
  avatar: String.nullable(),
});

const stringValue = Record({
  __typename: Literal("ActivityChangeStringValue"),
  value: String.nullable(),
});

const numberValue = Record({
  __typename: Literal("ActivityChangeIntegerValue"),
  integer: Number.nullable(),
});

const dateTimeValue = Record({
  __typename: Literal("ActivityChangeDateTimeValue"),
  dateTime: Number.nullable(),
});

const profileValue = Record({
  __typename: Literal("ActivityChangeProfileValue"),
  profile,
});

const buildingLevelValue = Record({
  __typename: Literal("ActivityChangeBuildingLevelValue"),
  buildingLevel: Record({
    uuid: String,
    name: String,
    building: Record({
      uuid: String,
      name: String,
      destination: Record({
        uuid: String,
        name: String,
      }),
    }),
  }),
});

const flexTenantValue = Record({
  __typename: Literal("ActivityChangeFlexTenantValue"),
  flexTenant: Record({
    uuid: String,
  }),
});

const profileSetValue = Record({
  __typename: Literal("ActivitySetProfileValue"),
  profile,
});

export const comment = Record({
  comment: String,
  audience: String,
});

export const profileUpdated = Record({
  change: Record({
    field: String,
    from: profileValue.nullable(),
    to: profileValue.nullable(),
  }),
});

export const profileSet = Record({
  change: Record({
    field: String,
    to: profileSetValue,
  }),
});

export const profilesUpdated = Record({
  change: Record({
    field: String,
    fromValues: Array(profileValue),
    toValues: Array(profileValue),
  }),
});

export const buildingLevelsUpdated = Record({
  change: Record({
    field: String,
    fromValues: Array(buildingLevelValue),
    toValues: Array(buildingLevelValue),
  }),
});

export const stringCreated = Record({
  change: Record({
    field: String,
    from: Null,
    to: stringValue.extend({
      value: String,
    }),
  }),
});

export const stringUpdated = Record({
  change: Record({
    field: String,
    from: stringValue.extend({
      value: String,
    }),
    to: stringValue.extend({
      value: String,
    }),
  }),
});

export const stringsUpdated = Record({
  change: Record({
    field: String,
    fromValues: Array(
      stringValue.extend({
        value: String,
      }),
    ),
    toValues: Array(
      stringValue.extend({
        value: String,
      }),
    ),
  }),
});

export const stringDeleted = Record({
  change: Record({
    field: String,
    from: stringValue.extend({
      value: String,
    }),
    to: Null,
  }),
});

export const numberCreated = Record({
  change: Record({
    field: String,
    from: Null,
    to: numberValue.extend({
      integer: Number,
    }),
  }),
});

export const numberUpdated = Record({
  change: Record({
    field: String,
    from: numberValue.extend({
      integer: Number,
    }),
    to: numberValue.extend({
      integer: Number,
    }),
  }),
});

export const numberDeleted = Record({
  change: Record({
    field: String,
    from: numberValue.extend({
      integer: Number,
    }),
    to: Null,
  }),
});

export const dateTimeCreated = Record({
  change: Record({
    field: String,
    from: Null,
    to: dateTimeValue.extend({
      dateTime: Number,
    }),
  }),
});

export const dateTimeUpdated = Record({
  change: Record({
    field: String,
    from: dateTimeValue.extend({
      dateTime: Number,
    }),
    to: dateTimeValue.extend({
      dateTime: Number,
    }),
  }),
});

export const dateTimeDeleted = Record({
  change: Record({
    field: String,
    from: dateTimeValue.extend({
      dateTime: Number,
    }),
    to: Null,
  }),
});

export const attachmentsUpdated = Record({
  change: Record({
    field: Literal("attachments"),
    from: Record({ attachments: Record({ key: String }) }),
    to: Record({ attachments: Record({ key: String }) }),
  }),
});

export const attachmentsRemoved = Record({
  change: Record({
    field: Literal("deletedAttachments"),
    from: Record({ attachments: Record({ key: String }) }),
    to: Record({ attachments: Record({ key: String }) }),
  }),
});

export const flexTenantCreated = Record({
  change: Record({
    field: Literal("flexTenant"),
    from: Null,
    to: flexTenantValue,
  }),
});
