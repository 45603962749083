import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useRouter } from "next/router";
import { DateTime } from "luxon";

import { useDisabledSiteSwitcher, useQueryState } from "@equiem/lib";
import { useApolloErrorTranslation, useTranslation } from "@equiem/localisation-eq1";
import { Button, EmptyState, ProgressCircle, Text, useDebounced, useTheme } from "@equiem/react-admin-ui";

import { BookingsTab } from "../../components/BookingsTab";
import { ScrollBottomObserverWrapper } from "../../components/ScrollBottomObserverWrapper";
import { withContexts } from "../../contexts/withContexts";
import { BookingSortOrder, useMyBookingCountsQuery } from "../../generated/gateway-client";
import { useBookingFilters } from "../../hooks/useBookingFilters";
import { usePagedMyBookings } from "../../hooks/usePagedMyBookings";
import { BookingsView } from "../../models/BookingsView";
import { BookingViewModal } from "../operations/components/BookingViewModal";
import { BookingModal } from "../operations/contexts/BookingModalContext";

import { CalendarsCheckFillIcon } from "./components/icons/CalendarsCheckFillIcon";
import { MyBookingCard, MyBookingCardLoading } from "./components/MyBookingCard";
import type { Tab } from "./components/MyBookingsFilterTabs";
import { defaultTab, isTab, MyBookingsFilterTabs, tabFilters } from "./components/MyBookingsFilterTabs";

const DEBOUNCE_MS = 100;
const REFRESH_INTERVAL_MS = 5000;

const EmptyMyBookings = () => {
  const { t } = useTranslation();
  const { colors, spacers } = useTheme();
  const router = useRouter();

  const browseCatalogue = useCallback(async () => router.push("/bookings"), [router]);

  return (
    <>
      <div className="empty-my-bookings-icon">
        <CalendarsCheckFillIcon size="24" color={colors.transparent.black[40]} />
      </div>
      <div>
        <Text variant="text" size="small" color={colors.transparent.black[40]} className="my-0 text-center">
          {t("bookings.operations.noBookingsYet")}
        </Text>
        <Text variant="text" size="small" color={colors.transparent.black[40]} className="my-0 text-center">
          {t("bookings.operations.getStartedByBrowsingCatalogue")}
        </Text>
      </div>
      <Button
        variant="secondary"
        size="lg"
        onClick={() => {
          browseCatalogue().catch(console.log);
        }}
      >
        {t("bookings.operations.browse")}
      </Button>
      <style jsx>{`
        .empty-my-bookings-icon {
          display: flex;
          padding: ${spacers.s5};
          justify-content: center;
          align-items: center;
          border-radius: 40px;
          background: ${colors.transparent.black[5]};
        }
      `}</style>
    </>
  );
};

// eslint-disable-next-line complexity
const MyBookingsLocal = () => {
  const { tError } = useApolloErrorTranslation();
  const { breakpoints, spacers } = useTheme();
  const [currentTime, setCurrentTime] = useState(Date.now());

  const [{ tab }, setQueryState] = useQueryState({
    initial: { tab: defaultTab },
    parse: { tab: (f) => (isTab(f) ? f : defaultTab) },
    clearQueryOnChange: true,
    rememberLastState: true,
  });

  useDisabledSiteSwitcher();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(Date.now());
    }, REFRESH_INTERVAL_MS);

    return () => clearInterval(intervalId);
  }, []);

  // 1000 years is basically forever, and we need this to be a valid timestamp
  // for GraphQL/Postgres
  const infinitelyFarPast = useMemo(() => DateTime.now().minus({ years: 1000 }).toMillis(), []);
  const infinitelyFarFuture = useMemo(() => DateTime.now().plus({ years: 1000 }).toMillis(), []);

  const {
    filtersLoading,
    filterOptions,
    filterValues,
    filters: { startDate, endDate, filters },
    onFilterChange,
  } = useBookingFilters(BookingsView.MY_BOOKINGS_GRID, "myBookingsGrid", tabFilters[tab]?.filters?.cancelled, {
    date: tabFilters[tab]?.after ?? infinitelyFarPast,
    endDate: tabFilters[tab]?.before ?? infinitelyFarFuture,
  });
  const complexFilters = useMemo(
    () => ({
      loading: filtersLoading,
      items: filterOptions,
      initialValues: filterValues,
      setValues: onFilterChange,
    }),
    [filterValues, filtersLoading, filterOptions, onFilterChange],
  );

  const { bookings, error, searchTextRaw, setSearchTextRaw, searchText, loading, loadingMore, hasMoreData, fetchMore } =
    usePagedMyBookings({
      after:
        startDate != null || tabFilters[tab]?.after != null
          ? Math.max(startDate ?? infinitelyFarPast, tabFilters[tab]?.after ?? infinitelyFarPast)
          : undefined,
      before:
        endDate != null || tabFilters[tab]?.before != null
          ? Math.min(endDate ?? infinitelyFarFuture, tabFilters[tab]?.before ?? infinitelyFarFuture)
          : undefined,
      filters: { ...filters, cancelled: tabFilters[tab]?.filters?.cancelled },
      sort: BookingSortOrder.StartdateDescending,
    });

  const counts = useMyBookingCountsQuery({
    variables: {
      after: startDate,
      before: endDate,
      filters: { ...filters, searchText },
    },
    fetchPolicy: "cache-and-network",
  });

  const loadingMoreDebounced = useDebounced(loadingMore, DEBOUNCE_MS);

  const onBottomVisible = () => {
    if (hasMoreData && !loading) {
      fetchMore();
    }
  };

  // Open/close booking side modal based on query param.
  const router = useRouter();
  const modal = useContext(BookingModal);
  useEffect(() => {
    if (typeof router.query.booking === "string") {
      if (modal.id !== router.query.booking) {
        modal.setDisplayMode("view");
        modal.open(router.query.booking);
      }
    } else if (modal.id != null) {
      modal.close();
    }
  }, [modal, router]);

  const handleTabFilterChange = (newTab: Tab) => {
    setQueryState({ tab: newTab });
  };

  return (
    <BookingsTab
      title={
        <MyBookingsFilterTabs
          onTabChanged={handleTabFilterChange}
          activeTab={tab}
          countLoading={counts.loading}
          totalCount={counts.data?.myBookingCounts.all}
          upcomingCount={counts.data?.myBookingCounts.upcoming}
          pastCount={counts.data?.myBookingCounts.past}
          cancelledCount={counts.data?.myBookingCounts.cancelled}
        />
      }
      search={{ searchText: searchTextRaw, setSearchText: setSearchTextRaw }}
      filters={complexFilters}
    >
      <ScrollBottomObserverWrapper onBottomVisible={onBottomVisible}>
        <InfiniteScroll
          next={fetchMore}
          dataLength={bookings.length}
          hasMore={hasMoreData}
          style={{ overflow: undefined }}
          loader={null}
        >
          {loading || error != null || bookings.length > 0 ? (
            <div className="listing">
              {error != null && <div>{tError(error)}</div>}
              {loading && Array.from({ length: 6 }).map((_, index) => <MyBookingCardLoading key={index} />)}
              {!loading &&
                error == null &&
                bookings.map((booking) => (
                  <MyBookingCard key={booking.reference} {...booking} currentTime={currentTime} />
                ))}
            </div>
          ) : (
            <div className="empty-listing">
              {loadingMoreDebounced ? (
                <ProgressCircle size="lg" />
              ) : bookings.length > 0 ? (
                <EmptyState />
              ) : (
                <EmptyMyBookings />
              )}
            </div>
          )}
        </InfiniteScroll>
      </ScrollBottomObserverWrapper>
      <BookingViewModal />
      <style jsx>{`
        .listing {
          display: grid;
          padding: ${spacers.s4} ${spacers.s5} ${spacers.s7};
          grid-gap: ${spacers.s7} ${spacers.s5};
          opacity: ${loadingMoreDebounced ? "0.5" : "1"};
        }
        .empty-listing {
          display: flex;
          flex-direction: column;
          gap: ${spacers.s5};
          padding: ${spacers.s7};
          width: 100%;
          align-items: center;
          align-self: center;
        }
        @media screen and (min-width: ${breakpoints.sm}px) {
          .listing {
            padding: ${spacers.s4} ${spacers.s7} ${spacers.s7};
          }
        }
        @media screen and (min-width: ${breakpoints.md}px) {
          .listing {
            grid-template-columns: repeat(auto-fill, minmax(307px, 1fr));
          }
        }
      `}</style>
    </BookingsTab>
  );
};

export const MyBookings = withContexts(MyBookingsLocal);
