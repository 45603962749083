import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import type { FormikHelpers } from "formik";
import { Field, Formik } from "formik";
import * as yup from "yup";

import { Modal as ModalContext } from "../../../../contexts/ModalContext";

import { notNullOrUndefined, useAllCompanies, useSaferFormikContext, useShowError } from "@equiem/lib";
import type { TFunction } from "@equiem/localisation-eq1";
import { useApolloErrorTranslation, useTranslation } from "@equiem/localisation-eq1";
import {
  Button,
  Modal,
  ProgressCircle,
  useTheme,
  useToast,
  Form as EqForm,
  useConfirmer,
} from "@equiem/react-admin-ui";
import { CreditAccountType, useCreditAccountFullCreateMutation } from "../../../../generated/gateway-client";
import type { ApolloError } from "@apollo/client";
import isEqual from "lodash/isEqual";
import { RiInformationLine } from "@equiem/react-admin-ui/icons";
import { useCompaniesCreditsContext } from "../../context/CompaniesCreditsContext";

export type FormValues = {
  companyUuid?: string;
  recurringCreditAllowance?: number;
  // whoCanUseCredits: { uuid: string; label: string; visible: boolean };
  groups: string[];
};

const MAX_SAFE_INTEGER_32 = Math.pow(2, 31) - 1;

// const useGetWhoCanUseCredits = () => {
//   const { t } = useTranslation();

//   return [
//     { uuid: v4(), label: t("credits.companiesCredits.whoCanUseCreditsLabels.allUsers"), visible: true },
//     { uuid: v4(), label: t("credits.companiesCredits.whoCanUseCreditsLabels.selectedGroups"), visible: false },
//   ].filter((item) => item.visible);
// };

const getValidationSchema = (t: TFunction) =>
  yup.object().shape<Partial<{ [key in keyof FormValues]: yup.AnySchema }>>({
    companyUuid: yup.string().required(t("common.validation.required", { path: t("common.company") })),
    recurringCreditAllowance: yup
      .number()
      .min(1, t("common.validation.numberMin", { path: t("credits.companiesCredits.monthlyCredits"), min: 1 }))
      .required(t("common.validation.required", { path: t("credits.companiesCredits.monthlyCredits") }))
      .max(MAX_SAFE_INTEGER_32 / 100),
  });

const AddCompaniesCreditsForm = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const { setFieldValue, values, initialValues, touched, resetForm, isValid, errors, submitForm, isSubmitting } =
    useSaferFormikContext<FormValues>();
  const modal = useContext(ModalContext);
  const { spacers, colors } = useTheme();
  const [search, setSearch] = useState<string | undefined>(undefined);
  const { companies } = useAllCompanies({ search });
  const { companiesCredits } = useCompaniesCreditsContext();
  const { withConfirmation } = useConfirmer();
  const { tError } = useApolloErrorTranslation();
  const showError = useShowError();
  const toast = useToast();
  const companiesCreditsUuids = useMemo(
    () => companiesCredits.data.map(({ creditCustomer: { company } }) => company?.uuid).filter(notNullOrUndefined),
    [companiesCredits.data],
  );
  const filteredCompanies = useMemo(
    () => companies.filter(({ value }) => !companiesCreditsUuids.includes(value)),
    [companiesCreditsUuids, companies],
  );

  const onClose = useCallback(() => {
    setShowModal(false);
    resetForm();
    modal.close();
  }, [modal]);

  const onCloseModal = useCallback(() => {
    const isFormChanged = isEqual(initialValues, values);

    if (isFormChanged) {
      onClose();
      return;
    }

    withConfirmation({
      title: t("common.areYouSure"),
      message: t("common.cancelMessage"),
      confirmButtonText: t("common.yes"),
      confirmButtonVariant: "danger",
      onConfirm: onClose,
    })();
  }, [onClose, initialValues, values]);

  const onSumbit = async () => {
    try {
      await submitForm();
      onClose();
      toast.positive(
        <div className="d-flex align-items-center">
          <RiInformationLine size={20} className="mr-2" />
          {t("common.changesSaved")}
        </div>,
      );
    } catch (e: unknown) {
      showError(e);
      console.error(e);
      toast.negative(tError(e as ApolloError));
    }
  };

  useEffect(() => {
    if (modal.activeModal === "AddCompaniesCredits") {
      setShowModal(true);
    }
  }, [modal.activeModal]);

  // TODO: Uncomment when two options will be available
  // const whoCanUseCredits = useGetWhoCanUseCredits();

  return (
    <>
      <Modal.Dialog
        title={t("credits.companiesCredits.addCompaniesCredits")}
        show={showModal}
        sideModal
        onHide={onCloseModal}
        hideOnEsc
        hideOnClick
        size="md"
        containerClassName="companies-credits-modal"
      >
        <Modal.Header closeButton noBorder={false} onClose={onCloseModal} />
        <Modal.Body>
          <EqForm.Group
            label={t("common.company")}
            error={touched.companyUuid === true ? errors.companyUuid : undefined}
            required
          >
            <EqForm.DynamicSelect
              search
              searchCb={(item) => {
                setSearch(item);
              }}
              value={values.companyUuid as string}
              searchPlaceholder={t("common.search")}
              mobileView="minimal"
              name="company"
              options={filteredCompanies}
              noneLabel={<span style={{ color: colors.grayscale[20] }}>{t("common.selectCompany")}</span>}
              onChange={(e) => {
                setFieldValue("companyUuid", e.target.value).catch(console.error);
              }}
            />
          </EqForm.Group>
          <EqForm.Group
            label={t("credits.companiesCredits.monthlyCredits")}
            tooltipText={t("credits.companiesCredits.tooltip.creditsPerMonth")}
            showTooltip
            error={touched.recurringCreditAllowance === true ? errors.recurringCreditAllowance : undefined}
            required
          >
            <Field
              id="recurringCreditAllowance"
              name="recurringCreditAllowance"
              as={EqForm.Input}
              type="number"
              placeholder={t("credits.companiesCredits.monthlyCreditsPlaceholder")}
            />
          </EqForm.Group>
          {/* TODO: Uncomment when two options will be available */}
          {/* <EqForm.Group
            label={t("credits.companiesCredits.whoCanUseCredits")}
            tooltipText={t("credits.companiesCredits.tooltip.whoCanUseCredits")}
            showTooltip
            error={touched.whoCanUseCredits != null ? (errors.whoCanUseCredits as string) : undefined}
            required
          >
            <div className="d-flex flex-column">
              {whoCanUseCredits.map((item) => (
                <Field
                  key={item.uuid}
                  id={item.uuid}
                  name="whoCanUseCredits"
                  value={whoCanUseCredits[0] === item} // TODO: Update when selected groups are implemented
                  label={item.label}
                  component={EqForm.RadioButton}
                  checked={whoCanUseCredits[0] === item}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.currentTarget.checked) {
                      setFieldValue("whoCanUseCredits", item).catch(console.error);
                    }
                  }}
                />
              ))}
            </div>
          </EqForm.Group> */}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" disabled={isSubmitting} className="w-100 mr-4" onClick={onCloseModal}>
            {t("common.cancel")}
          </Button>
          <Button
            disabled={!isValid || isSubmitting || Object.keys(touched).length === 0}
            className="w-100"
            type="submit"
            variant="primary"
            onClick={() => {
              void onSumbit();
            }}
          >
            {isSubmitting ? <ProgressCircle size="xs" /> : t("common.saveChanges")}
          </Button>
        </Modal.Footer>
      </Modal.Dialog>

      <style jsx>{`
        :global(.companies-credits-modal .title-row) {
          margin: 1.25rem 0 1.25rem !important;
        }

        .title {
          font-weight: 400;
          font-size: 18px;
          line-height: 28px;
        }

        .content {
          padding: 0 ${spacers.s6} 0;
        }

        hr {
          border: none;
          border-top: 1px solid ${colors.border};
          margin-bottom: 16px;
        }
      `}</style>
    </>
  );
};

export const AddCompaniesCredits = () => {
  // const whoCanUseCredits = useGetWhoCanUseCredits();
  const initialValues: FormValues = useMemo(
    () => ({
      companyUuid: undefined,
      recurringCreditAllowance: 0,
      // whoCanUseCredits: whoCanUseCredits[0],
      groups: [],
    }),
    [],
  );
  const { t } = useTranslation();

  const [creditAccountFullCreate] = useCreditAccountFullCreateMutation({
    refetchQueries: ["GetMyManagedCreditAccounts"],
  });

  const handleSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const { companyUuid, recurringCreditAllowance } = values;

    setSubmitting(true);

    if (companyUuid == null || recurringCreditAllowance == null) {
      setSubmitting(false);
      return;
    }

    await creditAccountFullCreate({
      variables: {
        input: {
          companyUuid,
          recurringCreditAllowance: recurringCreditAllowance * 100,
          type: CreditAccountType.RecurringWithPurchase,
        },
      },
    });

    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={getValidationSchema(t)}
      onSubmit={handleSubmit}
    >
      <AddCompaniesCreditsForm />
    </Formik>
  );
};
