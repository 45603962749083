import { useContext, useMemo } from "react";
import { CurrentRole, useSiteContext } from "../context";
import { Role } from "../Role";
import { useQueuesListQuery } from "../generated/gateway-client";

export function useRequestEnabled() {
  const { currentRole } = useContext(CurrentRole);
  const site = useSiteContext();
  const { data: queuesForUserData } = useQueuesListQuery({
    fetchPolicy: "cache-and-network",
  });
  const isRequestManagerFromQueue = useMemo(
    () => queuesForUserData?.reqMgt.queues.some((queue) => queue.viewerRelations.requestManager) ?? false,
    [queuesForUserData],
  );
  const isRequestAssigneeFromQueue = useMemo(
    () => queuesForUserData?.reqMgt.queues.some((queue) => queue.viewerRelations.requestAssignee) ?? false,
    [queuesForUserData],
  );

  // module is disabled so noone should see it
  if (!site.featureModules.requests.enabled) {
    return false;
  }

  // request managers and assignees should see it if the modules is enabled
  if (isRequestManagerFromQueue || isRequestAssigneeFromQueue) {
    return true;
  }

  // propety managers and flex managers should see it if the modules is enabled
  if ([Role.FlexManager, Role.PropertyManager].includes(currentRole)) {
    return true;
  }

  // for the rest types of users we need to check if the extra feature is enabled
  if (!site.featureMenus.requestsEnabled) {
    return false;
  }

  return true;
}
